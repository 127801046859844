import { withStyles } from '@mui/styles';
import React, { useState } from 'react';
import colors from '../../../../../src/utils/colors';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';

import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LanguageIcon from "@mui/icons-material/Language";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import BadgeIcon from "@mui/icons-material/Badge";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Card from "@mui/material/Card"; 
import Collapse from "@mui/material/Collapse"; 
import CardHeader from "@mui/material/CardHeader"; 
import Container from "@mui/material/Container"; 
import CardContent from "@mui/material/CardContent"; 
import KeyboardArrowDownIcon from  
    "@mui/icons-material/KeyboardArrowDown"; 
import KeyboardArrowUpIcon from  
    "@mui/icons-material/KeyboardArrowUp"; 
import IconButton from "@mui/material/IconButton"; 
import classNames from 'classnames';


const data = {  
  "name": "Montiel Michael",
  "specialty": "Physician Assistant",
  "qualification": "Bachelor of Science",
  "language": "English, Spanish, French",
  "gender": "Male",
  "npiCode": 1700257714,
  "location": [
    {
        "addressLine": "160 Broadway FI 16-New York-NY-10038",
        "distance": '2.3 miles',
        "latitude": 40.7094,
        "longitude": -74.0101
    },
    // {
    //     "addressLine": "160 Broadway FI 16-New York-NY-10038",
    //     "distance": '2.3 miles',
    //     "latitude": 40.7094,
    //     "longitude": -74.0101
    // },
    // {
    //     "addressLine": "160 Broadway FI 16-New York-NY-10038",
    //     "distance": '2.3 miles',
    //     "latitude": 40.7094,
    //     "longitude": -74.0101
    // }
  ]
};


const styles = (theme) => ({
    parentContainer:{
        marginRight: theme.spacing(3),
    },
    container: {
    padding: 0,

    },
    card:{
        width:'100%',
        border: '1px solid #E4E7EC',
        borderRadius: '12px'
    },    
    

    locationContainer: {
    backgroundColor: colors.WHITE,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    width: '100',
    border:`0.5px solid rgba(110, 110, 110, 0.3)`,
    display:'flex',
    alignItems:'center',
    marginTop: `${theme.spacing(2)}`,

    // boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',

    },
      text:{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        color: colors.BLACK_B500,
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
      },
      address: {
        fontSize: 16,
        color: 'rgb(102, 112, 133)',
      },
      label: {
        fontSize: 16,
        fontWeight: 500,
        display:'flex',
        alignItems:'center',
        margin: `${theme.spacing(1)} 0px`,
        color:'rgb(102, 112, 133)',
      },
      heading: {
        fontWeight: 600,
        fontSize: 18,
      },
      link: {
        fontSize: 15,
        color: colors.BLUE,
        textDecoration: 'none',
        display:'flex',
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'center'
      },
      linkIcon: {
        alignSelf:'center',
        fill:'rgb(102, 112, 133)',
        fontSize: 15,
        fill: colors.BLUE,
        color: colors.BLUE,
        marginLeft: theme.spacing(1),
      },
      distance: {
        color: 'rgb(102, 112, 133)',
      },
      distanceTitle: {
        fontWeight: 500,
        display:'flex',
        margin: `${theme.spacing(1)} 0px`,
      },
      name:{
        fontWeight: 500,
        fontSize:20,
      },
      nameContainer:{
        marginBottom:theme.spacing(2),
        display:'flex',
        alignItems:'center'
      },
      avatarContainer:{
        width:40,
        height:40,
        backgroundColor:colors.RED,
        borderRadius: 100,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        marginRight:theme.spacing(2),
      },
      avatar:{
        color: colors.WHITE,
        fontSize: '100%',
      },
      img:{
        alignSelf:'center',
        // height: 26,
        // width: 26,
        fontSize:'1.5rem',
        marginRight: theme.spacing(1),
        fill:'rgb(102, 112, 133)',
      },
      locationHeading:{
        marginTop: `${theme.spacing(2)}`,
      }
  });



const ProviderDetails =({classes, ...props})=>{
    const [open, setOpen] = useState(false); 

    function getInitials(fullName) {
        const names = fullName.split(' ');
      
        if (names.length >= 2) {
          const firstNameInitial = names[0].charAt(0).toUpperCase();
          const lastNameInitial = names[names.length - 1].charAt(0).toUpperCase();
          return `${firstNameInitial}${lastNameInitial}`;
        } else if (names.length === 1) {

            return names[0].charAt(0).toUpperCase();
        } else {

            return '';
        }
      }

  return(
    <Box className={classes.parentContainer}>

        <Card className={classes.card}> 
          <a onClick={() => setOpen(!open)} >
            <CardHeader 
              titleTypographyProps={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  color: colors.BLACK_B500,
                  fontWeight: 600,
                  fontSize: 18,
                  lineHeight: '24px',
              }}
                  title="Click here to view your PCP information"
                  // className={clsx([classes.text, classes.heading])}
                  action={ 
                      <IconButton 
                          onClick={() => setOpen(!open)} 
                          aria-label="expand"
                          size="small"
                      > 
                          {open ? <KeyboardArrowUpIcon /> 
                              : <KeyboardArrowDownIcon />} 
                      </IconButton> 
                  } 
              ></CardHeader> 
          </a>

            <div style={{
            }}> 
                <Collapse in={open} timeout="auto"
                    unmountOnExit> 
                    <CardContent> 
                        <Container sx={{  
                            lineHeight: 2  
                        }}
                        className={classes.container}
                        > 
                            <Box className={classes.nameContainer}>
                                <Box className={classes.avatarContainer}>
                                    <Typography variant="h6" className={clsx([classes.text, clsx([classes.text, classes.avatar])])}>
                                        {getInitials(data.name)}
                                    </Typography>
                                </Box>
                                
                                <Typography variant="h6" className={clsx([classes.text, clsx([classes.text, classes.name])])}>
                                    {data.name}
                                </Typography>
                            </Box>
                            <Typography variant="h6" className={clsx([classes.text, clsx([classes.text, classes.label])])}>
                                <MedicalServicesIcon className={classes.img} /> {data.specialty}
                            </Typography>
                            <Typography variant="h6" className={clsx([classes.text, classes.label])}>
                                <VerifiedUserIcon className={classes.img} /> {data.qualification}
                            </Typography>
                            <Typography variant="h6" className={clsx([classes.text, classes.label])}>
                                <LanguageIcon className={classes.img} /> {data.language}
                            </Typography>
                            <Typography variant="h6" className={clsx([classes.text, classes.label])}>
                                <PersonIcon className={classes.img} /> {data.gender}
                            </Typography>
                            <Typography variant="h6" className={clsx([classes.text, classes.label])}>
                                <BadgeIcon className={classes.img} /> {data.npiCode}
                            </Typography>


                            <Typography variant="h6" className={clsx([classes.text, classes.heading, classes.locationHeading])}>
                                {'Locations'}
                            </Typography>

                            {data?.location?.map((item)=>(
                                <Box className={classes.locationContainer}>
                                    <LocationOnIcon className={classes.img} />

                                    <Box>
                                        <Typography variant="h6" className={clsx([classes.text, classes.address])}>
                                            {item?.addressLine}
                                        </Typography>

                                        <Typography variant="h6" className={clsx([classes.text, classes.distanceTitle])}>
                                            Distance:&nbsp; <Typography variant="h6" className={clsx([classes.text, classes.distance])}>{`${  item?.distance}` }</Typography>
                                        </Typography>
                                        <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/?q=${item?.latitude},${item?.longitude}`} className={classes.link} >
                                            {'View Map  '}
                                            <OpenInNewIcon className={`${classes.linkIcon}`} />
                                        </a>
                                    </Box>

                                </Box>
                            ))
                            }
                        </Container> 
                    </CardContent> 
                </Collapse> 
            </div> 
        </Card> 

      {/* <Box className={classes.container}>
      </Box> */}
  </Box>
  );
};

export default withStyles(styles, { withTheme: true })(ProviderDetails);

