import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import { CODE_FRAGMENT, ALLERGY_FRAGMENT } from '../utils/fragments';

const FETCH_ALL_ALLERGIES_QUERY = gql`
  query fetchAllAllergies(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllAllergies(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      allergyList
      count
    }
  }
`;
const FETCH_ALL_ALLERGIES_BY_CONSENT = gql`
  query fetchAllAllergiesByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllAllergiesByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      allergyList
      count
    }
  }
`;

const FETCH_ALLERGY_DETAILS_BY_ID_QUERY = gql`
  query fetchAllergyById($allergyId: String) {
    fetchAllergyById(allergyId: $allergyId) {
      allergy
      docArn
      documentReferenceId
    }
  }
`;
const FETCH_ALLERGY_DETAILS_BY_ID_AND_GRANTOR_ID = gql`
  query fetchAllergyByIdAndGrantorId($allergyId: String, $grantorId: String) {
    fetchAllergyByIdAndGrantorId(allergyId: $allergyId, grantorId: $grantorId) {
      allergy
      docArn
      documentReferenceId
    }
  }
`;

const FETCH_RELATED_ALLERGIES = gql`
  query fetchRelatedAllergies($relatedResourceId: String, $relatedResourceType: String) {
    fetchRelatedAllergy(resourceId: $relatedResourceId, resourceType: $relatedResourceType) {
      id
      type
      verificationStatus {
        ...code
      }
      code {
        ...code
      }
      criticality
      category
      recordedDate
    }
  }
  ${(CODE_FRAGMENT, CODE_FRAGMENT)}
`;

const SAVE_ALLERGY_SELF_REPORTING = gql`
  mutation createAllergySelfReportData($data: AddAllergySelfReportData) {
    createAllergySelfReportData(data: $data) {
      ...allergy
    }
  }
  ${ALLERGY_FRAGMENT}
`;

const EDIT_ALLERGY_SELF_REPORTING = gql`
  mutation editAllergySelfReportData($data: AddAllergySelfReportData, $allergyId: String) {
    editAllergySelfReportData(data: $data, allergyId: $allergyId) {
      ...allergy
    }
  }
  ${ALLERGY_FRAGMENT}
`;

const FETCH_HISTORICAL_RECORDS = gql`
  query fetchHistoricalAllergyIntolerance(
    $allergyIntoleranceCode: String = ""
    $count: String = "10"
    $offset: Int = 0
    $dateFilter: Float = 0
  ) {
    fetchHistoricalAllergyIntolerance(
      allergyIntoleranceCode: $allergyIntoleranceCode
      count: $count
      offset: $offset
      dateFilter: $dateFilter
    ) {
      allergyList
      count
    }
  }
`;

const MARK_RESTRICTED = gql`
  mutation updateMarkAsRestrictedAllergyIntolerance($allergyId: String, $markAsRestricted: String) {
    updateMarkAsRestrictedAllergyIntolerance(
      allergyId: $allergyId
      markAsRestricted: $markAsRestricted
    ) {
      ...allergy
    }
  }
  ${ALLERGY_FRAGMENT}
`;

const AllergiesService = {
  fetchAllAllergies(variables, query = FETCH_ALL_ALLERGIES_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllAllergies);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllAllergiesByConsent(args, query = FETCH_ALL_ALLERGIES_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllAllergiesByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAllergyById(id, query = FETCH_ALLERGY_DETAILS_BY_ID_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            allergyId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllergyById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllergyByIdAndGrantorId(
    allergyId,
    grantorId,
    query = FETCH_ALLERGY_DETAILS_BY_ID_AND_GRANTOR_ID,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            allergyId,
            grantorId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllergyByIdAndGrantorId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRelatedAllergies(args, query = FETCH_RELATED_ALLERGIES) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: args,
        })
        .then((response) => {
          resolve(response.data.fetchRelatedAllergy);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createAllergySelfReportData(data, mutation = SAVE_ALLERGY_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
          },
        })
        .then((response) => {
          resolve(response.data.createAllergySelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateAllergySelfReportData(data, allergyId, mutation = EDIT_ALLERGY_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
            allergyId,
          },
        })
        .then((response) => {
          resolve(response.data.editAllergySelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchHistoricalRecords(args, query = FETCH_HISTORICAL_RECORDS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchHistoricalAllergyIntolerance);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateMarkAsRestrictedAllergyIntolerance(
    allergyId,
    markAsRestricted,
    mutation = MARK_RESTRICTED,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            allergyId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.updateMarkAsRestrictedAllergyIntolerance);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default AllergiesService;
