import { Grid, Typography, Box } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import {
  isPropertyPresentInArrayOfObjects,
  getEquivalentObjectFromContext,
} from '../../../../utils/helper';
import { DrawerBoxComponent } from './DrawerBoxComponent';
import FormComponent from './FormComponent';
// import FormComponent from '../FormComponent/FormComponent';
// import { APPOINTMENTS } from '../../shared/Strings';

const boxContainer = {
  width: '100%',
  maxWidth: '125px',
  height: '40px',
  border: '1px solid black',
  borderRadius: '8px',
  mt: 1,
  mb: 3,
  mr: 1,
  cursor: 'pointer',
};
const typo = {
  display: 'flex',
  justifyContent: 'center',
  mt: 1,
};

const typocolor = {
  color: '#036FCB',
  display: 'flex',
  justifyContent: 'center',
  mt: 1,
  borderColor: '#036FCB',
};
const deactivateColor = {
  color: '#d0d0d0',
  display: 'flex',
  justifyContent: 'center',
  mt: 1,
  borderColor: '#d0d0d0',
};
const normal = {
  color: '#555',
  display: 'flex',
  justifyContent: 'center',
  mt: 1,
  borderColor: '#555',
};

const dateArray = [
  {
    placeholder: 'Select Date',
    title: 'Choose Date',
    type: 'date',
    name: 'date',
    helperText: null,
    helperImage: false,
    helperImageText: null,
    helperImageUrl: null,
  },
];
const memberValues = {
  date: '',
};
export const AppointmentReshedule = ({ getFinalPopUpData, timeslot }) => {
  const [memberFormValues, setMemberFormValues] = useState(memberValues);
  const [morning, setMorning] = useState(timeslot?.morning);
  const [afternoon, setAfternoon] = useState(timeslot?.afternoon);
  const [evening, setEvening] = useState(timeslot?.evening);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [dateAndTime, setDateAndTime] = useState({});

  useEffect(() => {
    if (dateAndTime.date !== undefined || dateAndTime.startTime !== undefined) {
      getFinalPopUpData(dateAndTime);
      setDateAndTime({
        slotId: undefined,
        date: undefined,
        startTime: undefined,
        endTime: undefined,
      });
    }
  }, [dateAndTime, getFinalPopUpData]);

  useEffect(() => {
    setDateAndTime({
      slotId: selectedValue?.id,
      date: selectedDate,
      startTime: selectedValue?.startTime,
      endTime: selectedValue?.endTime,
    });
  }, [selectedValue, selectedDate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedDate(value);
    setMemberFormValues({ ...memberFormValues, [name]: value });
  };
  const getInputFieldArrayForUserIdentification = (list) => {
    return list
      .filter((item) => {
        return isPropertyPresentInArrayOfObjects(item.name, dateArray);
      })
      .map((item) => {
        const correspondingObject = getEquivalentObjectFromContext(item.name, dateArray);
        for (let prop in correspondingObject) {
          if (correspondingObject[prop] !== null) {
            item[prop] = correspondingObject[prop];
          }
        }
        return item;
      });
  };

  const setMemberValuesForUserValidation = useCallback(() => {
    const result = {};
    const arr = getInputFieldArrayForUserIdentification(dateArray);
    for (let value in memberValues) {
      if (isPropertyPresentInArrayOfObjects(value, arr)) {
        result[value] = '';
      }
    }
    setMemberFormValues(result);
  }, []);

  useEffect(() => {
    setMemberValuesForUserValidation();
  }, [setMemberValuesForUserValidation]);

  const inputFieldArray = [
    {
      value: memberFormValues.date,
      onChange: handleChange,
      name: 'date',
    },
  ];

  const handleSelectTime = (id, index, timeShift = '') => {
    if (
      morning[index]?.status === 'BUSY' ||
      afternoon[index]?.status === 'BUSY' ||
      evening[index]?.status === 'BUSY'
    ) {
      return;
    }
    if (timeShift === 'morning') {
      const newMorningTimes = morning?.map((obj, i) => {
        return {
          ...obj,
          status: obj.id === id ? '' : obj.status === 'BUSY' ? obj.status : 'FREE',
        };
      });
      const newAfternoonTime = afternoon?.map((obj) => {
        return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
      });
      const newEveningTime = evening?.map((obj, i) => {
        return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
      });
      setSelectedValue({ ...morning[index], index: index });
      setEvening(newEveningTime);
      setAfternoon(newAfternoonTime);
      setMorning(newMorningTimes);
    } else if (timeShift === 'afternoon') {
      const newAfternoonTime = afternoon?.map((obj) => {
        return {
          ...obj,
          status: obj.id === id ? '' : obj.status === 'BUSY' ? obj.status : 'FREE',
        };
      });

      const newMorningTimes = morning?.map((obj, i) => {
        return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
      });
      const newEveningTime = evening?.map((obj, i) => {
        return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
      });
      setSelectedValue({ ...afternoon[index], index: index });
      setEvening(newEveningTime);
      setMorning(newMorningTimes);
      setAfternoon(newAfternoonTime);
    } else if (timeShift === 'evening') {
      const newEveningTime = evening?.map((obj, i) => {
        return {
          ...obj,
          status: obj.id === id ? '' : obj.status === 'BUSY' ? obj.status : 'FREE',
        };
      });
      const newAfternoonTime = afternoon?.map((obj) => {
        return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
      });
      const newMorningTimes = morning?.map((obj, i) => {
        return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
      });
      setSelectedValue({ ...evening[index], index: index });
      setMorning(newMorningTimes);
      setAfternoon(newAfternoonTime);
      setEvening(newEveningTime);
    }
  };

  const timeSlote = JSON.parse(localStorage.getItem('time'));

  useEffect(() => {
    if (timeSlote) {
      setSelectedDate(timeSlote.date);
      setMemberFormValues({ ...memberFormValues, date: timeSlote.date });
      if (timeSlote.timeStatus >= 9 && timeSlote.timeStatus < 12) {
        const newMorningTimes = morning?.map((obj, i) => {
          return {
            ...obj,
            status: obj.id === timeSlote.id ? '' : obj.status,
          };
        });
        const newAfternoonTime = afternoon?.map((obj) => {
          return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
        });
        const newEveningTime = evening?.map((obj, i) => {
          return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
        });

        setSelectedValue(morning[timeSlote.index]);
        setEvening(newEveningTime);
        setAfternoon(newAfternoonTime);
        setMorning(newMorningTimes);
      } else if (timeSlote.timeStatus >= 12 && timeSlote.timeStatus < 17) {
        const newAfternoonTime = afternoon?.map((obj) => {
          return {
            ...obj,
            status: obj.id === timeSlote.id ? '' : obj.status,
          };
        });

        const newMorningTimes = morning?.map((obj, i) => {
          return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
        });
        const newEveningTime = evening?.map((obj, i) => {
          return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
        });
        setSelectedValue(afternoon[timeSlote.index]);
        setEvening(newEveningTime);
        setMorning(newMorningTimes);
        setAfternoon(newAfternoonTime);
      } else if (timeSlote.timeStatus >= 17 && timeSlote.timeStatus < 21) {
        const newEveningTime = evening?.map((obj, i) => {
          return {
            ...obj,
            status: obj.id === timeSlote.id ? '' : obj.status,
          };
        });
        const newAfternoonTime = afternoon?.map((obj) => {
          return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
        });
        const newMorningTimes = morning?.map((obj, i) => {
          return { ...obj, status: obj.status === '' ? 'FREE' : obj.status };
        });
        setSelectedValue(evening[timeSlote.index]);
        setMorning(newMorningTimes);
        setAfternoon(newAfternoonTime);
        setEvening(newEveningTime);
      }
    }

    return () => {
      localStorage.removeItem('time');
    };
  }, [afternoon, evening, morning, timeSlote]);

  const getTimeFormate = (time) => {
    const newTime = time?.slice(0, 5);
    const H = newTime.slice(0, 2);
    const h = H % 12 || 12;
    const timeformatted = h + newTime.substr(2, 3);
    const amPm = time?.slice(8);
    return `${timeformatted} ${amPm}`;
  };

  return (
    <DrawerBoxComponent>
      <Grid container spacing={2} alignItems="center">
        <Grid item sx={{ mb: 1 }}>
          <Typography variant="h3">Pick a date & Time</Typography>
        </Grid>
      </Grid>
      <Grid item sx={{ mb: 2 }}>
        <Box sx={{ width: '100%', maxWidth: '476px', pr: 2 }}>
          <FormComponent
            maxDate={true}
            inputFieldArray={getInputFieldArrayForUserIdentification(inputFieldArray)}
          ></FormComponent>
        </Box>
      </Grid>
      {!!morning?.length && (
        <Grid item>
          <Typography variant="h5">Morning</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row', flexWrap: 'wrap' },
            }}
          >
            {morning?.map((obj, index) => {
              return (
                <Box
                  key={index}
                  sx={[
                    boxContainer,
                    obj.status === 'FREE'
                      ? normal
                      : obj.status === 'BUSY'
                      ? deactivateColor
                      : typocolor,
                  ]}
                  onClick={(e) => handleSelectTime(obj.id, index, 'morning')}
                >
                  <Typography variant="h5" sx={typo}>
                    {`${getTimeFormate(obj.startTime)}`}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
      )}
      {!!afternoon?.length && (
        <Grid item>
          <Typography variant="h5">Afternoon</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row', flexWrap: 'wrap' },
            }}
          >
            {afternoon?.map((obj, index) => (
              <Box
                key={index}
                sx={[
                  boxContainer,
                  obj.status === 'FREE'
                    ? normal
                    : obj.status === 'BUSY'
                    ? deactivateColor
                    : typocolor,
                ]}
                onClick={(e) => handleSelectTime(obj.id, index, 'afternoon')}
              >
                <Typography variant="h5" sx={typo}>
                  {`${getTimeFormate(obj.startTime)}`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      )}
      {!!evening?.length && (
        <Grid item>
          <Typography variant="h5">Evening</Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row', flexWrap: 'wrap' },
            }}
          >
            {evening?.map((obj, index) => (
              <Box
                key={index}
                sx={[
                  boxContainer,
                  obj.status === 'FREE'
                    ? normal
                    : obj.status === 'BUSY'
                    ? deactivateColor
                    : typocolor,
                ]}
                onClick={(e) => handleSelectTime(obj.id, index, 'evening')}
              >
                <Typography variant="h5" sx={typo}>
                  {`${getTimeFormate(obj.startTime)}`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      )}
    </DrawerBoxComponent>
  );
};
