import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { PHONE_VERIFICATION_TEXTS } from '../../../utils/constants';
import TextField from '../../atoms/TextField';
import TextButton from '../../atoms/TextButton';
import ApprovedIcon from '../../../assets/images/approved.svg';
import { replace } from '../../../utils/helper';
import colors from '../../../utils/colors';

const style = (theme) => ({
  root: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '336px',
    height: '439px',
  },
  title: {
    paddingTop: '61px',
    paddingBottom: '35px',
    fontSize: '20px',
    lineHeight: 1.8,
    letterSpacing: '1.41px',
    textAlign: 'center',
  },
  contentRoot: {
    padding: '8px 31px',
  },
  body: {
    fontSize: '15px',
    letterSpacing: '1.06px',
    fontWeight: 300,
    textAlign: 'center',
    marginBottom: '24px',
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  noOtpMessageContainer: {
    marginTop: '19px',
    display: 'flex',
    alignItems: 'center',
  },
  notReceiveText: {
    ...theme.customComponent.body1RegularBlack,
  },
  textField: {
    width: '100%',
    paddingBottom: '30px',
  },
  verifiedText: {
    fontSize: '20px',
    letterSpacing: '1.41px',
    textAlign: 'center',
    marginBottom: '26px',
  },
  verifiedImg: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '32px',
  },
  blueColor: {
    color: colors.BLUE,
  }
});

const OTPVerificationDialog = (props) => {
  const {
    classes,
    open,
    completeProfileNudge,
    phoneNo,
    onVerify,
    onClose,
    otp,
    onOTPChange,
    phoneVerified,
    otpError,
    title,
    phoneVerifiedText,
    onValidation,
    handleRetry,
  } = props;

  const handleTextCharge = (name, value) => {
    onOTPChange(value);
  };

  const [openDialog, setOpenDialog] = useState(open);
  useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      if (completeProfileNudge) {
        return;
      }
      setOpenDialog();
      onClose();
    }
    onClose();
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      PaperProps={{
        className: classes.root,
      }}
    >
      {!phoneVerified && (
        <DialogTitle classes={{ root: classes.title }} disableTypography>
          {title}
        </DialogTitle>
      )}
      {phoneVerified ? (
        <DialogContent classes={{ root: classes.contentRoot }}>
          <img src={ApprovedIcon} alt="Approved Icon" className={classes.verifiedImg} />
          <Typography className={classes.verifiedText}>{phoneVerifiedText}</Typography>
        </DialogContent>
      ) : (
        <DialogContent classes={{ root: classes.contentRoot }}>
          <Typography className={classes.body}>
          {PHONE_VERIFICATION_TEXTS.BODY} < span className={classes.blueColor}> {phoneNo}</span>
          </Typography>
          <TextField
            name="otp"
            color="yellow"
            value={otp}
            onFieldChange={handleTextCharge}
            error={otpError.status}
            errorMsg={otpError.msg}
            onBlur={onValidation}
            label={'Security code'}
            variant="standard"
            textFieldClass={classes.textField}
          />
          <div className={classes.noOtpMessageContainer}>
            <Typography className={classes.notReceiveText}>
              {PHONE_VERIFICATION_TEXTS.NO_RECEIVE}
            </Typography>
            <TextButton
              text={PHONE_VERIFICATION_TEXTS.BTN_TEXT_RESEND}
              color="yellow"
              onClick={handleRetry}
            />
          </div>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          color="yellow"
          sx={{
            color: '#fff',
            '&:hover': { backgroundColor: '#FFBC44' },
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
          }}
          variant="contained"
          size="small"
          onClick={phoneVerified ? onClose : onVerify}
        >
          {PHONE_VERIFICATION_TEXTS.VERIFY}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(style, { withTheme: true })(OTPVerificationDialog);
