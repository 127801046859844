import gql from 'graphql-tag';

export const LOGIN_RESPONSE = gql`
  fragment loginResponse on LoginResponse {
    access_token
    expires_in
    refresh_expires_in
    refresh_token
    token_type
    session_state
    scope
    statusCode
    errorMessage
    memberEnabled
  }
`;

export const CODE_FRAGMENT = gql`
  fragment code on Coding {
    coding {
      system
      code
      display
    }
    text
  }
`;
export const ADDRESS_INFO = gql`
  fragment userAddress on AddressInfo {
    lines
    city
    state
    postalCode
    country
  }
`;
export const FETCH_PARTNER_PATIENT_FRAGMENT = gql`
  fragment patientDetails on PartnerPatientInfo {
    requestId
    gender
    dateofBirth
    email
    phone
    firstName
    lastName
    maritalStatus
    circleId
    partnerPatientId
    patientId
    userId
    address {
      ...userAddress
    }
  }
  ${ADDRESS_INFO}
`;

export const REFERENCE_FRAGMENT = gql`
  fragment reference on Reference {
    reference
    display
  }
`;

export const PERFORMER_FRAGMENT = gql`
  fragment performer on Performer {
    actor {
      ...reference
    }
  }
  ${REFERENCE_FRAGMENT}
`;

export const PERIOD_FRAGMENT = gql`
  fragment period on Period {
    start
    end
  }
`;

export const EXTENSION_FRAGMENT = gql`
  fragment extension on Extension {
    url
    valueReference {
      reference
      display
    }
    valueString
  }
`;

export const REACTION_FRAGMENT = gql`
  fragment reaction on Reaction {
    substance {
      ...code
    }
    manifestation {
      ...code
    }
    exposureRoute {
      ...code
    }
    description
  }
`;

export const IDENTIFIER_FRAGMENT = gql`
  fragment identifier on Identifier {
    system
    value
    use
  }
`;
export const CLAIM_CONDITION_FRAGMENT = gql`
  fragment claimCondition on ClaimConditionNew {
    text
    coding {
      system
      code
      display
    }
  }
`;
export const CLAIM_PROCEDURE_FRAGMENT = gql`
  fragment claimProcedure on ClaimProcedureNew {
    coding {
      system
      code
      display
    }
    text
  }
`;

export const QUANTITY_FRAGMENT = gql`
  fragment quantity on Quantity {
    value
    code
    unit
  }
`;
export const RANGE_FRAGMENT = gql`
  fragment range on Range {
    low {
      ...quantity
    }
    high {
      ...quantity
    }
  }
  ${QUANTITY_FRAGMENT}
`;
export const RATIO_FRAGMENT = gql`
  fragment ratio on Ratio {
    numerator {
      ...quantity
    }
    denominator {
      ...quantity
    }
  }
  ${QUANTITY_FRAGMENT}
`;
export const SAMPLED_DATA_FRAGMENT = gql`
  fragment sampleData on SampledData {
    origin
    period
    factor
    lowerLimit
    upperLimit
    dimensions
    data
  }
  ${QUANTITY_FRAGMENT}
`;

export const VISIT_TYPES = gql`
  fragment visits on CodingFHIR {
    display
    code
  }
`;

export const COST_TO_BENEFICIARY_FRAGMENT = gql`
  fragment costToBeneficiary on CostToBeneficiary {
    type {
      ...code
    }
    valueQuantity {
      ...quantity
    }
  }
  ${QUANTITY_FRAGMENT}
  ${CODE_FRAGMENT}
`;

export const CLASS_TYPE_FRAGMENT = gql`
  fragment classType on ClassType {
    type {
      ...code
    }
    value
    name
  }
  ${CODE_FRAGMENT}
`;

export const EOB_COST_FRAGMENT = gql`
  fragment cost on ClaimCost {
    patientAmount
    paymentByMedicare
    patientShareBreakdown {
      institutionalCost
      pharmacyCost
      professionalCost
      visionCost
      hhaCost
      dmeCost
      oralCost
    }
  }
`;

export const SERIES_DOSE_FRAGMENT = gql`
  fragment seriesDoses on SeriesDoses {
    seriesDosesPositiveInt
    seriesDosesString
  }
`;

export const DOSE_NUMBER_FRAGMENT = gql`
  fragment doseNumber on DoseNumber {
    doseNumberPositiveInt
    doseNumberString
  }
`;

export const DOSAGE_FRAGMENT = gql`
  fragment dosage on Dosage {
    timing {
      repeat {
        boundsPeriod {
          ...period
        }
        frequency
        period
        timeOfDay
      }
      code {
        ...code
      }
    }
    asNeededBoolean
    route {
      ...code
    }
    text
    method {
      ...code
    }
    doseAndRate {
      doseQuantity {
        ...quantity
      }
    }
    extension {
      ...extension
    }
  }
  ${PERIOD_FRAGMENT}
  ${CODE_FRAGMENT}
  ${QUANTITY_FRAGMENT}
  ${EXTENSION_FRAGMENT}
`;

export const EOB_SERVICE_FRAGMENT = gql`
  fragment serviceList on Service {
    date
    display
    quantity {
      value
      unit
      code
    }
    cost {
      ...cost
    }
  }
  ${EOB_COST_FRAGMENT}
`;

export const OBSERVATION_VALUE_FRAGMENT = gql`
  fragment observationValue on Value {
    valueQuantity {
      ...quantity
    }
    valueCodeableConcept {
      ...code
    }
    valueString
    valueBoolean
  }
  ${CODE_FRAGMENT}
  ${QUANTITY_FRAGMENT}
`;

export const EFFECTIVE_DATE_FRAGMENT = gql`
  fragment effectiveDate on EffectiveDate {
    effectiveDateTime
    effectivePeriod {
      ...period
    }
  }
  ${PERIOD_FRAGMENT}
`;

export const LAB_RESULTS_MEDIA_FRAGMENT = gql`
  fragment labResultsMedia on DiagnosticReportMedia {
    comment
    link {
      ...reference
    }
  }
  ${REFERENCE_FRAGMENT}
`;

export const CONDITIONS_FRAGMENT = gql`
  fragment condition on Condition {
    subject {
      ...reference
    }
    id
    onsetDateTime
    clinicalStatus {
      ...code
    }
    verificationStatus {
      ...code
    }
    encounter {
      ...reference
    }
    abatementDateTime
    asserter {
      ...reference
    }
    severity {
      ...code
    }
    bodySite {
      ...code
    }
    code {
      ...code
    }
    note {
      text
    }
    stage {
      summary {
        ...code
      }
      assessment {
        ...reference
      }
      type {
        ...code
      }
    }
    category {
      ...code
    }
    recordedDate
    recorder {
      ...reference
    }
    identifier {
      ...identifier
    }
  }
  ${CODE_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${IDENTIFIER_FRAGMENT}
`;

export const PROCEDURES_FRAGMENT = gql`
  fragment procedure on Procedure {
    id
    bodySite {
      ...code
    }
    reasonCode {
      ...code
    }
    followUp {
      ...code
    }
    category {
      ...code
    }
    status
    subject {
      ...reference
    }
    location {
      ...reference
    }
    outcome {
      ...code
    }
    note {
      text
    }
    complication {
      ...code
    }
    performer {
      ...performer
      onBehalfOf {
        ...reference
      }
    }
    performedPeriod {
      ...period
    }
    performedDateTime
    performedString
    recorder {
      ...reference
    }
    code {
      ...code
    }
    identifier {
      ...identifier
    }
    extension {
      ...extension
    }
  }
  ${CODE_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${PERFORMER_FRAGMENT}
  ${PERIOD_FRAGMENT}
  ${IDENTIFIER_FRAGMENT}
  ${EXTENSION_FRAGMENT}
`;

export const IMMUNIZATIONS_FRAGMENT = gql`
  fragment immunization on Immunization {
    id
    vaccineCode {
      ...code
    }
    status
    statusReason {
      ...code
    }
    identifier {
      ...identifier
    }
    encounter {
      ...reference
    }
    occurrenceDateTime
    occurenceString
    site {
      ...code
    }
    route {
      ...code
    }
    lotNumber
    expirationDate
    note {
      text
    }
    reasonCode {
      ...code
    }
    recorded
    doseNumber {
      ...doseNumber
    }
    seriesDoses {
      ...seriesDoses
    }
    reaction {
      detail {
        ...reference
      }
    }
    manufacturer {
      ...reference
    }
    location {
      ...reference
    }
    performer {
      ...performer
    }
    doseQuantity {
      ...quantity
    }
    protocolApplied {
      seriesDosesPositiveInt
      doseNumberPositiveInt
      series
    }
    extension {
      ...extension
    }
  }
  ${CODE_FRAGMENT}
  ${IDENTIFIER_FRAGMENT}
  ${DOSE_NUMBER_FRAGMENT}
  ${SERIES_DOSE_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${PERFORMER_FRAGMENT}
  ${QUANTITY_FRAGMENT}
  ${EXTENSION_FRAGMENT}
`;

export const ALLERGY_FRAGMENT = gql`
  fragment allergy on Allergy {
    id
    identifier {
      ...identifier
    }
    type
    encounter {
      ...reference
    }
    verificationStatus {
      ...code
    }
    code {
      ...code
    }
    clinicalStatus {
      ...code
    }
    criticality
    recordedDate
    category
    note {
      text
    }
    facility {
      ...reference
    }
    recorder {
      ...reference
    }
    reaction {
      ...reaction
    }
    extension {
      ...extension
    }
  }
  ${IDENTIFIER_FRAGMENT}
  ${CODE_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${REACTION_FRAGMENT}
  ${EXTENSION_FRAGMENT}
`;

export const MEDICATION_REQUEST_FRAGMENT = gql`
  fragment medicationRequest on MedicationRequest {
    id
    status
    requester {
      ...reference
    }
    performer {
      ...reference
    }
    note {
      text
    }
    dosageInstruction {
      ...dosage
    }
    authoredOn
    encounter {
      ...reference
    }
    medicationCodeableConcept {
      ...code
    }
    identifier {
      ...identifier
    }
    medicationReference {
      ...reference
    }
    dispenseRequest {
      quantity {
        ...quantity
      }
    }
    recorder {
      ...reference
    }
    extension {
      ...extension
    }
  }
  ${IDENTIFIER_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${DOSAGE_FRAGMENT}
  ${CODE_FRAGMENT}
  ${QUANTITY_FRAGMENT}
  ${EXTENSION_FRAGMENT}
`;

export const MEDICATION_STATEMENT_FRAGMENT = gql`
  fragment medicationStatement on MedicationStatement {
    id
    status
    subject {
      ...reference
    }
    informationSource {
      ...reference
    }
    note {
      text
    }
    context {
      ...reference
    }
    dosage {
      ...dosage
      extension {
        ...extension
        valueQuantity {
          unit
          value
        }
      }
    }
    medicationCodeableConcept {
      ...code
    }
    identifier {
      ...identifier
    }
    medicationReference {
      ...reference
    }
    extension {
      ...extension
    }
    effectiveDateTime
    effectivePeriod {
      ...period
    }
  }
  ${IDENTIFIER_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${DOSAGE_FRAGMENT}
  ${CODE_FRAGMENT}
  ${EXTENSION_FRAGMENT}
  ${PERIOD_FRAGMENT}
`;

export const MEDICATION_DISPENSE_FRAGMENT = gql`
  fragment medicationDispense on MedicationDispense {
    id
    status
    subject {
      ...reference
    }
    performer {
      ...performer
    }
    receiver {
      ...reference
    }
    dosageInstruction {
      ...dosage
    }
    note {
      text
    }
    whenPrepared
    whenHandedOver
    location {
      ...reference
    }
    medicationCodeableConcept {
      ...code
    }
    identifier {
      ...identifier
    }
    medicationReference {
      ...reference
    }
    extension {
      ...extension
    }
  }
  ${IDENTIFIER_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${PERFORMER_FRAGMENT}
  ${DOSAGE_FRAGMENT}
  ${CODE_FRAGMENT}
  ${EXTENSION_FRAGMENT}
`;

export const LAB_RESULTS_FRAGMENT = gql`
  fragment labResults on DiagnosticReport {
    status
    subject {
      ...reference
    }
    id
    encounter {
      ...reference
    }
    category {
      ...code
    }
    identifier {
      ...identifier
    }
    code {
      ...code
    }
    effectiveDateTime
    issued
    conclusion
    resultsInterpreter {
      ...reference
    }
    media {
      ...labResultsMedia
    }
    performer {
      ...reference
    }
    result {
      ...reference
    }
    extension {
      ...extension
    }
  }
  ${REFERENCE_FRAGMENT}
  ${CODE_FRAGMENT}
  ${IDENTIFIER_FRAGMENT}
  ${LAB_RESULTS_MEDIA_FRAGMENT}
  ${EXTENSION_FRAGMENT}
`;

export const COVERAGE_FRAGMENT = gql`
  fragment coverage on Coverage {
    id
    extension {
      ...extension
    }
    status
    policyHolder {
      ...reference
    }
    period {
      ...period
    }
    beneficiary {
      ...reference
    }
    identifier {
      ...identifier
    }
    subscriber {
      ...reference
    }
    payor {
      ...reference
    }
    type {
      ...code
    }
    relationship {
      ...code
    }
    subscriberId
    class {
      ...classType
    }
    costToBeneficiary {
      ...costToBeneficiary
    }
  }
  ${COST_TO_BENEFICIARY_FRAGMENT}
  ${CLASS_TYPE_FRAGMENT}
  ${CODE_FRAGMENT}
  ${IDENTIFIER_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${PERIOD_FRAGMENT}
  ${EXTENSION_FRAGMENT}
`;

export const DOCUMENT_FRAGMENT = gql`
  fragment document on DocumentReference {
    identifier {
      ...identifier
    }
    status
    docStatus
    type {
      ...code
    }
    category {
      ...code
    }
    event {
      ...code
    }
    subject {
      ...reference
    }
    encounter {
      ...reference
    }
    facilityType {
      ...code
    }
    practiceSetting {
      ...code
    }
  }
  ${IDENTIFIER_FRAGMENT}
  ${CODE_FRAGMENT}
  ${REFERENCE_FRAGMENT}
`;

export const GRANTOR_FRAGMENT = gql`
  fragment grantor on Grantor {
    id
    reference
    name
    avatarUrl
    relationship
    age
    email
    phoneNumber
    gender
    maritalStatus
    bloodGroup
    ethnicity
    race
    address
    dob
  }
`;
export const GRANTEE_FRAGMENT = gql`
  fragment grantee on Grantee {
    id
    name
    email
    phoneNumber
    reference
    granteeType
    avatarUrl
  }
`;

export const GRANTEE_CONTENT_FRAGMENT = gql`
  fragment granteeContent on GranteeContent {
    consentId
    grantedDate
    grantor {
      ...grantor
    }
    grantee {
      ...grantee
    }
    status
    scope
    grantExpiryDate
    mphContentClassIds
    declineMessage
    legalGuardianOrHealthcarePOA
  }
  ${GRANTOR_FRAGMENT}
  ${GRANTEE_FRAGMENT}
`;

export const GRANTOR_CONTENT_FRAGMENT = gql`
  fragment grantorContent on GrantorContent {
    consentId
    grantedDate
    grantee {
      ...grantee
    }
    grantor {
      ...grantor
    }
    status
    scope
    grantExpiryDate
    mphContentClassIds
    declineMessage
    initiator
  }
  ${GRANTEE_FRAGMENT}
  ${GRANTOR_FRAGMENT}
`;
export const SORT_FRAGMENT = gql`
  fragment sort on Sort {
    sorted
    unsorted
    empty
  }
`;
export const PAGEABLE_FRAGMENT = gql`
  fragment pageable on Pageable {
    sort {
      ...sort
    }
    offset
    pageNumber
    pageSize
    paged
    unpaged
  }
  ${SORT_FRAGMENT}
`;

export const FETCH_BY_GRANTEE_CONSENT_LIST = gql`
  fragment granteeResponse on GranteeResponse {
    content {
      ...granteeContent
    }
    pageable {
      ...pageable
    }
    last
    totalElements
    totalPages
    size
    sort {
      ...sort
    }
    first
    numberOfElements
    number
    empty
  }
  ${GRANTEE_CONTENT_FRAGMENT}
  ${PAGEABLE_FRAGMENT}
  ${SORT_FRAGMENT}
`;

export const COMMUNICATION_FRAGMENT = gql`
  fragment communication on Communication {
    preferred
    language {
      ...code
    }
  }
  ${CODE_FRAGMENT}
`;

export const RELATED_PERSON_FRAGMENT = gql`
  fragment relatedPerson on RelatedPerson {
    name
    relationship
    active
    email
    phone
    dob
    avatarUrl
  }
`;
export const FETCH_BY_GRANTEE_CONTENT_LIST = gql`
  fragment granteeContent on GranteeContent {
    consentId
    grantedDate
    grantee {
      ...grantee
    }
    grantor {
      ...grantor
    }
    status
    scope
    grantExpiryDate
    mphContentClassIds
    legalGuardianOrHealthcarePOA
  }
  ${GRANTEE_FRAGMENT}
  ${GRANTOR_FRAGMENT}
`;
export const FETCH_BY_GRANTOR_CONSENT_LIST = gql`
  fragment grantorResponse on GrantorResponse {
    content {
      ...grantorContent
    }
    pageable {
      ...pageable
    }
    last
    totalElements
    totalPages
    size
    sort {
      ...sort
    }
    first
    numberOfElements
    number
    empty
  }
  ${GRANTOR_CONTENT_FRAGMENT}
  ${PAGEABLE_FRAGMENT}
  ${SORT_FRAGMENT}
`;

export const CONSENT_ID_RESPONSE_FRAGMENT = gql`
  fragment consentIdResponse on ConsentIdResponse {
    initiator
    status
    scope
    categories
    grantor {
      ...grantor
    }
    grantorState
    grantedDate
    grantExpiryDate
    grantee {
      ...grantee
    }
  }
  ${GRANTOR_FRAGMENT}
  ${GRANTEE_FRAGMENT}
`;

export const CONSENT_CREATE_RESPONSE_FRAGMENT = gql`
  fragment consentCreateResponse on ConsentCreateResponse {
    id
    createdOn
    createdBy
    lastUpdatedOn
    lastUpdatedBy
    smileConsentId
    smileConsentUrl
    consent {
      ...consentIdResponse
    }
  }
  ${CONSENT_ID_RESPONSE_FRAGMENT}
`;
export const MONEY_FRAGMENT = gql`
  fragment money on Money {
    value
    currency
  }
`;

export const CLAIM_FRAGMENT = gql`
  fragment claim on Claim {
    id
    claimNumber
    claimType
    status
    item {
      unitPrice {
        ...money
      }
    }
    provider {
      ...reference
    }
    created
    claimProviderNumber
    claimFromDate
    claimToDate
    serviceList {
      ...serviceList
    }
    condition {
      extension {
        ...extension
      }
      coding {
        ...code
      }
    }
    procedure {
      extension {
        ...extension
      }
      coding {
        ...code
      }
    }
    identifier {
      ...identifier
    }
    claimAmountMedicare
    claimAmount
  }
  ${REFERENCE_FRAGMENT}
  ${IDENTIFIER_FRAGMENT}
  ${EXTENSION_FRAGMENT}
  ${CODE_FRAGMENT}
  ${EOB_SERVICE_FRAGMENT}
  ${MONEY_FRAGMENT}
`;

//Assessment fragments starts from here

export const ASSESSMENT_META_FRAGMENT = gql`
  fragment assessmentMeta on AssessmentMeta {
    assessmentName
    status
    orgImageURL
    orgName
    numberOfQuestions
    assignedDate
    expiryDate
    completedDate
  }
`;

export const ANSWER_FRAGMENT = gql`
  fragment answer on Answer {
    answerIds
    answerValue
  }
`;

export const QUESTION_RESPONSE_NON_RECURSIVE_FRAGMENT = gql`
  fragment questionTestResponse on QuestionResponse {
    title
    answerType
    id
    type
    answers {
      text
      id
      answered
      answerPlaceHolder
      answeredValue
    }
    mandatory
  }
  `;
  
  export const QUESTION_RESPONSE_FRAGMENT = gql`
  fragment questionResponse on QuestionResponse {
    title
    answerType
    id
    type
    answers {
      text
      id
      question {
        ...questionTestResponse
      }
      answered
      assessmentGroup {
        title
        questions {
          ...questionTestResponse
        }
      }
      answerPlaceHolder
      answeredValue
    }
    mandatory
  }
  ${QUESTION_RESPONSE_NON_RECURSIVE_FRAGMENT}
  `;

export const ASSESSMENT_GROUP_FRAGMENT = gql`
  fragment assessmentGroup on AssessmentGroup {
    title
    questions {
      ...questionResponse
    }
  }
  ${QUESTION_RESPONSE_FRAGMENT}
`;

export const ANSWER_RESPONSE_FRAGMENT = gql`
  fragment answerResponse on AnswerResponse {
    text
    id
    question {
      ...questionResponse
    }
    answered
    assessmentGroup {
      ...assessmentGroup
    }
    answerPlaceHolder
    answeredValue
  }
  ${QUESTION_RESPONSE_FRAGMENT}
  ${ASSESSMENT_GROUP_FRAGMENT}
`;

export const ASSESSMENT_RESPONSE_BODY_FRAGMENT = gql`
  fragment assessmentResponseBody on AssessmentResponseBody {
    assessmentQuestionnaires {
      title
      objectType
      answerType
      id
      type
      answers {
        ...answerResponse
      }
      mandatory
      submittedAnswer {
        ...answerResponse
      }
      questions {
        ...questionResponse
      }
    }
    answeredQuestionsCount
    lastAnsweredIndex
  }
  ${ANSWER_RESPONSE_FRAGMENT}
  ${QUESTION_RESPONSE_FRAGMENT}
`;

// End of assessments fragments
