import React, { useState, useEffect } from 'react';
import { Container, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import clsx from 'clsx';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import colors from '../../../../utils/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    maxWidth: '100%',
    '& .MuiFormControl-root': {
      boxSizing: 'border-box',
      height: theme.spacing(5),
    }
  },
  selectLabel: {
    fontSize: theme.spacing(1.5),
    fontWeight: 500,
    marginBottom: theme.spacing(0.5),
    lineHeight: theme.spacing(2),
    height: theme.spacing(2),
  },
  selectField: {
    '& .MuiOutlinedInput-input': {
      color: colors.PRIMARY_GRAY,
      fontFamily: 'Inter',
      fontSize: theme.spacing(2),
      fontWeight: 500,
      opacity: 0.87,
      display: 'flex',
      alignContent: 'center',
      height: theme.spacing(5),
      boxSizing: 'border-box',
    },
    '& .MuiSelect-iconOutlined': {
      right: theme.spacing(1.5),
      color: colors.PRIMARY_GRAY,
    },
  },
  menuItemHidden: {
    display: 'none',
  },
  dropDownMenuList: {
    fontSize: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    boxSizing: 'border-box',
    '& .MuiMenuItem-root': {
      padding: theme.spacing(1.5),
    },
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 230,
  },
  placeholder: {
    fontWeight: 400,
    fontSize: theme.spacing(2),
    color: colors.GRAY_FORD,
  },
  selectDisabled: {
    letterSpacing: 0,
    height: theme.spacing(5),
    boxSizing: 'border-box',
    '& .MuiOutlinedInput-input': {
      color: colors.GRAY_FORD,
      fontFamily: 'Inter',
      fontSize: theme.spacing(2),
      fontWeight: 400,
      display: 'flex',
      alignContent: 'center',
      opacity: 0.87,
    },
    '& .MuiSelect-iconOutlined': {
      right: theme.spacing(1.5),
      color: colors.TITLE_GRAY,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.BLUE}`, 
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.SUBTLE_GRAY}`,
    },
  },
  img: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    marginRight: theme.spacing(1)
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: theme.spacing(3),
    boxSizing: 'border-box'
  },
  required: {
    color: colors.RED,
    fontSize: theme.spacing(2),
    fontWeight: 500,
    marginLeft: -theme.spacing(0.25)
  },
  errorStyle: {
    color: colors.RED,
    margin: theme.spacing(0),
    fontSize: theme.spacing(1.5),
    marginTop: 3,
    textAlign: 'left',
    fontFamily: 'Inter',
    fontWeight: 500,
    lineHeight: 1.66,
    letterSpacing: 0.52,
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
  },
}));

const SelfReportingSelect = (props) => {
  const {
    variant,
    fullWidth = true,
    color,
    label,
    placeholder,
    valueKey: name,
    options,
    changeHandler,
    value,
    required = false,
    labelClass,
    error,
    helperText,
    onClose,
  } = props;

  const classes = useStyles();
  const [selectedVal, setSelectedVal] = useState(value || 'none');
  const [showPlaceholder, setShowPlaceholder] = useState(selectedVal === 'none');

  const handleOnChange = (val) => {
    setSelectedVal(val);
    changeHandler(val);
  };

  useEffect(() => {
    setSelectedVal(value ? value : 'none');
  }, [value]);

  return (
    <Container className={classes.root}>
      <Typography className={clsx(classes.selectLabel, labelClass)}>
        {label} <span className={classes.required}>{required ? '*' : ''}</span>
      </Typography>
      <FormControl variant={variant || 'outlined'} fullWidth={fullWidth}>
        <Select
          MenuProps={{
            classes: { list: classes.dropDownMenuList },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            MenuListProps: {
              disablePadding: true,
            },
            getContentAnchorEl: null,
          }}
          name={name}
          value={selectedVal}
          onChange={(e) => handleOnChange(e.target.value)}
          IconComponent={KeyboardArrowDown}
          variant={variant || 'outlined'}
          color={color || 'secondary'}
          className={selectedVal === 'none' ? classes.selectDisabled : classes.selectField}
          onFocus={(e) => setShowPlaceholder(false)}
          error={error}
          onClose={(e) => {
            onClose && onClose();
            setShowPlaceholder(e.target.value === undefined);
          }}
        >
          <MenuItem
            className={clsx(classes.placeholder, showPlaceholder ? classes.menuItemHidden : '')}
            key="0"
            disabled
            value="none"
          >
            {placeholder}
          </MenuItem>
          {options.map((item, i) => (
            <MenuItem key={i} value={item.value}>
              <div className={classes.labelWrapper}>
                {item.statusIcon && (
                  <span>
                    <StatusIcon htmlColor={item.color} className={classes.img} />
                  </span>
                )}
                <p className={classes.label}>{item.label || item.value}</p>
              </div>
            </MenuItem>
          ))}
        </Select>
        <Typography className={classes.errorStyle}>{error ? helperText : null}</Typography>
      </FormControl>
    </Container>
  );
};

export default SelfReportingSelect;
