import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export const BottomDrawerComponent = ({ open, onClose, children, setShowPage, sx, ...props }) => {
  // React.useEffect(() => {
  //   return () => {
  //     setShowPage(true);
  //   };
  // }, [setShowPage]);
  return (
    <Drawer
      sx={{ zIndex: 1300 }}
      anchor="bottom"
      variant="temporary"
      open={open}
      PaperProps={{ elevation: 0, style: { backgroundColor: 'transparent' } }}
      onClose={onClose}
    >
      <Box
        sx={{
          mt: 4,
          bgcolor: '#f8f8f8',
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
          position: 'relative',
          ...sx,
        }}
        {...props}
      >
        <Box
          onClick={() => onClose()}
          sx={{
            position: 'absolute',
            top: 36,
            right: 32,
            cursor: 'pointer',
          }}
        >
          <CloseIcon />
        </Box>
        <Box
          sx={{
            mt: 9,
          }}
        >
          {children}
        </Box>
      </Box>
    </Drawer>
  );
};

BottomDrawerComponent.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  sx: PropTypes.object,
};
