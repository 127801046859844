import React from 'react';
import { logBranchEvent } from '../utils/BranchMetricsUtils';
import FirebaseUtils from '../utils/FirebaseUtils';
import api from '../features/landingFlow/api';
import { detectDeviceName, detectBrowserVersion } from '../utils/helper';

/**
 * Custom hook for triggering Branch metrics and Firebase analytics events.
 *
 * @returns
 * logAnalyticsEvent - function to trigger branch and firebase analytics events
 */

const saveUserEvents = (obj) => {
  const object = {
    eventType: obj?.eventName,
    eventGroup: obj?.eventGroup,
    deviceType: detectDeviceName(),
    browserVersion: detectBrowserVersion(),
    deviceVersion: '',
    source: 'DEVICE/WEB',
    appVersion: '',
    sessionId: '',
  };
  const userId = localStorage.getItem('userId');
  if (userId !== undefined && userId !== null) {
    object['userId'] = userId;
  }
  api
    .saveUserEvent(object)
    .then((res) => {})
    .catch((e) => {
      console.log(e);
    });
};

export default function useAnalyticsLogger() {
  /**
   * @param eventName Name of the event which will be triggered
   * @param params Params Object will contain params for both branch and firebase.
   */
  const logAnalyticsEvent = React.useCallback((eventObject, params) => {
    if (params) {
      FirebaseUtils.generateParamEvent(eventObject?.eventName, params);
    } else {
      FirebaseUtils.generateEvent(eventObject?.eventName);
    }
    logBranchEvent(eventObject?.eventName);
    saveUserEvents(eventObject);
  }, []);

  return {
    logAnalyticsEvent,
  };
}
