import React from 'react';
import ReactDOM from 'react-dom';

import RouterWrapper from './components/RouterWrapper';

// const routes = makeMainRoutes();

ReactDOM.render(<RouterWrapper />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept();
}
