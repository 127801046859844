import React, { useState, useEffect, useCallback } from 'react';
import { Typography, InputAdornment, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import gql from 'graphql-tag';

import ProgressBar from '../../components/molecules/ProgressBar';
import TextField from '../../components/atoms/TextField';
import ProfileService from '../../service/ProfileService';
import Loader from '../../components/atoms/Loader';
import {
  ADD_CONTACT,
  FORM_LABEL,
  ADD_PHONE as ADD_CONTACT_CONST,
  ERROR_MSGS,
  PHONE_NO_START_ADORNMENT,
  SAVING_DATA,
  FIREBASE_EVENT_NAMES,
  SNACKBAR_TYPE,
  TOAST_TEXT_MESSAGE,
} from '../../utils/constants';
import Button from '../../components/atoms/Button';
import OTPVerificationDialog from '../../components/organisms/OTPVerificationDialog';
import { getUSFormatPhoneNo, getPhoneno } from '../../utils/helper';
import { validateUSPhone } from '../../utils/validations';
import { TwoStepContext } from '../../components/RouterWrapper';
import { logUserEvent } from '../../utils/UserEvent';
import SnackbarToast from '../../components/organisms/SnackbarToast';
import withRouter from '../../components/withRoute';

const ADD_PHONE = gql`
  mutation addPhoneNumber($phone: String) {
    addPhoneNumber(phone: $phone) {
      status
      message
    }
  }
`;

const VALIDATE_OTP = gql`
  mutation validateOTP($validateOTPRequest: ValidateOTPRequest) {
    validateOTP(validateOTPRequest: $validateOTPRequest) {
      phoneVerificationStatus
      verificationSid
    }
  }
`;

const VERIFY_PHONE_NUMBER = gql`
  mutation verifyPhoneNumber($phoneNumber: String) {
    verifyPhoneNumber(phoneNumber: $phoneNumber) {
      verificationSid
    }
  }
`;

const style = (theme) => ({
  formContainer: {
    paddingTop: '13px',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '64px',
    },
  },
  textField: {
    width: '100%',
    paddingBottom: '30px',
  },
  subheading: {
    ...theme.customComponent.body1RegularLight,
    paddingBottom: '30px',
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
});

const AddPhone = (props) => {
  const { classes } = props;
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [snackbarType, setSnackbarType] = useState(SNACKBAR_TYPE.SUCCESS);

  const [newPhone, setNewPhone] = useState('');
  const [newPhoneError, setNewPhoneError] = useState({
    status: false,
    msg: '',
  });
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState({
    status: false,
    msg: '',
  });
  const [otpDialog, setOtpDialog] = useState(false);
  const [codeSid, setCodeSid] = useState(null);

  const { twoStepInfo } = React.useContext(TwoStepContext);

  useEffect(() => {
    if (!twoStepInfo.userVerified) {
      // props.history.replace('/contact');
      props.navigate("/contact");
    }
  }, [twoStepInfo]);

  const handleRedirectContact = useCallback(() => {
    // props.history.push({
    //   pathname: '/contact',
    //   state: {
    //     detail: 1,
    //   },
    // });
    props.navigate('/contact',{state: {
      detail: 1,
    }});
  }, []);

  const startVerificationProcess = useCallback(
    async (resend) => {
      try {
        // setLoader(true);
        const sid = await ProfileService.generateOTPForPhone(getPhoneno(newPhone));
        setCodeSid(sid);
        setOtpDialog(true);
        setSnackbarType(SNACKBAR_TYPE.SUCCESS);
        setSnackbarMessage(
          resend === true ? TOAST_TEXT_MESSAGE.OTP_RESENT : TOAST_TEXT_MESSAGE.OTP_SENT,
        );
      } catch (error) {
        setNewPhoneError({
          status: true,
          msg: ERROR_MSGS.OTP.FAILED_GENERATE,
        });
        console.error('Failed to generate OTP');
      } finally {
        setLoader(false);
      }
    },
    [newPhone, setCodeSid, setOtpDialog, setNewPhoneError, setLoader],
  );

  const handleAddPhone = useCallback(
    async (isVerified) => {
      if (isVerified) {
        try {
          setLoader(true);
          await ProfileService.addPone(ADD_PHONE, getPhoneno(newPhone));
          handleRedirectContact();
        } catch (error) {
          console.error('Failed to add number');
          setNewPhoneError({
            status: true,
            msg: ERROR_MSGS.PHONE.FAILED_ADD,
          });
        } finally {
          setLoader(false);
        }
      } else {
        setNewPhoneError({
          status: true,
          msg: ERROR_MSGS.PHONE.UNVERIFIED,
        });
      }
    },
    [newPhone, setLoader, handleRedirectContact, setNewPhoneError],
  );

  const handleNewPhoneValidation = useCallback(() => {
    const { isValid, errorMessage } = validateUSPhone(newPhone);
    if (!isValid) {
      setNewPhoneError({
        status: true,
        msg: errorMessage,
      });
    }
    return isValid;
  }, [newPhone, setNewPhoneError]);

  const handleBackButton = () => {
    props.navigate(-1);
  };

  const handleFieldChange = (name, value) => {
    setNewPhoneError({
      status: false,
      msg: '',
    });
    setNewPhone(getUSFormatPhoneNo(value, newPhone));
  };

  const handleOTPChange = (value) => {
    setOtp(value);
  };

  const onSubmitClick = () => {
    const isValid = handleNewPhoneValidation();
    if (isValid) {
      startVerificationProcess(false);
    } else {
      console.log('Invalid Number');
    }
  };

  const handleOTPDialogClose = () => {
    // setOtpDialog(false);
    setOtp('');
    setOtpError({
      status: false,
      msg: '',
    });
  };

  const handleRetry = () => {
    handleOTPDialogClose();
    startVerificationProcess(true);
  };

  const handleVerify = async () => {
    logUserEvent(FIREBASE_EVENT_NAMES.VALIDATING_PHONE_NUM);
    if (otp === '') {
      setOtpError({
        status: true,
        msg: ERROR_MSGS.OTP.INVALID,
      });
    } else {
      try {
        const verified = await ProfileService.verifyPhoneOTP(getPhoneno(newPhone), codeSid, otp);
        if (verified) {
          setOtpDialog(false);
          handleAddPhone(true);
        } else {
          setOtpError({
            status: true,
            msg: ERROR_MSGS.OTP.INVALID,
          });
        }
      } catch (error) {
        console.error('Failed to verify OTP');
        setOtpError({
          status: true,
          msg: ERROR_MSGS.OTP.FAILED_VERIFY,
        });
      }
    }
  };

  const handleValidation = () => {
    if (otp === '') {
      setOtpError({
        status: true,
        msg: ERROR_MSGS.OTP.INVALID,
      });
    }
  };

  return (
    <React.Fragment>
      {loader && <Loader overlay text={SAVING_DATA} />}
      <SnackbarToast
        message={snackbarMessage}
        open={!!snackbarMessage}
        alertStyle={{ backgroundColor: 'black', color: 'white' }}
        onClose={() => {
          // setSnackbarType(SNACKBAR_TYPE.SUCCESS);
          setSnackbarMessage(false);
        }}
        type={snackbarType}
      />
      <ProgressBar heading={ADD_CONTACT.PHONE.HEADING} onBackBtnClick={handleBackButton} />

      <Grid container justify="center">
        <Grid item lg={4} xs={10} className={classes.formContainer}>
          <TextField
            name="addPhone"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{PHONE_NO_START_ADORNMENT}</InputAdornment>
              ),
            }}
            value={newPhone}
            onFieldChange={handleFieldChange}
            error={newPhoneError.status}
            errorMsg={newPhoneError.msg}
            label={FORM_LABEL.PHONE}
            textFieldClass={classes.textField}
          />
          <Typography className={classes.subheading}>{ADD_CONTACT.PHONE.SUBHEADING}</Typography>
          <Button
            color="primary"
            title={ADD_CONTACT.BTN_TITLE}
            variant={'contained'}
            size="small"
            rootClass={classes.button}
            onClick={onSubmitClick}
          />
        </Grid>
      </Grid>
      <OTPVerificationDialog
        title="Enter Code"
        open={otpDialog}
        phoneNo={newPhone}
        onVerify={handleVerify}
        onClose={handleOTPDialogClose}
        otp={otp}
        otpError={otpError}
        onOTPChange={handleOTPChange}
        phoneVerified={false}
        phoneVerifiedText={ADD_CONTACT_CONST.VERIFIED}
        onValidation={handleValidation}
        handleRetry={handleRetry}
      />
    </React.Fragment>
  );
};

export default withRouter(withStyles(style, { withTheme: true })(AddPhone));
