import { DEFAULT_NULL_REPLACEMENT, D_SOURCE, SELF_REPORTING_LABELS } from '../../utils/constants';
import { extractRestricted, findDataSource, getCommaSeparatedDate } from '../../utils/helper';
import { getBgColorForInitials } from '../AwardPoints/GiftAwardPoints/helper';

export const getFormattedCareTeamList = (response) => {
  const careTeamsList =
    response?.data?.fetchAllCareTeams ?? response.data.fetchAllCareTeamsByConsent;
  const count = careTeamsList?.count;
  let careTeamList = JSON.parse(careTeamsList?.careTeamList);
  careTeamList = careTeamList?.map((team) => {
    return {
      id: team.id,
      status: team?.status || DEFAULT_NULL_REPLACEMENT,
      dataSource: (team?.identifier && findDataSource(team.identifier)) ?? DEFAULT_NULL_REPLACEMENT,
      category:
        team?.category?.[0]?.coding?.[0]?.display ??
        team?.category?.[0]?.text ??
        team?.category?.[0]?.coding?.[0]?.text ??
        DEFAULT_NULL_REPLACEMENT,
      teamName: team?.name ?? '-',
    };
  });
  return {
    careTeamList,
    totalCount: count || 0,
  };
};
const getMemberDetails = (memberList = []) => {
  const res = memberList.map(({ member, onBehalfOf, role }, index) => {
    return {
      id: index,
      member_name:
        typeof member === String ? member : member?.display || member?.identifier?.display || '',
      imgUrl: member?.avatarUrl || '',
      specialty: role?.[0]?.coding?.[0]?.display || role?.[0]?.text || DEFAULT_NULL_REPLACEMENT,
      partnerName: onBehalfOf?.display || DEFAULT_NULL_REPLACEMENT,
      bgColor: getBgColorForInitials(),
    };
  });
  return res;
};
const getAssociateCarePlanDetails = (associateCarePlanList = []) => {
  return associateCarePlanList?.map((associateDetails) => {
    const dailyExerciseList = associateDetails?.activity?.map((item) => {
      if (item?.detail?.code?.text !== '') {
        return item.detail.code.text;
      }
      if (item?.detail?.code?.coding?.[0]?.display) {
        return item.detail.code.coding[0].display;
      }
      return '';
    });

    return {
      id: associateDetails?.id,
      imgUrl: null || '',
      heading: associateDetails?.title,
      subHeading: 'Activities',
      list: dailyExerciseList,
    };
  });
};

export const formattedCareTeamDataById = ({ careTeamList, carePlansList, isCircle }) => {
  const reason = `${careTeamList?.reasonCode?.[0]?.coding?.[0]?.code ?? ''}_${
    careTeamList?.reasonCode?.[0]?.coding?.[0]?.display ?? careTeamList?.reasonCode?.[0]?.text ?? ''
  }`;
  const category =
    careTeamList?.category?.[0]?.coding?.[0]?.display ??
    careTeamList?.category?.[0]?.text ??
    careTeamList?.category?.[0]?.coding?.[0]?.text ??
    '';
  const processedTeamDetails = {
    id: careTeamList?.id,
    markAsRestrictedInModal: careTeamList?.meta?.security?.length
      ? extractRestricted(careTeamList.meta.security)
      : false,
    columns: [
      {
        column_name: SELF_REPORTING_LABELS.STATUS,
        value: careTeamList?.status || DEFAULT_NULL_REPLACEMENT,
        show_status_icon: true,
      },
      {
        column_name: SELF_REPORTING_LABELS.START_DATE,
        value:
          (careTeamList?.period?.start && getCommaSeparatedDate(careTeamList.period.start)) ??
          DEFAULT_NULL_REPLACEMENT,
      },
      {
        column_name: SELF_REPORTING_LABELS.CONTACT_NUMBER,
        value: careTeamList?.telecom?.[0]?.value ?? DEFAULT_NULL_REPLACEMENT,
      },

      {
        column_name: SELF_REPORTING_LABELS.END_DATE,
        value:
          (careTeamList?.period?.end && getCommaSeparatedDate(careTeamList.period.end)) ||
          DEFAULT_NULL_REPLACEMENT,
      },
      {
        column_name: SELF_REPORTING_LABELS.CATEGORY,
        value: category || DEFAULT_NULL_REPLACEMENT,
      },
      {
        column_name: SELF_REPORTING_LABELS.REASON,
        value: reason || DEFAULT_NULL_REPLACEMENT,
      },
      {
        column_name: SELF_REPORTING_LABELS.NOTE,
        value: careTeamList?.note?.[0]?.text ?? DEFAULT_NULL_REPLACEMENT,
        width: 'fullWidth',
      },
    ],
    title: careTeamList?.name || 'Care team',
    category: category || DEFAULT_NULL_REPLACEMENT,
    body: {
      membersDetails: {
        title: 'Members',
        members: (careTeamList?.participant && getMemberDetails(careTeamList.participant)) || [],
      },
      assoc_care_plan_Details: {
        title: 'Associated care plans',
        assoc_care_plan: (carePlansList && getAssociateCarePlanDetails(carePlansList)) || [],
      },
    },
  };
  if (!isCircle) {
    processedTeamDetails.columns.unshift({
      column_name: D_SOURCE,
      value: findDataSource(careTeamList?.identifier),
    });
  }

  return processedTeamDetails;
};
