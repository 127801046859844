import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/atoms/Loader';

function DeepLinkHandler() {
  const navigate = useNavigate();
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params?.assessmentSubmissionId) {
      const assessmentSubmissionId = params.assessmentSubmissionId?.split('/')?.[0];
      localStorage.setItem('assessmentSubmissionId', assessmentSubmissionId);
      navigate('/');
    }
  }, []);

  return <Loader text={'Loading assessment details...'} />;
}

export default DeepLinkHandler;
