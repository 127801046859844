import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isUserCreated: true,
  userSchemaObj: {},
};

export const socialLoginSlice = createSlice({
  name: 'socialLogin',
  initialState,
  reducers: {
    updateIsUserCreated: (state, action) => {
      state.isUserCreated = action.payload?.isUserCreated;
      state.userSchemaObj = action.payload?.userSchemaObj ?? {};
      localStorage.setItem('socialLogin', JSON.stringify(state));
    },
  },
});

export const { updateIsUserCreated } = socialLoginSlice.actions;

export default socialLoginSlice.reducer;
