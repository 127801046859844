import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import { LOG_IN } from '../../utils/constants';
import { getAnalyticsEventsForRouteName } from '../../utils/helper';
import { ROUTE_NAMES } from '../../constants/routeNames';
import useAnalyticsLogger from '../../hooks/useAnalyticsLogger';
import { mockTemplates } from '../../features/dynamicRecords/mockTemplate';
import { useSelector } from 'react-redux';

const styles = () => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  carouselItem: {
    padding: '0px 0px 60px 0px',
    minHeight: 'fit-content',
    height: '100%',
    width: '100%',
    transition: 'all 1s ease-in-out',
  },
  leftSideTextBlack: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '4vw',
    lineHeight: '5.5vw',
    color: '#101828',
    letterSpacing: '0px',
  },
  leftSideTextBlue: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '4vw',
    lineHeight: '5.5vw',
    color: '#00A0DF',
    letterSpacing: '0px',
  },
  leftSideTextBlack2: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '4vw',
    lineHeight: '5.5vw',
    color: '#344054',
    letterSpacing: '0px',
  },
  buttonWrapper: {
    position: 'absolute',
    right: '30px',
    top: '50%',
    transform: 'translate(0,-50%)',
    display: 'flex',
    justifyContent: 'space-between',
  },

  nextItem: {
    height: '24px',
    width: '24px',
    color: '#036FCB',
    fontWeight: 400,
    border: '1px solid #D0D0D0',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',

    '&:hover': {
      opacity: 0.8,
    },
  },
  dotsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    left: '0px',
    bottom: '10px',
  },
  dots: {
    height: '8px',
    width: '8px',
    backgroundColor: '#E4E7EC',
    borderRadius: '50%',
  },
  carouselItemWrapper: {
    height: '100%',
    display: 'flex',
    position: 'relative',
  },
});

const Carousel = ({ classes }) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const { templates } = useSelector((state) => state.templates);
  const { selectedLanguage } = useSelector((state) => state.app);

  const handleNext = () => {
    setCurrentSlide(currentSlide === 4 ? 1 : (prev) => prev + 1);
  };

  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.LANDING_PAGE_CAROUSEL);
  const { logAnalyticsEvent } = useAnalyticsLogger();

  useEffect(() => {
    const handleLogAnalyticsEvent = () => {
      switch (currentSlide) {
        case 4:
          return logAnalyticsEvent(analyticsEvents.LANDING4_SHOP_V4);
        case 3:
          return logAnalyticsEvent(analyticsEvents.LANDING3_SHARE_RECORDS_V4);
        case 2:
          return logAnalyticsEvent(analyticsEvents.LANDING2_GET_RECORDS_V4);
        default:
          return logAnalyticsEvent(analyticsEvents.LANDING1_HEALTHCARE_V4);
      }
    };
    handleLogAnalyticsEvent();
  }, [currentSlide]);

  // const data = [
  //   {
  //     value: (
  //       <Fragment>
  //         <Typography className={classes.leftSideTextBlack}>All your</Typography>
  //         <Typography className={classes.leftSideTextBlue}>
  //           healthcare <span className={classes.leftSideTextBlack2}>in</span>
  //         </Typography>
  //         <Typography className={classes.leftSideTextBlack2}>one app</Typography>
  //       </Fragment>
  //     ),
  //   },
  //   {
  //     value: (
  //       <Fragment>
  //         <Typography className={classes.leftSideTextBlack}>Get your</Typography>
  //         <Typography className={classes.leftSideTextBlack}>
  //           <span className={classes.leftSideTextBlue}>records</span> from
  //         </Typography>
  //         <Typography className={classes.leftSideTextBlack}>all your health</Typography>
  //         <Typography className={classes.leftSideTextBlack}>plans and doctors</Typography>
  //       </Fragment>
  //     ),
  //   },
  //   {
  //     value: (
  //       <Fragment>
  //         <Typography className={classes.leftSideTextBlue}>Share</Typography>
  //         <Typography className={classes.leftSideTextBlack}>
  //           <span className={classes.leftSideTextBlue}>records</span> with
  //         </Typography>
  //         <Typography className={classes.leftSideTextBlack}>family, doctors</Typography>
  //         <Typography className={classes.leftSideTextBlack}>and caregivers</Typography>
  //       </Fragment>
  //     ),
  //   },
  //   // {
  //   //   value: (
  //   //     <Fragment>
  //   //       <Typography className={classes.leftSideTextBlack}>Connecting</Typography>
  //   //       <Typography className={classes.leftSideTextBlack}>and collecting</Typography>
  //   //       <Typography className={classes.leftSideTextBlue}>records</Typography>
  //   //     </Fragment>
  //   //   ),
  //   // },
  //   {
  //     value: (
  //       <Fragment>
  //         <Typography className={classes.leftSideTextBlack}>
  //           <span className={classes.leftSideTextBlue}>Shop </span>for
  //         </Typography>
  //         <Typography className={classes.leftSideTextBlack}>coverage & other services</Typography>
  //       </Fragment>
  //     ),
  //   },
  // ];

const CAROUSEL =
    // templates?.texts?.[selectedLanguage]?.SIGNUP?.CAROUSEL ||
    mockTemplates?.texts?.[selectedLanguage]?.SIGNUP?.CAROUSEL;
  const data = [
    {
      value: (
        <Fragment>
          <Typography className={classes.leftSideTextBlack}>
          {CAROUSEL?.CAROUSEL_TEXT1?.heading}
          </Typography>
          <Typography className={classes.leftSideTextBlue}>
            {CAROUSEL?.CAROUSEL_TEXT1?.title}
            <span className={classes.leftSideTextBlack2}>{CAROUSEL?.CAROUSEL_TEXT1?.text}</span>
          </Typography>
          <Typography className={classes.leftSideTextBlack2}>
            {CAROUSEL?.CAROUSEL_TEXT1?.text1}
          </Typography>
        </Fragment>
      ),
    },
  {
    value: (
      <Fragment>
        <Typography className={classes.leftSideTextBlack}>
          {CAROUSEL?.CAROUSEL_TEXT2?.heading}
        </Typography>
        <Typography className={classes.leftSideTextBlack}>
          <span className={classes.leftSideTextBlue}>{CAROUSEL?.CAROUSEL_TEXT2?.title}</span>
          {CAROUSEL?.CAROUSEL_TEXT2?.text}
        </Typography>
        <Typography className={classes.leftSideTextBlack}>
          {CAROUSEL?.CAROUSEL_TEXT2?.text1}
        </Typography>
        <Typography className={classes.leftSideTextBlack}>
          {CAROUSEL?.CAROUSEL_TEXT2?.text2}
        </Typography>
      </Fragment>
    ),
  },
    {
      value: (
        <Fragment>
          <Typography className={classes.leftSideTextBlue}>
            {CAROUSEL?.CAROUSEL_TEXT3?.heading}
          </Typography>
          <Typography className={classes.leftSideTextBlack}>
            <span className={classes.leftSideTextBlue}>{CAROUSEL?.CAROUSEL_TEXT3?.title}</span>
            {CAROUSEL?.CAROUSEL_TEXT3?.text}
          </Typography>
          <Typography className={classes.leftSideTextBlack}>
            {CAROUSEL?.CAROUSEL_TEXT3?.text1}
          </Typography>
          <Typography className={classes.leftSideTextBlack}>
            {CAROUSEL?.CAROUSEL_TEXT3?.text2}
          </Typography>
        </Fragment>
      ),
    },
  // {
  //   value: (
  //     <Fragment>
  //       <Typography className={classes.leftSideTextBlack}>Connecting</Typography>
  //       <Typography className={classes.leftSideTextBlack}>and collecting</Typography>
  //       <Typography className={classes.leftSideTextBlue}>records</Typography>
  //     </Fragment>
  //   ),
  // },
    {
      value: (
        <Fragment>
          <Typography className={classes.leftSideTextBlue}>
            {CAROUSEL?.CAROUSEL_TEXT4?.heading}
            <span className={classes.leftSideTextBlack}>{CAROUSEL?.CAROUSEL_TEXT4?.title}</span>
          </Typography>
          <Typography className={classes.leftSideTextBlack}>
            {CAROUSEL?.CAROUSEL_TEXT4?.text}
          </Typography>
          <Typography className={classes.leftSideTextBlack}>
            {CAROUSEL?.CAROUSEL_TEXT4?.text1}
          </Typography>
          <Typography className={classes.leftSideTextBlack}>
            {CAROUSEL?.CAROUSEL_TEXT4?.text2}
          </Typography>
        </Fragment>
      ),
    },
  ];

  return (
    <div className={classes.container}>
      <div className={classes.carouselItemWrapper} style={{ width: `${data?.length * 100}%` }}>
        {data?.map((item, index) => {
          return (
            <Box
              className={classes.carouselItem}
              style={{ transform: `translateX(-${currentSlide * 100 - 100}%)` }}
              key={index}
            >
              {item?.value}
            </Box>
          );
        })}
      </div>
      <div className={classes.buttonWrapper}>
        <div className={classes.nextItem} onClick={handleNext}>
          <ChevronRightIcon fontSize="20px" />
        </div>
      </div>
      <div className={classes.dotsWrapper} style={{ width: '56px' }}>
        {data?.map((dot, index) => (
          <span
            key={index}
            className={classes.dots}
            style={currentSlide === index + 1 ? { backgroundColor: '#667085' } : null}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(Carousel);
