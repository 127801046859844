import React from 'react';
import { withStyles } from '@mui/styles';
import MobileStepper from '@mui/material/MobileStepper';
import { Typography } from '@mui/material';
import LeftArrow from '../../../assets/images/back.svg';
import clsx from 'classnames';
import AddIcon from '../../../assets/images/addbtn.svg';
import EditIcon from '../../../assets/images/editOrange.svg';
import { isLarge } from '../../../utils/helper';
import { IMAGE_ALTERNATIVE_TXT } from '../../../utils/constants';
import useWidth from '../../../hooks/useWidth';

const styles = (theme) => ({
  root: {
    backgroundColor: '#FEFFFF',
    boxShadow: '0 -1px 4px 0 rgba(0, 0, 0, 0.5)',
  },
  noStepperRoot: {
    height: 64,
    marginTop: 12,
    boxSizing: 'border-box',
    paddingRight: '3%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stepper: {
    maxWidth: '100%',
    flexGrow: 1,
    padding: 0,
    backgroundColor: 'transparent',
    height: '0.78 %',
  },
  bar: {
    backgroundColor: 'transparent',
    height: 7,
    width: '100%',
  },
  heading: {
    marginLeft: '16px',
    [theme.breakpoints.up('lg')]: {
      marginLeft: (props) => (props.backButtonOnFull ? 0 : null),
    },
    letterSpacing: 1.2,
    fontWeight: 900,
  },
  barSection: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    width: '100%',
    marginLeft: '10px',
  },
  clickableIcon: {
    ...theme.customComponent.clickableItem,
  },
  backgroundBar: {
    backgroundColor: theme.palette.secondary.main,
  },
  addBtn: {
    marginLeft: '10px',
  },
  noBackButton: {
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  pwaAddIcon: {
    width: '48px',
  },
  pwaEditIcon: {
    width: '48px',
  },
  pwaHeading: {
    fontSize: '24px',
  },
  pwaBackIcon: {
    width: '26px',
  },
  categoryIcon: {
    width: '100%',
    height: '40px',
    objectFit: 'contain',
    borderRadius: 12,
  },
  imgWrapper: {
    marginLeft: 22,
    width: 'fit-content',
    maxWidth: 160,
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
});

const defaultProps = {};

const propTypes = {};

const ProgressBar = (props) => {
  const {
    classes,
    activeStep,
    heading,
    onBackBtnClick,
    endAdornment,
    editBtn,
    addBtn,
    onEditClick,
    onAddClick,
    backButtonOnFull,
    partnerIcon,
    ...rest
  } = props;

  const screenWidth = useWidth();

  const isPwa = isLarge(screenWidth);

  return (
    <React.Fragment>
      <div className={clsx(classes.noStepperRoot, activeStep && classes.root)}>
        <div className={classes.barSection}>
          <div className={classes.imgWrapper}>
            <img
              src={partnerIcon || LeftArrow}
              className={[
                classes.clickableIcon,
                partnerIcon ? classes.categoryIcon : null,
                backButtonOnFull ? classes.noBackButton : null,
                isPwa ? classes.pwaBackIcon : null,
              ].join(' ')}
              onClick={onBackBtnClick}
            />
          </div>
          <Typography
            variant="h3"
            className={[classes.heading, isPwa ? classes.pwaHeading : null].join(' ')}
          >
            {heading}
          </Typography>
        </div>
        <div>
          {onEditClick && (
            <img
              src={EditIcon}
              alt={IMAGE_ALTERNATIVE_TXT.EDIT_ICON}
              onClick={onEditClick}
              className={[classes.clickableIcon, isPwa ? classes.pwaEditIcon : null].join(' ')}
            />
          )}
          {onAddClick && (
            <img
              src={AddIcon}
              alt={IMAGE_ALTERNATIVE_TXT.ADD_ICON}
              onClick={onAddClick}
              className={[
                classes.clickableIcon,
                classes.addBtn,
                isPwa ? classes.pwaAddIcon : null,
              ].join(' ')}
            />
          )}
        </div>
      </div>
      {activeStep ? (
        <MobileStepper
          variant="progress"
          steps={6}
          position="static"
          activeStep={activeStep}
          className={classes.stepper}
          LinearProgressProps={{
            classes: {
              root: classes.bar,
              bar: classes.backgroundBar,
            },
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

ProgressBar.defaultProps = defaultProps;
ProgressBar.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(ProgressBar);
