import { NUMBER_OF_RECORDS_PER_PAGE } from '../../../utils/constants';

export const INITIAL_STATE = {
  loading: true,
  selectedDateFilter: 'all',
  selectedStatusFilter: 'all',
  data: [],
  totalPage: 0,
  selectedPlan: null,
  searchTerm: '',
  currentPageNo: 1,
  searchFlag: false,
  changeFiltersValue: false,
  customDateSelected: false,
};

export function reducer(state, action) {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, [action.key]: action.value };
    case 'UPDATE_PAGE':
      return {
        ...state,
        currentPageNo: action.pageNum,
      };
    default:
      throw new Error();
  }
}

export function updateState(dispatchFunc, key, value) {
  dispatchFunc({ type: 'UPDATE', key, value });
}

export function setLoading(dispatchFunc, state = true) {
  updateState(dispatchFunc, 'loading', state);
}

export function openDetailedPlanView(dispatchFunc, data = null) {
  updateState(dispatchFunc, 'selectedPlan', data);
}

export function closeDetailedView(dispatchFunc) {
  openDetailedPlanView(dispatchFunc);
}

export function selectDate(dispatchFunc, selectedDateFilter) {
  updateState(dispatchFunc, 'selectedDateFilter', selectedDateFilter);
}
export function customDateSelected(dispatchFunc, selectedDateFilter) {
  updateState(dispatchFunc, 'customDateSelected', selectedDateFilter);
}

export function selectStatus(dispatchFunc, selectedStatusFilter) {
  updateState(dispatchFunc, 'selectedStatusFilter', selectedStatusFilter);
}

export function updateSearchTerm(dispatchFunc, searchTerm) {
  updateState(dispatchFunc, 'searchTerm', searchTerm);
}

export function updatePage(dispatchFunc, pageNum) {
  dispatchFunc({ type: 'UPDATE_PAGE', pageNum });
}
