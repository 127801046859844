import { SHARE_LINK_DRAWER } from './constants';
import Unlink from '../assets/images/unlink.svg';
// import WhatsApp from '../assets/images/whatsapp.svg';
import Facebook from '../assets/images/facebook.svg';
// import Gmail from '../assets/images/gmail.svg';
import Mail from '../assets/images/mail.svg';
// import Hangouts from '../assets/images/social-media.svg';
import { replaceImageProperty } from './helper';

const ICONS = [
  null,
  Unlink,
  // WhatsApp,
  Facebook,
  // Gmail,
  Mail,
  // Hangouts
];
const PWA_ICONS = [];

const get = () => replaceImageProperty(SHARE_LINK_DRAWER, ICONS, PWA_ICONS);
const shareLinkDrawerIconGetter = { get };

export default shareLinkDrawerIconGetter;
