import React, { useState, useEffect, useRef, useContext } from 'react';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import StatusIcon from '@mui/icons-material/FiberManualRecord';

import {
  fetchStatusObj,
  capitalizeFirstLetter,
  getFileNameAndExtension,
  checkOneTimeUser,
  removeDuplicateObject,
  convertStrToTitleCase,
} from '../../../../utils/helper';
import { withStyles } from '@mui/styles';
import MarkRestrictedCard from '../../../../components/molecules/SelfReporting/MarkRestrictedCard';
import { Card, CardContent, CardActions, Typography, Grid, Box, Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../components/atoms/Loader';
import {
  DEFAULT_NULL_REPLACEMENT,
  OPTIONS_VIEW,
  SELF_REPORTING_TEXTS,
  SNACKBAR_TYPE,
} from '../../../../utils/constants';
import colors from '../../../../utils/colors';
import LaunchDocumentImg from '../../../../assets/images/launchDocument.png';
import DocumentService from '../../../../service/DocumentService/index.js';
import SnackbarToast from '../../../../components/organisms/SnackbarToast';
import UserCircle from '../../../../assets/images/defaultAvatarBlack.svg';
import useGetScreenMode from '../../../../hooks/useGetScreenMode';
import GenericServices from '../../Services/GenericServices';
import ViewProfile from '../ViewProfile';
import DocDownloadIcon from '../../../../assets/images/doc-download.svg';
import { useReactToPrint } from 'react-to-print';
import { UserInfoContext } from '../../../../components/Routes';

const styles = (theme) => ({
  editButton: {
    marginRight: 24,
    color: '#036FCB',
    border: '1px solid #A0A4A6',
    padding: '6px 16px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: 14,
    width: 74,
    borderRadius: 40,
    boxSizing: 'border-box',
    textAlign: 'center',
    '&:hover': {
      background: '#036FCB',
      color: '#fff',
    },
  },
  heading1: {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: '20px',
    margintTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    border: '1px solid #E4E4E4',
    borderRadius: theme.spacing(1.5),
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    height: 56,
    width: 280,
    cursor: 'pointer',
  },
  careTeamCardRoot: {
    border: '1px solid #E4E4E4',
    borderRadius: theme.spacing(1.5),
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    width: 280,
    cursor: 'pointer',
  },
  careTeamCardGrid: {
    paddingRight: 16,
    paddingBottom: 16,
  },
  optionsContainer: {
    marginTop: 20,
  },
  documentWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: -24,
  },
  documentUrl: {
    color: colors.BLUE,
    fontSize: 16,
    fontWeight: 500,
    marginRight: 40,
  },
  launchDocumentIcon: { marginLeft: 8, position: 'relative', top: 3, cursor: 'pointer' },
  subTitle: {
    fontWeight: 600,
    fontSize: 18,
    color: colors.BLACK_B900,
    lineHeight: '24px',
  },
  careTeamCategory: {
    lineHeight: 0,
    fontSize: '18px',
    color: '#667085',
    fontWeight: 600,
    fontFamily: 'Inter',
    lineHeight: '0px',
  },
  PopUpWrapper: {
    maxWidth: '640px',
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '24px',
    padding: '32px',
    fontFamily: 'Inter',
    position: 'fixed',
    right: 24,
    top: 24,
    bottom: 24,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      right: 0,
      padding: '20px',
    },
  },
  column_name: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    width: '100%',
    height: '20px',
    color: '#828282',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    order: 0,
    flex: 'none',
    flexGrow: 0,
    margin: '4px 0px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  spacer: {
    marginRight: theme.spacing(2),
  },
});
const customClasses = {
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    wordBreak: 'break-word',
    letterSpacing: '0px',
    weight: 600,
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
  },
  icon: {
    height: '32px',
    width: '32px',
  },
  column_value: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1F1F1F',
    order: 1,
    flex: 'none',
    margin: '4px 0px',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  status_value: {
    boxSizing: 'border-box',
    paddingRight: 10,
    marginTop: 2,
  },
  dataSource: {
    background: '#E4E4E4',
    border: '1px solid #C0C0C0',
    boxSizing: 'border-box',
    borderRadius: '24px',
    color: '#1F1F1F',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: 4,
    padding: '0px 8px',
    minWidth: 100,
    display: 'inline-block',
  },
  secondRow: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  wrapper: {
    borderRadius: '8px',
    height: '296px',
    padding: '0px 24px',
  },
  services_wrapper: {
    borderRadius: '8px',
    width: '456px',
    padding: '0px 24px',
    height: '152px',
  },
  flexBox: {
    display: 'flex',
    flex: 1,
  },
  flexContent: {
    flex: '33%',
  },
  scrollY: {
    overflow: 'scroll',
    border: 'none',
  },
  colWidth: (width) => {
    switch (width?.toLowerCase()) {
      case 'halfwidth':
        return '50%';
      case 'fullwidth':
        return '100%';
      case 'towthird':
        return '66%';
      default:
        return '33%';
    }
  },
  services_column_name: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    color: '4B4B4B',
  },
  services_column_value: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    display: 'flex',
    alignItems: 'center',
    color: '#828282',
  },
  services_container: {
    padding: '0px',
  },
  services_heading: {
    color: '#000000',
    lineHeight: '16px',
    fontSize: 16,
    fontWeight: 600,
  },
  PopUptitleBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '40px',
    padding: 0,
  },
  PopUptitleBar_heading_sec: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: 12,
  },
  iconWrapper: { marginRight: '12px' },
  careTeamIconWrapper: {
    paddingRight: 8,
  },
  closeButton: {
    height: 'fit-content',
    width: '110px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  status_icon: { width: '8px', height: '8px', marginRight: '8px' },
  statusIcon: { width: 12, height: 12 },
  statusContainer: { display: 'flex', alignItems: 'center' },
  statusColumn: { display: 'flex', alignItems: 'center', marginRight: 4 },
  subTitle: {
    fontWeight: 600,
    fontSize: 18,
    color: colors.BLACK_B900,
    lineHeight: '24px',
    marginTop: 12,
  },
  bgColorForInitial: (bgColor) => ({
    backgroundColor: bgColor,
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
    width: 48,
    borderRadius: '50px',
    margin: '-8px 8px 0px 0px',
    lineHeight: 0,
  }),
  labelType: {
    marginLeft: 8,
    cursor: 'pointer',
  }
};

export const renderStatusView = (fieldObj) => {
  const { value, status_color } = fieldObj;
  return (
    <div style={customClasses.statusContainer}>
      {status_color && value && (
        <span style={customClasses.statusColumn}>
          <StatusIcon htmlColor={status_color} style={customClasses.statusIcon} />
        </span>
      )}
      <p style={{ ...customClasses.column_value, ...customClasses.status_value }}>
        {value ? capitalizeFirstLetter(value) : '-'}
      </p>
    </div>
  );
};

function ViewDetailsFlyout(props) {
  const {
    setOpen,
    data,
    documents,
    title,
    icon,
    showEdit = false,
    hideOptions = false,
    markRestricted,
    handleEdit,
    markAsRestricted,
    isHistoricalCardEnable = true,
    historicalRecordsParamsObj,
    restrictedDescription,
    clinicalRecord,
    currentObj,
    customComponent,
    customBodyComponent,
    classes,
    careTeamViewFlag,
    careTeamCategory = '',
    bgColorForInitial = '',
    viewProfileData,
    buttonDetails,
  } = props;

  const [hideHistoricalRecordsOption, setHideHistoricalRecordsOption] = useState(false);
  const [careTeamView, setCareteamView] = useState(false);
  const [careTeamViewData, setCareTeamViewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState(SELF_REPORTING_TEXTS.PLEASE_WAIT);
  const [restricted, setRestricted] = useState(markAsRestricted);
  // const [documentsInfo, setDocumentsInfo] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState(SNACKBAR_TYPE.SUCCESS);
  const screenMode = useGetScreenMode();
  const isOneTimeUser = checkOneTimeUser();
  const { partner, grantorId, granteeId } = useParams();
  const printRef = useRef();
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const betaUser = userInfo && userInfo.betaUser;

  useEffect(() => {
    if (
      !historicalRecordsParamsObj ||
      historicalRecordsParamsObj.code === '-' ||
      !isHistoricalCardEnable
    ) {
      setHideHistoricalRecordsOption(true);
    } else {
      setHideHistoricalRecordsOption(false);
    }
  }, [historicalRecordsParamsObj]);

  useEffect(() => {
    if (careTeamViewFlag) {
      setCareteamView(true);
      setCareTeamViewData(customBodyComponent.careTeam);
    } else {
      setCareteamView(false);
      setCareTeamViewData('');
    }
  }, [careTeamViewFlag]);
  // useEffect(() => {
  //   // fetch documents binary data
  //   if (documents && documents.length > 0) {
  //     constructDocumentUrls(documents);
  //   }
  // }, [documents]);

  // const constructDocumentUrls = async (data) => {
  //   const promises = [];
  //   data.forEach(async (docArn) => {
  //     promises.push(DocumentService.downloadFile(docArn));
  //   });
  //   return Promise.all(promises).then((res) => {
  //     const documentsArr = [];
  //     res.forEach((obj, i) => {
  //       const url = window.URL.createObjectURL(new Blob([obj.data]));
  //       documentsArr.push({ url: url, binaryData: obj.data, docArn: data[i] });
  //     });
  //     setDocumentsInfo(documentsArr);
  //   });
  // };
  const navigate = useNavigate();

  const handleHistoricalRecordsClick = () => {
    if (historicalRecordsParamsObj && historicalRecordsParamsObj?.resourceType) {
      const url = `${
        historicalRecordsParamsObj?.partner ? '/circles' : '/records'
      }/${historicalRecordsParamsObj?.resourceType?.toLowerCase()}/historicalRecords`;
      navigate(url, {
        state: { resourceData: historicalRecordsParamsObj },
      });
    }
  };

  const OptionsView = () => {
    return (
      <div className={classes.optionsContainer}>
        {!hideHistoricalRecordsOption && (
          <div>
            <Typography className={classes.heading1}>{OPTIONS_VIEW.OPTIONS}</Typography>
            <Card className={classes.cardRoot} onClick={handleHistoricalRecordsClick}>
              <CardContent>
                <Typography>{OPTIONS_VIEW.HISTORICAL_RECORDS}</Typography>
              </CardContent>
              <CardActions>
                <IconButton>
                  <ChevronRightIcon />
                </IconButton>
              </CardActions>
            </Card>
          </div>
        )}
        <MarkRestrictedCard
          description={restrictedDescription}
          enabled={restricted}
          onChange={(val) => markAsRestrictedCall(val)}
        />
      </div>
    );
  };

  const BillsHeaderBox = () => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: 576,
          height: 144,
          borderRadius: 8,
          backgroundColor: '#f8f8f8',
        }}
      >
        <Typography style={{ fontWeight: 500, fontSize: 16, lineHeight: '24px', color: '#666666' }}>
          Amount paid
        </Typography>
        <Typography style={{ fontWeight: 600, fontSize: 28, lineHeight: '40px', color: '#344054' }}>
          $455.5
        </Typography>
        <Typography style={{ fontWeight: 400, fontSize: 14, lineHeight: '20px', color: '#000000' }}>
          <StatusIcon htmlColor="green" style={{ paddingRight: 5, width: 12, height: 12 }} />
          Paid on Mar, 26,2021
        </Typography>
      </Box>
    );
  };

  const markAsRestrictedCall = async (currentState) => {
    setIsLoading(true);
    try {
      await GenericServices.updateMarkAsRestrictedRecord({
        resourceId: currentObj?.id,
        markAsRestricted: currentState === true ? 'restricted' : 'unrestricted',
        resourceType: clinicalRecord,
      });
      setRestricted(currentState);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    setLoaderText('');
  };

  const openDocument = async (documentObj) => {
    const fileInfo = getFileNameAndExtension(documentObj.docArn);
    const link = document.createElement('a');
    link.href = documentObj.url;
    link.setAttribute('download', `${fileInfo.fileNameWithoutExtension}.${fileInfo.extension}`);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownloadDocument = async (docArn, folderName= 'self-reported') => {
    setSnackbarMessage('Downloading document ...');
    setSnackbarOpen(true);
    setSnackbarType(SNACKBAR_TYPE.INFO);
    try {
      const res = await DocumentService.downloadFile(docArn, folderName);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      openDocument({ url: url, binaryData: res.data, docArn: docArn });
      setSnackbarMessage('Downloaded document successfully');
      setSnackbarOpen(true);
      setSnackbarType(SNACKBAR_TYPE.SUCCESS);
    } catch (err) {
      setSnackbarMessage('Something went wrong while downloading the document');
      setSnackbarOpen(true);
      setSnackbarType(SNACKBAR_TYPE.ERROR);
    }
  };
  
  const renderDocumentName = (docArn) => {
    const fileInfo = getFileNameAndExtension(docArn);
    return <span>{fileInfo.fileName}</span>;
  };

  const displayListValues = (arr) => {
    const list = arr.length < 2 ? arr : removeDuplicateObject(arr);
    if (!list?.length) {
      return '-';
    }
    return (
      <div
        style={{
          ...customClasses.column_value,
          paddingLeft: '24px',
        }}
      >
        <ul style={{ margin: '0px', padding: '0px' }}>
          {list?.map((val, i) => (
            <li key={i} style={{ lineHeight: '24px' }}>
              {val?.description}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const displayObjectListValues = (arr) => {
    if (!arr?.length) {
      return '-';
    }
    return (
      <div
        style={{
          ...customClasses.column_value,
          paddingLeft: '24px',
        }}
      >
        <ul style={{ margin: '0px', padding: '0px' }}>
          {arr?.map((val, i) => (
            <li key={i} style={{ lineHeight: '24px' }}>
              {val}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const displayDocumentsList = (list) => {
    if (!list?.length) {
      return '-';
    }
    return (
      <div
        style={{
          ...customClasses.column_value,
          paddingLeft: '24px',
        }}
      >
        <div className={classes.documentWrapper}>
          {list?.map((val, i) => (
            <span key={val + i} className={classes.documentUrl}>
              {renderDocumentName(val)}
              <img
                src={LaunchDocumentImg}
                onClick={() => handleDownloadDocument(val)}
                className={classes.launchDocumentIcon}
              />
            </span>
          ))}
        </div>
      </div>
    );
  };
  const displayPillStringValue = (obj) => {
    return (
      <p
        style={{
          ...customClasses.column_value,
          ...customClasses.dataSource,
        }}
      >
        {obj?.value}
      </p>
    );
  };

  const getDate = (val) => {
    // let result = val && val?.split(',');
    // result = result?.length >= 2 && result?.slice(0, 2);
    // result = result?.length > 0 ? result?.join(', ') : val;
    // return result ? result : '-';
    return val;
  };
  const getTime = (val) => {
    let result = val && val?.split(',');
    result = result?.length >= 2 ? result?.[2] : val;
    return result ? convertStrToTitleCase(result) : '-';
  };

  const renderNote = (listItem) => {
    const noteList = listItem?.value?.split('\n');

    let noteView = [];
    if (noteList?.length > 1) {
      noteView = noteList.map((note) => (
        <p style={{ ...customClasses.column_value }}>
          &#8226;&nbsp;
          {listItem?.capitalize ? capitalizeFirstLetter(note) : note}
        </p>
      ));
    } else {
      noteView.push(
        <p style={{ ...customClasses.column_value }}>
          {listItem?.capitalize ? capitalizeFirstLetter(listItem?.value) : listItem?.value}
        </p>,
      );
    }

    return noteView;
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Modal
      keepMounted
      open={true}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      style={customClasses.scrollY}
    >
      <div className={classes.PopUpWrapper} ref={printRef}>
        {isLoading && <Loader overlay text={loaderText} />}
        <div style={customClasses.PopUptitleBar}>
          <div style={customClasses.PopUptitleBar_heading_sec}>
            {icon && icon !== DEFAULT_NULL_REPLACEMENT && (
              <div style={customClasses.iconWrapper}>
                <img
                  src={icon}
                  alt="cap"
                  style={customClasses.icon}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = UserCircle;
                  }}
                />
              </div>
            )}
            {bgColorForInitial && title !== DEFAULT_NULL_REPLACEMENT && title?.length && (
              <p style={customClasses.bgColorForInitial(bgColorForInitial)}>
                {title?.[0]?.toUpperCase()}
              </p>
            )}
            <div>
              <Typography style={customClasses.heading}>{title}</Typography>
              {careTeamCategory && <p className={classes.careTeamCategory}>{careTeamCategory}</p>}
            </div>
          </div>
          <div style={customClasses.closeButton}>
          {
            betaUser && <span
              className={classes.editButton}
              onClick={handlePrint}
            >
              {'Print'}
            </span>
          }
            {showEdit && !hideOptions && (
              <span
                className={classes.editButton}
                onClick={() => handleEdit({ newRestrictedVal: restricted })}
              >
                Edit
              </span>
            )}
            <CloseIcon onClick={(e) => setOpen(false)} />
          </div>
        </div>
        {customComponent && (
          <div>
            <customComponent.component {...customComponent.props} />
          </div>
        )}
        {/* <BillsHeaderBox /> */}
        <div style={customClasses.secondRow}>
          {data?.map((listItem, idx) => {
            if (listItem?.hide) {
              return '';
            }
            return (
              <div
                style={{
                  flex: customClasses.colWidth(listItem?.width),
                  maxWidth: customClasses.colWidth(listItem?.width),
                  marginBottom: '15px',
                  boxSizing: 'border-box',
                  paddingRight: '10px',
                }}
                key={idx}
              >
                {listItem?.labelType === 'labelWithIcon' ? (
                  <div
                    style={{ ...customClasses.column_name }}
                    onClick={() => handleDownloadDocument(listItem?.documentURL, 'mpowered-support')}
                  >
                    <span className={classes.column_name}>{listItem?.column_name}</span>
                    <img
                      className={classes.labelType}
                      style={{ ...customClasses.labelType }}
                      src={DocDownloadIcon}
                    />
                  </div>
                ) : (
                  <p className={classes.column_name}>{listItem?.column_name}</p>
                )}
                {listItem?.type === 'dot' && renderStatusView(listItem)}
                {listItem?.type === 'dotWithStatusfromdiffloc' && renderStatusView(listItem)}
                {listItem?.type === 'pill' && displayPillStringValue(listItem)}
                {listItem?.type === 'document' && displayDocumentsList(listItem?.value)}
                {listItem?.type === 'list' && displayListValues(listItem?.value)}
                {listItem?.type === 'arrayList' && displayObjectListValues(listItem?.value)}
                {listItem?.type === 'objectList' && displayObjectListValues(listItem?.value)}
                {listItem?.type === 'objectSubList' && displayObjectListValues(listItem?.value)}
                {listItem?.type === 'arrayFromExtension' &&
                  displayObjectListValues(listItem?.value)}
                {listItem?.type === 'subtitle' && (
                  <p className={classes.subTitle}>{listItem?.value}</p>
                )}
                {listItem?.type === 'string' &&
                  (listItem?.requestKeyForSelfReporting === 'note' ? (
                    renderNote(listItem)
                  ) : (
                    <p style={{ ...customClasses.column_value }}>
                      {listItem?.capitalize
                        ? capitalizeFirstLetter(listItem?.value)
                        : listItem?.value}
                    </p>
                  ))}
                {listItem?.type === 'nameWithCode' && (
                  <p style={{ ...customClasses.column_value }}>
                    {listItem?.capitalize
                      ? capitalizeFirstLetter(listItem?.value)
                      : listItem?.value}
                  </p>
                )}
                {listItem?.type === 'filter' && (
                  <p style={{ ...customClasses.column_value }}>{listItem?.value}</p>
                )}
                {listItem?.type === 'date' && (
                  <p style={{ ...customClasses.column_value }}>{getDate(listItem?.value)}</p>
                )}
                {listItem?.type === 'time' && (
                  <p style={{ ...customClasses.column_value }}>{getTime(listItem?.value)}</p>
                )}
              </div>
            );
          })}
          <div style={{ width: '100%' }}>{props.children}</div>
        </div>

        {customBodyComponent && (
          <div>
            <customBodyComponent.component {...customBodyComponent.props} />
          </div>
        )}

        {/* options ui */}
        {viewProfileData && viewProfileData?.length > 0 && (
          <ViewProfile viewProfileData={viewProfileData} />
        )}
        {!hideOptions && markRestricted && <OptionsView />}
        {snackbarOpen && (
          <SnackbarToast
            open={snackbarOpen}
            message={snackbarMessage}
            duration={3000}
            onClose={() => setSnackbarOpen(false)}
            alertStyle={{ backgroundColor: '#000000' }}
            type="success"
          />
        )}
      </div>
    </Modal>
  );
}

export default withStyles(styles)(ViewDetailsFlyout);
