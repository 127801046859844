import gql from 'graphql-tag';
import client from '../../components/ApolloClient';

export const saveAddPartnerFeedback = async (organizationId, feedback) => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation storeAddPartnerFeedback($organizationId: Int, $feedback: Feedback) {
          storeAddPartnerFeedback(organizationId: $organizationId, feedback: $feedback) {
            status
            __typename
          }
        }
      `,
      variables: {
        organizationId,
        feedback,
      },
    });
    return result;
  } catch (error) {
    console.error('Error saving feedback', error);
  }
};

export const updatePartnerAuthFlowStatus = async (organizationId, flowStatus) => {
  try {
    const result = await client.mutate({
      mutation: gql`
        mutation updatePartnerAuthFlowStatus($organizationId: Int, $flowStatus: String) {
          updatePartnerAuthFlowStatus(organizationId: $organizationId, flowStatus: $flowStatus) {
            status
            __typename
          }
        }
      `,
      variables: {
        organizationId: Number(organizationId),
        flowStatus,
      },
    });
    return result;
  } catch (error) {
    console.error('Error updating auth flow status', error);
  }
};

export const getPartnerAuthFlowStatus = async () => {
  try {
    const {
      data: { getPartnerAuthFlowStatus },
    } = await client.query({
      query: gql`
        query getPartnerAuthFlowStatus {
          getPartnerAuthFlowStatus {
            organizationId
            organizationName
            organizationIconUrl
            userId
            status
            createdAt
            updatedAt
          }
        }
      `,
      fetchPolicy: 'no-cache',
    });
    return getPartnerAuthFlowStatus;
  } catch (error) {
    console.error('Error getting auth flow status', error);
    return [];
  }
};
