import React, { useContext, useEffect } from 'react';
import { SwipeableDrawer, List, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import { withStyles } from '@mui/styles';
// import { withRouter } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Avatar from '../../atoms/Avatar';
import Logout from '../../../assets/images/logout _1.svg';
import WhiteBackArrow from '../../../assets/images/whitearrow.svg';
import WhiteLogOut from '../../../assets/images/logout.svg';
import { NAVIGATION_CONTENT } from '../../../utils/constants';
import clsx from 'clsx';
import { getUserName, setArrayAtIndex } from '../../../utils/helper';
import { auth, UserInfoContext } from '../../Routes';
import withRouter from '../../withRoute';

const styles = (theme) => ({
  list: {
    width: '222px',
    marginTop: '32px',
  },

  userName: {
    marginLeft: '6%',
    width: '127px',
    wordWrap: 'break-word',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '55px',
    width: 'auto',
    paddingLeft: '10%',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&:focus': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  divider: {
    marginLeft: '6%',
    marginRight: '6%',
  },
  logoutImage: {
    width: '15px',
    height: '17px',
    marginRight: '10px',
  },
  backIcon: {
    height: '26px',
    width: '26px',
  },
  avatar: {
    marginLeft: '6px',
    marginRight: '15px',
  },
  hoveredText: {
    color: 'white',
    fontWeight: 900,
  },
  listContainer: {
    border: 0,
  },
});

const NavigationDrawer = (props) => {
  const { classes, isOpen, toggle, showModal } = props;
  const [isNavDrawerOpen, setIsNavDrawerOpen] = React.useState(false);
  const { userInfo } = useContext(UserInfoContext);
  const [isHoverOrFocusUser, setIsHoverOrFocusUser] = React.useState(false);
  const [isHoverOrFocusList1, setIsHoverOrFocusList1] = React.useState([
    false,
    false,
    false,
    false,
  ]);
  const [isHoverOrFocusList2, setIsHoverOrFocusList2] = React.useState([false, false]);
  const [isHoverOrFocusLogout, setIsHoverOrFocusLogout] = React.useState(false);

  useEffect(() => {
    isOpen !== isNavDrawerOpen && setIsNavDrawerOpen(isOpen);
  }, [isOpen]);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return false;
    }

    setIsNavDrawerOpen(open);
    toggle && toggle(open);
    return true;
  };

  const handleLogoutClick = () => {
    auth.logout();
  };

  const handleNavigation = (link) => (event) => {
    const isToggled = toggleDrawer(false)(event);
    if (isToggled) {
      if (link && link.includes('http')) {
        window.open(link);
      } else {
        props.navigate(link);
      }
      setIsHoverOrFocusList1([false, false, false, false]);
      setIsHoverOrFocusList2([false, false]);
      setIsHoverOrFocusUser(false);
      setIsHoverOrFocusLogout(false);
    }
  };

  const showConsentModal = (consentType) => (event) => {
    showModal(consentType);
  };

  return (
    <React.Fragment>
      <SwipeableDrawer
        id="swipeable-menu-bar"
        open={isNavDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div id="list-outer-container" className={classes.list}>
          <div
            id="user-list-item"
            className={classes.itemContainer}
            // onMouseOver={() => { setIsHoverOrFocusUser(true) }}
            // onFocus={() => { setIsHoverOrFocusUser(true) }}
            // onBlur={() => { setIsHoverOrFocusUser(false) }}
            // onMouseOut={() => { setIsHoverOrFocusUser(false) }}
          >
            {isHoverOrFocusUser ? (
              <img
                id="white-arrow-back"
                onClick={toggleDrawer(false)}
                className={classes.backIcon}
                src={WhiteBackArrow}
              />
            ) : (
              <ArrowBackIcon
                id="back-arrow"
                onClick={toggleDrawer(false)}
                className={classes.backIcon}
              />
            )}
            <Avatar black={!isHoverOrFocusUser} className={classes.avatar} />
            <Typography
              variant="h3"
              id="user-name"
              className={clsx(classes.userName, isHoverOrFocusUser ? classes.hoveredText : null)}
            >
              {getUserName(userInfo.firstName, userInfo.lastName)}
            </Typography>
          </div>
          <div role="presentation">
            <List id="list-inner-container-1" className={classes.listContainer}>
              {NAVIGATION_CONTENT.MAIN_BODY.map((nav, index) => (
                <ListItem
                  id={`list-item-${index}`}
                  button
                  key={index}
                  disableGutters
                  className={classes.itemContainer}
                  onMouseOver={() => {
                    setIsHoverOrFocusList1(setArrayAtIndex(isHoverOrFocusList1, index, true));
                  }}
                  onFocus={() => {
                    setIsHoverOrFocusList1(setArrayAtIndex(isHoverOrFocusList1, index, true));
                  }}
                  onBlur={() => {
                    setIsHoverOrFocusList1(setArrayAtIndex(isHoverOrFocusList1, index, false));
                  }}
                  onMouseOut={() => {
                    setIsHoverOrFocusList1(setArrayAtIndex(isHoverOrFocusList1, index, false));
                  }}
                  onClick={handleNavigation(nav.link)}
                >
                  <ListItemText
                    id={`list-item-text-${index}`}
                    primaryTypographyProps={{
                      className: isHoverOrFocusList1[index] ? classes.hoveredText : null,
                      variant: 'body2',
                    }}
                    primary={nav.name}
                  />
                </ListItem>
              ))}
            </List>
            <Divider id="divider" className={classes.divider} />
            <List id="list-inner-container-2" className={classes.listContainer}>
              {NAVIGATION_CONTENT.SECONDARY_BODY.map((nav, index) => (
                <ListItem
                  id={`list-item-${index}`}
                  disableGutters
                  className={classes.itemContainer}
                  button
                  key={index}
                  onMouseOver={() => {
                    setIsHoverOrFocusList2(setArrayAtIndex(isHoverOrFocusList2, index, true));
                  }}
                  onFocus={() => {
                    setIsHoverOrFocusList2(setArrayAtIndex(isHoverOrFocusList2, index, true));
                  }}
                  onBlur={() => {
                    setIsHoverOrFocusList2(setArrayAtIndex(isHoverOrFocusList2, index, false));
                  }}
                  onMouseOut={() => {
                    setIsHoverOrFocusList2(setArrayAtIndex(isHoverOrFocusList2, index, false));
                  }}
                  onClick={
                    nav.consentType ? showConsentModal(nav.consentType) : handleNavigation(nav.link)
                  }
                >
                  <ListItemText
                    id={`list-item-text-${index}`}
                    primaryTypographyProps={{
                      className: isHoverOrFocusList2[index] ? classes.hoveredText : null,
                      variant: 'body2',
                    }}
                    primary={nav.name}
                  />
                </ListItem>
              ))}
            </List>
            <div
              id="logout-item"
              tabIndex="0"
              className={classes.itemContainer}
              onClick={handleLogoutClick}
              onKeyDown={handleLogoutClick}
              onMouseOver={() => {
                setIsHoverOrFocusLogout(true);
              }}
              onFocus={() => setIsHoverOrFocusLogout(true)}
              onBlur={() => setIsHoverOrFocusLogout(false)}
              onMouseOut={() => setIsHoverOrFocusLogout(false)}
            >
              <img
                id="logout-icon"
                className={classes.logoutImage}
                src={isHoverOrFocusLogout ? WhiteLogOut : Logout}
              />
              <Typography
                id="logout-text"
                variant="body2"
                className={clsx(isHoverOrFocusLogout ? classes.hoveredText : null)}
              >
                {NAVIGATION_CONTENT.LOGOUT_TXT}
              </Typography>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
};
NavigationDrawer.defaultProps = {
  isOpen: false,
};
export default withRouter(withStyles(styles, { withTheme: true })(NavigationDrawer));
