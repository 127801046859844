import { makeStyles } from '@mui/styles';
import colors from '../../../utils/colors';

export const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: colors.WHITE,
    borderRadius: theme.spacing(1.5),
    border: 'none',
    height: '100px',
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0),
    width: '100%',
    alignItems: 'center',
  },
  imgStyle: {
    height: '100px',
    width: '80px',
  },
  imgSection: {
    display: 'flex',
  },
  awardPointsSection: {
    paddingLeft: theme.spacing(3),
  },
  totalAwardPoints: {
    color: '#667085',
    fontFamily: 'Inter',
    fontSize: theme.spacing(2.25),
    lineHeight: '24px',
    height: theme.spacing(3),
    paddingTop: theme.spacing(2.25),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(2),
    },
  },
  awardPoints: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: theme.spacing(3),
    lineHeight: '36px',
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.spacing(2.5),
    },
  },
  btn: {
    marginRight: theme.spacing(3),
    borderColor: '#A0A4A6',
    color: colors.BLUE,
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    fontWeight: 500,
    lineHeight: '40px',
    height: '40px',
    padding: '8px 30px',
    borderRadius: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      padding: '0px 8px',
      fontSize: theme.spacing(1.5),
    },
    '&:hover': {
      color: colors.WHITE,
      backgroundColor: colors.BLUE,
    },
  },
  awardPointInDollar: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: '#667085',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '8px',
  },

}));
