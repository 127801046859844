import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPartnerAuthFlowStatus } from './api';

const initialState = {
  authFlowStatuses: [],
};

export const fetchAndSetAuthFlowStatuses = createAsyncThunk(
  'circles/fetchAndSetAuthFlowStatuses',
  getPartnerAuthFlowStatus,
);

export const circlesSlice = createSlice({
  name: 'circles',
  initialState,
  reducers: {
    updateAuthFlowStatus: (state, action) => {
      state.authFlowStatuses = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAndSetAuthFlowStatuses.fulfilled, (state, action) => ({
      authFlowStatuses: action.payload,
    }));
  },
});

export const { updateAuthFlowStatus } = circlesSlice.actions;

export default circlesSlice.reducer;
