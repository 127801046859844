import { DEFAULT_NULL_REPLACEMENT, D_SOURCE, SELF_REPORTING_LABELS } from '../../utils/constants';
import {
  extractRestricted,
  findDataSource,
  formatDateInMMDDYYYYWithComma,
  getCommaSeparatedDate,
} from '../../utils/helper';
// import { getBgColorForInitials } from '../AwardPoints/GiftAwardPoints/helper';

export const getFormattedFamilyHistoryRecords = (responseList = {}) => {
  let familyMemberHistoryList =
    responseList &&
    responseList.familyMemberHistoryList &&
    JSON.parse(responseList.familyMemberHistoryList);
  familyMemberHistoryList = familyMemberHistoryList?.map((member) => {
    return {
      id: member.id,
      recordedOn: member?.date ? getCommaSeparatedDate(member.date) : DEFAULT_NULL_REPLACEMENT,
      status: ['health-unknown', 'entered-in-error'].includes(member?.status)
        ? 'Unknown'
        : member?.status || DEFAULT_NULL_REPLACEMENT,
      dataSource: findDataSource(member?.identifier),
      relationShip:
        member?.relationship?.coding?.[0]?.display ||
        member?.relationship?.text ||
        DEFAULT_NULL_REPLACEMENT,
      name: {
        Name: member?.name || DEFAULT_NULL_REPLACEMENT,
        imgUrl: '',
      },
      bgColor: '#00A0DF',
      // getBgColorForInitials(),
    };
  });
  familyMemberHistoryList = familyMemberHistoryList?.filter((family) => family !== null);

  return { data: familyMemberHistoryList, count: responseList?.count };
};
export const getFormattedFamilyMemberHistoryDataById = (familyHistoryById = {}, isCircle) => {
  const processedFamilyMemberDetails = {
    id: familyHistoryById?.id,
    title: familyHistoryById?.name || 'Family history',
    markAsRestrictedInModal: familyHistoryById?.meta?.security?.length
      ? extractRestricted(familyHistoryById.meta.security)
      : false,
    columns: [
      {
        column_name: SELF_REPORTING_LABELS.STATUS,
        value: ['entered-in-error', 'health-unknown'].includes(familyHistoryById?.status)
          ? 'unknown'
          : familyHistoryById?.status || DEFAULT_NULL_REPLACEMENT,
      },
      {
        column_name: SELF_REPORTING_LABELS.RECORDED_ON,
        value: familyHistoryById?.date
          ? getCommaSeparatedDate(familyHistoryById.date)
          : DEFAULT_NULL_REPLACEMENT,
      },
      {
        column_name: SELF_REPORTING_LABELS.GENDER,
        value:
          familyHistoryById?.sex?.coding?.[0]?.display ||
          familyHistoryById?.sex?.text ||
          DEFAULT_NULL_REPLACEMENT,
      },
      {
        column_name: SELF_REPORTING_LABELS.RELATIONSHIP,
        value:
          familyHistoryById?.relationship?.coding?.[0]?.display ||
          familyHistoryById?.relationship?.text ||
          DEFAULT_NULL_REPLACEMENT,
      },
    ],
    body: {
      conditions: familyHistoryById?.condition,
      reasonForHistory: familyHistoryById?.reasonCode,
      note: familyHistoryById?.note?.[0]?.text || DEFAULT_NULL_REPLACEMENT,
    },
  };
  if (!isCircle) {
    processedFamilyMemberDetails?.columns.unshift({
      column_name: D_SOURCE,
      value: findDataSource(familyHistoryById?.identifier),
    });
  }

  return processedFamilyMemberDetails;
};
