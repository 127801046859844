import React from 'react';
import avatar from '../../../assets/images/defaultAvatar.svg';
import avatarBlack from '../../../assets/images/defaultAvatarBlack.svg';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  image: {
    height: 34,
    width: 35,
  },
});

const Avatar = (props) => {
  const { classes, black } = props;

  return (
    <React.Fragment>
      {black ? (
        <img id="black-avatar-icon" src={avatarBlack} className={classes.image} />
      ) : (
        <img id="avatar-icon" src={avatar} className={classes.image} />
      )}
    </React.Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(Avatar);
