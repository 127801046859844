import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

const StyledTypography = styled(Typography)({
  paddingTop: 40,
  paddingLeft: 60,
  paddingRight: 60,
  color: '#8C8C8C',
  lineHeight: '24px',
  fontSize: 16,
  fontWeight: 'normal',
  letterSpacing: 0.62,
});

export default function Description({ children }) {
  return <StyledTypography>{children}</StyledTypography>;
}
