import React, { Component } from 'react';
import Button from '../../components/atoms/Button';
import PageTemplate from '../../components/templates/PageTemplate';
import { withStyles } from '@mui/styles';
import { Typography, Link, Box, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '../../components/atoms/TextField';
import { IMAGE_ALTERNATIVE_TXT, LANDING_PAGE, LOG_IN, SIGNUP } from '../../utils/constants';
import Appstore from '../../assets/images/appstore.png';
import Playstore from '../../assets/images/playstore.png';
import LandingImage from '../../assets/images/landing.png';
import LogoAlternate from '../../assets/images/logoalternate.png';
import { auth } from '../../components/Routes';
import gql from 'graphql-tag';
import ReferralService from '../../service/Referral';
import { validateEmail, validatePassword, validateRePassword } from '../../utils/validations';
import { rankPassword, fireHanselEvent } from '../../utils/helper';
import { SIGN_UP, ERROR_MSGS, FIREBASE_EVENT_NAMES } from '../../utils/constants';
import User from '../../service/User';
import queryString from 'query-string';
import { logUserEvent } from '../../utils/UserEvent';
import linkedin from '../../assets/images/linkedin.png';
import linkedInIcon from '../../assets/images/linkedInIcon.svg';
import google from '../../assets/images/google.png';
import apple from '../../assets/images/apple.png';
import Description from '../../components/typography/Description';
import withRouter from '../../components/withRoute';
import Carousel from '../../components/Carousel/Carousel';

export const VERIFY_REFERRAL_CODE = gql`
  query verifyReferralCode($referralCode: String) {
    verifyReferralCode(referralCode: $referralCode) {
      verify
    }
  }
`;

export const checkIfEmailExistQuery = gql`
  query checkIfEmailExist($emailId: String) {
    checkIfEmailExist(emailId: $emailId) {
      errorCode
      message
    }
  }
`;

export const VERIFY_PROMO_CODE = gql`
  query verifyPromoCode($promoCode: String) {
    verifyPromoCode(promoCode: $promoCode) {
      verify
    }
  }
`;

const publicIp = require('public-ip');

const styles = (theme) => ({
  leftContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    height: '100%',
    width: '44.44%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 640,
  },
  strength: {
    ...theme.customComponent.passwordStrength,
  },
  cover: {
    alignItems: 'center',
    direction: 'column',
    justify: 'center',
  },
  heading: {
    marginTop: 8,
    fontSize: '28px',
    fontWeight: 600,
    color: '#000000',
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    height: 40,
    lineHeight: '40px',
    marginBottom: 16,
  },
  subtitle: {
    height: 24,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#8C8C8C',
    fontFamily: 'Inter',
    fontStyle: 'normal,',
  },
  infoIconStyle: {
    height: '12px',
    width: '12px',
    paddingRight: '2px',
  },
  loginLinkStyle: {
    marginLeft: 6,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    color: '#036FCB',
  },
  textFieldTitle: {
    marginTop: 16,
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    lineHeight: '16px',
    color: '#000000',
  },
  appStoreLink: { color: '#036FCB' },
  lineStyle: {
    marginLeft: 6,
    marginRight: 6,
    width: '100%',
    height: 0.1,
    color: '#979797',
    border: '1px solid #979797',
    marginTop: 10,
  },
  loginTextTitle: {
    display: 'flex',
    marginTop: 16,
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    lineHeight: '17px',
    color: '#000000',
  },
  separationLine: {
    display: 'flex',
    marginTop: 30,
    fontSize: '14px',
    fontWeight: 500,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    lineHeight: '20px',
    color: '#000000',
  },
  textFieldStyle: {
    borderRadius: 4,
    border: '1px solid #D0D0D0',
    backgroundColor: '#FFFFFF',
    '& .MuiOutlinedInput-input': {
      // height: '1.1876em',
      fontFamily: 'Inter',
      fontSize: 13,
    },
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
  },
  errMessage: {
    color: '#FF0000',
  },
  successText: {
    color: '#007D32',
  },
  defaultText: {
    color: '#666666',
  },
  image: {
    marginTop: 16,
    paddingRight: 20,
  },
  button: {
    padding: 10,
  },

  leftContainerBox: { flex: '0.35', justifyContent: 'center' },
  mainPageBox: {
    [theme.breakpoints.only('xs')]: {
      width: 'calc(100% - 60px)',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% - 300px)',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% - 100px)',
    },
    [theme.breakpoints.only('lg')]: {
      width: '480px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '480px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  landingImageBox: {
    paddingLeft: 72,
    marginTop: 80,
  },
  leftSideTextBlack: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#101828',
  },
  leftSideTextBlue: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#00A0DF',
  },
  leftSideTextBlack2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#343B40',
  },
  landingImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    objectFit: 'cover',
    maxWidth: 640,
    maxHeight: 616,
  },
  mainPage: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      alignItems: 'center',
    },
    background: '#f7f7f7',
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',

    minHeight: '100vh',
  },
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'scroll',
  },
  alternateLogo: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    paddingLeft: 72,
    paddingRight: 72,
    maxWidth: '100%',
  },
  logoAlternateContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    paddingTop: 72,
    maxWidth: '60%',
  },
  linkContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start-flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    paddingLeft: 72,
    flex: 1,
  },
  createAccountButtom: {
    //width: '480px',
    height: 48,
    padding: '8px 24px',
    borderRadius: 40,
    backgroundColor: '#036FCB',
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    fontWeight: 500,
    marginTop: 40,
  },
  socialAccountButtom: {
    color: 'rgba(0, 0, 0, 0.87)',
    textTransform: 'none',
    height: 48,
    borderRadius: 40,
    border: '1px solid #A0A4A6',
    backgroundColor: '#FFFFFF',
    marginTop: 16,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '24px',
    padding: '12px 32px 8px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: 'none',
      color: '#000000',
      border: '1px solid #A0A4A6',
    },
  },
  socialAccountButtomImage: {
    width: '20px',
    height: '20px',
    paddingRight: '25px',
  },
});

class Signup extends Component {
  constructor(props) {
    super(props);
    // get the referral from url if any
    const params = queryString.parse(this.props.location?.search);
    console.log('signup param', params, props, this.props);
    const referralCode = params.referralCode;
    this.state = {
      registrationAttempt: false,
      email: '',
      password: '',
      rePassword: '',
      emailError: false,
      passwordError: false,
      rePasswordError: false,
      emailErrorMsg: '',
      passwordErrorMsg: '',
      rePasswordErrorMsg: '',
      showPassword: false,
      showPasswordRe: false,
      passwordMatched: false,
      passwordStrength: 0,
      verifyRedirect: false,
      code: referralCode || '',
      referralCode: '',
      promoCode: '',
      referralCodeError: false,
      referralCodeSuccess: false,
      referralCodeErrorMsg: '',
      referralCodeSuccessMsg: '',
      noPasswordError: false,
    };
    referralCode && this.verifyReferral();
  }

  componentDidMount() {
    fireHanselEvent('MPH_SIGNUP_PAGE_LOAD', {});
  }
  emailValidation = () => {
    const { email } = this.state;
    const { isValid, errorMessage } = validateEmail(email);
    if (!isValid) {
      this.setState({
        emailError: true,
        emailErrorMsg: errorMessage,
      });
      return false;
    }
    return true;
  };
  passwordValidation = () => {
    const { password, passwordStrength } = this.state;
    const { isValid, errorMessage } = validatePassword(password, passwordStrength);
    if (!isValid) {
      this.setState({
        passwordError: true,
        passwordErrorMsg: errorMessage,
      });

      return false;
    }
    return true;
  };

  rePasswordValidation = () => {
    const { password, rePassword } = this.state;
    const { isValid, errorMessage } = validateRePassword(password, rePassword);
    if (!isValid) {
      this.setState({
        rePasswordError: true,
        rePasswordErrorMsg: errorMessage,
      });
      return false;
    }
    return true;
  };

  handleFieldChange = (name, value) => {
    const { registrationAttempt } = this.state;
    if (!registrationAttempt) {
      this.setState({
        registrationAttempt: true,
      });
      logUserEvent(FIREBASE_EVENT_NAMES.REGISTRATION_ATTEMPT);
    }

    const nameError = `${name}Error`;
    const nameErrorMsg = `${name}ErrorMsg`;
    const caretStart = event.target.selectionStart;
    const phoneLength = value.length;

    this.setState(
      {
        [name]: value,
        [nameError]: false,
        [nameErrorMsg]: '',
        error: '',
      },
      () => {
        if (name === 'phoneno' && phoneLength > caretStart) {
          event.target.selectionStart = event.target.selectionEnd = caretStart;
        }
      },
    );

    if (name === 'password' || name === 'rePassword') {
      this.setState((prevState, props) => ({
        passwordMatched:
          prevState[name] !== '' ? prevState.password === prevState.rePassword : false,
        showPasswordRe:
          prevState[name] !== ''
            ? prevState.password === prevState.rePassword
              ? false
              : prevState['showPasswordRe']
            : prevState['showPasswordRe'],
      }));
    }

    if (name === 'password') {
      this.setState((prevState, props) => {
        return prevState.passwordMatched
          ? {
              rePasswordError: false,
              rePasswordErrorMsg: '',
            }
          : null;
      });
      this.setState({
        passwordStrength: rankPassword(value),
      });
      if (rankPassword(value) === 4) {
        this.setState({
          noPasswordError: true,
        });
      } else {
        this.setState({
          noPasswordError: false,
        });
      }
    }
    if (name === 'code') {
      this.setState({
        referralCodeSuccessMsg: '',
        referralCodeErrorMsg: '',
        referralCodeError: false,
        referralCodeSuccess: false,
      });
    }
  };
  validation = () => {
    let error = true;
    if (!this.emailValidation()) {
      error = false;
    }
    if (!this.passwordValidation()) {
      error = false;
    }
    if (!this.rePasswordValidation()) {
      error = false;
    }
    return error;
  };
  signUpCall = async () => {
    const { email, password } = this.state;
    if (this.validation()) {
      User.checkIfEmailExist(checkIfEmailExistQuery, email)
        .then((res) => {
          if (res.data.checkIfEmailExist.errorCode === 404) {
            //Redirect to phone verification after account creation
            // this.props.history.push({
            //   pathname: `/complete-registration-signup`,
            //   userSchemaObj: {
            //     email: email,
            //     password: password,
            //     referral: this.state.code,
            //     isReferralCode: Boolean(this.state.referralCode),
            //     isPromoCode: Boolean(this.state.promoCode),
            //   },
            // });
            this.props.navigate(`/complete-registration-signup`,{state:{userSchemaObj: {
              email: email,
              password: password,
              referral: this.state.code,
              isReferralCode: Boolean(this.state.referralCode),
              isPromoCode: Boolean(this.state.promoCode),
            }}});
          } else {
            this.setState({
              emailError: true,
              emailErrorMsg: 'Email already exist... Try log in',
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            emailError: true,
            emailErrorMsg: err.errorMessage,
          });
        });
    }
  };
  showPassword = (type) => {
    const passwordField = `showPassword${type}`;

    this.setState((prevState, props) => {
      return { [passwordField]: !prevState[passwordField] };
    });
  };
  handleLoginClick = () => {
    window.location.href = '/login';
  };
  verifyReferral = () => {
    const { code } = this.state;
    if (code) {
      ReferralService.verifyReferralCode(VERIFY_REFERRAL_CODE, code)
        .then((res) => {
          const isValid = res.verify;
          if (isValid) {
            this.setState({
              referralCodeSuccessMsg: SIGN_UP.REFERRAL_CODE_APPLIED,
              referralCodeSuccess: true,
              referralCode: code,
              promoCode: '',
            });
          } else {
            ReferralService.verifyPromoCode(VERIFY_PROMO_CODE, code.toUpperCase()).then((res) => {
              const isPromoValid = res.verify;
              if (isPromoValid) {
                this.setState({
                  referralCodeSuccessMsg: SIGN_UP.PROMO_CODE_APPLIED,
                  referralCodeSuccess: true,
                  promoCode: code,
                  referralCode: '',
                });
              } else {
                this.setState({
                  referralCodeError: true,
                  referralCodeErrorMsg: ERROR_MSGS.REFERRAL_CODE.INVALID,
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleSocialLogin = (path, type) => {
    const referralPromoCode = {
      isReferral: !!this.state.referralCode,
      isPromoCode: !!this.state.promoCode,
      code: this.state.code,
    };
    auth.setReferralPromoCode(referralPromoCode);
    auth.socialLogin(path, type);
  };

  render() {
    const { classes } = this.props;
    const {
      emailError,
      passwordError,
      rePasswordError,
      passwordErrorMsg,
      emailErrorMsg,
      rePasswordErrorMsg,
      showPassword,
      showPasswordRe,
      passwordMatched,
      passwordStrength,
      noPasswordError,
    } = this.state;

    return (
      <Box className={classes.root}>
        <Box className={classes.leftContainer}>
          <Box className={classes.leftContainerBox}>
            <Box className={classes.logoAlternateContainer}>
              <img
                src={LogoAlternate}
                alt={IMAGE_ALTERNATIVE_TXT.LOGO}
                className={classes.alternateLogo}
              />
            </Box>
            {/* <Description>{SIGNUP.ONE_APP}</Description> */}
          </Box>
          <Box className={classes.landingImageBox}>
            <Carousel />
          </Box>
          <Box className={classes.linkContainer}>
            <Typography variant="h4" className={classes.appInfo}>
              {LOG_IN.DOWNLOAD}
              <Link
                href="https://apps.apple.com/us/app/mpoweredhealth/id1525925137"
                target="_blank"
                rel="noopener"
                variant="h4"
                className={classes.appStoreLink}
              >
                {LOG_IN.IOS}
              </Link>
              {LOG_IN.AND}
              <Link
                href="https://play.google.com/store/apps/details?id=com.mpoweredhealth"
                target="_blank"
                rel="noopener"
                variant="h4"
                className={classes.appStoreLink}
              >
                {LOG_IN.ANDROID}
              </Link>
            </Typography>
            <Box>
              <Link
                href="https://apps.apple.com/us/app/mpoweredhealth/id1525925137"
                target="_blank"
                rel="noopener"
              >
                <img src={Appstore} alt="App Store" className={classes.image} />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.mpoweredhealth"
                target="_blank"
                rel="noopener"
              >
                <img src={Playstore} alt="Play Store" className={classes.image} />
              </Link>
            </Box>
          </Box>
        </Box>
        <Box className={classes.mainPage}>
          <PageTemplate>
            <Box className={classes.mainPageBox}>
              <Typography className={classes.subtitle}>{SIGNUP.WELCOME}</Typography>
              <Typography variant="h2" className={classes.heading}>
                {SIGNUP.CREATE_ACCOUNT}
              </Typography>
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGNUP.EMAIL}
              </Typography>
              <TextField
                className={classes.textFieldStyle}
                id="email"
                name="email"
                color="primary"
                variant="outlined"
                size="small"
                type="text"
                placeholder={SIGNUP.EMAIL_PLACEHOLDER}
                onFieldChange={this.handleFieldChange}
              />
              {emailError ? (
                <Typography variant="h6" className={[classes.helperText, classes.errMessage]}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {emailErrorMsg}
                </Typography>
              ) : null}
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGNUP.PASSWORD}
              </Typography>
              <TextField
                className={classes.textFieldStyle}
                id="password-field"
                name="password"
                lightBorder={false}
                variant="outlined"
                size="small"
                color="primary"
                placeholder={SIGNUP.PASSWORD_PLACEHOLDER}
                type={showPassword ? 'text' : 'password'}
                onFieldChange={this.handleFieldChange}
                onShowPassword={() => this.showPassword('')}
                showPassword={true}
                passwordStrength={passwordStrength === 4}
              />
              {passwordError ? (
                <Typography variant="h6" className={[classes.helperText, classes.errMessage]}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {passwordErrorMsg}
                </Typography>
              ) : noPasswordError ? (
                <Typography variant="h6" className={[classes.helperText, classes.successText]}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon> Looks good
                </Typography>
              ) : (
                <Typography variant="h6" className={[classes.helperText, classes.defaultText]}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon> Must include an upper case
                  and lower case , number and symbol.
                </Typography>
              )}
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGNUP.CONFIRM_PASSWORD}
              </Typography>
              <TextField
                className={classes.textFieldStyle}
                id="repassword"
                name="rePassword"
                lightBorder={false}
                variant="outlined"
                size="small"
                color="primary"
                placeholder={SIGNUP.PASSWORD_PLACEHOLDER}
                type={showPasswordRe ? 'text' : 'password'}
                onFieldChange={this.handleFieldChange}
                onShowPassword={() => this.showPassword('Re')}
                showPassword={true}
              />
              {rePasswordError ? (
                <Typography variant="h6" className={[classes.helperText, classes.errMessage]}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {rePasswordErrorMsg}
                </Typography>
              ) : null}
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGNUP.REFERRAL}
              </Typography>
              <TextField
                id="referral-field"
                className={classes.textFieldStyle}
                lightBorder={false}
                variant="outlined"
                size="small"
                color="primary"
                value={this.state.code || ''}
                name="code"
                placeholder="Enter referral/promo code"
                onFieldChange={this.handleFieldChange}
                onBlur={this.verifyReferral}
              />
              {this.state.referralCodeError ? (
                <Typography variant="h6" className={[classes.helperText, classes.errMessage]}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {this.state.referralCodeErrorMsg}
                </Typography>
              ) : this.state.referralCodeSuccess ? (
                <Typography variant="h6" className={[classes.helperText, classes.successText]}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {this.state.referralCodeSuccessMsg}
                </Typography>
              ) : null}
              <Button
                color="secondary"
                title="Sign up"
                variant="contained"
                onClick={() => this.signUpCall()}
                className={classes.createAccountButtom}
              />
              <Typography variant="h6" className={classes.loginTextTitle}>
                {SIGNUP.HAVE_ACCOUNT}
                <Link onClick={this.handleLoginClick} className={classes.loginLinkStyle}>
                  {SIGNUP.LOGIN}
                </Link>
              </Typography>
              <Typography variant="h6" className={classes.separationLine}>
                <hr className={classes.lineStyle} />
                {SIGNUP.OR}
                <hr className={classes.lineStyle} />
              </Typography>
              <Button
                title="Continue with Google "
                variant="contained"
                className={classes.socialAccountButtom}
                onClick={() => this.handleSocialLogin('home', 'consumer-google')}
                startIcon={<img className={classes.socialAccountButtomImage} src={google}></img>}
              />
              <Button
                title="Continue with Linkedin"
                variant="contained"
                onClick={() => this.handleSocialLogin('home', 'consumer-linkedin')}
                className={classes.socialAccountButtom}
                startIcon={<img className={classes.socialAccountButtomImage} src={linkedInIcon}></img>}
              />
              <Button
                title="Continue with Apple"
                variant="contained"
                onClick={() => this.handleSocialLogin('home', 'consumer-apple')}
                className={classes.socialAccountButtom}
                startIcon={<img className={classes.socialAccountButtomImage} src={apple}></img>}
              />
            </Box>
          </PageTemplate>
        </Box>
      </Box>
    );
  }
}
export default withRouter(withStyles(styles, { withTheme: true })(Signup));
