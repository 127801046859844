import client from '../components/ApolloClient';
import gql from 'graphql-tag';

const STORE_REFERRAL_INITIATOR_REQUEST = gql`
  mutation storeReferralInitiatorRequest($codeRequest: CodeRequest) {
    storeReferralInitiatorRequest(codeRequest: $codeRequest) {
      initiateStatus
      message
    }
  }
`;

export default {
  verifyReferralCode(query, referralCode) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          variables: {
            referralCode: referralCode,
          },
        })
        .then((res) => {
          resolve(res.data.verifyReferralCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  verifyPromoCode(query, promoCode) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          variables: {
            promoCode: promoCode,
          },
        })
        .then((res) => {
          resolve(res.data.verifyPromoCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchReferralCode(query) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
        })
        .then((res) => {
          resolve(res.data.fetchReferralCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  storeReferralInitiatorRequest(referralCode, mutation = STORE_REFERRAL_INITIATOR_REQUEST) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            codeRequest: {
              code: referralCode,
            },
          },
        })
        .then((res) => {
          resolve(res.data.storeReferralInitiatorRequest);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
