import React, { useRef, useCallback } from 'react';
import { IdleTimer } from './IdleTimer';
import { onActive, onIdle, onAction } from '../../AutoLogout';
import { IDLE_TIME } from '../../utils/constants';

/**
 * Detects user Activity and Idle.
 * Performs logout if user is idle for more then IDLE_TIME period of time.
 * @component UserActivityDetector
 */
const UserActivityDetector = (props) => {
  const idleTimer = useRef(null);

  const handleOnAction = useCallback(
    (event) => {
      onAction(event, idleTimer.current);
    },
    [idleTimer.current],
  );

  const handleOnActivate = useCallback(
    (event) => {
      onActive(event, idleTimer.current);
    },
    [idleTimer.current],
  );

  const handleOnIdle = useCallback(
    (event) => {
      onIdle(event, idleTimer.current);
    },
    [idleTimer.current],
  );

  return (
    <IdleTimer
      crossTab={true}
      debounce={250}
      ref={(ref) => {
        idleTimer.current = ref;
      }}
      timeout={IDLE_TIME}
      onActive={handleOnActivate}
      onAction={handleOnAction}
      onIdle={handleOnIdle}
    >
      {props.children}
    </IdleTimer>
  );
};

export default UserActivityDetector;
