import gql from 'graphql-tag';

import client from '../components/ApolloClient';

const ADD_FAVOURITE_MUTATION = gql`
  mutation createUserFavoriteMapping($userFavourite: UserFavourite) {
    createUserFavoriteMapping(userFavourite: $userFavourite)
  }
`;
const FETCH_FAVOURITES = gql`
  query getFavoritesNames {
    getFavoritesNames {
      names
    }
  }
`;

const FavouriteServices = {
  async addFavourite(userFavourite, mutation = ADD_FAVOURITE_MUTATION) {
    const {
      data: { getRecentlyUsedServiceList },
    } = await client.mutate({
      mutation,
      variables: {
        userFavourite,
      },
      fetchPolicy: 'no-cache',
    });
    return getRecentlyUsedServiceList;
  },
  async fetchFavouritesList(query = FETCH_FAVOURITES) {
    const {
      data: { getFavoritesNames },
    } = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });

    return getFavoritesNames;
  },
};

export default FavouriteServices;
