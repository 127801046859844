import { Box } from '@mui/material';
import React from 'react';

export const DrawerBoxComponent = ({ children, sx, ...props }) => {
  return (
    <Box
      sx={{
        bgcolor: '#fff',
        mb: 2,
        mx: { sm: 8, xs: 4 },
        pl: { xs: 2, sm: 4 },
        pt: 2,
        pb: 2,
        borderRadius: '8px',
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
