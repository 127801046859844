import React from 'react';
import { withStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import { Typography } from '@mui/material';
import { getDrawerIcons } from '../../../utils/IconGetter';

const styles = (theme) => ({
  text: {
    ...theme.customComponent.h3Regular,
  },
  bigText: {
    ...theme.customComponent.h2Bold,
  },
  pointer: {
    ...theme.customComponent.clickableItem,
  },
});

const defaultProps = {};

const propTypes = {};

const MuiDrawer = (props) => {
  const { classes, side, open, onClick } = props;
  const toggleDrawer = (side, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props.handleDrawerOpen(open);
    // setState({ ...state, [side]: open });
  };
  const fullList = (side) => (
    <div className={classes.fullList} role="presentation">
      <List>
        {getDrawerIcons().map((item, index) => (
          <ListItem button key={item.title} onClick={(event) => onClick(item.title, event)}>
            <ListItemText
              primary={item.title}
              classes={{
                primary: index === 0 ? classes.bigText : classes.text,
              }}
            />
            <ListItemIcon>
              {item.text ? (
                <Typography
                  variant="h4"
                  color="primary"
                  className={classes.pointer}
                  onClick={toggleDrawer(side, false)}
                >
                  {item.text}
                </Typography>
              ) : (
                <img src={item.image} className={classes.pointer} />
              )}
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <Drawer anchor="bottom" open={open} onClose={toggleDrawer('bottom', false)}>
        {fullList('bottom')}
      </Drawer>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(MuiDrawer);
