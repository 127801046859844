import * as React from 'react';
import { Pagination } from '@mui/material';
import { createStyles, withStyles, makeStyles } from '@mui/styles';
import { PropTypes } from 'prop-types';
const styles = makeStyles((theme) =>
  createStyles({
    root: {
      '& .Mui-selected': {
        backgroundColor: '#24a0ed',
        color: '#FFF',
        opacity: 0.8,
        fontWeight: 600,
        fontFamily: 'Inter',
        fontSize: 14,
        '&:hover': { background: '#828282' },
      },
      '& ul > li:not(:first-child):not(:last-child) > button:not(.Mui-selected)': {
        backgroundColor: 'transparent',
        fontFamily: 'Inter',
        color: '#000',
        opacity: 0.7,
        fontSize: 14,
        fontWeight: 600,
        '&:hover': { background: '#828282' },
      },
    },
  }),
);
const PaginationComponent = (props) => {
  const classes = styles();
  const { total, numberOfRecordsPerPage, onPageClick, pageNumber = 1 } = props;
  const pageCount = Math.ceil(total / numberOfRecordsPerPage);

  return (
    <Pagination
      count={pageCount}
      size="medium"
      className={classes.root}
      page={pageNumber}
      onChange={(event, value) => {
        onPageClick(value);
      }}
    />
  );
};

PaginationComponent.prototype = {
  setPageNumber: PropTypes.func,
  numberOfRecordsPerPage: PropTypes.number,
};
export default PaginationComponent;
