import React, { useState, useEffect } from 'react';
import BreadCrumb from '../../../components/molecules/BreadCrumb';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import {
  diagnosticReportDetailsFormatter,
  labResultArrayConverter,
  fetchStatusObj,
  getBreadCrumbTitle,
  getPartnerName,
  medicationArrayConverter,
  calculateLabResultLabel,
  calculateDateFilterTimeStamp,
  historicalRecordCardDataFormatter,
  viewDetailsDataFormatter,
  capitalizeFirstLetter,
} from '../../../utils/helper';
import {
  dateFilterMenuItems,
  DEFAULT_NULL_REPLACEMENT,
  HISTORICAL_RECORDS_NOT_FOUND,
  NUMBER_OF_RECORDS_PER_PAGE,
} from '../../../utils/constants';
import { SELF_REPORTING_TEXTS } from '../../../utils/constants';
import PopUpModal from '../../../components/PopUpModal';
import MedicationsService from '../../../service/MedicationsService';
import ProceduresService from '../../../service/ProceduresService';
import ImmunizationsService from '../../../service/ImmunizationService';
import DiagnosticReportsService from '../../../service/LabResultsService';
import Loader from '../../../components/atoms/Loader';
import FilterDropDown from '../../../components/molecules/FilterDropDown';
import { SELF_REPORTED_VALUE_KEYS } from '../../../utils/selfReportingUtils';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import colors from '../../../utils/colors';
import Paper from '@mui/material/Paper';
import PaginationComponent from '../../../components/molecules/PaginationComponent';
import withRouter from '../../../components/withRoute';
import PartnerCard from '../../../components/organisms/NoPartnerCard';
import { useSelector } from 'react-redux';
import ViewDetailsFlyout from '../../../features/dynamicRecords/components/ViewDetailsFlyout/ViewDetailsFlyout';
import GenericServices from '../../../features/dynamicRecords/Services/GenericServices';
import { mockTemplates } from '../../../features/dynamicRecords/mockTemplate';
import ReadingsBanner from '../../../features/dynamicRecords/components/ReadingsBanner';

const styles = (theme) => ({
  container: {
    background: '#F5F5F5',
    position: 'relative',
    minHeight: '100%',
    padding: '32px 32px',
  },
  filterDropDownContainer: {
    display: 'flex',
    marginTop: 40,
    marginBottom: 40,
  },
  titleWrapper: {
    width: '100%',
    marginTop: 24,
    display: 'flex',
  },
  titleImage: {
    height: 40,
    width: 40,
    marginRight: 16,
  },
  titleText: {
    color: '#1b1b1b',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: 28,
    fontStyle: 'normal',
  },
  subTitle: {
    fontWeight: 600,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: 18,
    color: '#1B1B1B',
    marginTop: 40,
  },
  contentContainer: {
    marginTop: 16,
  },
  contentWrapper: {
    cursor: 'pointer',
    background: '#fff',
    border: '1px solid #e4e4e4',
    height: 'fit-content',
    borderRadius: 12,
    maxWidth: 584,
    display: 'flex',
    alignItems: 'center',
    padding: '16px 28px 16px 16px',
    boxSizing: 'border-box',
    marginBottom: 8,
    '&:hover': {
      opacity: 0.9,
    },
  },
  contentTitle: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    color: '#4b4b4b',
    fontStyle: 'normal',
    lineHeight: 1.3,
  },
  contentSubTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
    color: '#4B4B4B',
    fontFamily: 'Inter',
    marginRight: 16,
  },
  arrowSection: {
    width: '5%',
    display: 'flex',
    justifyContent: 'flex-end',
    '& span': {
      color: '#828282',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100%',
    },
  },
  leftSection: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  popUpRootContainer: {
    padding: '38px 24px',
    borderRadius: 16,
    width: '100%',
    maxWidth: 580,
  },
  popUpCloseIcon: {
    height: 18,
    cursor: 'pointer',
  },
  popUpIconContainer: {
    margin: 0,
  },
  popUpHeading: {
    fontSize: 20,
    marginTop: 24,
  },
  popUpParaContainer: {
    marginTop: 24,
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'center',
  },
  popUpParaText: {
    fontSize: 16,
    color: '#4b4b4b',
    lineHeight: 1.5,
    width: 440,
  },
  popUpBtn: {
    width: 160,
    '& .MuiButton-label': {
      fontWeight: 600,
    },
  },
  heading: {
    fontSize: 24,
    fontWeight: 600,
    color: '#333333',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    maxWidth: 440,
    width: '100%',
  },
  dataSource: {
    background: '#E4E4E4',
    border: '1px solid #C0C0C0',
    boxSizing: 'border-box',
    borderRadius: '24px',
    color: '#1F1F1F',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    textAlign: 'center',
    minWidth: 104,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 10px',
  },
  valueDiv: {
    height: 120,
    background: '#f8f8f8cc',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-between',
    padding: 16,
    marginTop: 40,
    marginBottom: 30,
  },
  valueLabel: {
    height: 24,
    fontWeight: 500,
    color: '#666666',
    fontSize: 16,
    lineHeight: '24px',
  },
  value: {
    height: 40,
    fontWeight: 600,
    fontSize: 28,
    lineHeight: '40px',
    color: colors.BLACK_B900,
  },
  statusDiv: {
    display: 'flex',
    alignItems: 'center',
    width: 82,
    height: 20,
    justifyContent: 'center',
  },
  labResultStatusIconContainer: { margin: '0px 8px 0px 0px' },
  statusIcon: { width: 12, height: 12 },
  labResultValue: { fontWeight: 400, fontSize: 14, lineHeight: '20px', color: '#000000' },
  dosageWrapper: {
    borderRadius: '8px',
    padding: '0px 24px',
    flex: 1,
  },
  doseHeading: {
    lineHeight: '24px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  flexBox: {
    display: 'flex',
    flex: 1,
  },
  dosageFlexContent: {
    flex: '33%',
  },
  column_name: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    width: '100%',
    height: '20px',
    color: '#828282',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    order: 0,
    flex: 'none',
    flexGrow: 0,
    margin: '4px 0px',
  },
  column_value: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    margin: '4px 0px',
  },
  dosageNotes: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    width: '150px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    margin: '4px 0px',
  },
  paginationWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 16,
  },
  dropDownWrapper: {
    width: 175,
    height: 40,
    '&:MuiInput-root': {
      height: 40,
    },
  },
});

const HistoricalRecordsDashboard = (props) => {
  const { classes } = props;
  const navigate = useNavigate();
  const { serviceCategory } = useParams();


  const {
    resourceData: { code, icon, title, partner, circleId, medicationCategoryType },
  } = (props && props.location && props.location.state) || {
    resourceData: {
      code: '',
      icon: '',
      title: '',
      partner: '',
      circleId: '',
      medicationCategoryType: '',
    },
  };
  const { templates } = useSelector((state) => state.templates);

  const historicalRecords =
    templates?.[serviceCategory]?.historicalRecordsCardFields ??
    mockTemplates?.[serviceCategory]?.historicalRecordsCardFields ??
    {};
  const categoryName = historicalRecords?.categoryName ?? '';
  const cardFields = historicalRecords?.cardFields ?? [];
  const detailsTemplate =
    templates?.[serviceCategory]?.details?.detailsTable?.cols ||
    mockTemplates?.[serviceCategory]?.details?.detailsTable?.cols
    || {};

  const [nextPageId, setNextPageId] = useState('');
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loaderText, setLoaderText] = useState(SELF_REPORTING_TEXTS.LOADING_HISTORICAL_RECORDS);
  const [customComponent, setCustomComponent] = useState({});
  // const [showDosageList, setShowDosageList] = useState(false);
  // const [dosageInfo, setDosageInfo] = useState({});
  const [resourceType, setResourceType] = useState('');
  const [timeFilter, setTimeFilter] = React.useState('all');
  const [documents, setDocuments] = useState([]);
  const [recordTitle, setRecordTitle] = useState(title);

  const [pageNumber, setPageNumber] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(NUMBER_OF_RECORDS_PER_PAGE);
  const [paginationObj, setPaginationObj] = useState({
    numberOfRecordsPerPage: recordsPerPage,
    currentPageNo: pageNumber,
    offset: 0,
    total: 0,
  });

  const onPageNumberClick = (pageNo) => {
    setPaginationObj({
      ...paginationObj,
      currentPageNo: pageNo,
      offset: (pageNo - 1) * recordsPerPage,
    });
  };

  const createPaginationObj = (total, reset) => {
    if (reset) {
      setPaginationObj({
        ...paginationObj,
        total: total,
      });
    }
  };

  const dateFilterOptions = {
    options: dateFilterMenuItems,
    value: timeFilter,
    changeHandler: (ev) => {
      setTimeFilter(ev.target.value);
    },
    rootContainerStyle: classes.dropDownWrapper,
  };

  const breadCrumbProps = !Boolean(partner)
    ? [
      { label: 'Records', route: '/records' },
      {
        label: getBreadCrumbTitle(serviceCategory),
        route: '/records/' + serviceCategory,
        redirectTo: () => navigate(-1),
      },
      { label: 'Historical records' },
    ]
    : [
      { label: 'Circles', route: '/circles' },
      { label: getPartnerName(partner), route: `/circles/${partner}/${circleId}` },
      {
        label: getBreadCrumbTitle(serviceCategory),
        route: '/circles/' + partner + serviceCategory,
        redirectTo: () => navigate(-1),
      },
      { label: 'Historical records' },
    ];

  const onPageClick = (val) => {
    if (val !== pageNumber) {
      onPageNumberClick(val);
    }
    setPageNumber(val);
  };

  const fetchHistoricalData = async (category) => {
    setIsLoading(true);
    setLoaderText(SELF_REPORTING_TEXTS.LOADING_HISTORICAL_RECORDS);
    let data = [];
    let formattedData = {};
    const date_filter = calculateDateFilterTimeStamp(timeFilter) || 0;

    const filters = [];
    filters.push({
      filterType: 'dateFilter',
      value: Array.isArray(date_filter) ? date_filter : [String(date_filter)],
    });
    filters.push({ filterType: 'statusFilter', value: [] });
    filters.push({ filterType: 'searchFilter', value: '' });
    try {
      switch (category.toLowerCase()) {
        case categoryName?.toLowerCase():
          data = await GenericServices.fetchHistoricalRecords({
            resourceType: category,
            nextPageId: nextPageId,
            filters,
            offset: paginationObj.offset || 0,
            count: '10',
            sourceUrl: '',
            initiatorId: '',
            grantorId: '',
            resourceId: '',
            recordCode: code,
          });
          if (data) {
            const parsedData = JSON.parse(data?.recordList);
            formattedData = historicalRecordCardDataFormatter(cardFields, parsedData);
          }
          setResourceType(category);
          break;
        case 'Medication':
          data = await MedicationsService.fetchAllMedicationByCode(code);
          formattedData = medicationArrayConverter(data);
          setResourceType('Medication');
          break;
        default:
          break;
      }
      setAllData(formattedData);
      setNextPageId(data?.nextPageId);
      setFilteredData(formattedData);
      createPaginationObj((data && data.count) || 10, true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setLoaderText('');
    }
  };

  const onCardClick = async (id) => {
    setIsLoading(true);
    setLoaderText(SELF_REPORTING_TEXTS.PLEASE_WAIT);
    setCustomComponent(null);
    // setShowDosageList(false);

    const selfReportingTemplate =
      templates?.[serviceCategory]?.selfReportingSection ||
      mockTemplates[serviceCategory]?.selfReportingSection;

    let data = {};
    let formattedData = {};
    let editData = {};
    let recordedOn = '';
    try {
      switch (resourceType?.toLowerCase()) {
        case categoryName?.toLowerCase():
          data = await GenericServices.fetchRecordById({
            grantorId: '',
            resourceType: resourceType,
            resourceId: id,
          });
          let responseObj = JSON.parse(data.record);
          responseObj = {
            ...responseObj,
            docArn: data?.docArn,
            documentReferenceId: data?.documentReferenceId,
          };
          formattedData = viewDetailsDataFormatter(responseObj, detailsTemplate, selfReportingTemplate);
          editData = formattedData?.editData;
          recordedOn = editData['recordedOn'] || editData['startDate'];
          if (!recordedOn) {
            recordedOn =
              !title && ['procedures'].includes(resourceType)
                ? `${capitalizeFirstLetter(resourceType)?.slice(0, resourceType?.length - 1)} id`
                : resourceType === 'allergies' && !title
                  ? 'Allergy ids'
                  : title || resourceType;
          }
          setRecordTitle(recordedOn && recordedOn !== '-' ? recordedOn : title);
          if (categoryName?.toLowerCase() === 'labs') {
            setCustomComponent({
              component: RenderValueView,
              props: {
                value: editData[SELF_REPORTED_VALUE_KEYS.VALUE_KEY],
                units: editData[SELF_REPORTED_VALUE_KEYS.UNITS_KEY],
                referenceRange: editData[SELF_REPORTED_VALUE_KEYS.REFERENCE_RANGE_KEY],
                labResult: editData[SELF_REPORTED_VALUE_KEYS.TEST_RESULTS_KEY],
              },
            });
          }
          break;
        case 'Medication':
          switch (medicationCategoryType) {
            case 'MedicationStatement':
              data = await MedicationsService.fetchMedicationStatementById(id);
              break;
            case 'MedicationDispense':
              data = await MedicationsService.fetchMedicationDispenseById(id);
              break;
            default:
              data = await MedicationsService.fetchMedicationRequestById(id);
              break;
          }
          let medicationObj = data.medicationRequest ? JSON.parse(data.medicationRequest) : data;
          medicationObj = {
            ...medicationObj,
            docArn: data.docArn,
            documentReferenceId: data.documentReferenceId,
          };
          formattedData = medicationDetailsFormatter(medicationObj);
          editData = formattedData.editData;
          recordedOn = editData[SELF_REPORTED_VALUE_KEYS.RECORDED_ON_KEY];
          recordedOn = recordedOn && getCommaSeparatedDate(recordedOn);
          setRecordTitle(
            recordedOn ||
            editData[SELF_REPORTED_VALUE_KEYS.MEDICATION_NAME_KEY] ||
            `${resourceType} id`,
          );
          setShowDosageList(true);
          setDosageInfo({ dosage: medicationObj.dosageInstruction, notes: medicationObj.note });
          break;
        default:
          break;
      }
      setDocuments(data.docArn);
      setModalData(formattedData.modalData);
      setOpenModal(true);
      setIsLoading(false);
      setLoaderText('');
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setLoaderText('');
    }
  };

  useEffect(() => {
    if (serviceCategory && code) {
      fetchHistoricalData(serviceCategory);
    }
  }, [serviceCategory, code, timeFilter, pageNumber]);

  useEffect(() => {
    if (!serviceCategory || !code) {
      navigate('/records');
    }

    const { onFooterItemClick } = props;
    if (onFooterItemClick) {
      if (partner) {
        onFooterItemClick(2);
      } else {
        onFooterItemClick(3);
      }
    }

    return () => {
      // clear the state obj stored in session storage after mount to prevent unnecessary loader texts.
      if (props.location.state && props.location.state.isOpen) {
        const state = {};
        delete state.isOpen;
        props.location.replace({ ...props.location.state, state });
      }
    };
  }, []);

  const RenderValueView = (props) => {
    const { value, units, labResult, referenceRange } = props;
    // let calculatedLabResult = labResult || '-';
    // // calculate labe result value
    // if (calculatedLabResult === '-' && value && referenceRange) {
    //   calculatedLabResult = calculateLabResultLabel(referenceRange, value);
    // }
    // const statusObj =
    //   calculatedLabResult && calculatedLabResult !== '-'
    //     ? fetchStatusObj(calculatedLabResult)
    //     : { status: '-', color: null };
    return (
      <div className={classes.valueDiv}>
        <span className={classes.valueLabel}>Value</span>
        {value ? (
          <span className={classes.value}>{`${value || '-'} ${units && units !== '-' ? units : ''
            }`}</span>
        ) : (
          DEFAULT_NULL_REPLACEMENT
        )}
        <div className={classes.statusDiv}>
          {/* {statusObj.color && (
            <span className={classes.labResultStatusIconContainer}>
              <StatusIcon htmlColor={statusObj.color} className={classes.statusIcon} />
            </span>
          )} */}
          <p className={classes.labResultValue}>
            {labResult && labResult !== '-' ? labResult : '-'}
          </p>
        </div>
      </div>
    );
  };

  const DosageList = (props) => {
    const {
      data: { dosage = [], notes = [] },
    } = props;
    const doseLength = (dosage && dosage.length) || 0;
    return (
      doseLength > 0 && (
        <Paper variant="outlined" className={classes.dosageWrapper}>
          {dosage.map((row, i) => {
            if (!row) {
              return '';
            }
            return (
              <div
                key={i}
                style={{
                  // borderBottom: doseLength !== i ? '1px solid #DBDBDB' : '',
                  paddingBottom: '10px',
                  paddingTop: '0px',
                }}
              >
                <h3 className={classes.doseHeading}>
                  {/* {row.text ||
                    (row.timing && row.timing.code && row.timing.code.text) ||
                    DEFAULT_NULL_REPLACEMENT} */}
                  Dose {i + 1}
                </h3>
                <div className={classes.flexBox}>
                  <div className={classes.dosageFlexContent}>
                    <p className={classes.column_name}>No Of Pills</p>
                    <p className={classes.column_value}>
                      {(row.timing && row.timing.repeat && row.timing.repeat.frequency) ||
                        DEFAULT_NULL_REPLACEMENT}
                    </p>
                  </div>
                  <div className={classes.dosageFlexContent}>
                    <p className={classes.column_name}>Time</p>
                    <p className={classes.column_value}>
                      {(row.timing &&
                        row.timing.repeat &&
                        row.timing.repeat.timeOfDay &&
                        row.timing.repeat.timeOfDay.length &&
                        row.timing.repeat.timeOfDay[0]) ||
                        DEFAULT_NULL_REPLACEMENT}
                    </p>
                  </div>
                  <div className={classes.dosageFlexContent}>
                    <p className={classes.column_name}>Note</p>
                    <p className={classes.dosageNotes}>
                      {(notes && notes[i] && notes[i].text) || DEFAULT_NULL_REPLACEMENT}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Paper>
      )
    );
  };

  return (
    <div className={classes.container}>
      {isLoading && <Loader overlay text={loaderText} />}
      <BreadCrumb breadCrumbProps={breadCrumbProps} />
      <Typography className={classes.titleWrapper}>
        <img src={icon} alt="logo" className={classes.titleImage} />
        <span className={classes.titleText}>{title}</span>
      </Typography>
      <Typography className={classes.subTitle}>
        {SELF_REPORTING_TEXTS.HISTORICAL_RECORDS}
      </Typography>
      <div className={classes.contentContainer}>
        <div className={classes.filterDropDownContainer}>
          <FilterDropDown {...dateFilterOptions} />
        </div>
        {!filteredData?.length && !isLoading ? (
          <PartnerCard data={HISTORICAL_RECORDS_NOT_FOUND} />
        ) : null}
        {filteredData?.length
          ? filteredData.map((item) => {
            return (
              <div
                className={classes.contentWrapper}
                key={item.id}
                onClick={(e) => onCardClick(item.id)}
              >
                <div className={classes.leftSection}>
                  {/* <Typography className={classes.contentTitle}>{item.contentTitle}</Typography> */}
                  <Typography className={classes.contentSubTitle}>
                    {item.recordedOn && item.recordedOn !== '-' && item.recordedOn}
                  </Typography>
                  <Typography className={classes.dataSource}>{item.dataSource}</Typography>
                </div>
                <div className={classes.arrowSection}>
                  <span>&#10095;</span>
                </div>
              </div>
            );
          })
          : null}
      </div>
      <div className={classes.paginationWrapper}>
        {paginationObj && paginationObj.total > paginationObj.numberOfRecordsPerPage && (
          <PaginationComponent
            numberOfRecordsPerPage={paginationObj.numberOfRecordsPerPage}
            total={paginationObj.total}
            onPageClick={onPageClick}
            pageNumber={pageNumber}
          />
        )}
      </div>
      {openModal && (
        <ViewDetailsFlyout
          setOpen={setOpenModal}
          data={modalData}
          customComponent={customComponent}
          title={recordTitle}
          icon={icon}
          showEdit={false}
          hideOptions={true}
          documents={documents}
        />
      )}
    </div>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(HistoricalRecordsDashboard));
