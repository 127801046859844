import { Button } from '@mui/material';
import React from 'react';

function BtnComponent({ type, onClick, disabled, title, sx, ...props }) {
  return (
    <Button
      data-testid="button-2"
      sx={{
        borderRadius: 40,
        textTransform: 'none',
        ...sx,
        '&:disabled': { bgcolor: 'primary.disabled', color: '#fff' },
      }}
      variant="contained"
      size="large"
      color="primary"
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {title}
    </Button>
  );
}

export default BtnComponent;
