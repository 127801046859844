import React from 'react';
import { withStyles } from '@mui/styles';
import NotFoundIcon from '../../../assets/images/dataNotFound.svg';
import { NOT_FOUND_PAGE } from '../../../utils/constants';

const styles = (theme) => ({
  rootContainer: {
    width: '100%',
    background: '#FFFFFF',
    display: 'flex',
    padding: '64px 0px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    boxSizing: 'border-box',
  },
  textContainer: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& p:nth-child(1)': {
      fontFamily: 'Inter',
      fontSize: 20,
      fontWeight: 600,
    },
    '& p:nth-child(2)': {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.7,
      color: '#333333',
      maxWidth: 560,
    },
  },
});

const NotFound = ({ classes, ...props }) => {
  const { rootContainerStyle, title } = props;

  return (
    <div className={rootContainerStyle || classes.rootContainer}>
      <img src={NotFoundIcon} alt="notfoundicon" />
      <div className={classes.textContainer}>
        <p>{NOT_FOUND_PAGE.TITLE(title)}</p>
        <p>{NOT_FOUND_PAGE.PARA(title)}</p>
      </div>
    </div>
  );
};

export default withStyles(styles)(NotFound);
