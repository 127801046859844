import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import {
  CODE_FRAGMENT,
  IDENTIFIER_FRAGMENT,
  MEDICATION_REQUEST_FRAGMENT,
  MEDICATION_STATEMENT_FRAGMENT,
  MEDICATION_DISPENSE_FRAGMENT,
  REFERENCE_FRAGMENT,
} from '../utils/fragments';

const DEFAULT_FETCH_ALL_MEDICATIONS_QUERY = gql`
  query fetchAllMedications(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllMedications(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      medicationRequestList
      count
    }
  }
`;
const FETCH_ALL_MEDICATIONS_BY_CONSENT = gql`
  query fetchAllMedicationRequestsByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllMedicationRequestsByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      medicationRequestList
      count
    }
  }
`;

const DEFAULT_FETCH_ALL_MEDICATIONS_STATEMENTS_QUERY = gql`
  query fetchMedicationStatement(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchMedicationStatement(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      medicationStatementList
      count
    }
  }
`;

const FETCH_ALL_MEDICATIONS_STATEMENTS_BY_CONSENT = gql`
  query fetchAllMedicationStatementByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllMedicationStatementByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      medicationStatementList
      count
    }
  }
`;

const DEFAULT_FETCH_ALL_MEDICATIONS_DISPENSES_QUERY = gql`
  query fetchMedicationDispense($sourceUrl: String) {
    fetchMedicationDispense(sourceUrl: $sourceUrl) {
      ...medicationDispense
    }
  }
  ${MEDICATION_DISPENSE_FRAGMENT}
`;

const DEFAULT_FETCH_MEDICATION_STATEMENT_BY_ID_QUERY = gql`
  query fetchMedicationStatementById($medicationStatementId: String) {
    fetchMedicationStatementById(medicationStatementId: $medicationStatementId) {
      ...medicationStatement
    }
  }
  ${MEDICATION_STATEMENT_FRAGMENT}
`;

const DEFAULT_FETCH_MEDICATION_DISPENSE_BY_ID_QUERY = gql`
  query fetchMedicationDispenseById($medicationDispenseId: String) {
    fetchMedicationDispenseById(medicationDispenseId: $medicationDispenseId) {
      ...medicationDispense
    }
  }
  ${MEDICATION_DISPENSE_FRAGMENT}
`;

const DEFAULT_FETCH_MEDICATION_REQUEST_BY_ID_QUERY = gql`
  query fetchMedicationById($medicationId: String) {
    fetchMedicationById(medicationId: $medicationId) {
      medicationRequest
      docArn
      documentReferenceId
    }
  }
`;
const DEFAULT_FETCH_MEDICATION_REQUEST_BY_ID_QUERY_AND_GRANTOR_ID = gql`
  query fetchMedicationByIdAndGrantorId($medicationId: String, $grantorId: String) {
    fetchMedicationByIdAndGrantorId(medicationId: $medicationId, grantorId: $grantorId) {
      medicationRequest
      docArn
      documentReferenceId
    }
  }
`;

const DEFAULT_RELATED_MEDICATIONS = gql`
  query fetchRelatedMedications($relatedResourceId: String, $relatedResourceType: String) {
    fetchRelatedMedications(resourceId: $relatedResourceId, resourceType: $relatedResourceType) {
      id
      requester {
        display
        reference
      }
      note {
        text
      }
      dosageInstruction {
        asNeededBoolean
        route {
          ...code
        }
        timing {
          repeat {
            boundsPeriod {
              start
              end
            }
            frequency
            period
          }
        }
        text
        method {
          text
        }
      }
      authoredOn
      medicationCodeableConcept {
        ...code
      }
      medicationReference {
        display
      }
    }
  }
  ${CODE_FRAGMENT}
`;

const SAVE_MEDICATION_SELF_REPORTING = gql`
  mutation createSelfReportData($data: AddMedicationRequestSelfReportedData) {
    createSelfReportData(data: $data) {
      ...medicationRequest
    }
  }
  ${MEDICATION_REQUEST_FRAGMENT}
`;

const UPDATE_MEDICATION_SELF_REPORTING = gql`
  mutation editSelfReportData($data: AddMedicationRequestSelfReportedData, $medicationId: String) {
    editSelfReportData(data: $data, medicationId: $medicationId) {
      ...medicationRequest
    }
  }
  ${MEDICATION_REQUEST_FRAGMENT}
`;

// const FETCH_MEDICATION_REQUEST_HISTORICAL_RECORDS = gql`
//   query fetchAllMedicationRequestsByCode($medicationCode: String) {
//     fetchAllMedicationRequestsByCode(medicationCode: $medicationCode) {
//       ...medicationRequest
//     }
//   }
//   ${MEDICATION_REQUEST_FRAGMENT}
// `;

// const FETCH_MEDICATION_STATEMENT_HISTORICAL_RECORDS = gql`
//   query fetchAllMedicationStatementByCode($medicationStatementCode: String) {
//     fetchAllMedicationStatementByCode(medicationStatementCode: $medicationStatementCode) {
//       ...medicationStatement
//     }
//   }
//   ${MEDICATION_STATEMENT_FRAGMENT}
// `;

// const FETCH_MEDICATION_DISPENSE_HISTORICAL_RECORDS = gql`
//   query fetchAllMedicationDispenseByCode($medicationDispenseCode: String) {
//     fetchAllMedicationDispenseByCode(medicationDispenseCode: $medicationDispenseCode) {
//       ...medicationDispense
//     }
//   }
//   ${MEDICATION_DISPENSE_FRAGMENT}
// `;

const FETCH_ALL_MEDICATIONS_BY_CODE = gql`
  query fetchAllMedicationByCode($medicationCode: String) {
    fetchAllMedicationByCode(medicationCode: $medicationCode) {
      id
    }
  }
`;

const MARK_RESTRICTED_REQUEST = gql`
  mutation markAsRestrictedMedicationRequest($medicationId: String, $markAsRestricted: String) {
    markAsRestrictedMedicationRequest(
      medicationId: $medicationId
      markAsRestricted: $markAsRestricted
    ) {
      ...medicationRequest
    }
  }
  ${MEDICATION_REQUEST_FRAGMENT}
`;

const MARK_RESTRICTED_STATEMENT = gql`
  mutation markAsRestrictedMedicationStatement(
    $medicationStatementId: String
    $markAsRestricted: String
  ) {
    markAsRestrictedMedicationStatement(
      medicationStatementId: $medicationStatementId
      markAsRestricted: $markAsRestricted
    ) {
      ...medicationStatement
    }
  }
  ${MEDICATION_STATEMENT_FRAGMENT}
`;

const MARK_RESTRICTED_DISPENSE = gql`
  mutation markAsRestrictedMedicationDispense(
    $medicationDispenseId: String
    $markAsRestricted: String
  ) {
    markAsRestrictedMedicationDispense(
      medicationDispenseId: $medicationDispenseId
      markAsRestricted: $markAsRestricted
    ) {
      ...medicationDispense
    }
  }
  ${MEDICATION_DISPENSE_FRAGMENT}
`;

const MedicationsService = {
  fetchAllMedications(variables, query = DEFAULT_FETCH_ALL_MEDICATIONS_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllMedications);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllMedicationRequestsByConsent(variables, query = FETCH_ALL_MEDICATIONS_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllMedicationRequestsByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAllMedicationsStatements(variables, query = DEFAULT_FETCH_ALL_MEDICATIONS_STATEMENTS_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchMedicationStatement);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllMedicationStatementByConsent(
    variables,
    query = FETCH_ALL_MEDICATIONS_STATEMENTS_BY_CONSENT,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllMedicationStatementByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAllMedicationsDispenses(source = '', query = DEFAULT_FETCH_ALL_MEDICATIONS_DISPENSES_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            sourceUrl: source,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchMedicationDispense);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchMedicationRequestById(id, query = DEFAULT_FETCH_MEDICATION_REQUEST_BY_ID_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            medicationId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchMedicationById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchMedicationByIdAndGrantorId(
    medicationId,
    grantorId,
    query = DEFAULT_FETCH_MEDICATION_REQUEST_BY_ID_QUERY_AND_GRANTOR_ID,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            medicationId,
            grantorId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchMedicationByIdAndGrantorId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchMedicationStatementById(id, query = DEFAULT_FETCH_MEDICATION_STATEMENT_BY_ID_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            medicationStatementId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchMedicationStatementById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchMedicationDispenseById(id, query = DEFAULT_FETCH_MEDICATION_DISPENSE_BY_ID_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            medicationDispenseId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchMedicationDispenseById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRelateMedications(
    relatedResourceId,
    relatedResourceType,
    query = DEFAULT_RELATED_MEDICATIONS,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            relatedResourceId,
            relatedResourceType,
          },
        })
        .then((response) => {
          resolve(response.data.fetchRelatedMedications);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createMedicationSelfReportData(data, mutation = SAVE_MEDICATION_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
          },
        })
        .then((response) => {
          resolve(response.data.createSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateMedicationSelfReportData(data, medicationId, mutation = UPDATE_MEDICATION_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
            medicationId,
          },
        })
        .then((response) => {
          resolve(response.data.editSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // fetchAllMedicationRequestsByCode(medicationCode = '', query=FETCH_MEDICATION_REQUEST_HISTORICAL_RECORDS) {
  //   return new Promise((resolve, reject) => {
  //     client
  //       .query({
  //         query,
  //         variables: {
  //           medicationCode: medicationCode,
  //         },
  //         fetchPolicy: 'no-cache',
  //       })
  //       .then((response) => {
  //         resolve(response.data.fetchAllMedicationRequestsByCode);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  // fetchAllMedicationStatementByCode(medicationStatementCode = '', query=FETCH_MEDICATION_STATEMENT_HISTORICAL_RECORDS) {
  //   return new Promise((resolve, reject) => {
  //     client
  //       .query({
  //         query,
  //         variables: {
  //           medicationStatementCode: medicationStatementCode,
  //         },
  //         fetchPolicy: 'no-cache',
  //       })
  //       .then((response) => {
  //         resolve(response.data.fetchAllMedicationStatementByCode);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  // fetchAllMedicationDispenseByCode(medicationCode = '', query=FETCH_MEDICATION_DISPENSE_HISTORICAL_RECORDS) {
  //   return new Promise((resolve, reject) => {
  //     client
  //       .query({
  //         query,
  //         variables: {
  //           medicationCode: medicationCode,
  //         },
  //         fetchPolicy: 'no-cache',
  //       })
  //       .then((response) => {
  //         resolve(response.data.fetchAllMedicationDispenseByCode);
  //       })
  //       .catch((error) => {
  //         reject(error);
  //       });
  //   });
  // },

  fetchAllMedicationByCode(medicationCode = '', query = FETCH_ALL_MEDICATIONS_BY_CODE) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            medicationCode: medicationCode,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllMedicationByCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markAsRestrictedMedicationRequest(
    medicationId,
    markAsRestricted,
    mutation = MARK_RESTRICTED_REQUEST,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            medicationId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.markAsRestrictedMedicationRequest);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markAsRestrictedMedicationStatement(
    medicationStatementId,
    markAsRestricted,
    mutation = MARK_RESTRICTED_STATEMENT,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            medicationStatementId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.markAsRestrictedMedicationStatement);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markAsRestrictedMedicationDispense(
    medicationDispenseId,
    markAsRestricted,
    mutation = MARK_RESTRICTED_DISPENSE,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            medicationDispenseId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.markAsRestrictedMedicationDispense);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default MedicationsService;
