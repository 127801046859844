import axios from 'axios';

export const AXIOS_API_BASE_URL = process.env.APOLLO_CLIENT;
// export const AXIOS_API_BASE_URL= 'https://cb0f70ea5c088e.lhrtunnel.link';

const instance = axios.create({
  baseURL: AXIOS_API_BASE_URL,
});

export default instance;
