import { useEffect } from 'react';
import CirclesService from '../service/CirclesService';
import RecentServices from '../service/RecentServices';
import { SERVICES_TYPE, CIRCLES_SERVICE_TYPE } from '../utils/constants';

export default function useUpdateUsedService(serviceTypeKey, isCircle = false, circleId = null) {
  useEffect(() => {
    try {
      if (isCircle) {
        CirclesService.updateRecentlyUsedServices({
          circleId: Number(circleId),
          serviceType: CIRCLES_SERVICE_TYPE[serviceTypeKey],
        });
      } else {
        RecentServices.updateUsedService(SERVICES_TYPE[serviceTypeKey]);
      }
    } catch (error) {
      console.error('Error in updating used Service: ', error);
    }
  }, []);
}
