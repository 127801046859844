import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children, loggedIn }) => {
  localStorage.setItem('URL', window.location.pathname.replace('/', ''));
  return loggedIn ? (
    children
  ) : (
    <Navigate to="/" />
  );

};

export default PrivateRoute;
