import { createSlice } from '@reduxjs/toolkit';
// import { mockTemplates } from '../../features/dynamicRecords/mockTemplate';

const initialState = {
  templates: null,
};

export const dynamicTemplateSlice = createSlice({
  name: 'dynamicTemplate',
  initialState,
  reducers: {
    updateTemplate: (state, action) => {
      state.templates = action.payload;
      // state.templates = mockTemplates;
    },
  },
});

export const { updateTemplate } = dynamicTemplateSlice.actions;

export default dynamicTemplateSlice.reducer;
