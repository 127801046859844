import Deactivate from '../assets/images/deactivateIcon.svg';
import Payments from '../assets/images/payments.svg';
import { SETTINGS } from './constants';
import { replaceImageProperty } from './helper';

const ICONS = [Deactivate];

const INACTIVE_ICONS = [Deactivate];

const PWA_ICONS = [Deactivate];

const INACTIVE_PWA_ICONS = [Deactivate];

const get = () =>
  replaceImageProperty(SETTINGS, ICONS, PWA_ICONS, INACTIVE_ICONS, INACTIVE_PWA_ICONS);

const settingsIconsGetter = { get };

export default settingsIconsGetter;
