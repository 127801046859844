import { withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import colors from '../../../../utils/colors';
import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '../TextField';
import clsx from 'clsx';
import Button from '../../../../components/atoms/Button';
import InfoIcon from '@mui/icons-material/Info';
import { UPDATE_USERNAME_CONSTANTS } from '../../constants';
import { validateFirstName, validateLastName } from '../../../../utils/validations';
import ProfileService from '../../../../service/ProfileService';
import { UserInfoContext } from '../../../../components/Routes';
import { SNACKBAR_TYPE, TOAST_TEXT_MESSAGE } from '../../../../utils/constants';
import SnackbarToast from '../../../../components/organisms/SnackbarToast';

const styles = (theme) => ({
  parentContainer:{
    width:'100%',
    height:'100%',
  },
  container: {
    backgroundColor: colors.WHITE,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    position: 'absolute',
    width: 556,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  heading: {
    color: colors.BLACK_B500,
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  subHeading:{
    color: colors.GRAY_SECONDARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  close: {
    color: colors.BLACK,
    fontFamily: 'Material Icons Round',
    fontWeight: 400,
    fontSize: 22,
    lineHeight: '24px',
  },
  textFieldContainer: { width: '100%' },
  required: { color: 'red', fontSize: 12, fontWeight: 500 },
  textFieldTitle: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    color: colors.PRIMARY_GRAY,
    letterSpacing: '0px',
    lineHeight: '16px',
    marginBottom: theme.spacing(0.5),
  },
  infoIconStyle: {
    height: '12px',
    width: '12px',
    paddingRight: '2px',
  },
  textFieldStyle: {
    width: '100%',
  },
  errMessage: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    color: '#FF0000',
  },
  spacing:{
    width:'100%',
    height: theme.spacing(2),
  },

  saveButton: {
    textTransform: 'none',
    padding: '24px 24px',
    borderRadius: 40,
    width: '100%',
    color: colors.WHITE,
    backgroundColor: colors.BLUE,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop: theme.spacing(3),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.BLUE_HOVER,
      color: colors.WHITE,
    },
  },
});


const { TITLE,
  SUB_TITLE,
  FIRST_NAME,
  FIRST_NAME_PLACEHOLDER,
  LAST_NAME,
  LAST_NAME_PLACEHOLDER,
  SAVE,
  FIRSTNAME,
  LASTNAME } = UPDATE_USERNAME_CONSTANTS;

const UpdateUserNameModal = ({ classes, ...props }) => {
  const { show, onClose, onSave } = props;

  const { userInfo, setUserInfo } = React.useContext(UserInfoContext);
  const [ snackbarMessage, setSnackbarMessage ] = useState('');

  const [names, setNames] = useState({
    firstName: '',
    lastName: '',
  });

  const [focus, setFocus] = useState({
    firstName: true,
    lastName: false,
  });

  const [error, setError] = useState({
    firstName: null,
    lastName: null,
  });

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClose = () => {
    onClose();

    if (error.firstName && error.lastName) {
      resetErrors();
      resetNames();
    }
  };

  const handleOnSavePress = async () => {
    const { firstName, lastName } = names;

    setIsLoading(true);
    const {
      dob,
      gender
     } = userInfo;

    try {
      if (validateNames()) {
        const args = {
          basicProfileDetails: {
            bloodGroup: null,
            dob: dob ?? null,
            ethnicity: null,
            firstName,
            gender: gender?.toUpperCase() ?? null,
            lastName,
            maritalStatus: null,
            middleName: '',
            preferredLanguage: null,
            preferredName: '',
            previousName: '',
            race: null,
            suffix: null,
          },
        };
        const res = await ProfileService.editProfile(args);
        if (res) {
          await generateReferralCode(firstName, lastName);
          setUserInfo((prevState) => ({
            ...prevState,
            firstName,
            lastName,
          }));
          onSave();
        }
      }
    } catch (err) {
      console.error(err);
      setSnackbarMessage(TOAST_TEXT_MESSAGE.SOMETHING_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const generateReferralCode = async (userFirstName, userLastName) => {
    try {
      const userId = localStorage.getItem('userId');
      await ProfileService.generateReferralCode({
        userFirstName,
        userLastName,
        userId,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const validateNames = () =>{

    const firstNameResult = validateFirstName(names.firstName);
    const lastNameResult = validateLastName(names.lastName);

    setError({
      firstName: firstNameResult?.errorMessage ?? null,
      lastName: lastNameResult?.errorMessage ?? null,
    });


    return firstNameResult.isValid && lastNameResult.isValid;
  };

  const validateOnBlur = (key, value) => {
    const res = key === FIRSTNAME ? validateFirstName(value) : validateLastName(value);

    if (!res.isValid || res.errorMessage) {
      setError({
        ...error,
        [key]: res.errorMessage,
      });
      return false;
    }

    return true;
  };

  const resetErrors = () => {
    setError({
      firstName: null,
      lastName: null,
    });
  };

  const resetNames = () => {
    setNames({
      firstName: null,
      lastName: null,
    });
  };

  const onChange = (key, value) => {
    setNames({
      ...names,
      [key]: value,
    });

    setError({
      ...error,
      [key]: null,
    });
  };

  const handleOnNext = () => {
    setFocus({
      firstName: false,
      lastName: true,
    });
  };

  useEffect(() => {
    setDisabled(
      error.firstName || error.lastName || !names.firstName || !names.lastName || isLoading,
    );
  }, [error.firstName, error.lastName, names.firstName, names.lastName, isLoading]);

  useEffect(() => {
    if (!show) {
      if (error.firstName || error.lastName) {
        resetNames();
        resetErrors();
      } else if (!names.firstName && !names.lastName) {
        resetErrors();
      };
    }
  }, [show]);

  return (
    <Modal open={show} disableAutoFocus={true}>
      <Box className={classes.parentContainer}>
        <Box className={classes.container}>
          <Box>
            <Box className={classes.headingContainer}>
              <Box>
                <Typography className={classes.heading}>{TITLE} </Typography>
                <Typography className={classes.subHeading}>{SUB_TITLE} </Typography>            
              </Box>

                <CloseIcon className={classes.close} onClick={handleOnClose} />
            </Box>
            <Box className={classes.textFieldContainer}>
              <Typography variant="h6" className={classes.textFieldTitle}>
                {FIRST_NAME}
                <span className={classes.required}>*</span>
              </Typography>
              <TextField
                autoComplete="off"
                textFieldStyle={classes.textFieldStyle}
                id={FIRSTNAME}
                name={FIRSTNAME}
                color="primary"
                variant="outlined"
                size="small"
                type="text"
                value={names.firstName}
                placeholder={FIRST_NAME_PLACEHOLDER}
                onFieldChange={onChange}
                error={error.firstName}
                autoFocus={focus.firstName}
                onBlur={()=> names.firstName && validateOnBlur(FIRSTNAME, names.firstName )}
              />
              {error.firstName && (
                <Typography variant="h6" className={classes.errMessage}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {error.firstName}
                </Typography>
              )}

              <Box className={classes.spacing}/>

              <Typography variant="h6" className={classes.textFieldTitle}>
                {LAST_NAME}
                <span className={classes.required}>*</span>
              </Typography>
              <TextField
                autoComplete="off"
                textFieldStyle={classes.textFieldStyle}
                id={LASTNAME}
                name={LASTNAME}
                color="primary"
                variant="outlined"
                size="small"
                type="text"
                value={names.lastName}
                placeholder={LAST_NAME_PLACEHOLDER}
                onFieldChange={onChange}
                error={error.lastName}
                autoFocus={focus.lastName}
                onBlur={()=> names.lastName && validateOnBlur(LASTNAME, names.lastName)}
              />
              {!!error.lastName && (
                <Typography variant="h6" className={classes.errMessage}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {error.lastName}
                </Typography>
              )}

            </Box>
            <Box>
              <Button
                disableFocusRipple
                inactive = {disabled}
                color={"primary"}
                title={SAVE}
                variant="contained"
                className={clsx([classes.saveButton])}
                onClick={() => !disabled && handleOnSavePress()}
              />
            </Box>
          </Box>
        </Box>
        <SnackbarToast
            open={!!snackbarMessage}
            message={snackbarMessage ?? ''}
            duration={4000}
            onClose={() => setSnackbarMessage('')}
            type={SNACKBAR_TYPE.ERROR}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          />
      </Box>
    </Modal>
  );
};

export default withStyles(styles, { withTheme: true })(UpdateUserNameModal);
