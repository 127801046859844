import React from 'react';
import { withStyles } from '@mui/styles';
import { Box, Typography, Tooltip } from '@mui/material';
import BtnComponent from './BtnComponent';
import { DrawerBoxComponent } from './DrawerBoxComponent';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: '450px',
  },
}))(Tooltip);

export const AppointmentVisitLink = () => {
  return (
    <DrawerBoxComponent>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: 2,
        }}
      >
        <Typography variant="h3">Visit Link</Typography>
        <CustomTooltip title="Joining link will be enabled 10 minutes before your scheduled visit">
          <span>
            <BtnComponent
              title="Join Visit"
              target="_blank"
              //   href={visitLink}
              rel="noopener"
              disabled
            />
          </span>
        </CustomTooltip>
      </Box>
    </DrawerBoxComponent>
  );
};
