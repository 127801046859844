import client from '../components/ApolloClient';
import gql from 'graphql-tag';

const ITEM_RESPONSE_FRAGMENT = gql`
  fragment item on ItemResponse {
    title
    description
    imageUrl
    rank
    code
    webUrl
    searchKeywords
    searchPlaceHolder
  }
`;

const FETCH_ALL_CATEGORIES = gql`
  query fetchAllCategories($page: String) {
    fetchAllCategories(page: $page) {
      title
      code
      items {
        ...item
      }
    }
  }
  ${ITEM_RESPONSE_FRAGMENT}
`;

const FETCH_ALL_ITEMS_BY_PARENT_CODE = gql`
  query fetchAllItemsByParentCode($code: String) {
    fetchAllItemsByParentCode(code: $code) {
      items {
        ...item
      }
    }
  }
  ${ITEM_RESPONSE_FRAGMENT}
`;

export default {
  fetchAllCategories(page) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: FETCH_ALL_CATEGORIES,
          variables: { page: page },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllCategories);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllItemsByParentCode(code) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: FETCH_ALL_ITEMS_BY_PARENT_CODE,
          variables: { code: code },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllItemsByParentCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
