import { withStyles } from '@mui/styles';
import React from 'react';
import colors from '../../utils/colors';
import {
  Box,
  ListItem,
  Modal,
  Paper,
  Stack,
  Typography,
  StatusIcon,
  Grid,
  ListItemIcon,
} from '@mui/material';
import Benefits from '../../assets/images/Benefits.png';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const styles = (theme) => ({
  parentContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgb(93,175,240,0)',
  },
  container: {
    backgroundColor: colors.WHITE,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    position: 'absolute',
    width: 600,
    top: '50%',
    right: '150%',
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
    maxHeight: '650px',
    overflowY: 'auto',
    paddingBottom: '2rem',
  },
  modalSize: {
    width: '50px',
    height: '50px',
    left: '100%',
    right: '10%',
    backgroundColor: 'rgb(93,175,240,0)',
    marginBottom: '30px',
  },
  planBenefitIcon: {
    width: 46,
    backgroundSize: 'cover',
    marginRight: 8,
  },
  heading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontWeight: 600,
    letterSpacing: 0,
    color: '#000000',
    fontSize: 28,
  },
  iconAndHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  leftSide: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  icon: {
    size: 24,
    fontFamily: 'Inter',
    color: '#000000',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  typo: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: 0,
  },

  primaryDr: {
    fontSize: '1.25rem',
    padding: '24px',
    marginLeft: '-11px',
    display: 'inline-flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: theme.spacing(3),
    paddingRight: 0,
  },
  btn: {
    padding: '4px 8px 6px 8px',
    borderRadius: 20,
    cursor: 'pointer',
    marginLeft: '1.25rem',
    backgroundColor: colors.BLUE,
    height: 20,
    color: colors.WHITE,
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: colors.BLUE_HOVER,
      color: '#FFFFFF',
    },
  },
  viewMoreDetailsButton: {
    marginLeft: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  points: {
    minWidth: '32px',
    fontSize: '14px',
  },
});

const PlanBenefitsFlyout = ({ classes, ...props }) => {
  const {
    open,
    handleClose,
    currentSelectedObj: { benifitsFlyoutData = {}, coverage = '' },
  } = props;
  // const items = benifitsFlyoutData?.plan_benifits ?? [];
  const items = [
    'Inpatient Hospital Coverage',
    'Outpatient Hospital Services',
    'Doctor Visits',
    'Preventive Care',
    'Emergency Care',
    'Urgently Needed Services',
    'Diagnostic Services/Labs/Imaging',
    'Hearing Services',
    'Dental Services',
    'Vision Services',
    'Mental Health Services',
    'Skilled Nursing Facility',
    'Physical Therapy',
    'Ambulance',
    'Transportation (Non Medicare covered routine)',
    'Medicare Part B Drugs',
  ]

  const handleViewMoreDetails = () => {
    window.open(
      'https://mpowered-assets.s3.us-east-2.amazonaws.com/H4869_SOB-Super_Plus_Super_Complete_0923M+(1).pdf',
    );
  };
  return (
    <Modal className={classes.modalSize} open={open} disableAutoFocus={true}>
      <Paper className={classes.parentContainer}>
        <Paper className={classes.container}>
          <Box className={classes.iconAndHeading}>
            <Stack className={classes.leftSide}>
              <img src={Benefits} className={classes.planBenefitIcon} />
              <Typography className={classes.heading}>{coverage}</Typography>
            </Stack>
            <Stack onClick={handleClose} className={classes.icon}>
              <CloseIcon />
            </Stack>
          </Box>

          <Box className={classes.primaryDr}>
            <Stack style={{ marginRight: '30px' }}>
              <Typography variant="p">Primary Dr Co-Pay</Typography>
              <Typography variant="h1">${benifitsFlyoutData?.primary_copay}</Typography>
            </Stack>

            <Stack>
              <Typography variant="p">Specialist Co-Pay</Typography>
              <Typography variant="h1">${benifitsFlyoutData?.specialist_copay}</Typography>
            </Stack>

            <Stack onClick={handleViewMoreDetails} className={classes.viewMoreDetailsButton}>
              <Stack className={classes.btn}>View more details</Stack>
            </Stack>
          </Box>
          <Typography variant="h1" sx={{ marginLeft: '9px', marginBottom: '16px' }}>
            Plan Benefits
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {items?.map((val, index) => (
              <ListItem sx={{ width: '50%', alignItems: 'flex-start' }} key={index}>
                <ListItemIcon className={classes.points}>
                  <FiberManualRecordIcon sx={{ fontSize: '1rem', mt: '0.3rem' }} />
                </ListItemIcon>
                <Typography className={classes.typo}>{val}</Typography>
              </ListItem>
            ))}
          </Box>
        </Paper>
      </Paper>
    </Modal>
  );
};

export default withStyles(styles, { withTheme: true })(PlanBenefitsFlyout);
