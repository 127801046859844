import { withStyles } from '@mui/styles';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import Loader from '../../../components/atoms/Loader';
import BreadCrumb from '../../../components/molecules/BreadCrumb';
import {
  carePlanDateFilterMenuItems,
  CARE_PLAN,
  DEFAULT_NULL_REPLACEMENT,
  NUMBER_OF_RECORDS_PER_PAGE,
  SELF_REPORTING_TEXTS,
} from '../../../utils/constants';
// import SearchBar from '../../../components/atoms/Search';
import FilterDropDown from '../../../components/molecules/FilterDropDown';
import ReusableTable from '../../../components/molecules/ReusableTable';

import carePlanIcon from '../../../assets/images/carePlan.svg';
import {
  carePlanDeviceHeading,
  getCarePlanTableHeadings,
} from '../../../utils/labels/tableHeadings';
import {
  fetchAllCarePlans,
  fetchAllCarePlansByConsent,
  fetchCarePlanById,
} from '../carePlanService';
import {
  closeDetailedView,
  customDateSelected,
  INITIAL_STATE,
  openDetailedPlanView,
  reducer,
  selectDate,
  selectStatus,
  setLoading,
  // updateSearchTerm,
  updatePage,
  updateState,
} from './CarePlanScreen.reducer';
import styles from './CarePlanScreen.styles';

import { useLocation, useParams } from 'react-router-dom';
import CustomDropDown from '../../../components/atoms/CustomeDropDown';
import NotFound from '../../../components/atoms/NotFound';
import PartnerSubTitleCard from '../../../components/molecules/PartnerSubTitleCard';
import PopUpModal from '../../../components/PopUpModal';
import useClickFooter from '../../../hooks/useClickFooter';
import useGetScreenMode from '../../../hooks/useGetScreenMode';
import useUpdateUsedService from '../../../hooks/useUpdateUsedService';
import useWidth from '../../../hooks/useWidth';
import {
  calculateDateFilterTimeStamp,
  checkOneTimeUser,
  getAnalyticsEventsForRouteName,
  getBreadCrumbs,
  getConsentUserDetailsFromLocalStorage,
  getPartnerInfoConsolidated,
} from '../../../utils/helper';
import CarePlanDetails from '../CarePlanDetails';
import { useInitialLoading } from '../../../hooks/useInitialLoading';
import useAnalyticsLogger from '../../../hooks/useAnalyticsLogger';
import { ROUTE_NAMES } from '../../../constants/routeNames';

function CarePlanScreen({ classes, onFooterItemClick }) {
  const location = useLocation();
  const isCirclesOrRecords = location.pathname.includes('circls');
  const { logAnalyticsEvent } = useAnalyticsLogger();
  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.CARE_PLANS);
  const { partner: partnerUrlParam, grantorId, granteeId } = useParams();
  const { state: propsState } = location;
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const [allCarePlanData, setAllCarePlanData] = useState([]);

  const [loaderText, setLoaderText] = useState(CARE_PLAN.WORDINGS.LOADING_CARE_PLANS);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasInfiniteScroll, setHasInfiniteScroll] = useState(true);
  const [initialRender, setInitialRender] = useInitialLoading();

  const pageNumber = useRef(1);
  const statusFilter = useRef('all');
  const timeFilter = useRef('all');
  const searchValue = useRef('');
  const changeFiltersValue = useRef(true);
  const selectedDateFilter = useRef('');

  const partnerInfo = getPartnerInfoConsolidated(partnerUrlParam);
  const consentUserName = getConsentUserDetailsFromLocalStorage();
  const isOneTimeUser = checkOneTimeUser();
  const screenWidth = useWidth();
  const isLarge = screenWidth === 'lg' || screenWidth === 'xl';

  const screenMode = useGetScreenMode();
  useUpdateUsedService('CARE_PLAN', screenMode.isCircle, partnerInfo && partnerInfo.circleId);
  useClickFooter(onFooterItemClick);

  const breadCrumbs = getBreadCrumbs(
    CARE_PLAN.WORDINGS.TITLE,
    screenMode,
    partnerInfo,
    consentUserName,
    isOneTimeUser,
  );

  useEffect(() => {
    isCirclesOrRecords
      ? logAnalyticsEvent(analyticsEvents.CIR_CARE_PLANS_VISITED)
      : logAnalyticsEvent(analyticsEvents.REC_CARE_PLANS_VISITED);
  }, []);

  const getAllCarePlanData = async (offset = 0) => {
    setIsLoading(true);
    const varsForFetchAll = {
      date_filter: calculateDateFilterTimeStamp(timeFilter.current, selectedDateFilter.current),
      status_filter: statusFilter.current === 'all' ? '' : statusFilter.current,
      search_parameter: searchValue.current,
      offset,
      sourceUrl: screenMode.isCircle ? partnerInfo?.partnerSourceUrl : '',
    };
    if (grantorId) {
      varsForFetchAll.grantorId = grantorId;
      varsForFetchAll.initiatorId = granteeId;
      const { sourceUrl, ...rest } = varsForFetchAll;
      return await fetchAllCarePlansByConsent({ ...rest });
    } else {
      return await fetchAllCarePlans(varsForFetchAll);
    }
  };

  const updateUi = async (offset) => {
    try {
      const { carePlanList: data } = await getAllCarePlanData(offset);

      if (pageNumber.current !== 1 && data?.length === 0) {
        setHasInfiniteScroll(false);
        return;
      } else if (data?.length === 0 && pageNumber.current === 1) {
        setHasInfiniteScroll(false);
        setAllCarePlanData([]);
        return;
      } else if (changeFiltersValue.current && data?.length && pageNumber.current === 1) {
        changeFiltersValue.current = false;
        setAllCarePlanData(data);
        return;
      } else if (!changeFiltersValue.current && data?.length && pageNumber.current !== 1) {
        setAllCarePlanData((prevData) => [...prevData, ...data]);
        return;
      }
    } catch (error) {
      console.log(error);
      setHasInfiniteScroll(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateUi(0);
  }, []);

  const tableHeadings = useMemo(() => {
    if (isOneTimeUser && !isLarge) {
      return carePlanDeviceHeading;
    }
    return getCarePlanTableHeadings(screenMode);
  }, [screenMode.isCircle, isLarge]);

  function viewDetails(rowId) {
    // rowId = 2434136;
    const selectedPlan = state?.data.find((item) => item.id === rowId);
    setLoading(dispatch, true);
    setLoaderText(CARE_PLAN.WORDINGS.LOADING_DETAILS);
    setDetailsLoading(true);
    fetchCarePlanById(rowId, grantorId, granteeId, screenMode.isCircle)
      .then((plan) => {
        openDetailedPlanView(dispatch, { ...plan });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(dispatch, false);
        setDetailsLoading(false);
      });
  }

  useEffect(() => {
    if (propsState?.carePlanId) {
      viewDetails(propsState?.carePlanId);
    }
  }, [propsState]);

  const handleChangeFilter = () => {
    changeFiltersValue.current = true;
    setHasInfiniteScroll(true);
    setInitialRender(true);
    pageNumber.current = 1;
    updateUi(0);
    setLoaderText(CARE_PLAN.WORDINGS.LOADING_CARE_PLANS);
  };
  const handleCustomDate = (date) => {
    selectedDateFilter.current = date;
    timeFilter.current = 'custom_date';
    handleChangeFilter();
  };

  const handlepageNumber = () => {
    pageNumber.current += 1;
    const offsetValue = 10 * (pageNumber.current - 1);
    updateUi(offsetValue);
  };

  return (
    <div className={classes.container}>
      {(initialRender || detailsLoading) && <Loader overlay text={loaderText} />}
      <BreadCrumb breadCrumbProps={breadCrumbs} />
      <h2 className={classes.title}>{CARE_PLAN.WORDINGS.TITLE}</h2>
      {partnerUrlParam && (
        <PartnerSubTitleCard icon={partnerInfo?.partnerIconUrl} title={partnerInfo?.partnerName} />
      )}

      <div className={classes.filtersContainer}>
        <div className={classes.filterDropDownContainer}>
          {/* <SearchBar
            rootContainerStyle={classes.searchBar}
            searchInput={classes.searchInput}
            onSearch={(searchTerm) => {
              searchValue.current = searchTerm;
           handleChangeFilter();
            }}
          /> */}

          <CustomDropDown
            options={carePlanDateFilterMenuItems}
            filterType="Date:"
            datePickerEnabled
            handleCustomDate={handleCustomDate}
            value={timeFilter.current}
            changeHandler={(e) => {
              timeFilter.current = e.target.value;
              handleChangeFilter();
            }}
            rootContainerStyle={classes.dropDownDateWrapper}
          />
          <FilterDropDown
            filterType="Status:"
            options={CARE_PLAN.FILTER_ITEMS.STATUS}
            value={statusFilter.current}
            changeHandler={(e) => {
              statusFilter.current = e.target.value;
              handleChangeFilter();
            }}
            rootContainerStyle={classes.dropDownWrapper}
          />
        </div>
        {/* Add data not in scope for R3 */}
        {/* <Button
          variant="outlined"
          color="secondary"
          className={classes.addDataBtn}
          onClick={addData}
        >
          {ADD_DATA}
        </Button> */}
      </div>
      <div className={classes.tableWrapper}>
        {allCarePlanData?.length > 0 && (
          <ReusableTable
            data={allCarePlanData}
            paginationObj={{
              handlepageNumber,
              loading: isLoading,
              detailsLoading,
              hasInfiniteScroll: hasInfiniteScroll,
            }}
            tableHeading={tableHeadings}
            viewDetailsHandler={viewDetails}
          />
        )}
        {!allCarePlanData?.length && !isLoading && (
          <NotFound title={CARE_PLAN.WORDINGS.NO_RECORDS} />
        )}
      </div>
      {state.selectedPlan && (
        <PopUpModal
          icon={carePlanIcon}
          data={state.selectedPlan.columns}
          title={state.selectedPlan.title}
          setOpen={() => closeDetailedView(dispatch)}
          currentObj={{ id: state.selectedPlan.id }}
          markAsRestricted={state.selectedPlan.markAsRestricted}
          customBodyComponent={{
            component: CarePlanDetails,
            props: { ...state.selectedPlan.body },
            careTeam: state.selectedPlan.careTeam,
          }}
          showEdit={false}
          clinicalRecord="Care plan"
          careTeamViewFlag={true}
          restrictedDescription={SELF_REPORTING_TEXTS.MARK_RESTRICTED_DESC('care plan')}
          hideOptions={Boolean(partnerUrlParam || grantorId)}
        />
      )}
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(CarePlanScreen);
