import ApolloClient from 'apollo-client';
import { GRAPHQL } from '../../config/api_end_point';
import { auth } from './Routes';
import 'cross-fetch/polyfill';
import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ERROR_MESSAGES } from '../utils/constants';

const BASE_URL = process.env.APOLLO_CLIENT;
const uploadLink = createUploadLink({ uri: BASE_URL + GRAPHQL });

const request = (operation, forward) => {
  const dependentFhirId = localStorage.getItem('dependentFhirId');
  const organisationName = localStorage.getItem('organizationName');

  const header = dependentFhirId ? { fetch_id: dependentFhirId } : {};
  header['time_zone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  header['org_name'] = organisationName;
  header['domain'] = window.location.hostname.startsWith('demo.mpowered') ? 'DEMO' : 'DEFAULT',

  operation.setContext(({ headers = header }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${auth.getAccessToken()}`,
      },
    };
  });
  return forward(operation);
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper, forward))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) {
          handle.unsubscribe();
        }
      };
    }),
);

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors[0].message) {
    if (
      graphQLErrors[0].message.startsWith('401') ||
      graphQLErrors[0].message.includes('UNAUTHORIZED') ||
      graphQLErrors[0].message.includes('Not Authorized')
    ) {
      auth.logout(true);
    }
  }
});

const link = errorLink.concat(uploadLink);

export default new ApolloClient({
  link: ApolloLink.from([requestLink, link]),
  cache: new InMemoryCache(),
});
