import * as firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';
import { createUuidv4, getDeviceType } from './helper';
import gql from 'graphql-tag';
import PushNotificationService from '../service/PushNotificationService';

let messaging = null;

const SAVE_TOKEN = gql`
  mutation saveFcmToken($tokenRequest: TokenRequest) {
    saveFcmToken(tokenRequest: $tokenRequest) {
      fcmToken
    }
  }
`;

const init = async () => {
  const firebaseConfig = {
    apiKey: `${process.env.FIREBASE_API_KEY}`,
    authDomain: `${process.env.FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.FIREBASE_MESSAGE_SENDER_ID}`,
    appId: `${process.env.FIREBASE_APP_ID}`,
    measurementId: `${process.env.FIREBASE_MEASUREMENT_ID}`,
  };

  try {
    await firebase.initializeApp(firebaseConfig);
    postInit();
  } catch (err) {
    console.error('Failed to initialize Firebase', err);
  }
};

const postInit = () => {
  firebase.analytics();
};

const notificationMessaging = () => {
  const publicVapidKey = `${process.env.FIREBASE_VAPID_KEY}`;

  messaging = firebase.messaging();

  getToken(publicVapidKey);

  messaging.onMessage((payload) => {
    const { title, ...options } = payload.notification;
    navigator.serviceWorker.ready.then((registration) => {
      registration.showNotification(title, options);
    });
  });
};

const sendTokenToServer = (currentToken) => {
  if (!isTokenSentToServer()) {
    const deviceId = createUuidv4();
    const deviceType = getDeviceType();
    const fcmToken = currentToken;

    const tokenRequest = {
      deviceId,
      deviceType,
      fcmToken,
    };

    PushNotificationService.saveClientPushDetails(SAVE_TOKEN, tokenRequest)
      .then((response) => {
        console.info('Sent to server');
        setTokenSentToServer(true);
      })
      .catch((error) => {
        console.warn('Failed to save token to server', error);
        setTokenSentToServer(false);
      });
  }
};

const isTokenSentToServer = () => {
  return window.localStorage.getItem('sentToServer') === '1';
};

const setTokenSentToServer = (sent) => {
  window.localStorage.setItem('sentToServer', sent ? '1' : '0');
};

const getToken = async (publicVapidKey) => {
  const swRegistration = await navigator.serviceWorker.register(
    `${process.env.FIREBASE_SERVICE_WORKER}`,
  );

  try {
    const token = await messaging.getToken({
      serviceWorkerRegistration: swRegistration,
      vapidKey: publicVapidKey,
    });

    if (token) {
      sendTokenToServer(token);
    } else {
      setTokenSentToServer(false);
    }
  } catch (error) {
    console.warn('Failed to get the notification token', error);
  }
};

const clearToken = () => {
  messaging = firebase.messaging();
  messaging
    .deleteToken()
    .then((deleted) => {
      console.log(('Token deleted ', deleted));
    })
    .catch((error) => console.log(error));
};

const generateEvent = (eventName) => {
  if (process.env.ENABLE_FIREBASE_ANALYTICS === 'true') {
   firebase.analytics().logEvent(eventName);
  }
};

const generateParamEvent = (eventName, params) => {
  if (process.env.ENABLE_FIREBASE_ANALYTICS === 'true') {
    firebase.analytics().logEvent(eventName, params);
  }
};

const FirebaseUtils = {
  init,
  generateEvent,
  generateParamEvent,
  notificationMessaging,
  clearToken,
};

export default FirebaseUtils;
