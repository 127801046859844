import React from 'react';
import { withStyles } from '@mui/styles';
import MuiButton from '@mui/material/Button';
import clsx from 'clsx';

const styles = (theme) => ({
  primaryHover: {
    '&:hover': {
      opacity: 1,
      backgroundColor: theme.palette.primary.hover,
    },
  },
  secondaryHover: {
    '&:hover': {
      opacity: 1,
      // backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.common.black,
      backgroundColor: '#389DEF',
      color: '#FFFFFF',
    },
    '&:disabled': {
      // background: theme.palette.primary || 'red'
      color: '#FFFFFF',
    },
  },
  inactive: {
    borderColor: theme.palette.grey[500],
    color: theme.palette.common.main,
    opacity: 0.36,
  },
});
const defaultProps = {
  disableFocusRipple: false,
};
const propTypes = {};

const Button = (props) => {
  const { classes, rootClass, color, title, inactive, disableFocusRipple, ...rest } = props;

  return (
    <MuiButton
      classes={{
        root: clsx(
          rootClass,
          !disableFocusRipple
            ? color === 'primary'
              ? classes.primaryHover
              : classes.secondaryHover
            : null,
          inactive ? classes.inactive : null,
        ),
      }}
      // color={color}
      {...rest}
    >
      {title}
    </MuiButton>
  );
};

Button.defaultProps = defaultProps;
Button.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(Button);
