import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import theme from '../utils/Theme';
import Routes from './Routes';
import UserActivityDetector from './UserActivityDetector/UserActivityDetector';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from '@apollo/client';
import ApolloClient from './ApolloClient';
import { store,persistor } from '../store';
import { PersistGate } from 'redux-persist/integration/react';


const propTypes = {};

const defaultProps = {};

export const TwoStepContext = React.createContext({});

export const AlertCountContext = React.createContext();

const RouterWrapper = (props) => {
  const [twoStepInfo, setTwoStepInfo] = React.useState({
    userVerified: false,
  });

  const [alertsCount, setAlertsCount] = React.useState(0);

  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <ApolloProvider client={ApolloClient}>
              <TwoStepContext.Provider value={{ twoStepInfo, setTwoStepInfo }}>
                <AlertCountContext.Provider value={{ alertsCount, setAlertsCount }}>
                  <UserActivityDetector>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={theme}>
                        <BrowserRouter>
                          <Routes />
                        </BrowserRouter>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </UserActivityDetector>
                </AlertCountContext.Provider>
              </TwoStepContext.Provider>
            </ApolloProvider>
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </div>
  );
};

RouterWrapper.propTypes = propTypes;

RouterWrapper.defaultProps = defaultProps;

export default RouterWrapper;
