//Stopgap hook to get screen mode
import { useLocation } from 'react-router-dom';
import { checkOneTimeUser } from '../utils/helper';

export default function useGetScreenMode() {
  const location = useLocation();
  const isOneTimeUser = checkOneTimeUser();

  const screenMode = {
    isCircle: false,
    involvesConsent: false,
  };

  if (location.pathname.includes('circles')) {
    screenMode.isCircle = true;
  }

  if (location.pathname.includes('individual') && !isOneTimeUser) {
    screenMode.involvesConsent = true;
  }

  return screenMode;
}
