import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import gql from 'graphql-tag';

import ProgressBar from '../../components/molecules/ProgressBar';
import Loader from '../../components/atoms/Loader';
import Button from '../../components/atoms/Button';
import ProfileService from '../../service/ProfileService';
import TextField from '../../components/atoms/TextField';
import ProfileScreenAlert from '../../components/molecules/ProfileScreenAlert';
import { validateEmail } from '../../utils/validations';

import { ADD_CONTACT, FORM_LABEL, ADD_EMAIL, SAVING_DATA, ERROR_MSGS } from '../../utils/constants';
import { TwoStepContext } from '../../components/RouterWrapper';
import withRouter from '../../components/withRoute';
const ADD_EMAIL_ID = gql`
  mutation addEmailId($emailId: String) {
    addEmailId(emailId: $emailId) {
      status
      message
    }
  }
`;

const style = (theme) => ({
  formContainer: {
    paddingTop: '13px',
    [theme.breakpoints.up('lg')]: {
      paddingTop: '64px',
    },
  },
  textField: {
    width: '100%',
    paddingBottom: '30px',
  },
  subheading: {
    ...theme.customComponent.body1RegularLight,
    paddingBottom: '30px',
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
});

const AddEmail = (props) => {
  const { classes } = props;

  const [newEmail, setNewEmail] = useState('');
  const [addedAlert, setAddedAlert] = useState(false);
  const [loader, setLoader] = useState(false);
  const [newEmailError, setNewEmailError] = useState({
    status: false,
    msg: '',
  });

  const { twoStepInfo } = React.useContext(TwoStepContext);

  useEffect(() => {
    if (!twoStepInfo.userVerified) {
      // props.history.replace('/contact');
      props.navigate("/contact");
    }
  }, [twoStepInfo]);

  const handleBackButton = () => {
    props.navigate(-1);
  };

  const handleFieldChange = (name, value) => {
    setNewEmail(value);
    setNewEmailError({
      status: false,
      msg: '',
    });
  };

  const onSubmitClick = () => {
    const { isValid, errorMessage } = validateEmail(newEmail);
    if (isValid) {
      setLoader(true);
      ProfileService.addEmail(ADD_EMAIL_ID, newEmail)
        .then((res) => {
          // props.history.push('/contact');
          props.navigate('/contact');
          // setAddedAlert(true);
        })
        .catch((err) => {
          setNewEmailError({
            status: true,
            msg: ERROR_MSGS.EMAIL_ALREADY_EXIST,
          });
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      setNewEmailError({
        status: true,
        msg: errorMessage,
      });
    }
  };

  const handleDialogClose = () => {
    setAddedAlert(false);
    // props.history.push('/contact');
    props.navigate('/contact');
  };

  return (
    <React.Fragment>
      {loader && <Loader overlay text={SAVING_DATA} />}
      <ProgressBar heading={ADD_CONTACT.EMAIL.HEADING} onBackBtnClick={handleBackButton} />
      <Grid container justify="center">
        <Grid item lg={4} xs={10} className={classes.formContainer}>
          <TextField
            name="addEmail"
            value={newEmail}
            onFieldChange={handleFieldChange}
            error={newEmailError.status}
            errorMsg={newEmailError.msg}
            // onBlur={emailValidation}
            label={FORM_LABEL.EMAIL}
            textFieldClass={classes.textField}
          />
          <Typography className={classes.subheading}>{ADD_CONTACT.EMAIL.SUBHEADING}</Typography>
          <Button
            color="primary"
            title={ADD_CONTACT.BTN_TITLE}
            variant={'contained'}
            size="small"
            rootClass={classes.button}
            onClick={onSubmitClick}
          />
        </Grid>
      </Grid>
      <ProfileScreenAlert
        open={addedAlert}
        onClose={handleDialogClose}
        title={ADD_EMAIL.TITLE}
        body={ADD_EMAIL.BODY}
      />
    </React.Fragment>
  );
};

export default withRouter(withStyles(style, { withTheme: true })(AddEmail));
