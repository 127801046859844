// import { createMuiTheme } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

/**
 * @param px input px as per 1920 * 1080 resolution
 */
export const pxToRem = (px) => {
  return `${px}px`;
};

export const theme = {
  palette: {
    primary: {
      main: '#036FCB',
      light: '#389DEF',
      dark: 'rgb(93,175,240,0.2)',
      hover: '#FFBC44',
      contrastText: '#fff',
    },
    secondary: {
      main: '#036FCB',
      light: '#00a0df',
      dark: '#003a64',
    },
    error: {
      main: '#E02020',
    },
    common: {
      main: 'rgb(0,0,0)',
      white: '#FFFFFF',
      black: 'rgb(0,0,0)',
    },
    background: {
      main: 'radial-gradient(#58687E, #39495E)',
      paper: '#FFF',
      //default: 'rgba(39, 61, 73, .75)',
    },
    cardBackground: {
      // main: 'rgba(39, 61, 73, .75)',
      color: '#f1f1f1',
    },
    solidBackground: {
      main: '#273D49',
    },
    chatHeadBackground: {
      main: '#FC7500',
      color: '#FFFFFF',
    },
    chatWindowBackground: {
      main: '#39495E',
    },
    popOver: {
      main: '#273d49',
    },
    notificationBadge: {
      main: '#DB0032',
    },
    expandMore: {
      main: '#DB0032',
    },
    textFieldUnderline: {
      main: 'rgb(0,0,0,0.42)',
    },
    yellow: {
      main: '#FFA400',
      light: '#FFBC44',
      disabled: '#ffce7e',
    },
  },
  shadows: Array(25).fill('none'),
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: pxToRem(22),
      fontWeight: 'bold',
      letterSpacing: '1.56px',
    },
    //used MWB
    h2: {
      fontSize: pxToRem(20),
      fontWeight: 'normal',
      letterSpacing: '1.41px',
      lineHeight: 1.8,
      opacity: 1,
    },
    //used
    h3: {
      fontSize: pxToRem(17),
      fontWeight: 900,
      letterSpacing: '1.2px',
    },
    h4: {
      fontSize: pxToRem(15),
      fontWeight: 500,
      letterSpacing: '1.06px',
      color: '#737373',
    },
    // //used
    // h5: {
    //     fontSize: pxToRem(24),
    //     lineHeight: "normal"
    // },
    // h6: {
    //     fontSize: pxToRem(22)
    // },
    //used MWD
    // subtitle1: {
    //     fontSize: pxToRem(20),
    //     color: "#000000",
    //     lineHeight: "normal"
    // },
    // //used call prep
    // subtitle2: {
    //     fontSize: pxToRem(17),
    //     color: "#003A64",
    // },
    body1: {
      fontSize: pxToRem(13),
      fontWeight: 600,
      // opacity: 0.56,
      letterSpacing: '0.62px',
      color: 'black',
    },
    //used call prep
    body2: {
      fontSize: pxToRem(12),
      fontWeight: 400,
      // color: "rgb(255,255,255,0.65)",
      letterSpacing: '0.47px',
      opacity: 0.34,
    },
    caption: {
      fontSize: pxToRem(11),
      color: 'black',
      fontWeight: 500,
      letterSpacing: '0.52px',
    },
    overline: {
      fontSize: pxToRem(9),
      fontWeight: 300,
      letterSpacing: '0.43px',
      textTransform: 'none',
      opacity: '0.5',
      lineHeight: '1.44',
      // color: "#FFFFFF"
    },
    inter16500: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
    },

    fontFamily: 'Roboto',
  },
  status: {
    // My business variables
    safe: '#5FC32C',
    safe2: '#CFFFB5',
    warning: '#FF8888',
  },

  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black',
          opacity: 1,
          '&$focused': {
            color: '#F7B500 !important',
            fontWeight: 'bold',
          },
        },
        focused: {},
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          // fontWeight: 500,
          opacity: 0.36,
          fontWeight: 400,
          lineHeight: 1.33,
          '&$error': {
            color: '#000000',
          },
          '&$focused': {
            color: '#F7B500 !important',
            fontWeight: 'bold',
          },
        },
        formControl: {
          transform: 'none',
        },
      },
    },
    input: {
      border: '0px',
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          borderRadius: 6,
          height: 33,
          textTransform: 'none',
        },
        text: {
          padding: 0,
        },
        contained: {
          fontWeight: 900,
        },
        outlined: {
          textTransform: 'none',
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 500,
          borderRadius: '40px',
          padding: '8px 24px',
        },
        outlinedPrimary: {
          borderColor: 'rgba(0, 0, 0, 0.23)',

          '&$disabled': {
            border: 0,
            backgroundColor: '#e4e4e4',
            color: '#fff',
          },
        },
        outlinedSecondary: {
          borderColor: '#004987',
          '&$disabled': {
            border: 0,
            backgroundColor: 'black',
            color: '#fff',
          },
        },
        sizeLarge: {
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 500,
          borderRadius: '40px',
          padding: '24px',
        },
        sizeSmall: {
          fontSize: '13px',
          height: 36,
          width: 154,
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#389DEF',
          },
          '&$disabled': {
            backgroundColor: '#ffce7e',
            color: '#fff',
          },
        },
      },
      variants: [
        {
          props: { variant: 'contained', size: 'xlarge' },
          style: {
            padding: '20px 32px',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 500,
            borderRadius: '40px',
          },
        },
      ],
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          fontWeight: 600,
          height: 35,
          letterSpacing: '0.62px',
          '&:after': {
            backgroundColor: '#F7B500',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          border: 'none',
          borderColor: '#fff',
        },
      },
      input: {
        // opacity: 0.56,
        // fontSize: '13px',
        // fontWeight: 600,
        // opacity: 0.56,
        // letterSpacing: '0.62px',
        '&$disabled': {
          // opacity: 0.36
        },
      },
    },

    // MuiIconButton: {
    //     root: {
    //         color: 'white'
    //     },
    //     // disabled: {
    //     //   color: "red"
    //     //}
    // },
    MuiPickersToolbar: {
      toolbar: {
        // backgroundColor: blue.A200,
        // height: '13vh'
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&$error': {
            color: '#E02020 !important',
          },
        },
      },
    },
    MuiPickersModal: {
      dialogAction: {
        // color: blue['400']
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '13px',
          fontWeight: 400,
          opacity: 0.56,
          letterSpacing: '0.62px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
          border: 'solid 1px #d2d2d2',
          backgroundColor: '#feffff',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: '50px',
          opacity: 1,
          padding: '20px 0 20px 0',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: '5%',
          marginRight: '5%',
          marginBottom: '20px',
        },
        indicator: {
          height: '7px',
          width: '42.13%',
          borderRadius: '3.5px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
        wrapper: {
          fontSize: 13,
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          opacity: 1,
          padding: '8px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: '8vh',
          '&:last-child td': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          border: 'solid 1px #dfd9d9',
          borderRadius: '5px',
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },

    MuiAutocomplete: {
      root: {
        width: '100%',
      },
      popper: {
        backgroundColor: '#feffff',
      },
      paper: {
        maxHeight: '22.5vh',
        borderRadius: '5px',
        border: 'solid 1px #d2d2d2',
      },
      option: {
        minHeight: '2vh',
      },
    },
    MuiSnackbar: {
      root: {
        right: 'auto',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#004987',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: '#000000',
      },
      daySelected: {
        backgroundColor: '#FFA400',
      },
      dayDisabled: {
        color: '#000000',
      },
      current: {
        color: '#000000',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#FFA400',
      },
    },
  },
  customComponent: {
    headerIconText: {
      fontSize: '12px',
      letterSpacing: 0.52,
      color: 'black',
      opacity: 1,
      fontWeight: 400,
    },

    seeMoreText: {
      color: '#f7b500',
      fontSize: '13px',
    },
    rowLeftText: {
      fontSize: '13px',
      opacity: 0.56,
      lineSpacing: '0.62px',
      fontWeight: 600,
    },
    rowRightText: {
      fontSize: '15px',
      letterSpacing: '0.62px',
      fontWeight: 600,
      opacity: 1,
    },
    listItem: {
      fontSize: '13px',
      fontWeight: 500,
      opacity: 0.56,
      height: '40px',
      '&:hover': {
        opacity: 1,
        background: '#efefef',
        fontWeight: 900,
      },
    },
    passwordStrength: {
      width: '25%',
      height: '6px',
      borderRadius: '5px',
      marginRight: '1.6%',
      marginBottom: '0.8%',
    },
    passwordStrengthActive: {
      backgroundColor: '#ffa400',
    },
    passwordStrengthInActive: {
      opacity: 0.08,
      backgroundColor: '#000000',
    },
    passwordStrengthTitle: {
      opacity: 0.5,
      fontSize: '9px',
      fontWeight: 500,
      lineHeight: 1.44,
      letterSpacing: '0.43px',
      marginBottom: '1.8%',
      marginTop: '0.8%',
    },
    passwordStrengthContainer: {
      width: '329px',
      display: 'flex',
    },
    smallIconText: {
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.62px',
      opacity: 1,
    },
    largeIconText: {
      fontSize: '17px',
      fontWeight: 500,
      letterSpacing: '1.06px',
      opacity: 1,
    },
    // addButton:
    // {
    //     width: '11vw',
    //     minWidth: '69.5px',
    //     maxWidth: '140px',
    //     height: '22px',
    //     borderRadius: '11.5px',
    //     textAlign: 'center',
    //     backgroundColor: '#00a0df',
    //     color: 'white',
    //     fontSize: '14px',
    //     fontWeight: 900,
    //     paddingTop: '2px'
    // },
    h4Black: {
      fontSize: pxToRem(15),
      fontWeight: 500,
      opacity: 1,
      letterSpacing: '1.06px',
    },
    h4Bold: {
      fontSize: '15px',
      fontWeight: 900,
      opacity: 0.6,
      letterSpacing: '1.06px',
    },
    body2Black: {
      fontSize: pxToRem(12),
      fontWeight: 600,
      opacity: 1,
      letterSpacing: '0.47px',
    },
    card: {
      borderRadius: '5px',
      border: 'solid 1px #d2d2d2',
      backgroundColor: '#feffff',
    },
    creditCard: {
      borderRadius: '10px',
      border: 'solid 1px #d2d2d2',
      backgroundColor: '#feffff',
    },
    overlineBlack: {
      fontSize: '9px',
      color: 'black',
      opacity: 1,
    },
    body1Regular: {
      fontSize: '13px',
      fontWeight: 400,
      opacity: 0.56,
      letterSpacing: '0.62px',
    },
    body1RegularLight: {
      fontSize: '13px',
      fontWeight: 400,
      opacity: 0.36,
    },
    body1RegularBlack: {
      fontSize: '13px',
      fontWeight: 400,
      opacity: 1,
      letterSpacing: '0.62px',
    },
    body1Black: {
      fontSize: '13px',
      fontWeight: 600,
      opacity: 1,
      letterSpacing: '0.62px',
    },
    body2RegularBlack: {
      fontSize: pxToRem(12),
      fontWeight: 400,
      color: 'black',
      opacity: 1,
      letterSpacing: '0.47px',
    },
    captionBold: {
      fontSize: '11px',
      fontWeight: 600,
      opacity: 1,
    },
    captionLight: {
      fontSize: '11px',
      fontWeight: 600,
      opacity: 0.54,
    },
    h3Regular: {
      fontSize: '17px',
      fontWeight: 400,
      letterSpacing: 1.2,
      opacity: 1,
    },
    h2Bold: {
      fontSize: '20px',
      fontWeight: 'bold',
      letterSpacing: '1.41px',
      opacity: 1,
    },
    h2RegularLight: {
      fontSize: '20px',
      fontWeight: 500,
      letterSpacing: '1.41px',
      opacity: 0.6,
    },
    tabDataTitle: {
      fontSize: '13px',
      fontWeight: 600,
      opacity: 1,
    },
    tabDataDetail: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'black',
      opacity: 0.56,
      letterSpacing: '0.47px',
    },
    detailTileTitle: {
      opacity: 0.36,
      fontSize: '13px',
      fontWeight: 500,
    },
    detailTileValue: {
      opacity: 0.56,
      fontSize: '13px',
      fontWeight: 500,
    },
    clickableItem: {
      cursor: 'pointer',
    },
    coverageSummaryContainer: {
      backgroundColor: '#F5F5F5',
      height: '100%',
    },
    coveragePlanLabel: {
      color: '#036FCB',
    },
    coverageBtn: {
      backgroundColor: '#036FCB',
      color: '#F2F2F2',
      fontSize: 16,
      lineHeight: 0.85,
      borderRadius: 40,
      fontWeight: 600,
      border: 'hidden',
    },
  },
};

export default createTheme(theme);
