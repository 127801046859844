import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import { CODE_FRAGMENT, IMMUNIZATIONS_FRAGMENT } from '../utils/fragments';

const FETCH_ALL_IMMUNIZATIONS_QUERY = gql`
  query fetchAllImmunizations(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllImmunizations(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      immunizationList
      count
    }
  }
`;

const FETCH_ALL_IMMUNIZATIONS_BY_CONSENT = gql`
  query fetchAllImmunizationsByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllImmunizationsByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      immunizationList
      count
    }
  }
`;

const FETCH_IMMUNIZATION_DETAILS_BY_ID_QUERY = gql`
  query fetchImmunizationById($immunizationId: String) {
    fetchImmunizationById(immunizationId: $immunizationId) {
      immunization
      docArn
      documentReferenceId
    }
  }
`;
const FETCH_IMMUNIZATION_DETAILS_BY_ID_AND_GRANTOR_ID = gql`
  query fetchImmunizationByIdAndGrantorId($immunizationId: String, $grantorId: String) {
    fetchImmunizationByIdAndGrantorId(immunizationId: $immunizationId, grantorId: $grantorId) {
      immunization
      docArn
      documentReferenceId
    }
  }
`;

const FETCH_RELATED_IMMUNIZATIONS = gql`
  query fetchRelatedImmunizations($relatedResourceId: String, $relatedResourceType: String) {
    fetchRelatedImmunizations(resourceId: $relatedResourceId, resourceType: $relatedResourceType) {
      id
      vaccineCode {
        ...code
      }
      occurenceString
      occurrenceDateTime
      site {
        text
        coding {
          display
          code
        }
      }
      route {
        ...code
      }
      location {
        reference
        display
      }
      performer {
        actor {
          reference
          display
        }
        onBehalfOf {
          reference
          display
        }
      }
      doseQuantity {
        value
        unit
        code
      }
      doseNumber
      lotNumber
      manufacturer {
        reference
        display
      }
    }
  }
  ${(CODE_FRAGMENT, CODE_FRAGMENT)}
`;

const SAVE_IMMUNIZATION_SELF_REPORTING = gql`
  mutation createImmunizationSelfReportData($data: AddImmunizationSelfReportData) {
    createImmunizationSelfReportData(data: $data) {
      ...immunization
    }
  }
  ${IMMUNIZATIONS_FRAGMENT}
`;

const EDIT_IMMUNIZATION_SELF_REPORTING = gql`
  mutation editImmunizationSelfReportData(
    $data: AddImmunizationSelfReportData
    $immunizationId: String
  ) {
    editImmunizationSelfReportData(data: $data, immunizationId: $immunizationId) {
      ...immunization
    }
  }
  ${IMMUNIZATIONS_FRAGMENT}
`;

const FETCH_HISTORICAL_RECORDS = gql`
  query fetchHistoricalImmunizations(
    $immunizationCode: String
    $count: String = "10"
    $offset: Int = 0
    $dateFilter: Float = 0
  ) {
    fetchHistoricalImmunizations(
      immunizationCode: $immunizationCode
      count: $count
      offset: $offset
      dateFilter: $dateFilter
    ) {
      immunizationList
      count
    }
  }
`;

const MARK_RESTRICTED = gql`
  mutation updateMarkAsRestrictedImmunizations($immunizationId: String, $markAsRestricted: String) {
    updateMarkAsRestrictedImmunizations(
      immunizationId: $immunizationId
      markAsRestricted: $markAsRestricted
    ) {
      ...immunization
    }
  }
  ${IMMUNIZATIONS_FRAGMENT}
`;

const ImmunizationsService = {
  fetchAllImmunizations(variables, query = FETCH_ALL_IMMUNIZATIONS_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllImmunizations);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllImmunizationsByConsent(args, query = FETCH_ALL_IMMUNIZATIONS_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllImmunizationsByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchImmunizationById(id, query = FETCH_IMMUNIZATION_DETAILS_BY_ID_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            immunizationId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchImmunizationById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchImmunizationByIdAndGrantorId(
    immunizationId,
    grantorId,
    query = FETCH_IMMUNIZATION_DETAILS_BY_ID_AND_GRANTOR_ID,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            immunizationId,
            grantorId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchImmunizationByIdAndGrantorId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRelatedImmunizations(
    relatedResourceId,
    relatedResourceType,
    query = FETCH_RELATED_IMMUNIZATIONS,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            relatedResourceId,
            relatedResourceType,
          },
        })
        .then((response) => {
          resolve(response.data.fetchRelatedImmunizations);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createImmunizationSelfReportData(data, mutation = SAVE_IMMUNIZATION_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
          },
        })
        .then((response) => {
          resolve(response.data.createImmunizationSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateImmunizationSelfReportData(
    data,
    immunizationId,
    mutation = EDIT_IMMUNIZATION_SELF_REPORTING,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
            immunizationId,
          },
        })
        .then((response) => {
          resolve(response.data.editImmunizationSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchHistoricalRecords(args, query = FETCH_HISTORICAL_RECORDS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchHistoricalImmunizations);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateMarkAsRestrictedImmunizations(
    immunizationId,
    markAsRestricted,
    mutation = MARK_RESTRICTED,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            immunizationId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.updateMarkAsRestrictedImmunizations);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default ImmunizationsService;
