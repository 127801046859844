// import React from 'react';
import HealthPlan from '../assets/images/healthPlan.svg';
import ObGyn from '../assets/images/obGyn.svg';
import PCP from '../assets/images/pcp.png';
import Paediatrician from '../assets/images/paediatrician.png';
import Dental from '../assets/images/dental.png';
import Care from '../assets/images/care.svg';
import Friends from '../assets/images/friends.svg';
import Son from '../assets/images/son.svg';
import Daughter from '../assets/images/daughter.svg';
import Parent from '../assets/images/parent.svg';
import Others from '../assets/images/others.svg';
import PwaHealthPlan from '../assets/images/pwaMyHealthPlan.svg';
import PwaObGyn from '../assets/images/pwaMyObgyn.svg';
import PwaPCP from '../assets/images/pwaMyPCP.jpg';
import PwaPaediatrician from '../assets/images/pwaMyPaeditrician.jpg';
import PwaDental from '../assets/images/pwaMyDental.jpg';
import PwaCare from '../assets/images/pwaMyCareTeam.svg';
import PwaFriends from '../assets/images/pwaMyFriends.svg';

import { CIRCLES, CIRCLES_GROUPS } from './constants';
import { replaceImageProperty } from './helper';

const CIRCLE_ICONS = [HealthPlan, ObGyn, PCP, Paediatrician, Dental, Care, Friends];

const PWA_CIRLCE_ICONS = [
  PwaHealthPlan,
  PwaObGyn,
  PwaPCP,
  PwaPaediatrician,
  PwaDental,
  PwaCare,
  PwaFriends,
];

const CIRCLES_GROUPS_ICONS = [Son, Daughter, Parent, Others];

const getCircles = () => replaceImageProperty(CIRCLES, CIRCLE_ICONS, PWA_CIRLCE_ICONS);

const getCirclesGroups = () =>
  replaceImageProperty(CIRCLES_GROUPS, CIRCLES_GROUPS_ICONS, CIRCLES_GROUPS_ICONS);

const homeIconsGetter = {
  getCircles,
  getCirclesGroups,
};

export default homeIconsGetter;
