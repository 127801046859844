import gql from 'graphql-tag';
import client from '../../components/ApolloClient';
import { formattedCareTeamDataById, getFormattedCareTeamList } from './helper';

const getCareTeamsQuery = (grantorId) => {
  return gql`
  query fetchAllCareTeams${grantorId ? 'ByConsent' : ''}(
    ${!grantorId ? '$sourceUrl: String' : ''}
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
    ${grantorId ? '$initiatorId: String' : ''}
    ${grantorId ? '$grantorId: String' : ''}
  ) {
    fetchAllCareTeams${grantorId ? 'ByConsent' : ''}(
      ${!grantorId ? 'sourceUrl: $sourceUrl' : ''}
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
      ${grantorId ? 'initiatorId: $initiatorId' : ''}
      ${grantorId ? 'grantorId: $grantorId' : ''}
    ) {
      careTeamList
      count
    }
  }
`;
};

const FETCH_CARE_TEAM_BY_ID = gql`
  query fetchCareTeamById($careTeamId: String) {
    fetchCareTeamById(careTeamId: $careTeamId) {
      careTeam
      carePlans
    }
  }
`;
const MARK_RESTRICTED_CARE_TEAM = gql`
  mutation markAsRestrictedCareTeam($careTeamId: String, $markAsRestricted: String) {
    markAsRestrictedCareTeam(careTeamId: $careTeamId, markAsRestricted: $markAsRestricted) {
      id
      status
      name
    }
  }
`;

export function fetchAllCareTeams(variables = {}) {
  return new Promise((resolve, reject) => {
    client
      .query({ query: getCareTeamsQuery(variables.grantorId), variables, fetchPolicy: 'no-cache' })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => reject(err));
  });
}

export function fetchCareTeamById(careTeamId, isCircle, query = FETCH_CARE_TEAM_BY_ID) {
  const variables = {
    careTeamId: Number(careTeamId).toString(),
  };
  return new Promise((resolve, reject) => {
    client
      .query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        const careTeamList = JSON.parse(response.data.fetchCareTeamById.careTeam);
        const carePlansList = JSON.parse(response.data.fetchCareTeamById.carePlans);
        const processedTeamDetails = formattedCareTeamDataById({
          careTeamList,
          carePlansList,
          isCircle,
        });
        resolve(processedTeamDetails);
      })
      .catch((err) => reject(err));
  });
}

export function markAsRestrictedCareTeam(
  careTeamId,
  markAsRestricted,
  mutation = MARK_RESTRICTED_CARE_TEAM,
) {
  const variables = {
    careTeamId: String(careTeamId),
    markAsRestricted: String(markAsRestricted),
  };
  return new Promise((resolve, reject) => {
    client
      .mutate({ mutation, variables })
      .then((response) => resolve(response.data.markAsRestrictedCareTeam))
      .catch((err) => reject(err));
  });
}
