import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import { DrawerBoxComponent } from './DrawerBoxComponent';
// import { APPOINTMENTS } from '../../shared/Strings';

export const AppointmentVisitStatus = ({ visitStatus, refundStatus }) => {
  return (
    <DrawerBoxComponent>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="navSelected">Visit </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StatusIcon
                  htmlColor="red"
                  sx={{
                    height: 12,
                    width: 12,
                    mr: 0.5,
                  }}
                />
                <Typography variant="h5">{visitStatus}</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Refund status</Typography>
                <StatusIcon
                  htmlColor="blue"
                  sx={{
                    height: 12,
                    width: 12,
                    mr: 0.5,
                    ml: 1,
                  }}
                />
                <Typography variant="h5">{refundStatus}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DrawerBoxComponent>
  );
};
