import StatusIcon from '@mui/icons-material/FiberManualRecord';
import { TableContainer } from '@mui/material';
import { UserInfoContext } from '../../../components/Routes';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Benefits from '../../../assets/images/Benefits.png';
import UserCircle from '../../../assets/images/defaultAvatarBlack.svg';
import hotLinkIcon from '../../../assets/images/hot-link.png';
import colors from '../../../utils/colors';
import { D_SOURCE } from '../../../utils/constants';
import { fetchStatusObj } from '../../../utils/helper';
import Button from '../../atoms/Button';
import PaginationComponent from '../PaginationComponent';
import PlanBenefitsFlyout from '../../PlanBenefitsFlyout';
const PaperWithoutShadow = styled(Paper)({
  boxShadow: 'unset',
});

const styles = (theme) => ({
  tableContainer: {
    height: 'max-content',
    overflow: 'auto',
    borderRadius: 16,
    backgroundColor: '#F5F5F5',
  },
  table: {
    borderCollapse: 'inherit',
    backgroundColor: colors.WHITE,
    borderTopRightRadius: 16,
    scrollBehaviour: 'smooth',
    '&:last-child': {
      // '&:nth-last-child(1)': {
      border: ' 1px solid #E4E4E4',
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
    },
  },
  tableHead: {},
  endOfFileText: {
    textAlign: 'center',
    background: 'white',
    width: '100%',
    margin: 0,
    padding: '12px 0px',
    lineHeight: '24px',
    borderTop: ' 1px solid #E4E4E4',
    borderBottom: ' 1px solid #E4E4E4',
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    fontWeight: 600,
  },
  viewDetailsButton: {
    background: '#036FCB',
    color: 'white',
    height: 24,
    padding: '2px 8px 0px',
    borderRadius: 20,
    minWidth: 90,
    [theme.breakpoints.down('sm')]: {
      height: 34,
    },
  },
  viewRecordButton: {
    marginRight: 10,
    color: '#036FCB',
    border: '1px solid #A0A4A6',
    height: 24,
    padding: '2px 8px 0px',
    borderRadius: 20,
    minWidth: 90,
  },
  planBenefit: {
    width: '75px',
    padding: '0px 8px',
    display: 'flex',
    boxSizing: 'border-box',
    marginLeft: '8px',
    cursor:'pointer'
  },
  consentRequestButton: {
    border: '1px solid #A0A4A6',
    color: '#036FCB',
    height: 24,
    padding: '2px 8px 0px',
    borderRadius: 20,
    minWidth: 90,
  },
  paginationWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  dataSource: {
    background: '#E4E4E4',
    border: '1px solid #C0C0C0',
    boxSizing: 'border-box',
    borderRadius: 40,
    color: '#1F1F1F',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    textAlign: 'center',
    width: 108,
    padding: '1px 8px',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    top: 3,
  },
  tooltipDisplay: {
    background: '#1B1B1B',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '4px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    padding: '0px 8px 6px 8px',
    minWidth: 98,
    display: 'block',
    position: 'relative',
    top: 2,
  },
  statusContainer: { display: 'flex' },
  statusIcon: { width: 12, height: 12 },
  statusColumn: {
    marginRight: 4,
    display: 'flex',
    alignItems: 'center',
  },
  moreWrapper: { textAlign: 'right' },
  moreWrapperBtn: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
  payBtn: {
    border: '1px solid #A0A4A6',
    borderRadius: 40,
    width: 94,
    height: 24,
    color: '#036FCB',
    fontSize: 12,
    fontWeight: 500,
    fontFamily: 'Inter',
  },
  tooltip: {
    padding: 0,
    background: '#1B1B1B',
    minWidth: 120,
    position: 'relative',
    bottom: 5,
    height: 20,
  },
  hotlInkTooltip: {
    fontSize: '0.8rem',
  },
  hotLink: {
    width: '15px',
    height: '15px',
    display: 'inline-block',
    marginLeft: '8px',
    cursor: 'pointer',
  },
  imgIcon: {
    width: 20,
    height: 20,
    borderRadius: 80,
    marginRight: 8,
    objectFit: 'cover',
    overflow: 'hidden',
  },
  ImgWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  initialWrapper: {
    boxSizing: 'border-box',
    fontFamily: 'Inter',
    height: 24,
    width: 24,
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 600,
    color: '#002534',
    fontSize: '12px',
    lineHeight: 0,
    marginRight: 4,
    padding: 4,
  },
  typeOfConsentWrapper: {
    fontSize: '11px',
    color: '#1f1f1f',
    fontWeight: 500,
    fontFamily: 'Inter',
    background: '#e4e4e4',
    padding: '0px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #c0c0c0',
    borderRadius: 24,
    width: 'fit-content',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    paddingLeft: 16,
    fontWeight: 600,
    fontSize: 14,
    color: '#333333',
    opacity: 1,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    height: 48,
    minWidth: 100,
  },
  body: {
    paddingLeft: 15,
    paddingRight: 16,
    fontWeight: 'normal',
    fontSize: 14,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    height: 48,
    minWidth: 100,
    overflow: 'hidden',
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 150,
      paddingRight: 0,
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))(TableCell);

const ReusableTable = ({
  classes,
  tableHeading,
  onPayClick,
  handleRequestConsent,
  data,
  viewDetailsHandler,
  // showPagination = true,if button base pagination enable then is this is the flag to hide or not
  paginationObj: {
    // total = 0,//on button base pagination this is required to calculate total page
    // numberOfRecordsPerPage = 10,//this is for calculating number of records per page
    handlepageNumber,
    loading = false,
    detailsLoading = false,
    hasInfiniteScroll = false,
    infiniteScrollMargin = '100px',
  } = {},
  viewRecordsHandler,
  viewBtnTitle = '',
  nextPageId,
}) => {
  const [tableBodyData, setTableData] = useState(data);
  const observor = useRef();
  const { userInfo } = useContext(UserInfoContext);
  const betaUser = userInfo && userInfo.betaUser;

  const [showPlanBenefits, setShowPlanBenefits] = useState(false);
  const [currentSelectedObj, setCurrentSelectedObj] = useState({});

  ////////////////Button base paginatin////////////////////////
  // const [pageNumber, setPageNumber] = useState(currentPageNo.current);
  // const onPageClick = (val) => {
  //   if (onPageNumberClick && val !== pageNumber) {
  //     onPageNumberClick(val);
  //   }
  //   setPageNumber(val);
  // };

  // useEffect(() => {
  //   setTableData(data);
  // }, [data]);

  // useEffect(() => {
  //   setPageNumber(currentPageNo);
  // }, [currentPageNo]);
  //////////////////////////////////////////////////////////

  useEffect(() => {
    setTableData(data);
    if (!hasInfiniteScroll && observor) {
      observor.current?.disconnect();
    }
  }, [data]);

  useEffect(() => {
    if (!hasInfiniteScroll && observor) {
      observor.current?.disconnect();
    }
  }, [hasInfiniteScroll]);

  const lastElementObserver = useCallback((element) => {
    if (loading || !element) {
      return;
    }
    if (observor.current) {
      observor.current.disconnect();
    }
    observor.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (hasInfiniteScroll) {
            handlepageNumber();
          }
        }
      },
      {
        rootMargin: infiniteScrollMargin,
      },
    );
    if (element) {
      observor.current.observe(element);
    }
  });

  const renderStatusView = (status) => {
    let statusObj;
    if (status === '-') {
      statusObj = {
        status: '-',
        color: '#fff',
      };
    } else {
      statusObj = fetchStatusObj(status);
    }
    return (
      <div className={classes.statusContainer}>
        <span className={classes.statusColumn}>
          <StatusIcon htmlColor={statusObj.color} className={classes.statusIcon} />
        </span>
        {statusObj.status}
      </div>
    );
  };

  const renderDataSourceColumn = (val) => {
    let showToolTip = false;
    if (val && val.length >= 15) {
      showToolTip = true;
    }

    const toolTipHtml = (partnerName) => {
      return <span className={classes.tooltipDisplay}>{partnerName}</span>;
    };

    return showToolTip ? (
      <Tooltip arrow title={toolTipHtml(val)} classes={{ tooltip: classes.tooltip }}>
        <span className={classes.dataSource}>{val}</span>
      </Tooltip>
    ) : (
      <span className={classes.dataSource}>{val}</span>
    );
  };
  const renderNameColumn = (val) => {
    let showToolTip = false;
    if (val && val.length > 50) {
      showToolTip = true;
    }

    const toolTipHtml = (partnerName) => {
      return <span className={classes.tooltipDisplay}>{partnerName}</span>;
    };
    return showToolTip ? (
      <Tooltip arrow title={toolTipHtml(val)} classes={{ tooltip: classes.tooltip }}>
        <span>{val}</span>
      </Tooltip>
    ) : (
      <span>{val}</span>
    );
  };

  const renderHotLink = (rowObj) => {
    return (
      rowObj.hotLink && (
        <Tooltip
          placement="top"
          title="On clicking, you will be redirected to eHealth's site to view the coverage details"
          classes={{ tooltip: classes.hotLinkTooltip }}
        >
          <img
            id="hot-link-icon"
            onClick={() => redirectHotlink(rowObj.hotLink || rowObj.hotlink)}
            src={hotLinkIcon}
            className={classes.hotLink}
          />
        </Tooltip>
      )
    );
  };

  const redirectHotlink = (url) => {
    window.open(`https://${url}`, '_blank');
  };

  const handlePlanBenefit = (id) => {
    const selectedObj = tableBodyData?.find((item, idx) => item.id === id);
    setCurrentSelectedObj(selectedObj);
    setShowPlanBenefits(true);
  };
  const handleClosePlanBenifit = () => {
    setShowPlanBenefits(false);
  };

  return (
    <React.Fragment>
      <TableContainer
        id="scrollableDiv"
        component={PaperWithoutShadow}
        className={classes.tableContainer}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHead}>
            <TableRow style={{ height: 48 }}>
              {tableHeading?.map((heading_cell_obj, i) => {
                if (heading_cell_obj.label === 'More') {
                  return (
                    <StyledTableCell key={i} className={classes.moreWrapper}></StyledTableCell>
                  );
                }
                if(!betaUser && heading_cell_obj.label==="Plan Benefits"){
                  return '';

                }
                if (heading_cell_obj['hide'] === undefined || heading_cell_obj.hide === false ) {
                  return <StyledTableCell key={i}>{heading_cell_obj.label}</StyledTableCell>;
                }
                return '';
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableBodyData?.map((row, i) => {
              const isLastElement = nextPageId ? true : false;
              return (
                <TableRow
                  key={Math.random()}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  style={{ height: 48 }}
                  ref={lastElementObserver}
                >
                  {tableHeading?.map((headerColumnObj, j) => {
                    if (headerColumnObj['hide'] !== undefined && headerColumnObj.hide === true) {
                      return '';
                    }
                    // else if (headerColumnObj.key === 'pay') {
                    // return (
                    //   <StyledTableCell key={row['id'] * Math.random()}>
                    //     {row['pay'] === true ? (
                    //       <Button
                    //         onClick={() => (onPayClick ? onPayClick(row['id']) : null)}
                    //         rootClass={classes.payBtn}
                    //         title="Pay now"
                    //       />
                    //     ) : (
                    //       row[headerColumnObj.key]
                    //     )}
                    //   </StyledTableCell>
                    // );
                    // }
                    else if (headerColumnObj.label === 'More') {
                      if (row.hideViewDetails) {
                        return (
                          <StyledTableCell
                            key={row['id'] * Math.random()}
                            className={classes.moreWrapperBtn}
                          ></StyledTableCell>
                        );
                      }
                      if (
                        row['status'] === 'Not initiated' &&
                        headerColumnObj.routeFrom === 'InMyCare'
                      ) {
                        return (
                          <StyledTableCell
                            key={row['id'] * Math.random()}
                            className={classes.moreWrapperBtn}
                          >
                            <Button
                              onClick={() =>
                                handleRequestConsent ? handleRequestConsent(row) : null
                              }
                              className={classes.consentRequestButton}
                              title={'Request consent'}
                            />
                          </StyledTableCell>
                        );
                      }
                      return (
                        <StyledTableCell
                          key={row['id'] * Math.random()}
                          className={classes.moreWrapperBtn}
                        >
                          {headerColumnObj.routeFrom === 'receivedConsent' &&
                            (row['status'] === 'ACTIVE' || row['status'] === 'Active') &&
                            !['LegalGuardian', 'HealthcarePOA'].includes(
                              row['legalGuardianOrHealthcarePOA'],
                            ) && (
                              <Button
                                onClick={() =>
                                  viewRecordsHandler ? viewRecordsHandler(row['id']) : null
                                }
                                className={classes.viewRecordButton}
                                title={'View records'}
                              />
                            )}
                          <Button
                            onClick={() =>
                              viewDetailsHandler ? viewDetailsHandler(row['id']) : null
                            }
                            className={classes.viewDetailsButton}
                            title={viewBtnTitle || 'View details'}
                          />
                        </StyledTableCell>
                      );
                    } else if (
                      headerColumnObj.label === 'Status' ||
                      headerColumnObj.label === 'Consent status' ||
                      headerColumnObj.showStatusIcon
                    ) {
                      return (
                        <StyledTableCell key={row['id'] * Math.random()}>
                          {renderStatusView(row[headerColumnObj.key])}
                        </StyledTableCell>
                      );
                    }else if (headerColumnObj.label === 'Plan Benefits') {
                      return (
                        <StyledTableCell
                          key={row['id'] * Math.random()}
                          className={classes.moreWrapperBtn}
                        >
                          <img
                            src={Benefits}
                            className={classes.planBenefit}
                            onClick={() => handlePlanBenefit(row['id'])}
                          />
                        </StyledTableCell>
                      );
                    } 
                    else if (headerColumnObj.label === D_SOURCE) {
                      return (
                        <StyledTableCell key={row['id'] * Math.random()}>
                          {renderDataSourceColumn(row[headerColumnObj.key])}
                        </StyledTableCell>
                      );
                    } else if (headerColumnObj.type === 'hotLink') {
                      return (
                        <StyledTableCell key={row['id'] * Math.random()}>
                          {row[headerColumnObj.key]}
                          {renderHotLink(row)}
                        </StyledTableCell>
                      );
                    } else if (
                      headerColumnObj.label === 'Given to' ||
                      headerColumnObj.label === 'Received from' ||
                      headerColumnObj.routeFrom === 'InMyCare' ||
                      headerColumnObj.routeFrom === 'FamilyMember'
                    ) {
                      return (
                        <StyledTableCell key={row['id'] * Math.random()}>
                          <span className={classes.ImgWrapper}>
                            {row[headerColumnObj.key].imgUrl ? (
                              <span className={classes.imgIcon}>
                                <img
                                  src={row[headerColumnObj.key].imgUrl}
                                  alt={row[headerColumnObj.key].Name}
                                  className={classes.imgIcon}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = UserCircle;
                                  }}
                                />
                              </span>
                            ) : row[headerColumnObj.key].Name.length > 1 &&
                              row[headerColumnObj.key].Name[0] ? (
                              <p
                                className={classes.initialWrapper}
                                style={{ backgroundColor: row.bgColor }}
                              >
                                {row[headerColumnObj.key].Name[0].toUpperCase()}
                              </p>
                            ) : (
                              ''
                            )}
                            {row[headerColumnObj.key].Name || row[headerColumnObj.key]}
                          </span>
                        </StyledTableCell>
                      );
                    } else if (headerColumnObj.label === 'Name') {
                      return (
                        <StyledTableCell key={row['id'] * Math.random()}>
                          {renderNameColumn(row[headerColumnObj.key])}
                        </StyledTableCell>
                      );
                    } else if (headerColumnObj.label === 'Type of consent') {
                      return (
                        <StyledTableCell key={row['id'] * Math.random()}>
                          <span className={classes.typeOfConsentWrapper}>
                            {row[headerColumnObj.key]}
                          </span>
                        </StyledTableCell>
                      );
                    }
                    return (
                      <StyledTableCell key={row['id'] * Math.random()}>
                        {row[headerColumnObj.key] || '-'}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {loading && !detailsLoading && <h5 className={classes.endOfFileText}>Loading...</h5>}
        {!hasInfiniteScroll && <h5 className={classes.endOfFileText}>Yah! you have seen it all</h5>}
      </TableContainer>
      {/* <div className={classes.paginationWrapper}>
        {showPagination && total > numberOfRecordsPerPage && (
          <PaginationComponent
            numberOfRecordsPerPage={numberOfRecordsPerPage}
            total={total}
            onPageClick={onPageClick}
            pageNumber={pageNumber}
          />
        )}
      </div> */}
        <PlanBenefitsFlyout
        currentSelectedObj={currentSelectedObj}
        open={showPlanBenefits}
        handleClose={handleClosePlanBenifit}
      />
    </React.Fragment>
  );
};
export default withStyles(styles, { withTheme: true })(ReusableTable);
