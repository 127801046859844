import React, { useState, useEffect } from 'react';

const optionalQtnList = ['radio', 'checkbox', 'selectbox'];
const nonOptionalQtnList = ['longText', 'shortText'];

const useHandleValidationForAssessments = ({
  questionsList,
  answerIdAndValue,
  currentQuestionIndex,
}) => {
  const [disabledButtonList, setDisabledButtonList] = useState([]);
  const currentQuestion = questionsList?.assessmentDefinition?.[currentQuestionIndex];

  useEffect(() => {
    if (
      currentQuestion?.mandatory &&
      currentQuestion?.type === 'standalone' &&
      ((currentQuestion?.answerId?.length <= 0 &&
        optionalQtnList.includes(currentQuestion?.answerType)) ||
        (!currentQuestion?.answerValue && nonOptionalQtnList.includes(currentQuestion?.answerType)))
    ) {
      setDisabledButtonList(['next', 'finish']);
    } else {
      let isDisabled = false;
      if (currentQuestion?.subQuestion?.length > 0) {
        for (const qtn of currentQuestion?.subQuestion) {
          if (
            qtn?.mandatory &&
            qtn?.type === 'standalone' &&
            ((qtn?.answerId?.length <= 0 && optionalQtnList.includes(qtn?.answerType)) ||
              (!qtn?.answerValue && nonOptionalQtnList.includes(qtn?.answerType)))
          ) {
            isDisabled = true;
          }
        }
      } else {
        isDisabled = false;
      }
      if (isDisabled) {
        setDisabledButtonList(['next', 'finish']);
      } else {
        setDisabledButtonList([]);
      }
    }
  }, [answerIdAndValue, currentQuestion]);
  return { disabledButtonList };
};

export default useHandleValidationForAssessments;
