export default function styles(theme) {
  return {
    container: {
      background: '#F5F5F5',
      position: 'relative',
      minHeight: '100%',
      padding: '32px 32px',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 16px',
      },
    },
    filtersContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    filterDropDownContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0.75rem',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '8px 0px',
      },
    },
    title: {
      fontSize: 28,
      fontWeight: 600,
      height: 40,
      marginTop: 24,
      marginBottom: 40,
    },
    tableWrapper: {
      marginTop: 16,
      // width: 948,
      position: 'relative',
    },
    flexBox: {
      display: 'flex',
      flex: 1,
    },
    searchBar: {
      backgroundColor: 'white',
      width: 160,
      borderRadius: 4,
      border: '1px solid #D0D0D0',
      height: '2.5rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    searchInput: {
      border: 'none',
      opacity: 1,
      fontWeight: 400,
      fontFamily: 'Inter',
      height: '2.5rem',
    },
    filterDropDownWrapper: {
      flexWrap: 'wrap',
      display: 'flex',
    },
    addDataBtn: {
      borderRadius: 40,
      border: '1px solid #A0A4A6',
      Padding: '8px, 24px, 8px, 24px',
      color: '#036FCB',
      fontWeight: 500,
      fontSize: 16,
      fontFamily: 'Inter',
      width: 120,
      height: 40,
      backgroundColor: '#fff',
    },
    dropDownWrapper: {
      width: 225,
      paddingRight: 10,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: 0,
      },
    },
    dropDownDateWrapper: {
      width: 190,
      paddingRight: 10,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: 0,
      },
    },
  };
}
