import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import {
  CLAIM_CONDITION_FRAGMENT,
  CLAIM_FRAGMENT,
  CLAIM_PROCEDURE_FRAGMENT,
  EOB_COST_FRAGMENT,
  EOB_SERVICE_FRAGMENT,
  IDENTIFIER_FRAGMENT,
} from '../utils/fragments';

const FETCH_ALL_EOB_QUERY = gql`
  query fetchAllEob(
    $eobType: String
    $timePeriod: TimePeriod
    $sourceUrl: String
    $count: String
    $offset: Int
    $dateFilter: Float
    $statusFilter: String
    $searchFilter: String
  ) {
    fetchAllEob(
      eobType: $eobType
      timePeriod: $timePeriod
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $dateFilter
      statusFilter: $statusFilter
      searchFilter: $searchFilter
    ) {
      eobList {
        ...claim
      }
      count
    }
  }
  ${CLAIM_FRAGMENT}
`;
const FETCH_ALL_EOB_BY_CONSENT = gql`
  query fetchAllEOBByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String
    $offset: Int
    $date_filter: Float
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllEOBByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      eobList {
        ...claim
      }
      count
    }
  }
  ${CLAIM_FRAGMENT}
`;

const FETCH_ALL_EOB_COST_QUERY = gql`
  query fetchAllEobCost($eobType: EobType, $timePeriod: TimePeriod, $sourceUrl: String) {
    fetchAllEobCost(eobType: $eobType, timePeriod: $timePeriod, sourceUrl: $sourceUrl) {
      ...cost
    }
  }
  ${EOB_COST_FRAGMENT}
`;

const DEFAULT_FETCH_EOB_ID_QUERY = gql`
  query fetchEobById($id: String) {
    fetchEobById(eobId: $id) {
      condition {
        ...claimCondition
      }
      procedure {
        ...claimProcedure
      }
      identifier {
        ...identifier
      }
      claimType
      claimNumber
      claimToDate
      claimFromDate
      claimProviderNumber
      paidToProvider
      meta {
        security {
          system
          code
          display
          version
        }
        source
        lastUpdated
        versionId
      }
      serviceList {
        ...serviceList
      }
    }
  }
  ${IDENTIFIER_FRAGMENT}
  ${CLAIM_CONDITION_FRAGMENT}
  ${CLAIM_PROCEDURE_FRAGMENT}
  ${EOB_SERVICE_FRAGMENT}
`;
const MARK_AS_RESTRICTED_CLAIMS_QUERY = gql`
  query markAsRestrictedEOB($eobId: String, $markAsRestricted: String) {
    markAsRestrictedEOB(eobId: $eobId, markAsRestricted: $markAsRestricted) {
      identifier {
        ...identifier
      }
      claimType
      claimNumber
      claimToDate
      claimFromDate
      claimProviderNumber
      paidToProvider
      serviceList {
        ...serviceList
      }
    }
  }
  ${IDENTIFIER_FRAGMENT}
  ${EOB_SERVICE_FRAGMENT}
`;

const DEFAULT_FETCH_EOB_COST_QUERY = gql`
  query fetchEobCost($eobId: String, $sourceUrl: String) {
    fetchEobCost(eobId: $eobId, sourceUrl: $sourceUrl) {
      paymentByMedicare
      patientAmount
    }
  }
`;

const FETCH_RELATED_CLAIM = gql`
  query fetchRelatedClaim($relatedResourceId: String!, $relatedResourceType: String!) {
    fetchRelatedClaim(resourceId: $relatedResourceId, resourceType: $relatedResourceType) {
      id
      identifier {
        ...identifier
      }
      claimType
      claimNumber
      claimToDate
      claimFromDate
      claimProviderNumber
      serviceList {
        ...serviceList
      }
    }
  }
  ${IDENTIFIER_FRAGMENT}
  ${EOB_SERVICE_FRAGMENT}
`;

const ClaimService = {
  fetchAllEob(variables, query = FETCH_ALL_EOB_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllEob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllEOBByConsent(args, query = FETCH_ALL_EOB_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllEOBByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAllEobCost(eobType, timePeriod, sourceUrl = '', query = FETCH_ALL_EOB_COST_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            eobType,
            timePeriod,
            sourceUrl,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllEobCost);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchClaimDetails(args, query = DEFAULT_FETCH_EOB_ID_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: args,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchEobById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchClaimCost(args, query = DEFAULT_FETCH_EOB_COST_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: args,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchEobCost);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRelatedClaims(args, query = FETCH_RELATED_CLAIM) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: args,
        })
        .then((response) => {
          resolve(response.data.fetchRelatedClaim);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markAsRestrictedClaims(eobId, markAsRestricted, query = MARK_AS_RESTRICTED_CLAIMS_QUERY) {
    const variables = {
      eobId: String(eobId),
      markAsRestricted: String(markAsRestricted),
    };
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables,
        })
        .then((response) => {
          resolve(response.data.markAsRestrictedEOB);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default ClaimService;
