import AllergyImg from '../assets/images/allergy-transparent.png';
import ClaimsImg from '../assets/images/Claims-transparent.png';
import ConditionsImg from '../assets/images/conditions.png';
import MedicationsImg from '../assets/images/medications.png';
import ProceduresImg from '../assets/images/procedure-transparent.png';
import ImmunizationsImg from '../assets/images/shield-transparent.png';
import VitalsImg from '../assets/images/vital-transparent.png';
import LabResults from '../assets/images/labs.svg';
import { replaceImageProperty } from './helper';
import { MY_MEDICARE_MENU_ITEMS, AHK_MENU_ITEMS } from './constants';
const MEDICARE_ICONS = [
  ClaimsImg,
  ConditionsImg,
  ProceduresImg,
  MedicationsImg,
  AllergyImg,
  ImmunizationsImg,
  VitalsImg,
];
const AHK_ICONS = [
  MedicationsImg,
  ConditionsImg,
  AllergyImg,
  VitalsImg,
  ImmunizationsImg,
  ProceduresImg,
  MedicationsImg,
];

const CIRCLES_SERVICES_ITEMS = [
  {
    caption: 'Claims',
    image: ClaimsImg,
    link: '/circles/$PARTNER/claims',
    type: 'CLAIMS',
  },
  {
    caption: 'Conditions',
    image: ConditionsImg,
    link: '/circles/$PARTNER/conditions',
    type: 'CONDITIONS',
  },
  {
    caption: 'Procedures',
    image: ProceduresImg,
    link: '/circles/$PARTNER/procedures',
    type: 'PROCEDURES',
  },
  {
    caption: 'Medications',
    image: MedicationsImg,
    link: '/circles/$PARTNER/medications',
    type: 'MEDICATIONS',
  },
  {
    caption: 'Allergies',
    image: AllergyImg,
    link: '/circles/$PARTNER/allergies',
    type: 'ALLERGIES',
  },
  {
    caption: 'Immunizations',
    image: ImmunizationsImg,
    link: '/circles/$PARTNER/immunizations',
    type: 'IMMUNIZATIONS',
  },
  {
    caption: 'Vitals',
    image: VitalsImg,
    link: '/circles/$PARTNER/vitals',
    type: 'VITALS',
  },
  {
    caption: 'Lab results',
    image: LabResults,
    link: '/circles/$PARTNER/lab_results',
    type: 'LAB_RESULTS',
  },
];

const getImageByName = (name) =>
  CIRCLES_SERVICES_ITEMS.find(({ type }) => name.toLowerCase() === type.toLowerCase());

const getMedicareIcons = () => replaceImageProperty(MY_MEDICARE_MENU_ITEMS, MEDICARE_ICONS, []);
const getAhkIcons = () => replaceImageProperty(AHK_MENU_ITEMS, AHK_ICONS, []);
const circleIconGetter = { getImageByName, getMedicareIcons, getAhkIcons };

export default circleIconGetter;
