import client from '../components/ApolloClient';

// Update is not applicable for web after recent Firebase changes
const PushNotificationService = {
  saveClientPushDetails(query, tokenRequest) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            tokenRequest,
          },
        })
        .then((response) => {
          resolve(response.data.saveFcmToken);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default PushNotificationService;
