import gql from 'graphql-tag';

import client from '../components/ApolloClient';

const FETCH_RECENTLY_USED_SERVICE_QUERY = gql`
  query {
    getRecentlyUsedServiceList {
      lastUpdatedTime
      service {
        serviceType
        webIconUrl
        mobIconUrl
        serviceName
        screenPathWeb
        borderColor
      }
    }
  }
`;
const UPDATE_RECENTLY_USED_SERVICE_MUTATION = gql`
  mutation updateServiceTouchedTime($serviceType: RecentServiceType) {
    updateServiceTouchedTime(serviceType: $serviceType) {
      success
    }
  }
`;

const RecentServices = {
  async fetchRecentlyUsedService(query = FETCH_RECENTLY_USED_SERVICE_QUERY) {
    const {
      data: { getRecentlyUsedServiceList },
    } = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return getRecentlyUsedServiceList;
  },
  async updateUsedService(serviceType, mutation = UPDATE_RECENTLY_USED_SERVICE_MUTATION) {
    const {
      data: { success },
    } = await client.mutate({
      mutation,
      variables: {
        serviceType,
      },
    });
    return success;
  },
};

export default RecentServices;
