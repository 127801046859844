import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import colors from '../../../utils/colors';
import { ChevronRight } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mockTemplates } from '../../../features/dynamicRecords/mockTemplate';
import EarnMorePoints from '../../../assets/images/earnMorePoints.svg';

const styles = () => ({
  root: {
    backgroundColor: colors.WHITE,
    boxShadow: ' 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 84,
    borderRadius: 8,
    overflow: 'hidden',
    marginTop: 24,
    width: '100%',
  },
  titleStyle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: ' 24px',
    color: colors.TITLE_GRAY,
  },
  contentContainer: {
    boxSizing: 'border-box',
    padding: '16px 8px 16px 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrop: 5,
  },
  contentStyle: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.GRAY_SECONDARY,
  },
  image: {
    minHeight: 84,
    objectFit: 'contain',
    alignSelf: 'flex-end',
  },
  headingStyle: {
    display: 'flex',
  },
  chevronStyle: {
    width: 24,
    height: 24,
    fontWeight: 400,
    color: colors.PRIMARY_GRAY,
    marginLeft: 4,
  },
});

const EarnPOintsCard = ({ classes }) => {
  const navigate = useNavigate();
  const { templates } = useSelector((state) => state.templates);
  const { selectedLanguage } = useSelector((state) => state.app);

  const EARNPOINTS =
    // templates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.EARNPOINTS||
    mockTemplates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.EARNPOINTS;

  return (
    <div className={classes.root} onClick={() => navigate('/utilities/awardpoints/earnPoints')}>
      <Box className={classes.contentContainer}>
        <Box className={classes.headingStyle}>
          <Typography className={classes.titleStyle}>{EARNPOINTS.HEADING}</Typography>
          <ChevronRight className={classes.chevronStyle} />
        </Box>
        <Typography className={classes.contentStyle}>{EARNPOINTS.SUB_HEADING}</Typography>
      </Box>

      <img className={classes.image} src={EarnMorePoints} />
    </div>
  );
};

export default withStyles(styles)(EarnPOintsCard);
