import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import colors from '../../utils/colors';
import ListCustom from '../../components/ListCustom';

const styles = {
  sectionHeading: {
    color: colors.TITLE_GRAY,
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: 16,
    marginBottom: '16px',
  },
};

function CarePlanDetailsSection({ classes, title, body }) {
  return (
    <div>
      <Typography className={classes.sectionHeading}>{title}</Typography>
      {body.length > 0 && <ListCustom items={body} />}
      {body.length === 0 && <div>-</div>}
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(CarePlanDetailsSection);
