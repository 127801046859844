import AxiosClient from '../../components/AxiosClient';
import ApolloClient from '../../components/ApolloClient';
// import { gql } from '@apollo/client';

import gql from 'graphql-tag';
import { DOCUMENT_FRAGMENT } from '../../utils/fragments';

export const DOCUMENT_SERVICE_END_POINTS = {
  UPLOAD_SELF_REPORTING_DOCUMENTS: '/documentservice/upload',
  DOWNLOAD_SELF_REPORTED_DOCUMENT: '/documentservice/document/download',
  SCAN_DOC_DOWNLOAD_PATH: '/documentservice/document/download',
  SCAN_DOC_BUCKET_NAME: 'mpowered-documents',
  SCAN_DOC_UPLOAD_PATH: '/documentservice/upload',
};

export const SELF_REPORTED_DOCUMENTS_BUCKET_NAME = 'self-reported';

const GET_DOC_INFO = gql`
  query {
    getDocumentInfo {
      documentId
      documentName
      documentUrl
      documentType
      userId
    }
  }
`;

const ADD_DOC_INFO = gql`
  mutation addDocumentInfo($documentRequest: DocumentRequest) {
    addDocumentInfo(documentRequest: $documentRequest) {
      documentId
      documentName
      documentUrl
      documentType
      userId
      __typename
    }
  }
`;

const DELETE_DOC = gql`
  mutation deleteDocumentReference($documentReferenceId: String) {
    deleteDocumentReference(documentReferenceId: $documentReferenceId) {
      message
    }
  }
`;

const CREATE_DOCUMENT_REFERENCE = gql`
  mutation createDocumentReference($documentReferenceRequest: DocumentReferenceRequest) {
    createDocumentReference(documentReferenceRequest: $documentReferenceRequest) {
      ...document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

const EDIT_DOCUMENT_REFERENCE = gql`
  mutation editDocumentReference(
    $documentReferenceRequest: DocumentReferenceRequest
    $documentReferenceId: String
  ) {
    editDocumentReference(
      documentReferenceRequest: $documentReferenceRequest
      documentReferenceId: $documentReferenceId
    ) {
      ...document
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

const SAVE_SUPPORT = gql`
  mutation saveSupport($SupportRequest: SupportRequest) {
    saveSupport(data: $SupportRequest) {
      s3Url
      folderName
    }
  }
`;
const SUPPORT_EMAIL = gql`
  mutation sendSupportEmail($SupportRequest: SupportRequest) {
    sendSupportEmail(data: $SupportRequest)
  }
`;

const DocumentService = {
  uploadSelfReportingDocuments: (formData) => {
    return new Promise((resolve, reject) => {
      AxiosClient.put(DOCUMENT_SERVICE_END_POINTS.UPLOAD_SELF_REPORTING_DOCUMENTS, {}, formData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  uploadDocument: (path, formData) => {
    return new Promise((resolve, reject) => {
      AxiosClient.put(path, {}, formData)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addDocumentInfo: (documentRequest, mutation = ADD_DOC_INFO) => {
    return new Promise((resolve, reject) => {
      ApolloClient.mutate({
        mutation,
        variables: {
          documentRequest,
        },
        fetchPolicy: 'no-cache',
      })
        .then((response) => resolve(response.data.getDocumentInfo))
        .catch((error) => reject(error));
    });
  },

  deleteDocument: (documentReferenceId, mutation = DELETE_DOC) => {
    return new Promise((resolve, reject) => {
      ApolloClient.mutate({
        mutation,
        variables: {
          documentReferenceId,
        },
        fetchPolicy: 'no-cache',
      })
        .then((response) => resolve(response.data.getDocumentInfo))
        .catch((error) => reject(error));
    });
  },

  getDocumentInfo: (query = GET_DOC_INFO) => {
    return new Promise((resolve, reject) => {
      ApolloClient.query({
        query: query,
        fetchPolicy: 'no-cache',
      })
        .then((response) => resolve(response.data.getDocumentInfo))
        .catch((error) => reject(error));
    });
  },
  saveSupport: (userText, supportType, documentUploaded, category, mutation = SAVE_SUPPORT) => {
    return new Promise((resolve, reject) => {
      ApolloClient.mutate({
        mutation,
        variables: {
          SupportRequest: {
            userText,
            supportType,
            documentUploaded,
            category,
          },
        },
        fetchPolicy: 'no-cache',
      })
        .then((response) => {
          resolve(response.data.saveSupport);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  sendSupportEmail: (userText, supportType, documentUploaded, mutation = SUPPORT_EMAIL) => {
    return new Promise((resolve, reject) => {
      ApolloClient.mutate({
        mutation,
        variables: {
          SupportRequest: { userText, supportType, documentUploaded },
        },
        fetchPolicy: 'no-cache',
      })
        .then((response) => {
          resolve(response.data.sendSupportEmail);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createDocumentReference(
    { filesList, resourceName, resourceId, markAsRestricted = 'false' },
    mutation = CREATE_DOCUMENT_REFERENCE,
  ) {
    return new Promise((resolve, reject) => {
      ApolloClient.mutate({
        mutation,
        variables: {
          documentReferenceRequest: {
            docArnList: filesList,
            resourceName: resourceName,
            resourceId: resourceId,
            markAsRestricted: markAsRestricted,
          },
        },
      })
        .then((response) => {
          resolve(response.data.createDocumentReference);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  editDocumentReference(
    { filesList, resourceName, resourceId, markAsRestricted = 'false' },
    documentReferenceId,
    mutation = EDIT_DOCUMENT_REFERENCE,
  ) {
    return new Promise((resolve, reject) => {
      ApolloClient.mutate({
        mutation,
        variables: {
          documentReferenceRequest: {
            docArnList: filesList,
            resourceName: resourceName,
            resourceId: resourceId,
            markAsRestricted: markAsRestricted,
          },
          documentReferenceId,
        },
      })
        .then((response) => {
          resolve(response.data.editDocumentReference);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  downloadFile(docArn, folder = SELF_REPORTED_DOCUMENTS_BUCKET_NAME) {
    return new Promise((resolve, reject) => {
      AxiosClient.get(
        DOCUMENT_SERVICE_END_POINTS.DOWNLOAD_SELF_REPORTED_DOCUMENT,
        { docArn: docArn, folder: folder },
        true,
        {
          responseType: 'blob',
        },
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  downloadFile(docArn, folder = SELF_REPORTED_DOCUMENTS_BUCKET_NAME) {
    return new Promise((resolve, reject) => {
      AxiosClient.get(
        DOCUMENT_SERVICE_END_POINTS.DOWNLOAD_SELF_REPORTED_DOCUMENT,
        { docArn: docArn, folder: folder },
        true,
        {
          responseType: 'blob',
        },
      )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
};

export default DocumentService;
