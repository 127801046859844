import client from '../../components/ApolloClient';
import gql from 'graphql-tag';
import { LOGIN_RESPONSE } from '../../utils/fragments';

export const VERIFY_REFERRAL_CODE = gql`
  query verifyReferralCode($referralCode: String) {
    verifyReferralCode(referralCode: $referralCode) {
      verify
    }
  }
`;

export const VERIFY_PROMO_CODE = gql`
  query verifyPromoCode($promoCode: String) {
    verifyPromoCode(promoCode: $promoCode) {
      verify
    }
  }
`;

export const CHECK_FOR_EMAIL_EXIST = gql`
  query checkIfEmailExist($emailId: String) {
    checkIfEmailExist(emailId: $emailId) {
      errorCode
      message
    }
  }
`;

const VALIDATE_ONE_TIME_ACCESS = gql`
  query validateOneTimeAccessLink($requestId: String) {
    validateOneTimeAccessLink(requestId: $requestId) {
      status
      grantorName
      email
      phoneNumber
      consentId
      linkExpiryDate
    }
  }
`;

const TRACK_NO_OF_LINK_CLICKS = gql`
  mutation trackNoOfLinkClicks($requestId: String) {
    trackNoOfLinkClicks(requestId: $requestId)
  }
`;

const GENERATE_AND_SEND_QUERY = gql`
  mutation generateAndSendOTP($oneTimeAccessOTPRequest: OneTimeAccessOTPRequest) {
    generateAndSendOTP(oneTimeAccessOTPRequest: $oneTimeAccessOTPRequest) {
      verificationSid
      verificationStatus
    }
  }
`;

const VERIFY_ONE_TIME_OTP_QUERY = gql`
  mutation validateOneTimeAccessOTP($oneTimeAccessOTPRequest: OneTimeAccessOTPRequest) {
    validateOneTimeAccessOTP(oneTimeAccessOTPRequest: $oneTimeAccessOTPRequest) {
      verificationStatus
    }
  }
`;

export const CHECK_FOR_PHONE_EXIST = gql`
  query checkIfPhoneExist($phone: String) {
    checkIfPhoneExist(phone: $phone) {
      errorCode
      message
    }
  }
`;

export const VALIDATE_OTP_UNAUTH = gql`
  mutation validateOTPUnAuthNew($validateOTPRequest: ValidateOTPRequest) {
    validateOTPUnAuthNew(validateOTPRequest: $validateOTPRequest) {
      phoneVerificationStatus
    }
  }
`;

export const CREATE_NEW_USER_ACCOUNT = gql`
  mutation newSignupv4($quickUserSignupRequest: QuickUserSignupRequest) {
    newSignupv4(quickUserSignupRequest: $quickUserSignupRequest) {
      id
      userId
      email
      phone
      firstName
      lastName
      birthDate
      gender
      isActive
      maritalStatus
      avatarUrl
      referringResourceId
      referringResourceType
      tags
      loginResponse {
        ...loginResponse
      }
    }
  }
  ${LOGIN_RESPONSE}
`;

export const REDEEMED_REFERRAL_POINTS = gql`
  mutation redeemReferralCode($redeemReferralRequest: RedeemReferralRequest) {
    redeemReferralCode(redeemReferralRequest: $redeemReferralRequest) {
      redeemStatus
      message
    }
  }
`;

export const REDEEMED_PROMO_CODE_POINTS = gql`
  mutation redeemPromoCode($userId: String, $promoCode: String, $ipAddress: String) {
    redeemPromoCode(userId: $userId, promoCode: $promoCode, ipAddress: $ipAddress) {
      redeemStatus
      message
    }
  }
`;

export const CREATE_SOCIAL_SIGNUP_ACCOUNT = gql`
  mutation createAccountSocialSignupv4($fastUserCreateReq: FastUserCreateReq) {
    createAccountSocialSignupv4(fastUserCreateReq: $fastUserCreateReq)
  }
`;

export const GET_REFERRAL_CODE_AWARD_POINTS = gql`
  query getAwardPointsforRefCode($referralCode: String) {
    getAwardPointsforRefCode(referralCode: $referralCode)
  }
`;

const GET_PROMO_CODE_AWARD_POINTS = gql`
  query getAwardPointforPromoCode($promoCode: String) {
    getAwardPointforPromoCode(promoCode: $promoCode)
  }
`;

const SAVE_INCOMPLETE_SIGN_UP_DETAILS = gql`
  mutation saveUserTemp($quickUserSignupRequest: QuickUserSignupRequest) {
    saveUserTemp(quickUserSignupRequest: $quickUserSignupRequest) {
      id
      draftSaved
    }
  }
`;

const UPDATE_USER_TEMP = gql`
  mutation updateUserTemp($quickUserSignupRequest: QuickUserSignupRequest) {
    updateUserTemp(quickUserSignupRequest: $quickUserSignupRequest) {
      id
      draftSaved
    }
  }
`;

const SAVE_USER_EVENT_QUERY = gql`
  mutation saveEvent($data: EventInput) {
    saveEvent(data: $data)
  }
`;
const MAKE_ASSESSMENT_ASSOCIATION = gql`
  mutation mapCnsToEntPatient($empiRequest: EMPIRequest) {
    mapCnsToEntPatient(empiRequest: $empiRequest) {
      statusMessage
    }
  }
`;

export default {
  verifyReferralCode(query = VERIFY_REFERRAL_CODE, referralCode) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          variables: {
            referralCode: referralCode,
          },
        })
        .then((res) => {
          resolve(res.data.verifyReferralCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  verifyPromoCode(query = VERIFY_PROMO_CODE, promoCode) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          variables: {
            promoCode: promoCode,
          },
        })
        .then((res) => {
          resolve(res.data.verifyPromoCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkForEmailExist(emailId, checkIfEmailExistQuery = CHECK_FOR_EMAIL_EXIST) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: checkIfEmailExistQuery,
          variables: {
            emailId: emailId,
          },
        })
        .then((res) => {
          resolve(res.data.checkIfEmailExist);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkForPhoneExists(phone, query = CHECK_FOR_PHONE_EXIST) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          variables: {
            phone: phone,
          },
        })
        .then((res) => {
          resolve(res.data.checkIfPhoneExist);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  validateOneTimeAccess(requestId) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: VALIDATE_ONE_TIME_ACCESS,
          fetchPolicy: 'no-cache',
          variables: {
            requestId,
          },
        })
        .then((response) => {
          resolve(response.data.validateOneTimeAccessLink);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  trackLinkCLicks(requestId) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: TRACK_NO_OF_LINK_CLICKS,
          fetchPolicy: 'no-cache',
          variables: {
            requestId,
          },
        })
        .then((response) => {
          resolve(response.data.validateOneTimeAccessLink);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateAndSendOTP(emailOrMobile, isEmailOrPhone) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: GENERATE_AND_SEND_QUERY,
          variables: {
            oneTimeAccessOTPRequest: {
              phoneNumber: isEmailOrPhone === 'mobile' ? emailOrMobile : null,
              email: isEmailOrPhone === 'email' ? emailOrMobile : null,
            },
          },
        })
        .then((response) => {
          resolve(response.data.generateAndSendOTP);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createSocialSignupAccount(obj, query = CREATE_SOCIAL_SIGNUP_ACCOUNT) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            fastUserCreateReq: obj,
          },
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data.createAccountSocialSignupv4);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  validateOTPByMobileOrEmail(oneTimeAccessOTPRequest) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: VERIFY_ONE_TIME_OTP_QUERY,
          variables: {
            oneTimeAccessOTPRequest: oneTimeAccessOTPRequest,
          },
        })
        .then((response) => {
          resolve(response.data.validateOneTimeAccessOTP);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyOTPUnAuthNew(validateOTPRequest, query = VALIDATE_OTP_UNAUTH) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            validateOTPRequest: validateOTPRequest,
          },
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data.validateOTPUnAuthNew);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  createNewUserAccount(obj, query = CREATE_NEW_USER_ACCOUNT) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            quickUserSignupRequest: obj,
          },
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  createNewAccount(obj, query = '') {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: obj,
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data);
        })
        .then((e) => reject(e));
    });
  },
  redeemReferralCode(obj, query = REDEEMED_REFERRAL_POINTS) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: { redeemReferralRequest: obj },
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data.redeemReferralCode);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  redeemPromoCode(obj, query = REDEEMED_PROMO_CODE_POINTS) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: obj,
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  getReferralCodeAwardPoints(referralCode, query = GET_REFERRAL_CODE_AWARD_POINTS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            referralCode: referralCode,
          },
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data.getAwardPointsforRefCode);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  getPromoCodeAwardPoints(promoCode, query = GET_PROMO_CODE_AWARD_POINTS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            promoCode: promoCode,
          },
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data.getAwardPointforPromoCode);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  saveIncompleteSignupDetails(obj, query = SAVE_INCOMPLETE_SIGN_UP_DETAILS) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: { quickUserSignupRequest: obj },
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data.saveUserTemp);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  updateInCompleteSignupDetails(obj, query = UPDATE_USER_TEMP) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: { quickUserSignupRequest: obj },
          errorPolicy: 'none',
        })
        .then((res) => {
          resolve(res.data.updateUserTemp);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  saveUserEvent(obj, query = SAVE_USER_EVENT_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            data: obj,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export const makeAssessmentAssociation = (args, mutation = MAKE_ASSESSMENT_ASSOCIATION) => {
  return new Promise((resolve, reject) => {
    client
      .mutate({
        mutation,
        variables: {
          empiRequest: {
            targetKCid: String(args?.targetPid),
            assessmentSubmissionId: String(args?.assessmentSubmissionId),
          },
        },
      })
      .then((res) => {
        resolve(res?.data?.mapCnsToEntPatient);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
