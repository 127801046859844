import { IDLE_TIME, CHECK_SESSION_TIME } from './utils/constants';
import { auth } from './components/Routes';
import User from './service/User';

export const lastActiveTimeKey = 'last-active-time'; // TODO: Move to "config" or other shared constants file

const isUserLoggedIn = () => auth.isLoggedIn().status !== 'false';

export const onAction = (event, idleTimer) => {
  if (!isUserLoggedIn()) {
    return;
  }

  const currTime = Math.floor(Date.now());
  const lastActiveTimestamp = idleTimer?.getLastActiveTime() && new Date(idleTimer.getLastActiveTime()).getTime();
  const lastActiveTime = lastActiveTimestamp ?? localStorage.getItem(lastActiveTimeKey);
  // this is a fallback if onIdle is not triggered
  if ((currTime - lastActiveTime) > IDLE_TIME) {
    auth.logout(true);
  } else if ((currTime - lastActiveTime) > CHECK_SESSION_TIME) {
    User.checkSession();
    localStorage.setItem(lastActiveTimeKey, currTime);
  } else {
    localStorage.setItem(lastActiveTimeKey, currTime); // TODO: Same code as previous case, refactor to use return before
  }
};

export const onActive = (event, idleTimer) => {
  if (isUserLoggedIn() && idleTimer) {
    idleTimer.reset();
  }
};

export const onIdle = (event, idleTimer) => {
  const currTime = Math.floor(Date.now());
  const lastActiveTimestamp = idleTimer?.getLastActiveTime() && new Date(idleTimer.getLastActiveTime()).getTime();
  const lastActiveTime = lastActiveTimestamp ?? localStorage.getItem(lastActiveTimeKey);
  // handles edge case where user presses login button and doesn't move from there
  if (lastActiveTime === null || (currTime - lastActiveTime) > IDLE_TIME) {
    auth.logout(true);
  } else if ((currTime - lastActiveTime) > CHECK_SESSION_TIME) {
    User.checkSession();
    localStorage.setItem(lastActiveTimeKey, currTime);
  } else {
    localStorage.setItem(lastActiveTimeKey, currTime); // TODO: Same code as previous case, refactor to use return before
  }
};