import OrgnaizationBonus from '../../../assets/images/partnerBonus.svg';
import CoverageBonus from '../../../assets/images/coverageBonus.svg';
import ProfileUpdate from '../../../assets/images/profileUpdate.svg';
import ReferralBonus from '../../../assets/images/referralBonus.svg';
import Donation from '../../../assets/images/donation.svg';
import PromoCode from '../../../assets/images/promoCode.svg';
import RedeemAwardPoints from '../../../assets/images/redeemAwardPoints.svg';
import { BONUS_TYPE, TRANSACTION_TYPE } from '../../../utils/constants';

export const getRewardDetails = (transactionType, name) => {
  let icon = '';
  let bonusType = '';
  switch (transactionType) {
    case TRANSACTION_TYPE.ADD_TO_CIRCLE:
      icon = OrgnaizationBonus;
      bonusType = BONUS_TYPE.organizationAddedBonus;
      break;
    case TRANSACTION_TYPE.ADD_PARTNER:
      icon = OrgnaizationBonus;
      bonusType = BONUS_TYPE.organizationAddedBonus;
      break;
    case TRANSACTION_TYPE.EHEALTH:
      icon = CoverageBonus;
      bonusType = BONUS_TYPE.coverageBonus;
      break;
    case TRANSACTION_TYPE.SHARED_TO_USER:
      icon = Donation;
      bonusType = BONUS_TYPE.giftedTo(name.username);
      break;
    case TRANSACTION_TYPE.SHARED_BY_USER:
      icon = Donation;
      bonusType = BONUS_TYPE.giftedBy(name.username);
      break;
    case TRANSACTION_TYPE.PROFILE:
      icon = ProfileUpdate;
      bonusType = BONUS_TYPE.profileUpdateBonus;
      break;
    case TRANSACTION_TYPE.REFERRAL:
      icon = ReferralBonus;
      bonusType = BONUS_TYPE.referralBonus;
      break;
    case TRANSACTION_TYPE.GIFT_CARD_REDEMPTION:
      icon = RedeemAwardPoints;
      bonusType = BONUS_TYPE.redeemAwardPoints;
    case TRANSACTION_TYPE.PROMO_CODE:
      icon = PromoCode;
      bonusType = BONUS_TYPE.promoCodeBonus;
      break;
    case TRANSACTION_TYPE.PROMO:
      icon = PromoCode;
      bonusType = BONUS_TYPE.promoCodeBonus;
      break;
    default:
      icon = PromoCode;
      bonusType = BONUS_TYPE.promoCodeBonus;
      break;
  }
  return { icon, bonusType };
};
