import client from '../components/ApolloClient';
import gql from 'graphql-tag';
import { COVERAGE_FRAGMENT } from '../utils/fragments';

const FETCH_ALL_COVERAGES_QUERY = gql`
  query getCoverages(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $status_filter: String = ""
    $search_parameter: String = ""
    $nextPageId: String
  ) {
    getCoverages(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      statusFilter: $status_filter
      searchFilter: $search_parameter
      nextPageId: $nextPageId
    ) {
      coverageList
      count
      nextPageId
    }
  }
`;
const FETCH_ALL_COVERAGES_BY_CONSENT = gql`
  query fetchAllCoveragesByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllCoveragesByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      coverageList
      count
    }
  }
`;

const FETCH_PARTNER_COVERAGE_BY_ID = gql`
  query fetchPartnerCoverageById($coverageId: String) {
    fetchPartnerCoverageById(coverageId: $coverageId) {
      coverage
      docArn
      documentReferenceId
    }
  }
`;
const FETCH_PARTNER_COVERAGE_BY_ID_AND_GRANTOR_ID = gql`
  query fetchCoveragesByIdAndGrantorId($coverageId: String, $grantorId: String) {
    fetchCoveragesByIdAndGrantorId(coverageId: $coverageId, grantorId: $grantorId) {
      coverage
      docArn
      documentReferenceId
    }
  }
`;

const SAVE_COVERAGE_SELF_REPORTING = gql`
  mutation createCoverageSelfReportData($data: AddCoverageSelfReportData) {
    createCoverageSelfReportData(data: $data) {
      ...coverage
    }
  }
  ${COVERAGE_FRAGMENT}
`;

const EDIT_COVERAGE_SELF_REPORTING = gql`
  mutation editCoverageSelfReportData($data: AddCoverageSelfReportData, $coverageId: String) {
    editCoverageSelfReportData(data: $data, coverageId: $coverageId) {
      ...coverage
    }
  }
  ${COVERAGE_FRAGMENT}
`;

const MARK_RESTRICTED = gql`
  mutation markAsRestrictedCoverage($coverageId: String, $markAsRestricted: String) {
    markAsRestrictedCoverage(coverageId: $coverageId, markAsRestricted: $markAsRestricted) {
      ...coverage
    }
  }
  ${COVERAGE_FRAGMENT}
`;

const ADD_COVERAGE_QUERY = gql`
  query addCoverageTransaction($planType: String!, $consentType: String!) {
    addCoverageTransaction(planType: $planType, consentType: $consentType)
  }
`;

const CoverageService = {
  fetchAllCoverages(variables, query = FETCH_ALL_COVERAGES_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.getCoverages);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchAllCoveragesByConsent(args, query = FETCH_ALL_COVERAGES_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllCoveragesByConsent);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  fetchPartnerCoverageById(coverageId, query = FETCH_PARTNER_COVERAGE_BY_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            coverageId: coverageId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchPartnerCoverageById);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchCoveragesByIdAndGrantorId(
    coverageId,
    grantorId,
    query = FETCH_PARTNER_COVERAGE_BY_ID_AND_GRANTOR_ID,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            coverageId,
            grantorId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchCoveragesByIdAndGrantorId);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  fetchFastQuoteLink(args, query = ADD_COVERAGE_QUERY) {
    const { type: planType, consentType } = args;

    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            planType,
            consentType,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.addCoverageTransaction);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  saveSelfReportedCoverage(data, mutation = SAVE_COVERAGE_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
          },
        })
        .then((response) => {
          resolve(response.data.createCoverageSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateCoverageSelfReportData(data, coverageId, mutation = EDIT_COVERAGE_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
            coverageId,
          },
        })
        .then((response) => {
          resolve(response.data.editCoverageSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markAsRestrictedCoverage(coverageId, markAsRestricted, mutation = MARK_RESTRICTED) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            coverageId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.markAsRestrictedCoverage);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default CoverageService;
