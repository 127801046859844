import ClaimsIcon from '../assets/images/Claims-transparent.png';
import ConditionsIcon from '../assets/images/conditions.png';
import MoreIcon from '../assets/images/more.png';
import ProceduresIcon from '../assets/images/procedure-transparent.png';
import { LINKED_RECORDS } from './constants';
import { replaceImagePropertyV2 } from './helper';

const LINKED_RECORD_ICONS = [ClaimsIcon, ConditionsIcon, ProceduresIcon, MoreIcon];

const getLinkedRecordsIcon = () => replaceImagePropertyV2(LINKED_RECORDS, LINKED_RECORD_ICONS);

const linkedRecordsIconGetter = { getLinkedRecordsIcon };

export default linkedRecordsIconGetter;
