import { combineReducers, configureStore } from '@reduxjs/toolkit';
import socialLoginSlice from './features/socialLoginSlice';
import signupSlice from './features/signupSlice';
import dynamicTemplateSlice from './features/dynamicTemplate';
import circlesSlice from '../features/circles/slice';
import appSlice from './appSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  socialLogin: socialLoginSlice,
  signup: signupSlice,
  templates: dynamicTemplateSlice,
  circles: circlesSlice,
  app: appSlice,
});

const persistConfig = {
  key: 'root',
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

export const persistor = persistStore(store);
