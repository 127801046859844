import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import {
  CODE_FRAGMENT,
  QUANTITY_FRAGMENT,
  IDENTIFIER_FRAGMENT,
  OBSERVATION_VALUE_FRAGMENT,
  REFERENCE_FRAGMENT,
  RANGE_FRAGMENT,
  PERIOD_FRAGMENT,
  RATIO_FRAGMENT,
  SAMPLED_DATA_FRAGMENT,
} from '../utils/fragments';

const DEFAULT_GET_VITALS_QUERY = gql`
  query fetchAllVitals($groupBy: vitalsGroupByEnum, $sourceUrl: String) {
    fetchAllVitals(groupBy: $groupBy, sourceUrl: $sourceUrl) {
      collectedDate
      type {
        key
        value
      }
      vitalsList {
        effectiveDateTime
        id
        code {
          ...code
        }
        valueString
        valueQuantity {
          ...quantity
        }
        component {
          code {
            ...code
          }
          valueQuantity {
            ...quantity
          }
        }
      }
    }
  }
  ${CODE_FRAGMENT}
  ${QUANTITY_FRAGMENT}
`;
const DEFAULT_GET_VITALS_BY_CONSENT = gql`
  query fetchAllVitalsByConsent(
    $groupBy: vitalsGroupByEnum
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllVitalsByConsent(
      groupBy: $groupBy
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      collectedDate
      type {
        key
        value
      }
      vitalsList {
        effectiveDateTime
        id
        code {
          ...code
        }
        valueString
        valueQuantity {
          ...quantity
        }
        component {
          code {
            ...code
          }
          valueQuantity {
            ...quantity
          }
        }
      }
    }
  }
  ${CODE_FRAGMENT}
  ${QUANTITY_FRAGMENT}
`;

const FETCH_VITAL_BY_ID = gql`
  query fetchVitalById($vitalId: String) {
    fetchVitalById(vitalId: $vitalId) {
      effectiveDateTime
      id
      status
      issued
      code {
        ...code
      }
      meta {
        security {
          system
          code
          display
          version
        }
        source
        lastUpdated
        versionId
      }
      identifier {
        ...identifier
      }
      performer {
        ...reference
      }
      valueString
      valueInteger
      valueBoolean
      valuePeriod {
        ...period
      }
      valueSample {
        ...sampleData
      }
      valueRange {
        ...range
      }
      valueRatio {
        ...ratio
      }
      valueDateTime
      valueQuantity {
        ...quantity
      }
      valueCodeableConcept {
        ...code
      }
      component {
        code {
          ...code
        }
        valueQuantity {
          ...quantity
        }
      }
    }
  }
  ${CODE_FRAGMENT}
  ${PERIOD_FRAGMENT}
  ${SAMPLED_DATA_FRAGMENT}
  ${RANGE_FRAGMENT}
  ${RATIO_FRAGMENT}
  ${IDENTIFIER_FRAGMENT}
  ${REFERENCE_FRAGMENT}
  ${QUANTITY_FRAGMENT}
`;

const DEFAULT_GET_SOCIAL_HISTORY_QUERY = gql`
  query fetchSmokingStatus(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchSmokingStatus(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      smokingStatusList
      count
    }
  }
`;

const FETCH_SOCIAL_HISTORY_BY_CONSENT = gql`
  query fetchAllSmokingStatusByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllSmokingStatusByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      smokingStatusList
      count
    }
  }
`;

const MARK_RESTRICTED_VITALS = gql`
  mutation markAsRestrictedObservation($vitalId: String, $markAsRestricted: String) {
    markAsRestrictedObservation(vitalId: $vitalId, markAsRestricted: $markAsRestricted) {
      id
      status
    }
  }
`;

const VitalService = {
  getVitals(source = '', groupBy = 'DATE', query = DEFAULT_GET_VITALS_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            sourceUrl: source,
            groupBy: groupBy,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllVitals);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllVitalsByConsent(args, query = DEFAULT_GET_VITALS_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            groupBy: 'DATE',
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllVitalsByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchSmokingStatus(variables, query = DEFAULT_GET_SOCIAL_HISTORY_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchSmokingStatus);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllSmokingStatusByConsent(variables, query = FETCH_SOCIAL_HISTORY_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllSmokingStatusByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchVitalById(vitalId, query = FETCH_VITAL_BY_ID) {
    const variables = {
      vitalId: Number(vitalId).toString(),
    };
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchVitalById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  markAsRestrictedObservation(vitalId, markAsRestricted, mutation = MARK_RESTRICTED_VITALS) {
    const variables = {
      vitalId: String(vitalId),
      markAsRestricted: String(markAsRestricted),
    };
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables,
        })
        .then((response) => {
          resolve(response.data.markAsRestrictedObservation);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default VitalService;
