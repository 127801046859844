import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import { FETCH_PARTNER_PATIENT_FRAGMENT } from '../utils/fragments';

const CIRCLE_FRAGMENT_CONTENT = gql`
  fragment CircleDetails on CirclesResponse {
    circleId
    name
    type
    circleAuthUrl
    iconUrl
    sourceUrl
    setLoader
    isFindCareEnabled
    isVirtualOrg
  }
`;

const CIRCLE_SERVICES_FETCH_STATUS = gql`
  fragment circleServicesFetchStatus on CircleServiceFetchStatus {
    resourceName
    fetchStatus
    ingestionStatus
  }
`;
const PATIENT_DATA_FETCH_STATUS = gql`
  query getPatientDataFetchStatus($circleId: Int) {
    getPatientDataFetchStatus(circleId: $circleId) {
      circleId
      services {
        ...circleServicesFetchStatus
      }
    }
  }
  ${CIRCLE_SERVICES_FETCH_STATUS}
`;

const CIRCLE_SERVICE_CONTENT = gql`
  fragment CirclesServices on CircleServicesResponse {
    circleServicesId
    serviceType
    lastUpdatedTime
    numberOfRecords
    active
    serviceName
    serviceWebIconUrl
    serviceMobIconUrl
    rank
    subType
  }
`;

const FETCH_ALL_ACTIVE_CIRCLES_QUERY = gql`
  query {
    fetchallActiveCircles {
      ...CircleDetails
      recentCircleServicesUsedResponse {
        ...CirclesServices
      }
    }
  }
  ${CIRCLE_FRAGMENT_CONTENT}
  ${CIRCLE_SERVICE_CONTENT}
`;

const FETCH_UN_ADDED_LIST_OF_PARTNERS_QUERY = gql`
  query fetchCirclesForType($offset: Int, $count: String, $searchFilter: String) {
    fetchCirclesForType(offset: $offset, count: $count, searchFilter: $searchFilter) {
      circlesResponseList {
        ...CircleDetails
      }
      count
    }
  }
  ${CIRCLE_FRAGMENT_CONTENT}
`;

const FETCH_CRICLES_FOR_TYPE = gql`
  query fetchCirclesForType($circlesType: CirclesType) {
    fetchCirclesForType(type: $circlesType) {
      ...CircleDetails
    }
  }
  ${CIRCLE_FRAGMENT_CONTENT}
`;

const FETCH_SERVICES_BY_CIRCLE_ID = gql`
  query fetchAllCircleServices($circleId: Int) {
    fetchAllCircleServices(circleId: $circleId) {
      ...CirclesServices
    }
  }
  ${CIRCLE_SERVICE_CONTENT}
`;

const ADD_NEW_CIRCLE_MUTATION = gql`
  mutation addNewCircle($circleId: Int) {
    addNewCircle(circleId: $circleId) {
      success
    }
  }
`;

const REMOVE_CIRCLE_MUTATION = gql`
  mutation removeCircle($circleId: Int) {
    removeCircle(circleId: $circleId) {
      success
    }
  }
`;

const FETCH_EAM_OAUTH_URL = gql`
  mutation fetchEamOauthUrl($userId: String!, $circleId: String!, $redirectUri: String) {
    fetchEamOauthUrl(userId: $userId, circleId: $circleId, redirectUri: $redirectUri) {
      url
    }
  }
`;

const EAM_CALLBACK_WITH_CIRCLE = gql`
  mutation eamCallbackWithCircle(
    $code: String!
    $state: String!
    $redirectUri: String
    $circleId: Int
  ) {
    eamCallbackWithCircle(
      code: $code
      state: $state
      redirectUri: $redirectUri
      circleId: $circleId
    ) {
      success
    }
  }
`;

const TRIGGER_PARTNER_DATA_FETCH_QUERY = gql`
  mutation triggerDataFetch(
    $requestId: String
    $userId: String
    $circleId: String
    $patientId: String
    $partnerPatientId: String
  ) {
    triggerDataFetch(
      requestId: $requestId
      userId: $userId
      circleId: $circleId
      patientId: $patientId
      partnerPatientId: $partnerPatientId
    ) {
      success
    }
  }
`;

const FETCH_PARTNER_PATIENT = gql`
  mutation fetchPartnerPatient(
    $code: String!
    $state: String!
    $redirectUri: String
    $circleIdToBeAdded: Int
  ) {
    fetchPartnerPatient(
      code: $code
      state: $state
      redirectUri: $redirectUri
      circleId: $circleIdToBeAdded
    ) {
      partnerPatientDetails {
        ...patientDetails
      }
      isPartnerAdded
    }
  }
  ${FETCH_PARTNER_PATIENT_FRAGMENT}
`;

const UPDATE_CIRCLES_RECENT_SERVICES_USED = gql`
  mutation updateCircleServiceTimeUsed($serviceType: ServiceTouchedType, $circleId: Int) {
    updateCircleServiceTimeUsed(serviceType: $serviceType, circleId: $circleId) {
      success
    }
  }
`;
const RE_AUTHENTICATE_PARTNER_FOR_USER_AND_CIRCLE = gql`
  query reauthenticatePartnerForUserAndCircle($circleId: Int) {
    reauthenticatePartnerForUserAndCircle(circleId: $circleId)
  }
`;
const INITIATE_WORKFLOW_TO_ADD_MINORS = gql`
  mutation initiateWorkflow($request: WorkflowInitiateRequest) {
    initiateWorkflow(request: $request) {
      businessKey
      workflowName
      taskId
      templateCategoryName
      templateName
      isWorkflowEnded
      template
      variables
    }
  }
`;

const SAVE_SEARCH_ORGANIZATION = gql`
  mutation saveSearch($data: SearchInput) {
    saveSearch(data: $data)
  }
`;

const CirclesService = {
  getUnAddedCirclesList(variables, query = FETCH_UN_ADDED_LIST_OF_PARTNERS_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          resolve(res.data.fetchCirclesForType);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchEamOauthUrl(userId, circleId, redirectUri, mutation = FETCH_EAM_OAUTH_URL) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            userId,
            circleId,
            redirectUri,
          },
        })
        .then((res) => {
          resolve(res.data.fetchEamOauthUrl);
        })
        .catch((error) => reject(error));
    });
  },

  eamCallbackWithCircle(code, state, redirectUri, circleId, mutation = EAM_CALLBACK_WITH_CIRCLE) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            code,
            state,
            redirectUri,
            circleId,
          },
        })
        .then((res) => {
          resolve(res.data.eamCallbackWithCircle);
        })
        .catch((error) => reject(error));
    });
  },
  fetchPartnerPatient(
    code,
    state,
    redirectUri,
    circleIdToBeAdded,
    mutation = FETCH_PARTNER_PATIENT,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            code,
            state,
            redirectUri,
            circleIdToBeAdded,
          },
        })
        .then((res) => {
          resolve(res.data.fetchPartnerPatient);
        })
        .catch((error) => reject(error));
    });
  },

  getAllActiveCircles(query = FETCH_ALL_ACTIVE_CIRCLES_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          resolve(res.data.fetchallActiveCircles);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getPatientDataFetchStatus(circleId = null, query = PATIENT_DATA_FETCH_STATUS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            circleId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          resolve(res.data.getPatientDataFetchStatus);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addNewCircle(args, mutation = ADD_NEW_CIRCLE_MUTATION) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: args,
        })
        .then((res) => resolve(res.data.addNewCircle))
        .catch((error) => reject(error));
    });
  },

  removeCircle(args, mutation = REMOVE_CIRCLE_MUTATION) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: args,
        })
        .then((res) => resolve(res.data.removeCircle))
        .catch((error) => reject(error));
    });
  },

  getCirclesByType(circlesType, query = FETCH_CRICLES_FOR_TYPE) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            circlesType,
          },
        })
        .then((res) => resolve(res.data.fetchCirclesForType))
        .catch((error) => reject(error));
    });
  },

  updateRecentlyUsedServices(args, mutation = UPDATE_CIRCLES_RECENT_SERVICES_USED) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: args,
        })
        .then((res) => resolve(res.data.updateCircleServiceTimeUsed))
        .catch((error) => reject(error));
    });
  },

  fetchServicesByCircleId(circleId, query = FETCH_SERVICES_BY_CIRCLE_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            circleId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => resolve(result.data.fetchAllCircleServices))
        .catch((error) => reject(error));
    });
  },
  reauthenticatePartnerForUserAndCircle(
    circleId,
    query = RE_AUTHENTICATE_PARTNER_FOR_USER_AND_CIRCLE,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            circleId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          resolve(result.data.reauthenticatePartnerForUserAndCircle);
        })
        .catch((error) => reject(error));
    });
  },
  initiateWorkflow(dependentProfiles, mutation = INITIATE_WORKFLOW_TO_ADD_MINORS) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            request: {
              workflowName: 'AddDependentResourceinAddOrg',
              data: { dependents: dependentProfiles },
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          resolve(result.data.initiateWorkflow);
        })
        .catch((error) => reject(error));
    });
  },
  triggerDataFetch(
    requestId,
    userId,
    circleId,
    patientId,
    partnerPatientId,
    mutation = TRIGGER_PARTNER_DATA_FETCH_QUERY,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            requestId,
            userId,
            circleId,
            patientId,
            partnerPatientId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => reject(error));
    });
  },
  saveSearchOrganization(obj, mutation = SAVE_SEARCH_ORGANIZATION) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data: obj,
          },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};

export default CirclesService;
