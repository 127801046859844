import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import { CODE_FRAGMENT, IDENTIFIER_FRAGMENT, VISIT_TYPES } from '../utils/fragments';

const FETCH_ALL_VISITS_QUERY = gql`
  query fetchAllVisits(
    $visitType: VisitType
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllVisits(
      visitType: $visitType
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      visitsList
      count
    }
  }
`;
const FETCH_ALL_VISITS_BY_CONSENT = gql`
  query fetchAllVisitsByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllVisitsByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      visitsList
      count
    }
  }
`;

const FETCH_VISIT_DETAILS_BY_ID_QUERY = gql`
  query fetchVisitById($visitId: String) {
    fetchVisitById(visitId: $visitId) {
      encounter
      docArn
      documentReferenceId
    }
  }
`;
const FETCH_VISIT_DETAILS_BY_ID_AND_GRANTOR_ID = gql`
  query fetchVisitByIdAndGrantorId($visitId: String, $grantorId: String) {
    fetchVisitByIdAndGrantorId(visitId: $visitId, grantorId: $grantorId) {
      encounter
      docArn
      documentReferenceId
    }
  }
`;

const FETCH_RELATED_Visits = gql`
  query fetchRelatedVisits($relatedResourceId: String, $relatedResourceType: String) {
    fetchRelatedAllergy(resourceId: $relatedResourceId, resourceType: $relatedResourceType) {
      id
      type
      verificationStatus {
        ...code
      }
      code {
        ...code
      }
      criticality
      category
      recordedDate
    }
  }
  ${(CODE_FRAGMENT, CODE_FRAGMENT)}
`;

const MARK_RESTRICTED_VISITS = gql`
  mutation markAsRestrictedVisit($visitId: String, $markAsRestricted: String) {
    markAsRestrictedVisit(visitId: $visitId, markAsRestricted: $markAsRestricted) {
      id
      status
    }
  }
`;

const VisitsService = {
  fetchAllVisits(variables, query = FETCH_ALL_VISITS_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllVisits);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllVisitsByConsent(variables, query = FETCH_ALL_VISITS_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllVisitsByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchVisitById(id, query = FETCH_VISIT_DETAILS_BY_ID_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            visitId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchVisitById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchVisitByIdAndGrantorId(visitId, grantorId, query = FETCH_VISIT_DETAILS_BY_ID_AND_GRANTOR_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            visitId,
            grantorId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchVisitByIdAndGrantorId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRelatedVisits(args, query = FETCH_RELATED_Visits) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: args,
        })
        .then((response) => {
          resolve(response.data.fetchRelatedAllergy);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  markAsRestrictedVisit(visitId, markAsRestricted, mutation = MARK_RESTRICTED_VISITS) {
    const variables = {
      visitId: String(visitId),
      markAsRestricted: String(markAsRestricted),
    };
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables,
        })
        .then((response) => {
          resolve(response.data.markAsRestrictedVisit);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default VisitsService;
