export const SIGNUP_SCREEN = {
  EMAIL_OR_PHONE: 'Mobile number/email',
  CREATE_PASSWORD: ' Create password',
  CREATE_ACCOUNT: 'Create your account',
  CONTINUE: 'Continue',
  ONE_APP:
    ' One app to help you consolidate all your health records, track your medications, manage your healthcare expenses, shop for plans and more..',
  WELCOME: 'Welcome!',
  CREATE_MY_ACCOUNT: 'Create my account',
  EMAIL: 'Email',
  EMAIL_OR_PHONE_PLACEHOLDER: 'Enter your mobile number or email',
  PASSWORD: 'Create password',
  PASSWORD_PLACEHOLDER: 'Password (8+ characters)',
  CONFIRM_PASSWORD: 'Confirm password',
  CHANGE_PASSWORD: 'Change password',
  REFERRAL: 'Have a referral/promo code? (Optional)',
  HAVE_ACCOUNT: 'Have an account?',
  LOGIN: 'Log in',
  OR: ' or ',
  DOWNLOAD: 'Download Mpowered Health for free on ',
  IOS: 'iOS',
  AND: ' and ',
  ANDROID: 'Android',
  LABEL: `Don't have an account?`,
  HEADING: 'Get Mpowered with us.',
  SIGN_UP_NOW: 'Sign up now.',
  BODY: 'Sign up',
  BTN_TEXT_APPLY: 'Apply',
  BTN_TEXT_SIGNUP: 'Sign up',
  EXISTING_USER_TEXT: 'Existing user?',
  LOGIN_TEXT: ' Log in',
  PASSWORD_STRENGTH_TEXT: 'Password strength - ',
  ERRORLOG_USER_EXIST_MSG: 'User exists with same username',
  LOOKS_GOOD: 'Looks good',
  PASSWORD_INFO: 'Must include an upper case, lower case , number and symbol.',
  LEN_ERR_8: 'At least have 8 characters',
  OR_CONTINUE_WITH: 'Or continue with',
  EMAIL_EXIST_MESSAGE: 'Email already exists... Try log in',
  PHONE_EXIST_MESSAGE: 'Mobile number already exists... Try log in',
  EMAIL_OR_PHONE_ERROR_MESSAGE: 'Enter a valid mobile number or email',
  MOBILE_OR_EMAIL_REQUIRED: 'Mobile number or email required',
  BY_CREATING: 'By creating an account you are accepting the ',
  TS: 'terms of service ',
  PP: ' privacy policy',
  OTP_SENT: 'OTP sent successfully ',
  EMAIL_EXIST_MEG: 'Email already exists',
  MOBILE_EXIST_MES: 'Mobile number already exists',
};

export const REFERRAL_PROMO_CODE_COMPONENT = {
  REFERRAL_CODE_APPLIED: 'Referral code successfully applied',
  PROMO_CODE_APPLIED: 'Promo code successfully applied',
  AWARD_POINTS: (awardPoints) => `${awardPoints} award points`,
  SUCCESSFULLY_APPLIED: 'Successfully applied',
  HAS_REFERRAL_PROMO_CODE: 'Have a referral/promo code?',
  REFERRAL_LABEL: 'Referral code',
  PROMO_LABEL: 'Promo code',
  ENTER_CODE: 'Enter code',
  INVALID_REFFERAL_PROMO_CODE: 'Invalid or incorrect referral or promo code',
  ERROR: 'Some error occured while verifying referral or promo code',
  APPLY: 'Apply',
};

export const OTP_SCREEN = {
  ENTER_OTP: 'Enter OTP',
  EDIT: 'Enter a 4 digit code sent to ',
  DID_NOT_RECEIVE: 'Didn’t receive it? ',
  RESEND_OTP: 'Resend OTP',
  CREATE_ACCOUNT: 'Create account',
  OTP_SENT: 'OTP sent successfully ',
  OTP_RESENT: 'OTP resent successfully ',
  NEW_CODE: (time) => ` Request new code in 00:${time}`,
  OTP_VERIFIED: 'OTP verified',
  ERROR_MESSAGE: 'Invalid or incorrect OTP',
  PHONE_VERIFICATION_STATUS: {
    APPROVED: 'approved',
    PENDING: 'pending',
    EXPIRED: 'expired',
    DENIED: 'denied',
  },
  SOME_ERROR: 'Something went wrong!',
  OTP_RESEND_MAX_TIMES: 3,
  OTP_RESEND_MAX_REACHED: 'OTP maximum time resend reached.',
  ACCOUNT_CREATED: 'Account created',
};

export const SOCIAL_SIGN_IN = {
  APPLE: 'consumer-apple',
  GOOGLE: 'consumer-google',
  FACEBOOK: 'consumer-facebook',
  LINKEDIN: 'consumer-linkedin',
  APPLE_BTN: 'Apple',
  GOOGLE_BTN: 'Google',
  FACEBOOK_BTN: 'Facebook',
  LINKEDIN_BTN: 'Linkedin',
};

export const EDIT_MOBILE_EMAIL_MODAL = {
  HEADING: 'Edit mobile number/email',
  MOBILE_EMAIL_LABEL: 'Mobile number/email',
  MOBILE_EMAIL_PLACEHOLDER: 'Enter your mobile number or email',
  SAVE_BUTTON: 'Save',
  REQUIRED_ERROR: 'Mobile number or email required',
  ERROR: 'Enter a valid mobile number or email',
  EMAIL_EXIST_MEG: 'Email already exists',
  MOBILE_EXIST_MES: 'Mobile number already exists',
};

export const UPDATE_USERNAME_CONSTANTS = {
  TITLE: 'Please enter your name',
  SUB_TITLE: 'This will help us provide the best experience!',
  FIRST_NAME: 'First name',
  FIRST_NAME_PLACEHOLDER: 'John',
  LAST_NAME: 'Last name',
  LAST_NAME_PLACEHOLDER: 'doe',
  SAVE: 'Save',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
};

export const UPDATE_EMAIL_CONSTANTS = {
  TITLE: 'Please enter your email',
  SUB_TITLE: 'This will help us provide the best experience!',
  EMAIL: 'Email',
  EMAIL_PLACEHOLDER: 'Enter email ID',
  SAVE: 'Save',
};

export const ONE_TIME_ACCESS_STATES = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
  INVALID: 'INVALID',
};

export const DEVICE_TYPE = {
  ANDROID: 'WEB_BROWSER_IN_ANDROID',
  IOS: 'WEB_BROWSER_IN_IOS',
  WEB: 'WEB_BROWSER',
};
