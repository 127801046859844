import React, { Component } from 'react';
import Button from '../../components/atoms/Button';
import { withStyles } from '@mui/styles';
import { Typography, Link, Box } from '@mui/material';
import { detectDeviceName, getUSFormatPhoneNo } from '../../utils/helper';
import InputAdornment from '@mui/material/InputAdornment';
import gql from 'graphql-tag';
import { validateFirstName, validateLastName, validateUSPhone } from '../../utils/validations';
import InfoIcon from '@mui/icons-material/Info';
import { logUserEvent } from '../../utils/UserEvent';
import {
  PHONE_NO_START_ADORNMENT,
  FIREBASE_EVENT_NAMES,
  CONSENT_TYPES,
  ERROR_STATE_FOR_MOBILE_NUMBER,
  SIGN_UP_DETAILS,
  SIGN_UP,
  ERROR_MSGS,
} from '../../utils/constants';
import ReferralService from '../../service/Referral';
import PolicyDialog from '../../components/molecules/PolicyDialog';
import ConsentService from '../../service/ConsentService';
import User from '../../service/User';
import PageTemplate from '../../components/templates/PageTemplate';
import TextField from '../../components/atoms/TextField';
import Loader from '../../components/atoms/Loader';
import Auth from '../../service/Auth';
import { VERIFY_PROMO_CODE, VERIFY_REFERRAL_CODE } from '../Signup';
import withRouter from '../../components/withRoute';
const publicIp = require('public-ip');
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
export const auth = new Auth();

const generateOTPQueryUnAuth = gql`
  mutation generateOTPUnAuth($phone: String) {
    generateOTPUnAuth(phone: $phone) {
      verificationSid
    }
  }
`;

const styles = (theme) => ({
  strength: {
    ...theme.customComponent.passwordStrength,
  },
  cover: {
    alignItems: 'center',
    direction: 'column',
    justify: 'center',
  },
  heading: {
    fontSize: '28px',
    fontWeight: 600,
    color: '#000000',
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    height: 80,
    [theme.breakpoints.only('xs')]: {
      height: 100,
    },
    lineHeight: '40px',
    marginBottom: 16,
  },
  mainPageBox: {
    [theme.breakpoints.only('xs')]: {
      width: 'calc(100% - 60px)',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% - 300px)',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% - 200px)',
    },
    [theme.breakpoints.only('lg')]: {
      width: '480px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '480px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mainPageBoxStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'left',
    alignSelf: 'flex-start',
  },
  textFieldTitle: {
    marginTop: 16,
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    lineHeight: '16px',
    color: '#000000',
  },
  helperText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
  },
  errMessage: {
    color: '#FF0000',
  },
  successText: {
    color: '#007D32',
  },
  defaultText: {
    color: '#666666',
  },
  infoIconStyle: {
    width: 5,
    height: 5,
  },
  infoIconError: { height: '12px', width: '12px' },
  textFieldStyle: {
    borderRadius: 4,
    border: '1px solid #D0D0D0',
    backgroundColor: '#FFFFFF',
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Inter',
      fontSize: 13,
    },
  },
  typographystyle: {
    marginTop: 30,
    color: '#666666',
    fontWeight: '200',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
  },
  termsOfUserLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  agreeToConsentText: {
    ...theme.customComponent.body1RegularLight,
  },
  termsAndPrivacy: {
    textDecoration: 'underline',
    ...theme.customComponent.clickableItem,
  },
  linkstyle: {
    color: '#167dff',
    fontWeight: '700',
    fontSize: '12px',
    textDecoration: 'underline',
  },
  mainPage: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      background: '#FFFFFF',
      alignItems: 'center',
    },
    background: '#f7f7f7',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  createAccountButtom: {
    height: 48,
    padding: '8px 24px',
    borderRadius: 40,
    backgroundColor: '#036FCB',
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    fontWeight: 500,
    marginTop: 18,
    '&:hover': {
      backgroundColor: '#389def',
    },
  },
});
class FillDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationAttempt: false,
      type: '',
      password: '',
      getDerivedcalled: true,
      firstName: '',
      lastName: '',
      email: '',
      id: '',
      birthDate: '',
      gender: '',
      maritalStatus: '',
      phoneno: '',
      firstNameError: '',
      lastNameError: '',
      emailError: false,
      phonenoError: false,
      firstNameErrorMsg: '',
      lastNameErrorMsg: '',
      emailErrorMsg: '',
      phonenoErrorMsg: '',
      code: '',
      referralCode: '',
      promoCode: '',
      referralCodeError: false,
      referralCodeErrorMsg: '',
      isCheckedPolicy: false,
      referralCodeSuccessMsg: '',
      referralCodeSuccess: false,
      termsDialogOpen: false,
      privacyPolicyDialogOpen: false,
      privacyPolicyContent: '',
      termsContent: '',
      checked: false,
      isLoading: false,
      deviceType: '',
    };
  }

  fetchConsent = async (consentType) => {
    try {
      const consentData = await ConsentService.fetchConsentByType(consentType);
      if (consentType === CONSENT_TYPES.TERMS_CONDITION) {
        this.setState({
          termsContent: consentData.htmlContent,
        });
      } else if (consentType === CONSENT_TYPES.PRIVACY_POLICY) {
        this.setState({
          privacyPolicyContent: consentData.htmlContent,
        });
      }
    } catch (error) {
      console.error('error while fetching consent of type', consentType, error);
    }
  };

  componentDidMount() {
    if (this.state.code) {
      this.verifyReferral();
    }
    this.setState({
      deviceName: detectDeviceName(),
    });
  }

  static getDerivedStateFromProps(props, state) {
    const userSchemaObj = props.userSchemaObj;
    if (state.getDerivedcalled && userSchemaObj.type === 'NS') {
      return {
        ...state,
        email: userSchemaObj.data.email,
        password: userSchemaObj.data.password,
        type: userSchemaObj.type,
        getDerivedcalled: false,
        code: userSchemaObj.data.referral,
        promoCode: userSchemaObj.data.isPromoCode ? userSchemaObj.data.referral : '',
        referralCode: userSchemaObj.data.isReferralCode ? userSchemaObj.data.referral : '',
      };
    } else if (state.getDerivedcalled && userSchemaObj.type === 'SS') {
      const referralCodeData = auth.getReferralPromoCode();
      const code = referralCodeData && referralCodeData.code;
      const isReferral = referralCodeData && referralCodeData.isReferral;
      const isPromoCode = referralCodeData && referralCodeData.promoCode;
      return {
        ...state,
        type: userSchemaObj.type,
        firstName: userSchemaObj.data.firstName,
        lastName: userSchemaObj.data.lastName,
        id: userSchemaObj.data.id,
        email: userSchemaObj.data.email,
        getDerivedcalled: false,
        code: code || '',
        referralCode: isReferral ? code : '',
        promoCode: isPromoCode ? code : '',
      };
    }
    return state;
  }
  handleTermDialogClose = () => {
    this.setState({ termsDialogOpen: false, privacyPolicyDialogOpen: false });
  };
  handlePrivacyPolicyTextClick = async () => {
    if (this.state.privacyPolicyContent === '') {
      await this.fetchConsent(CONSENT_TYPES.PRIVACY_POLICY);
    }
    this.setState({
      privacyPolicyDialogOpen: true,
    });
  };
  handleTermsTextClick = async () => {
    if (this.state.termsContent === '') {
      await this.fetchConsent(CONSENT_TYPES.TERMS_CONDITION);
    }
    this.setState({
      termsDialogOpen: true,
    });
  };

  firstNameValidation = () => {
    const { firstName } = this.state;
    const trimmedVal = firstName.trim();
    const { isValid, errorMessage } = validateFirstName(trimmedVal);

    if (!isValid) {
      this.setState({
        firstNameError: true,
        firstNameErrorMsg: errorMessage,
      });
      return false;
    }
    return true;
  };
  lastNameValidation = () => {
    const { lastName } = this.state;
    const trimmedVal = lastName.trim();
    const { isValid, errorMessage } = validateLastName(trimmedVal);

    if (!isValid) {
      this.setState({
        lastNameError: true,
        lastNameErrorMsg: errorMessage,
      });
      return false;
    }
    return true;
  };
  numberValidation = () => {
    const { phoneno } = this.state;

    const { isValid, errorMessage } = validateUSPhone(phoneno);

    if (!isValid) {
      this.setState({
        phonenoError: true,
        phonenoErrorMsg: errorMessage,
      });
      return false;
    }
    return true;
  };
  setChecked = () => {
    this.setState({
      checked: !checked,
    });
  };
  handleFieldChange = (name, val) => {
    const { registrationAttempt } = this.state;
    let value = val;
    if (!registrationAttempt) {
      this.setState({
        registrationAttempt: true,
      });
      logUserEvent(FIREBASE_EVENT_NAMES.REGISTRATION_ATTEMPT);
    }

    const nameError = `${name}Error`;
    const nameErrorMsg = `${name}ErrorMsg`;
    const caretStart = event.target.selectionStart;
    const phoneLength = value.length;

    if (name === 'phoneno') {
      if (value.length >= this.state.phoneno.length) {
        value = getUSFormatPhoneNo(value);
      }
    }
    this.setState(
      {
        [name]: value,
        [nameError]: false,
        [nameErrorMsg]: '',
        error: '',
      },
      () => {
        if (name === 'phoneno' && phoneLength > caretStart) {
          event.target.selectionStart = event.target.selectionEnd = caretStart;
        }
      },
    );
    if (name === 'code') {
      this.setState({
        referralCodeSuccessMsg: '',
        referralCodeErrorMsg: '',
        referralCodeSuccess: false,
        referralCodeError: false,
      });
    }
  };

  validation = () => {
    let error = true;
    if (!this.firstNameValidation()) {
      error = false;
    }
    if (!this.lastNameValidation()) {
      error = false;
    }
    if (!this.numberValidation()) {
      error = false;
    }
    return error;
  };

  //Adding this code on second screen also to accommodate adding referral/promo code for social login/signup

  verifyReferral = () => {
    const { code } = this.state;
    if (code) {
      ReferralService.verifyReferralCode(VERIFY_REFERRAL_CODE, code)
        .then((res) => {
          const isValid = res.verify;
          if (isValid) {
            this.setState({
              referralCodeSuccess: true,
              referralCodeSuccessMsg: SIGN_UP.REFERRAL_CODE_APPLIED,
              referralCode: code,
              promoCode: '',
            });
          } else {
            ReferralService.verifyPromoCode(VERIFY_PROMO_CODE, code.toUpperCase()).then((res) => {
              const isPromoValid = res.verify;
              if (isPromoValid) {
                this.setState({
                  referralCodeSuccess: true,
                  referralCodeSuccessMsg: SIGN_UP.PROMO_CODE_APPLIED,
                  promoCode: code,
                  referralCode: '',
                });
              } else {
                this.setState({
                  referralCodeError: true,
                  referralCodeErrorMsg: ERROR_MSGS.REFERRAL_CODE.INVALID,
                });
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  singUpCall = async () => {
    this.setState({ isLoading: true });
    let ipAddress = '0.0.0.0';
    try {
      ipAddress = await publicIp.v4();
      if (this.validation()) {
        const mobile_no_with_brackate = this.state.phoneno.replace(/\s+/g, '');
        const phoneno = mobile_no_with_brackate.replace(/[()]/g, '');
        const createAccountObj = {
          email: this.state.email,
          phone: phoneno,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          ipAddress: ipAddress,
          code: this.state.code,
          deviceType: this.deviceType,
        };
        if (this.state.type === 'NS') {
          createAccountObj.isSocialLogin = false;
          createAccountObj.password = this.state.password;
          createAccountObj.id = '';
          User.signupUser(
            createAccountObj.firstName,
            createAccountObj.lastName,
            createAccountObj.email,
            createAccountObj.password,
            createAccountObj.phone,
            createAccountObj.ipAddress,
            createAccountObj.code,
            createAccountObj.deviceType,
          )
            .then((result) => {
              User.generateOTPUnAuth(generateOTPQueryUnAuth, result.data.createNewUser.phone)
                .then((res) => {
                  this.props.setOTP({
                    verificationSid: res.verificationSid,
                    phone: this.state.phoneno,
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    id: result.data.createNewUser.id,
                    isSocialLogin: createAccountObj.isSocialLogin,
                    isReferral: this.state.referralCode ? true : false,
                    isPromoCode: this.state.promoCode ? true : false,
                    code: this.state.code,
                  });
                })
                .catch((error) => {
                  console.log(error);

                  // need to check error phone number already exists
                  if (
                    error &&
                    error.message &&
                    error.message.includes(
                      ERROR_STATE_FOR_MOBILE_NUMBER.INVALID_MOBILE_NUMBER.lookup,
                    )
                  ) {
                    this.setState({
                      phonenoError: true,
                      phonenoErrorMsg: ERROR_STATE_FOR_MOBILE_NUMBER.INVALID_MOBILE_NUMBER.Label,
                    });
                  } else if (
                    error &&
                    error.message &&
                    error.message.includes(
                      ERROR_STATE_FOR_MOBILE_NUMBER.MOBILE_NUMBER_ALREADY_EXISTS.lookup,
                    )
                  ) {
                    this.setState({
                      phonenoError: true,
                      phonenoErrorMsg:
                        ERROR_STATE_FOR_MOBILE_NUMBER.MOBILE_NUMBER_ALREADY_EXISTS.Label,
                    });
                  } else if (
                    error &&
                    error.message &&
                    error.message.includes('Phone Number already exists')
                  ) {
                    this.setState({
                      phonenoError: true,
                      phonenoErrorMsg: 'Phone Number already exists',
                    });
                  } else {
                    this.setState({
                      phonenoError: true,
                      phonenoErrorMsg: error.message,
                    });
                  }
                });
            })
            .catch((error) => {
              console.log(error);
              if (
                error &&
                error.message &&
                error.message.includes(ERROR_STATE_FOR_MOBILE_NUMBER.INVALID_MOBILE_NUMBER.lookup)
              ) {
                this.setState({
                  phonenoError: true,
                  phonenoErrorMsg: ERROR_STATE_FOR_MOBILE_NUMBER.INVALID_MOBILE_NUMBER.Label,
                });
              } else if (
                (error && error.message && error.message.includes('User already exist')) ||
                (error && error.message && error.message.includes('Failed to create user'))
              ) {
                this.setState({
                  phonenoError: true,
                  phonenoErrorMsg: 'Phone Number already exists',
                });
              } else if (
                error &&
                error.message &&
                error.message.includes('Phone Number already exists')
              ) {
                this.setState({
                  phonenoError: true,
                  phonenoErrorMsg: 'Phone Number already exists',
                });
              } else {
                this.setState({
                  phonenoError: true,
                  phonenoErrorMsg: error.message,
                });
              }
            })
            .finally(() => {
              this.setState({
                isLoading: false,
              });
            });
        } else if (this.state.type === 'SS') {
          createAccountObj.isSocialLogin = true;
          createAccountObj.password = '';
          createAccountObj.id = this.state.id;
          User.createAccount(createAccountObj)
            .then((result) => {
              if (this.state.referralCode) {
                logUserEvent(FIREBASE_EVENT_NAMES.REGISTRATION_WITH_REFERRAL);
              } else if (this.state.promoCode) {
                logUserEvent(FIREBASE_EVENT_NAMES.REGISTRATION_WITH_PROMO);
              } else {
                logUserEvent(FIREBASE_EVENT_NAMES.REGISTRATION);
              }
              this.props.setOTP({
                verificationSid: result.data.createAccount.verificationSid,
                phone: this.state.phoneno,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                id: this.state.id,
                isSocialLogin: createAccountObj.isSocialLogin,
                isReferral: this.state.referralCode ? true : false,
                isPromoCode: this.state.promoCode ? true : false,
                code: this.state.code,
              });
            })
            .catch((error) => {
              console.log(error);
              if (
                error &&
                error.message &&
                error.message.includes(ERROR_STATE_FOR_MOBILE_NUMBER.INVALID_MOBILE_NUMBER.lookup)
              ) {
                this.setState({
                  phonenoError: true,
                  phonenoErrorMsg: ERROR_STATE_FOR_MOBILE_NUMBER.INVALID_MOBILE_NUMBER.Label,
                });
              } else if (['User already exist', 'Failed to create user'].includes(error?.message)) {
                this.setState({
                  phonenoError: true,
                  phonenoErrorMsg: 'Phone Number already exists',
                });
              } else if (error?.message?.includes('Failed to create user')) {
                //"Failed to create user" message responded when phone number already exist.
                this.setState({
                  phonenoError: true,
                  phonenoErrorMsg: 'Phone Number already exists',
                });
              } else {
                this.setState({
                  phonenoError: true,
                  phonenoErrorMsg: error.message,
                });
              }
            })
            .finally(() => {
              this.setState({
                isLoading: false,
              });
            });
        }
      }
    } catch (error) {
      console.warn('Error occurred while fetching ip address', error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes, userSchemaObj } = this.props;
    const {
      firstNameError,
      lastNameError,
      phonenoError,
      firstNameErrorMsg,
      lastNameErrorMsg,
      phonenoErrorMsg,
      referralCodeError,
      referralCodeSuccess,
      referralCodeErrorMsg,
      referralCodeSuccessMsg,
      phoneno,
      privacyPolicyDialogOpen,
      termsContent,
      privacyPolicyContent,
      termsDialogOpen,
      checked,
      isLoading,
    } = this.state;
    return (
      <Box className={classes.mainPage}>
        {isLoading && <Loader overlay text={null} />}
        <PageTemplate>
          <Box className={classes.mainPageBox}>
            <Box className={classes.mainPageBoxStyle}>
              <Typography variant="h2" className={classes.heading}>
                {SIGN_UP_DETAILS.WELCOME}
              </Typography>
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGN_UP_DETAILS.FIRST_NAME}
              </Typography>
              <TextField
                id="first-name-field"
                name="firstName"
                color="primary"
                variant="outlined"
                size="small"
                type="text"
                placeholder={SIGN_UP_DETAILS.FIRST_NAME_PLACEHOLDER}
                onFieldChange={this.handleFieldChange}
                value={this.state.firstName}
                className={classes.textFieldStyle}
                lightBorder={false}
              />
              {firstNameError ? (
                <Typography variant="h6" className={[classes.helperText, classes.errMessage]}>
                  <InfoIcon className={classes.infoIconError}></InfoIcon>
                  {firstNameErrorMsg}
                </Typography>
              ) : null}
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGN_UP_DETAILS.LAST_NAME}
              </Typography>
              <TextField
                id="last-name-field"
                name="lastName"
                type="text"
                placeholder={SIGN_UP_DETAILS.LAST_NAME_PLACEHOLDER}
                onFieldChange={this.handleFieldChange}
                value={this.state.lastName}
                className={classes.textFieldStyle}
                lightBorder={false}
                variant="outlined"
                size="small"
                color="primary"
              />
              {lastNameError ? (
                <Typography variant="h6" className={[classes.helperText, classes.errMessage]}>
                  <InfoIcon className={classes.infoIconError}></InfoIcon>
                  {lastNameErrorMsg}
                </Typography>
              ) : null}
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGN_UP_DETAILS.PHONE_NUMBER}
              </Typography>
              <TextField
                id="phoneno-field"
                name="phoneno"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{PHONE_NO_START_ADORNMENT}</InputAdornment>
                  ),
                }}
                value={phoneno}
                type="text"
                placeholder={SIGN_UP_DETAILS.PHONE_NUMBER_PLACEHOLDER}
                onFieldChange={this.handleFieldChange}
                className={classes.textFieldStyle}
                lightBorder={false}
                variant="outlined"
                size="small"
                color="primary"
              />
              {!phonenoError ? (
                <Typography variant="h6" className={[classes.helperText, classes.defaultText]}>
                  <InfoIcon className={classes.infoIconError}></InfoIcon>
                  {SIGN_UP_DETAILS.CONFIRM_OTP}
                </Typography>
              ) : (
                <Typography variant="h6" className={[classes.helperText, classes.errMessage]}>
                  <InfoIcon className={classes.infoIconError}></InfoIcon>
                  {phonenoErrorMsg}
                </Typography>
              )}
              {/* Create a common component for referral/promo code as its being used on both the signup screens */}
              {userSchemaObj.type === 'SS' && (
                <React.Fragment>
                  <Typography variant="h6" className={classes.textFieldTitle}>
                    {SIGN_UP_DETAILS.REFERRAL}
                  </Typography>
                  <TextField
                    id="referral-field"
                    className={classes.textFieldStyle}
                    lightBorder={false}
                    variant="outlined"
                    size="small"
                    color="primary"
                    value={this.state.code || ''}
                    name="code"
                    placeholder="Enter referral/promo code"
                    onFieldChange={this.handleFieldChange}
                    onBlur={this.verifyReferral}
                  />
                  {referralCodeError ? (
                    <Typography variant="h6" className={[classes.helperText, classes.errMessage]}>
                      <InfoIcon className={classes.infoIconError}></InfoIcon>
                      {referralCodeErrorMsg}
                    </Typography>
                  ) : referralCodeSuccess ? (
                    <Typography variant="h6" className={[classes.helperText, classes.successText]}>
                      <InfoIcon className={classes.infoIconError}></InfoIcon>
                      {referralCodeSuccessMsg}
                    </Typography>
                  ) : null}
                </React.Fragment>
              )}
              <Typography className={classes.typographystyle}>
                <input
                  style={{
                    height: 15,
                    width: 15,
                    marginRight: '8px',
                  }}
                  type="checkbox"
                  defaultChecked={checked}
                  onChange={() => this.setState({ checked: !checked })}
                />
                <div>
                  {SIGN_UP_DETAILS.READ_AGREE}
                  <PolicyDialog
                    open={termsDialogOpen}
                    onClose={() => this.setState({ termsDialogOpen: false })}
                    content={termsContent}
                    consentType={CONSENT_TYPES.TERMS_CONDITION}
                    rawHTML
                  />
                  <PolicyDialog
                    open={privacyPolicyDialogOpen}
                    onClose={() => this.setState({ privacyPolicyDialogOpen: false })}
                    content={privacyPolicyContent}
                    consentType={CONSENT_TYPES.PRIVACY_POLICY}
                    rawHTML
                  />

                  <Link
                    target="_blank"
                    onClick={this.handleTermsTextClick}
                    rel="noopener"
                    variant="h4"
                    className={classes.linkstyle}
                  >
                    {SIGN_UP_DETAILS.TERMS}
                  </Link>
                  {SIGN_UP_DETAILS.AND}
                  <Link
                    target="_blank"
                    onClick={this.handlePrivacyPolicyTextClick}
                    rel="noopener"
                    variant="h4"
                    className={classes.linkstyle}
                  >
                    {SIGN_UP_DETAILS.POLICY}
                  </Link>
                  {'.'}
                </div>
              </Typography>
              <Button
                className={classes.createAccountButtom}
                id="ssignup-button"
                onClick={this.singUpCall}
                title={SIGN_UP_DETAILS.CREATE_ACCOUNT}
                variant="contained"
                color="primary"
                disabled={!checked}
              />
            </Box>
          </Box>
        </PageTemplate>
      </Box>
    );
  }
}
export default withRouter(withStyles(styles, { withTheme: true })(FillDetails));
