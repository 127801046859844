import React, { useContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import useAnalyticsLogger from '../hooks/useAnalyticsLogger';
import { UserInfoContext } from './Routes';

const withRouter = (WrappedComponent) => (props) => {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  // adding analytics hook as we cannot modify all the existing classes
  const { logAnalyticsEvent } = useAnalyticsLogger(); 
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <WrappedComponent
      {...props}
      params={params}
      location={location}
      navigate={navigate}
      userInfo={userInfo}
      setUserInfo={setUserInfo}
      logAnalyticsEvent={logAnalyticsEvent}
    />
  );
};

export default withRouter;
