import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { CARE_TEAM } from '../../utils/constants';
import InfoIcon from '../../assets/images/infoIcon.svg.svg';
import carePlanIcon from '../../assets/images/carePlan.svg';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import { checkOneTimeUser } from '../../utils/helper';

const { VIEW_DETAILS, READ_LESS, READ_MORE } = CARE_TEAM.WORDINGS;

const AssociateCarePlan = ({ item, classes, screenMode }) => {
  const [showMore, setShowMore] = useState(false);
  const [lastIndex, setLastIndex] = useState(1);
  const navigate = useNavigate();
  const isOneTimeUser = checkOneTimeUser();
  const { partner, grantorId, granteeId } = useParams();

  const handleViewCarePlanDetails = (carePlanId) => {
    let url = '/records/care_plan';
    if (screenMode.isCircle) {
      url = `/circles/${partner}/care_plan`;
    } else if (screenMode.involvesConsent || isOneTimeUser) {
      url = `/${
        screenMode.isCircle ? 'circles' : 'records'
      }/individual/care_plan/${grantorId}/${granteeId}`;
    }
    if (carePlanId) {
      navigate(url, { state: { carePlanId } });
    }

  };
  return (
    <div className={classes.associateCardWrapper}>
      <div className={classes.headingSection}>
        <span className={classes.assocImgWrapper}>
          <img
            src={item.imgUrl}
            alt="icon"
            className={classes.associateLogo}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = carePlanIcon;
            }}
          />
          <span className={classes.associateHeading}>{item.heading}</span>
        </span>
        <Typography
          className={clsx([classes.infoIconWrapper, classes.readMoreAndLess])}
          onClick={() => handleViewCarePlanDetails(item?.id)}
        >
          <img src={InfoIcon} alt="infoIcon" className={classes.infoIcon} />
          {VIEW_DETAILS}
        </Typography>
      </div>
      <div className={classes.contentSection}>
        <Typography className={classes.associateSubHeading}>{item?.subHeading}</Typography>
        <ul className={classes.list}>
          {item?.list?.slice(0, lastIndex)?.map((listItem, i) => (
            <li className={classes.listItems} key={i}>
              {listItem}
              {item?.list?.length - 1 === i ? (
                <span
                  className={classes.readMoreAndLess}
                  onClick={() => {
                    setShowMore((prev) => !prev);
                    setLastIndex(1);
                  }}
                >
                  {!showMore ? '' : READ_LESS}
                </span>
              ) : (
                <span
                  className={classes.readMoreAndLess}
                  onClick={() => {
                    setLastIndex(item?.list?.length);
                    setShowMore((prev) => !prev);
                  }}
                >
                  {!showMore ? READ_MORE : ''}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AssociateCarePlan;
