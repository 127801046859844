import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { FindCareModule } from '@premkumarmph/find-care';
import { GRAPHQL } from '../../../config/api_end_point';
import { auth } from '../../components/Routes';
import { withStyles } from '@mui/styles';
import '../../../src/assets/styles/style.css';
import useClickFooter from '../../hooks/useClickFooter';
import { useLocation } from 'react-router-dom';

const styles = () => ({
  container: {
    backgroundColor: '#F5F5F5',
    minHeight: '100vh',
    padding: '0px',
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    marginTop: 24,
    color: '#1B1B1B',
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    color: '#1B1B1B',
    marginTop: 40,
  },
  billCardContainer: {
    display: 'flex',
    marginTop: 12,
  },
  firstCard: {
    display: 'flex',
    backgroundColor: '#ffffff',
    borderRadius: 16,
    boxShadow: '0px 2px 6px rgba(0,0,0,0.15)',
  },
  secondCard: {
    display: 'flex',
    backgroundColor: '#ffffff',
    borderRadius: 16,
    boxShadow: '0px 2px 6px rgba(0,0,0,0.15)',
  },
  contentSection: {
    height: 160,
    width: 340,
    position: 'relative',
  },
  cardTitle: {
    paddingTop: 20,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    paddingLeft: 30,
    lineHeight: ' 20px',
    color: '#101828',
  },
  cardContent: {
    paddingTop: 12,
    fontWeight: 400,
    fontSize: 14,
    color: '#828282',
    paddingLeft: 30,
    width: '50%',
    color: '#667085',
  },
  image: { width: 32, height: 32 },
  noOfBilldDueText: {
    paddingTop: 24,
    fontWeight: 500,
    fontSize: 14,
    paddingLeft: 30,
    lineHeight: ' 20px',
    color: '#667085',
  },
  consentRequestButton: {
    border: '1px solid #A0A4A6',
    color: '#036FCB',
    height: 24,
    padding: '2px 8px 0px',
    borderRadius: 20,
    minWidth: 90,
    margin: '14px 0px 0px 28px',
  },
});

const BASE_URL = process.env.APOLLO_CLIENT;
const API_PATH = GRAPHQL;

const COMPLETE_URL = BASE_URL + API_PATH;
sessionStorage.setItem('FINDCARE_URL', COMPLETE_URL);

const FindCare = ({ classes, ...props }) => {
  useClickFooter(props.onFooterItemClick);
  const token = auth.getAccessToken();
  sessionStorage.setItem('FROM_ENTERPRISE', false);
  const location = useLocation();
  const [ hidePartnerList, setHidePartnerList]= useState(location.state?.hidePartnerList ?? false)
  useEffect(() => {
    if (window.location.hostname.startsWith('demo.mpowered')) {
      setHidePartnerList(true);
    }
  }, []);
  return (
    <div className={classes.container}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: { xs: '95%', sm: '100%' },
        }}
      >
        <FindCareModule
          token={token}
          URL={COMPLETE_URL}
          path={window.location.pathname}
          hidePartnerList={hidePartnerList}
          hideInNetwork={false}
          fromEnterprise={false}
        />
      </Box>
    </div>
  );
};

export default withStyles(styles)(FindCare);
