import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '../../../../assets/images/infoIcon.svg.svg';
import carePlanIcon from '../../../../assets/images/carePlan.svg';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import { checkOneTimeUser } from '../../../../utils/helper';

const labels = {
  VIEW_DETAILS: 'View details',
  READ_LESS: '...Read less',
  READ_MORE: '...Read more',
};

const AssociatePlans = ({ item, classes, screenMode }) => {
  const [showMore, setShowMore] = useState(false);
  const [lastIndex, setLastIndex] = useState(1);
  const navigate = useNavigate();
  const isOneTimeUser = checkOneTimeUser();
  const { partner, grantorId, granteeId,circleId } = useParams();
  const handleViewDetails = (id) => {
    if (item.resource === 'CarePlan') {
      item.resource = 'care_plan';
    }
    let url = `/records/new/${item.resource}`;

    if (screenMode.isCircle && !screenMode.involvesConsent && !isOneTimeUser) {
      url = `/circles/${partner}/${circleId}/new/${item.resource}`;
    } else if (screenMode.involvesConsent || isOneTimeUser) {
      url = `/records/individual/new/${
        item.resource
      }/${grantorId}/${granteeId}`;
    }

    if (item.resource === 'care_plan') {
      navigate(url, { state: { carePlanId: id } });
    } else if (id) {
      navigate(url, { state: { id } });
    }
  };

  useEffect(() => {
    setLastIndex(showMore ? item?.list?.data?.lenght : 1);
  }, [showMore]);

  return (
    <Box className={classes.associateCardWrapper}>
      <Box className={classes.headingSection}>
        <span className={classes.assocImgWrapper}>
          <img
            src={item.imgUrl}
            alt="icon"
            className={classes.associateLogo}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = carePlanIcon;
            }}
          />
          <span className={classes.associateHeading}>{item.heading}</span>
        </span>
        <Typography
          className={clsx([classes.infoIconWrapper, classes.readMoreAndLess])}
          onClick={() => handleViewDetails(item?.id)}
        >
          <img src={InfoIcon} alt="infoIcon" className={classes.infoIcon} />
          {labels.VIEW_DETAILS}
        </Typography>
      </Box>
      <Box className={classes.contentSection}>
        <Typography className={classes.associateSubHeading}>{item?.subHeading}</Typography>
        {item?.list?.data && (
          <ul className={classes.list}>
            {item?.list?.data?.slice(0, lastIndex)?.map((listItem, i) => (
              <li className={classes.listItems} key={i}>
                {listItem}
              </li>
            ))}
            {item?.list?.data?.length > 1 ? (
              <span
                className={classes.readMoreAndLess}
                onClick={() => {
                  setShowMore((prev) => !prev);
                }}
              >
                {showMore ? labels.READ_LESS : labels.READ_MORE}
              </span>
            ) : null}
          </ul>
        )}
      </Box>
    </Box>
  );
};

export default AssociatePlans;
