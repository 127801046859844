import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  throughAutoLogin: false,
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    updateThroughAutoLogin: (state, action) => {
      state.throughAutoLogin = action.payload;
    },
  },
});

export const { updateThroughAutoLogin } = signupSlice.actions;

export default signupSlice.reducer;
