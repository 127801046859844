import FavouriteServices from '../service/FavouriteServices';
import { FAVOURITES_KEY_NAMES } from '../utils/constants';
import React, { useState, useEffect } from 'react';
import HeartIcon from '../assets/images/heart.svg';
import ActiveHeartIcon from '../assets/images/active-heart.svg';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useFavouritesHook = (resource) => {
  const [favLoading, setFavLoading] = useState(false);
  const [isFavourite, setIsFavourite] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    getFavoritesNames();
  }, [resource]);

  const getFavoritesNames = async () => {
    try {
      setFavLoading(true);

      const res = await FavouriteServices.fetchFavouritesList();
      const isPresent = res?.names.find(
        (item) => item?.toLowerCase() === FAVOURITES_KEY_NAMES[resource]?.toLowerCase(),
      );
      setIsFavourite(isPresent);
    } catch (err) {
      console.error('error', err);
    } finally {
      setFavLoading(false);
    }
  };

  const handleFavClick = async () => {
    if (favLoading) {
      return null;
    }
    try {
      setFavLoading(true);
      setIsFavourite(!isFavourite);
      await FavouriteServices.addFavourite({
        name: FAVOURITES_KEY_NAMES[resource],
        selected: !isFavourite,
      });
    } catch (err) {
      console.log('error', err);
    } finally {
      setFavLoading(false);
    }
  };

  const renderFavIcon = () => (
    <a className={classes.container} onClick={handleFavClick}>
      <Typography className={classes.label}>Add to Favorites</Typography>
      {isFavourite ? (
        <img className={classes.img} src={ActiveHeartIcon} />
      ) : (
        <img className={classes.img} src={HeartIcon} />
      )}
    </a>
  );

  return { isFavourite, renderFavIcon };
};

const useStyles = makeStyles((theme) => ({
  img: {
    height: '24px',
    width: '24px',
    alignSelf: 'center',
  },
  label: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#1F1F1F',
  },
  container: {
    width: '230px',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // width: '150px',
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
    backgroundColor: '#ffff',
    marginBottom: '20px',
  },
}));

export default useFavouritesHook;
