export const logBranchEvent = (eventName) => {
  if (eventName && process.env.ENABLE_BRANCH_METRICS === 'true') {
    branch.logEvent(eventName, (err) => {
      if (err) {
        console.log('branch event err', err);
      } else {
        console.log('branch event fired', eventName);
      }
    });
  }
};
