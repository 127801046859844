import { Box } from '@mui/material';
import React from 'react';
import { withStyles } from '@mui/styles';
import { calculateLabResultLabel, fetchStatusObj } from '../../../../utils/helper';
import colors from '../../../../utils/colors';
import { DEFAULT_NULL_REPLACEMENT } from '../../../../utils/constants';
import StatusIcon from '@mui/icons-material/FiberManualRecord';

const styles = (theme) => ({
  root: {
    height: 120,
    background: '#f8f8f8cc',
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    marginTop: 40,
    marginBottom: 30,
  },
  labelStyles: {
    height: 24,
    fontWeight: 500,
    color: '#666666',
    fontSize: 16,
    lineHeight: '24px',
  },
  value: {
    height: 40,
    fontWeight: 600,
    fontSize: 28,
    lineHeight: '40px',
    color: colors.BLACK_B900,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    justifyContent: 'center',
  },
});

const ReadingsBanner = (props) => {
  const { classes, editDataForFlyout = [] } = props;
  let value = '-';
  let units = '';
  let referenceRange = null;
  let labResult = '-';

  for (const obj of editDataForFlyout) {
    if (obj?.url === 'value') {
      value = obj?.valueString;
    }
    if (obj?.url === 'unit') {
      units = obj?.valueString;
    }
    if (obj?.url === 'referenceRange') {
      referenceRange = obj?.valueString;
    }
    if (obj?.url === 'test_results') {
      labResult = obj?.valueString;
    }
  }
  // let calculatedLabResult = labResult || '-';
  // if (calculatedLabResult === '-' && value && referenceRange) {
  //   calculatedLabResult = calculateLabResultLabel(referenceRange, value);
  // }
  // const statusObj =
  //   calculatedLabResult && calculatedLabResult !== '-'
  //     ? fetchStatusObj(calculatedLabResult)
  //     : { status: '-', color: null };

  return (
    <Box className={classes.root}>
      <span className={classes.labelStyles}>Value</span>
      {value ? (
        <span className={classes.value}>{`${value} ${units}`}</span>
      ) : (
        DEFAULT_NULL_REPLACEMENT
      )}
      <Box className={classes.statusContainer}>
        {/* {statusObj.color && (
          <span className={classes.labResultStatusIconContainer}>
            <StatusIcon htmlColor={statusObj.color} className={classes.statusIcon} />
          </span>
        )} */}
        <p className={classes.labResultValue}>{labResult}</p>
      </Box>
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(ReadingsBanner);
