// import { FOOTER } from './constants';
import HomeIcon from '../assets/images/footerHomeIcon2.svg';
import HomeLightIcon from '../assets/images/homeLight2.svg';
import DemoHomeIcon from '../assets/images/home-active.png';
import DemoHomeLightIcon from '../assets/images/home_light.png';
import ShopIcon from '../assets/images/shopping_cart.svg';
import ShopLightIcon from '../assets/images/shopping_cart2.svg';
import ServicesIcon from '../assets/images/footerServiceIcon2.svg';
import ServicesLightIcon from '../assets/images/servicesLight2.svg';
import CirclesIcon from '../assets/images/footerCircleIcon2.svg';
import CirclesLightIcon from '../assets/images/circlesLight2.svg';
import UtilitiesActiveIcom from '../assets/images/utilitiesActiveIcon.svg';
import UtilitiesInActiveIcon from '../assets/images/utilitiesInActiveIcon.svg';
import { mockTemplates } from '../features/dynamicRecords/mockTemplate';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const get = () => {
  const { selectedLanguage } = useSelector((state) => state.app);
  const { templates } = useSelector((state) => state.templates);
  const [ICONS, SETICONS] = useState([]);
  const [INACTIVE_ICONS, SET_INACTIVE_ICONS] = useState([])

  useEffect(() => {
    if (window.location.hostname.startsWith('demo.mpowered')) {
      SETICONS([DemoHomeIcon, ShopIcon, CirclesIcon, ServicesIcon, UtilitiesActiveIcom])
      SET_INACTIVE_ICONS([
        DemoHomeLightIcon,
        ShopLightIcon,
        CirclesLightIcon,
        ServicesLightIcon,
        UtilitiesInActiveIcon,
      ])
    } else {
      SETICONS([HomeIcon, ShopIcon, CirclesIcon, ServicesIcon, UtilitiesActiveIcom])
      SET_INACTIVE_ICONS([
        HomeLightIcon,
        ShopLightIcon,
        CirclesLightIcon,
        ServicesLightIcon,
        UtilitiesInActiveIcon,
      ])
    }
  }, [selectedLanguage, templates]);

  const FOOTER =
    // templates?.texts?.en?.HEADER?.CONSENT_TYPES ||
    mockTemplates?.texts?.[selectedLanguage]?.HEADER?.NAVIGATION?.FOOTER;
  const footerCopy = [...FOOTER];

  for (let i = 0; i < footerCopy.length; i++) {
    footerCopy[i].icon = ICONS[i];
    footerCopy[i].lightIcon = INACTIVE_ICONS[i];
  }

  return footerCopy;
};
const footerIconGetter = { get };

export default footerIconGetter;
