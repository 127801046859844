export const MAIN_CONTAINER = {
  SAVE_EXIT_BTN: 'Save and exit',
  EXIT_BTN: 'Exit',
  LANDING_PAGE_HEADING: 'Social determinants of health (SDOH) assessment',
  LANDING_PAGE_SUB_HEADING: 'Fill the form and share your social factors for a comprehensive health assessment',
  FOOTER_SUBMIT_BTN: 'Submit',
  LOADING_TEXT: 'Fetching details...',
  STATUS: {
    IN_PROGRESS: 'in-progress',
    DRAFT: 'draft',
  }
  };

export const CONGRATS_POPUP = {
  HEADING: 'Congratulations!',
  SUBHEADING: 'Your assessment has been submitted successfully.',
};

