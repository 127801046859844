import gql from 'graphql-tag';
import client from '../../components/ApolloClient';
import { CODE_FRAGMENT, IDENTIFIER_FRAGMENT, RANGE_FRAGMENT } from '../../utils/fragments';
import { getFormattedFamilyHistoryRecords } from './helper';

const FETCH_FAMILY_HISTORY = gql`
  query fetchAllFamilyMemberHistory(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllFamilyMemberHistory(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      familyMemberHistoryList
      count
    }
  }
`;
const FETCH_FAMILY_HISTORY_BY_CONSENT = gql`
  query fetchAllFamilyMemberHistoryByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllFamilyMemberHistoryByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      familyMemberHistoryList
      count
    }
  }
`;

const FETCH_FAMILY_MEMBER_BY_ID = gql`
  query fetchFamilyMemberHistoryById($familyMemberHistoryId: String) {
    fetchFamilyMemberHistoryById(familyMemberHistoryId: $familyMemberHistoryId) {
      id
      status
      name
      meta {
        security {
          system
          code
          display
          version
        }
        source
        lastUpdated
        versionId
      }
      date
      sex {
        ...code
      }
      reasonCode {
        ...code
      }
      estimatedAge
      relationship {
        ...code
      }
      identifier {
        ...identifier
      }
      note {
        text
      }
      condition {
        code {
          ...code
        }
        onsetAge {
          value
          unit
          system
          code
        }
        onsetString
        onsetRange {
          ...range
        }
        onsetPeriod {
          start
          end
        }
      }
    }
  }
  ${CODE_FRAGMENT}
  ${IDENTIFIER_FRAGMENT}
  ${RANGE_FRAGMENT}
`;
const MARK_RESTRICTED_FAMILY_HISTORY = gql`
  mutation markAsRestrictedFamilyMemberHistory(
    $familyMemberHistoryId: String
    $markAsRestricted: String
  ) {
    markAsRestrictedFamilyMemberHistory(
      familyMemberHistoryId: $familyMemberHistoryId
      markAsRestricted: $markAsRestricted
    ) {
      id
      status
      name
    }
  }
`;

export function fetchAllFamilyMemberHistory(variables = {}) {
  return new Promise((resolve, reject) => {
    client
      .query({ query: FETCH_FAMILY_HISTORY, variables, fetchPolicy: 'no-cache' })
      .then((response) => {
        resolve(response.data.fetchAllFamilyMemberHistory);
      })
      .catch((err) => reject(err));
  });
}
export function fetchAllFamilyMemberHistoryByConsent(variables = {}) {
  return new Promise((resolve, reject) => {
    client
      .query({ query: FETCH_FAMILY_HISTORY_BY_CONSENT, variables, fetchPolicy: 'no-cache' })
      .then((response) => {
        resolve(response.data.fetchAllFamilyMemberHistoryByConsent);
      })
      .catch((err) => reject(err));
  });
}

export function fetchFamilyMemberHistoryById(
  familyMemberHistoryId,
  query = FETCH_FAMILY_MEMBER_BY_ID,
) {
  const variables = {
    familyMemberHistoryId: Number(familyMemberHistoryId).toString(),
  };
  return new Promise((resolve, reject) => {
    client
      .query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        resolve(response.data.fetchFamilyMemberHistoryById);
      })
      .catch((err) => reject(err));
  });
}

export function markAsRestrictedFamilyMemberHistory(
  familyMemberHistoryId,
  markAsRestricted,
  mutation = MARK_RESTRICTED_FAMILY_HISTORY,
) {
  const variables = {
    familyMemberHistoryId: String(familyMemberHistoryId),
    markAsRestricted: String(markAsRestricted),
  };
  return new Promise((resolve, reject) => {
    client
      .mutate({ mutation, variables })
      .then((response) => resolve(response.data.markAsRestrictedFamilyMemberHistory))
      .catch((err) => reject(err));
  });
}
