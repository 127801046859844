import React from 'react';
import { withStyles } from '@mui/styles';
import { fetchStatusObj } from '../../utils/helper';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import styles from './ListCustom.styles';
import clsx from 'clsx';

export const showStatus = (status, color, classes) => {
  const statusObj =
    status && status !== '-' ? fetchStatusObj(status, color) : { status: '-', color: null };
  return (
    <div className={classes.statusContainer}>
      {statusObj.color && (
        <span className={classes.statusColumn}>
          <StatusIcon htmlColor={statusObj.color} className={classes.statusIcon} />
        </span>
      )}
      <p className={clsx([classes.column_value, classes.status_value])}>{statusObj.status}</p>
    </div>
  );
};

function ListCustom({ items, classes }) {
  return (
    <div className={classes.listCustom}>
      {items.map((item) => {
        return (
          // extend functionality later to accept array of objects as well (with proper keys, for now rely on React's fallback index as key)
          <div key={item.id} className={classes.listItemWrapper}>
            <p className={classes.listItem}>{item.value}</p>
            {item.status && showStatus(item.status, '', classes)}
          </div>
        );
      })}
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(ListCustom);
