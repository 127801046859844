import { STATUS_LIST } from './constants';
import colors from './colors';

export const SELF_REPORTED_VALUE_KEYS = {
  FACILITY_KEY: 'facility',
  STATUS_KEY: 'status',
  CATEGORY_KEY: 'category',
  SEVERITY_KEY: 'severity',
  RECORDED_BY_KEY: 'recordedBy',
  RECORDED_ON_KEY: 'recordedOn',
  START_TIME_KEY: 'startTime',
  CODE_KEY: 'codeName',
  NOTE_KEY: 'note',
  DOCUMENT_KEY: 'documents',
  MARKED_AS_RESTRICTED_KEY: 'markAsRestricted',
  OUTCOME_KEY: 'outcome',
  REASON_CODE_KEY: 'reasonCode',
  COMPLICATIONS_KEY: 'complications',
  FOLLOW_UP_KEY: 'followUp',
  BODY_SITE_KEY: 'bodySite',
  CONDITION_NAME_KEY: 'conditionName',
  PROCEDURE_NAME_KEY: 'procedureName',
  IMMUNIZATION_NAME_KEY: 'immunizationName',
  VACCINE_DOSE_NUMBER_KEY: 'vaccineDosenumber',
  DOSE_KEY: 'dose',
  ROUTE_KEY: 'route',
  VACCINE_MANUFACTURER_KEY: 'vaccineManufacturer',
  VACCINE_LOT_NUMBER_KEY: 'vaccineLotnumber',
  VACCINE_EXPIRATION_DATE_KEY: 'vaccineExpirationdate',
  NDC_CODE_KEY: 'ndcCode',
  REASONS_KEY: 'reasons',
  REACTION_KEY: 'reaction',
  DOSES_KEY: 'dose',
  CRITICALITY_KEY: 'criticality',
  ALLERGIC_TO_KEY: 'allergicTo',
  CRITICALITY_KEY: 'criticality',
  MEDICATION_NAME_KEY: 'medicationName',
  MEDICATION_TYPE_KEY: 'medicationType',
  FREQUENCY_KEY: 'frequency',
  START_DATE_KEY: 'startDate',
  END_DATE_KEY: 'endDate',
  DOSAGE_KEY: 'dosage',
  PRESCRIBER_KEY: 'prescriber',
  MANUFACTURER_KEY: 'manufacturer',
  INGREDIENTS_KEY: 'ingredients',
  DIAGNOSTIC_REPORT_NAME_KEY: 'testName',
  VALUE_KEY: 'value',
  UNITS_KEY: 'units',
  TEST_RESULTS_KEY: 'testResults',
  REFERENCE_RANGE_KEY: 'referenceRange',
  CATEGORY_CODE_KEY: 'categoryCode',
  LOINC_CODE_KEY: 'loincCode',
  ISSUED_DATE_KEY: 'issuedDate',
  ISSUED_TIME_KEY: 'issuedTime',
  ISSUER_OF_REPORT_KEY: 'issuerOfTheReport',
  POLICY_NUMBER_KEY: 'policyNumber',
  MEMBER_ID_KEY: 'memberId',
  PLAN_TYPE_KEY: 'planType',
  COVERAGE_TYPE_KEY: 'coverageType',
  RELATIONSHIP_KEY: 'relationship',
  PLAN_NAME_KEY: 'planName',
  SUBSCRIBER_ID_KEY: 'subscriberId',
  SUBSCRIBER_KEY: 'subscriber',
  GROUP_SIZE_KEY: 'groupSize',
  HEALTH_PLAN_NAME_KEY: 'healthPlanName',
  TOTAL_AMOUNT_KEY: 'totalCost',
  CONTACT_NAME_KEY: 'contactName',
  CONTACT_PHONE_NUMBER_KEY: 'contactPhoneNumber',
  CONTACT_ADDRESS_KEY: 'contactAddress',
  ISSUER_ORGANIZATION_KEY: 'issuerOrganization',
  COMMENT_KEY: 'comment',
  RELATIONSHIP_KEY: 'relationship',
  PLAN_ID_KEY: 'planId',
};

export const CONDITION_STATUS = [
  {
    value: STATUS_LIST.ACTIVE.toLowerCase(),
    label: STATUS_LIST.ACTIVE,
    statusIcon: true,
    color: colors.BLUE,
  },
  {
    value: STATUS_LIST.RECURRENCE.toLowerCase(),
    label: STATUS_LIST.RECURRENCE,
    statusIcon: true,
    color: colors.GREY,
  },
  {
    value: STATUS_LIST.RELAPSED.toLowerCase(),
    label: STATUS_LIST.RELAPSED,
    statusIcon: true,
    color: colors.GREY,
  },
  {
    value: STATUS_LIST.INACTIVE.toLowerCase(),
    label: STATUS_LIST.INACTIVE,
    statusIcon: true,
    color: colors.RED,
  },
  {
    value: STATUS_LIST.REMISSION.toLowerCase(),
    label: STATUS_LIST.REMISSION,
    statusIcon: true,
    color: colors.GREY,
  },
  {
    value: STATUS_LIST.RESOLVED.toLowerCase(),
    label: STATUS_LIST.RESOLVED,
    statusIcon: true,
    color: colors.GREEN,
  },
];

export const CONDITION_SEVERITY = [
  {
    value: 'Severe',
    label: 'Severe',
  },
  {
    value: 'Moderate',
    label: 'Moderate',
  },
  {
    value: 'Mild',
    label: 'Mild',
  },
];

export const CONDITION_CATEGORY = [
  {
    value: 'Problem list item',
    label: 'Problem list item',
  },
  {
    value: 'Encounter diagnosis',
    label: 'Encounter diagnosis',
  },
];

export const PROCEDURES_STATUS_LIST = [
  {
    label: STATUS_LIST.PREPARATION,
    value: 'preparation',
    statusIcon: true,
    color: colors.BLUE,
  },
  {
    label: STATUS_LIST.IN_PROGRESS,
    value: 'in-progress',
    statusIcon: true,
    color: colors.BLUE,
  },
  {
    label: STATUS_LIST.NOT_DONE,
    value: 'not-done',
    statusIcon: true,
    color: colors.RED,
  },
  {
    label: STATUS_LIST.ON_HOLD,
    value: 'on-hold',
    statusIcon: true,
    color: colors.GREY,
  },
  {
    label: STATUS_LIST.STOPPED,
    value: 'stopped',
    statusIcon: true,
    color: colors.GREY,
  },
  {
    label: STATUS_LIST.COMPLETED,
    value: 'completed',
    statusIcon: true,
    color: colors.GREEN,
  },
  {
    label: STATUS_LIST.ENTERED_ERROR,
    value: 'entered-in-error',
    statusIcon: true,
    color: colors.GREY,
  },
  {
    label: STATUS_LIST.UNKNOWN,
    value: 'unknown',
    statusIcon: true,
    color: colors.GREY,
  },
];

export const PROCEDURES_CATEGORY = [
  {
    label: 'Psychiatry procedure or service',
    value: 'Psychiatry procedure or service',
  },
  {
    label: 'Counseling',
    value: 'Counselling',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Surgical procedure',
    value: 'Surgical procedure',
  },
  {
    label: 'Diagnostic procedure',
    value: 'Diagnostic procedure',
  },
  {
    label: 'Chiropractic manipulation',
    value: 'Chiropractic manipulation',
  },
  {
    label: 'Social service procedure',
    value: 'Social service procedure',
  },
];

export const PROCEDURE_OUTCOME = [
  {
    label: 'Successful',
    value: 'Successful',
  },
  {
    label: 'Unsuccessful',
    value: 'Unsuccessful',
  },
  {
    label: 'Partially successful',
    value: 'Partially successful',
  },
];

export const MEDICATION_STATUS = [
  {
    value: 0,
    label: STATUS_LIST.ACTIVE,
    value: 'active',
    statusIcon: true,
    color: colors.BLUE,
  },
  {
    value: 1,
    label: STATUS_LIST.ON_HOLD,
    value: 'on-hold',
    statusIcon: true,
    color: colors.GREY,
  },
  {
    value: 2,
    label: STATUS_LIST.CANCELLED,
    value: 'cancelled',
    statusIcon: true,
    color: colors.RED,
  },
  {
    value: 3,
    label: STATUS_LIST.COMPLETED,
    value: 'completed',
    statusIcon: true,
    color: colors.GREEN,
  },
  {
    value: 4,
    label: STATUS_LIST.ENTERED_ERROR,
    value: 'entered-in-error',
    statusIcon: true,
    color: colors.GREY,
  },
  {
    value: 5,
    label: STATUS_LIST.STOPPED,
    value: 'stopped',
    statusIcon: true,
    color: colors.GREY,
  },
  {
    value: 5,
    label: STATUS_LIST.DRAFT,
    value: 'draft',
    statusIcon: true,
    color: colors.ORANGE,
  },
  {
    value: 5,
    label: STATUS_LIST.UNKNOWN,
    value: 'unknown',
    statusIcon: true,
    color: colors.GREY,
  },
];

export const MEDICATION_FREQUENCY = [
  {
    label: 'Once a day',
    value: 1,
  },
  {
    label: 'Twice a day',
    value: 2,
  },
  {
    label: 'Thrice a day',
    value: 3,
  },
  {
    label: 'Four times a day',
    value: 4,
  },
];

export const MEDICATION_TYPE = [
  {
    value: 0,
    label: 'Powder',
    value: 1,
  },
  {
    value: 1,
    label: 'Tablet',
    value: 2,
  },
  {
    value: 2,
    label: 'Capsule',
    value: 3,
  },
];

export const IMMUNIZATION_ROUTES = [
  {
    key: 0,
    label: 'Injection, intradermal',
    value: 'IDINJ',
  },
  {
    key: 1,
    label: 'Injection, intramuscular',
    value: 'IM',
  },
  {
    key: 2,
    label: 'Inhalation, nasal',
    value: 'NASINHLC',
  },
  {
    key: 3,
    label: 'Injection, intravenous',
    value: 'IVINJ',
  },
  {
    key: 4,
    label: 'Swallow, oral',
    value: 'PO',
  },
  {
    key: 5,
    label: 'Injection, subcutaneous',
    value: 'SQ',
  },
  {
    key: 6,
    label: 'Transdermal',
    value: 'TRNSDERM',
  },
];

export const IMMUNIZATION_STATUS = [
  {
    key: 0,
    label: STATUS_LIST.COMPLETED,
    value: 'completed',
    statusIcon: true,
    color: colors.GREEN,
  },
  {
    key: 1,
    label: STATUS_LIST.ENTERED_ERROR,
    value: 'entered-in-error',
    statusIcon: true,
    color: colors.GREY,
  },
  {
    key: 2,
    label: STATUS_LIST.NOT_DONE,
    value: 'not-done',
    statusIcon: true,
    color: colors.RED,
  },
];

export const ALLERGY_STATUS_LIST = [
  {
    label: STATUS_LIST.ACTIVE,
    value: 'active',
    statusIcon: true,
    color: colors.BLUE,
  },
  {
    label: STATUS_LIST.INACTIVE,
    value: 'inactive',
    statusIcon: true,
    color: colors.RED,
  },
  {
    label: STATUS_LIST.RESOLVED,
    value: 'resolved',
    statusIcon: true,
    color: colors.GREEN,
  },
];

export const ALLERGY_CATEGORY_TYPE = [
  {
    label: 'Food',
    value: 'food',
  },
  {
    label: 'Medication',
    value: 'medication',
  },
  {
    label: 'Environment',
    value: 'environment',
  },
  {
    label: 'Biologic',
    value: 'biologic',
  },
];

export const ALLERGY_CRITICALITY = [
  {
    key: 0,
    label: 'Low risk',
    value: 'low',
  },
  {
    key: 1,
    label: 'High risk',
    value: 'high',
  },
  {
    key: 2,
    label: 'Unable to assess risk',
    value: 'unable-to-assess',
  },
];

export function getAllergyCriticalityValue(displayVal) {
  return ALLERGY_CRITICALITY.find((result) => result.label === displayVal)?.value;
}

export const LAB_RESULTS_TEST_RESULTS = [
  {
    label: 'High',
    value: 'High',
  },
  {
    label: 'Normal',
    value: 'Normal',
  },
  {
    label: 'Low',
    value: 'Low',
  },
  {
    label: 'Abnormal',
    value: 'Abnormal',
  },
  {
    label: 'Indeterminate',
    value: 'Indeterminate',
  },
  {
    label: 'Equivocal',
    value: 'Equivocal',
  },
  {
    label: 'Negative',
    value: 'Negative',
  },
  {
    label: 'Not detected',
    value: 'Not detected',
  },
  {
    label: 'Positive',
    value: 'Positive',
  },
  {
    label: 'Detected',
    value: 'Detected',
  },
  {
    label: 'Expected',
    value: 'Expected',
  },
  {
    label: 'Unexpected',
    value: 'Unexpected',
  },
  {
    label: 'Non-reactive',
    value: 'Non-reactive',
  },
  {
    label: 'Reactive',
    value: 'Reactive',
  },
  {
    label: 'Others',
    value: 'Others',
  },
];

export const LAB_RESULTS_STATUS_LIST = [
  {
    label: STATUS_LIST.REGISTERED,
    key: 0,
    value: STATUS_LIST.REGISTERED.toLowerCase(),
    statusIcon: true,
    color: colors.BLUE,
  },
  {
    label: STATUS_LIST.PARTIAL,
    key: 1,
    value: STATUS_LIST.PARTIAL.toLowerCase(),
    statusIcon: true,
    color: colors.ORANGE,
  },
  {
    label: STATUS_LIST.PRELIMINARY,
    key: 2,
    value: STATUS_LIST.PRELIMINARY.toLowerCase(),
    statusIcon: true,
    color: colors.ORANGE,
  },
  {
    label: STATUS_LIST.FINAL,
    key: 3,
    value: STATUS_LIST.FINAL.toLowerCase(),
    statusIcon: true,
    color: colors.GREEN,
  },
  {
    label: STATUS_LIST.AMMENDED,
    key: 4,
    value: STATUS_LIST.AMMENDED.toLowerCase(),
    statusIcon: true,
    color: colors.GREEN,
  },
  {
    label: STATUS_LIST.CORRECTED,
    key: 5,
    value: STATUS_LIST.CORRECTED.toLowerCase(),
    statusIcon: true,
    color: colors.BLUE,
  },
  {
    label: STATUS_LIST.APPENDED,
    key: 6,
    value: STATUS_LIST.APPENDED.toLowerCase(),
    statusIcon: true,
    color: colors.GREEN,
  },
  {
    label: STATUS_LIST.CANCELLED,
    key: 7,
    value: STATUS_LIST.CANCELLED.toLowerCase(),
    statusIcon: true,
    color: colors.RED,
  },
  {
    label: STATUS_LIST.ENTERED_ERROR,
    key: 8,
    value: 'entered-in-error',
    statusIcon: true,
    color: colors.GREY,
  },
  {
    label: STATUS_LIST.UNKNOWN,
    key: 9,
    value: STATUS_LIST.UNKNOWN.toLowerCase(),
    statusIcon: true,
    color: colors.GREY,
  },
];

export const LAB_RESULTS_CATEGORY_CODES = [
  { key: 0, label: 'Audiology', value: 'AU' },
  { key: 1, label: 'Blood Gases', value: 'BG' },
  { key: 2, label: 'Blood Bank', value: 'BLB' },
  { key: 3, label: 'Cytogenetics', value: 'CG' },
  { key: 4, label: 'Chemistry', value: 'CH' },
  { key: 5, label: 'Cytopathology', value: 'CP' },
  { key: 6, label: 'CAT Scan', value: 'CT' },
  { key: 7, label: 'Cardiac Catheterization', value: 'CTH' },
  { key: 8, label: 'Cardiac Ultrasound', value: 'CUS' },
  {
    key: 9,
    label: 'Electrocardiac (e.g., EKG, EEC, Holter)',
    value: 'EC',
  },
  { key: 10, label: 'Electroneuro (EEG, EMG, EP, PSG)', value: 'EN' },
  { key: 11, label: 'Genetics', value: 'GE' },
  { key: 12, label: 'Hematology', value: 'HM' },
  { key: 13, label: 'Bedside ICU Monitoring', value: 'ICU' },
  { key: 14, label: 'Immunology', value: 'IMM' },
  { key: 15, label: 'Laboratory', value: 'LAB' },
  { key: 16, label: 'Microbiology', value: 'MB' },
  { key: 17, label: 'Mycobacteriology', value: 'MCB' },
  { key: 18, label: 'Mycology', value: 'MYC' },
  { key: 19, label: 'Nuclear Magnetic Resonance', value: 'NMR' },
  { key: 20, label: 'Nuclear Medicine Scan', value: 'NMS' },
  { key: 21, label: 'Nursing Service Measures', value: 'NRS' },
  { key: 22, label: 'Outside Lab', value: 'OSL' },
  { key: 23, label: 'Occupational Therapy', value: 'OT' },
  { key: 24, label: 'Other', value: 'OTH' },
  { key: 25, label: 'OB Ultrasound', value: 'OUS' },
  { key: 26, label: 'Pulmonary Function', value: 'PF' },
  { key: 27, label: 'Pharmacy', value: 'PHR' },
  { key: 28, label: 'Physician (Hx. Dx, admission note, etc.)', value: 'PHY' },
  { key: 29, label: 'Physical Therapy', value: 'PT' },
  { key: 30, label: 'Radiology', value: 'RAD' },
  { key: 31, label: 'Respiratory Care (therapy)', value: 'RC' },
  { key: 32, label: 'Radiation Therapy', value: 'RT' },
  { key: 33, label: 'Radiology Ultrasound', value: 'RUS' },
  { key: 34, label: 'Radiograph', value: 'RX' },
  { key: 35, label: 'Surgical Pathology', value: 'SP' },
  { key: 36, label: 'Serology', value: 'SR' },
  { key: 37, label: 'Toxicology', value: 'TX' },
  { key: 38, label: 'Virology', value: 'VR' },
  { key: 39, label: 'Vascular Ultrasound', value: 'VUS' },
  { key: 40, label: 'Cineradiograph', value: 'XRC' },
];

export const COVERAGE_STATUS_LIST = [
  {
    label: STATUS_LIST.ACTIVE,
    value: 'ACTIVE',
    statusIcon: true,
    color: colors.BLUE,
  },
  {
    label: STATUS_LIST.DRAFT,
    value: 'DRAFT',
    statusIcon: true,
    color: colors.ORANGE,
  },
  {
    label: STATUS_LIST.CANCELLED,
    value: 'CANCELLED',
    statusIcon: true,
    color: colors.RED,
  },
  {
    label: STATUS_LIST.ENTERED_ERROR,
    value: 'ENTERED-IN-ERROR',
    statusIcon: true,
    color: colors.GREY,
  },
];

export const RELATIONSHIP_LIST = {
  CHILD: 'Child',
  PARENT: 'Parent',
  SPOUSE: 'Spouse',
  COMMON: 'Common',
  OTHER: 'Other',
  SELF: 'Self',
  INJURED: 'Injured',
};

export const COVERAGE_RELATIONSHIP_LIST = [
  {
    label: RELATIONSHIP_LIST.CHILD,
    value: RELATIONSHIP_LIST.CHILD,
  },
  {
    label: RELATIONSHIP_LIST.PARENT,
    value: RELATIONSHIP_LIST.PARENT,
  },
  {
    label: RELATIONSHIP_LIST.COMMON,
    value: RELATIONSHIP_LIST.COMMON,
  },
  {
    label: RELATIONSHIP_LIST.OTHER,
    value: RELATIONSHIP_LIST.OTHER,
  },
  {
    label: RELATIONSHIP_LIST.SELF,
    value: RELATIONSHIP_LIST.SELF,
  },
  {
    label: RELATIONSHIP_LIST.INJURED,
    value: RELATIONSHIP_LIST.INJURED,
  },
];

export const getAllergyCriticalityLabel = (value) => {
  if (!value) {
    return null;
  }
  const criticality = ALLERGY_CRITICALITY.find(
    (val) => val.value.toLowerCase() === value.toLowerCase(),
  );
  if (criticality && criticality.label) {
    return criticality.label;
  }
  return value;
};

export const findImmunizationRouteLabel = (value) => {
  if (!value) {
    return null;
  }
  const route = IMMUNIZATION_ROUTES.find((val) => val.value.toLowerCase() === value.toLowerCase());
  if (route && route.label) {
    return route.label;
  }
  return value;
};

export const findConditionCategoryLabel = (value) => {
  if (!value) {
    return null;
  }
  const category = CONDITION_CATEGORY.find(
    (val) => val.value.toLowerCase() === value.toLowerCase(),
  );
  if (category && category.label) {
    return category.label;
  }
  return value;
};

export const getMedicationFrequencyLabel = (val) => {
  if (!val) {
    return '';
  }
  const obj = MEDICATION_FREQUENCY.find((item) => item.value === val);
  if (obj && obj.value) {
    return obj.label;
  }
  return val;
};

// export const getFrequencyLabel = (val) => {
//   const obj = MEDICATION_FREQUENCY.find((item) => `${item.value}` == val);
//   if (obj && obj.value) {
//     return obj.label;
//   }
//   return '';
// };
