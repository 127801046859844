import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import DarkTriangle from '../../../assets/images/darkTriangle.svg';
import LightTriangle from '../../../assets/images/lightTriangle.svg';
import { EMPTY_FUNCTION } from '../../../utils/constants';
import Button from '../../atoms/Button';

const styles = (theme) => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px',
    gap: '16px',
    maxWidth: '349px',
    borderRadius: '20px',
    boxSizing: 'border-box',
    background: '#FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.12)',
  },
  card1: {
    paddingLeft: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '158px',
    width: '324px',
    position: 'relative',
    ...theme.customComponent.card,
    border: ' 0px white solid',
    boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 15%)',
  },
  data: {
    lineHeight: '20px',
    fontSize: '14px',
    color: '#101828',
    fontFamily: 'Inter',
    fontWeight: 400,
    letterSpacing: '0px',
  },
  btn: {
    borderRadius: '40px',
    padding: '8px 24px',
    width: 'fit-content',
    height: '32px',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Inter',
    marginTop: '16px',
    border: '1px solid #E4E7EC',
    '& .MuiButton-label': {
      height: 32,
      fontWeight: 500,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    // height: 160,
  },
  img: {
    width: '74px',
    height: '74px',
    position: 'absolute',
  },
  imageLeft: {
    width: '24px',
    height: '24px',
    marginRight: 8,
    borderRadius: 5,
  },
  text1: {
    marginTop: 24,
    width: 215,
    height: 40,
    fontSize: 14,
    fontWeight: 600,
    opacity: 0.75,
  },
  arrow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 310,
  },
  imgAndText: {
    display: 'flex',
    flexDirection: 'column',
  },
  container1: {
    display: 'flex',
  },
  imageContainer: {
    position: 'absolute',
    left: 183,
    top: 30,
  },
  rightImage: {
    position: 'absolute',
    borderRadius: 5,
    width: 157,
    height: 128,
  },
  rightImage1: {
    position: 'relative',
    left: 31,
    top: 7,
    borderRadius: 5,
    width: 126,
    height: 121,
  },
  imgAndValue: {
    display: 'flex',
    alignItems: 'center',
  },
  awardPoints: {
    fontSize: 20,
    fontWeight: 600,
  },
  imgWrapper: {
    background: '#E6F6FC',
    minWidth: '88px',
    height: '88px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
});

const defaultProps = {
  onUploadBtnClick: EMPTY_FUNCTION,
};

const propTypes = {};

const NoDataCards = (props) => {
  const {
    classes,
    rootClass,
    title,
    background,
    onClick,
    src,
    text,
    btnTitle,
    iconClass,
    variant,
    imgClass,
    onMouseEnter,
    textClass,
    buttonClass,
    ...rest
  } = props;

  return btnTitle === 'Award Points' ? (
    <div className={classes.card1} onMouseEnter={onMouseEnter}>
      <div style={{ width: 210, paddingTop: 16 }}>
        <div className={classes.arrow}>
          <Typography component="div">{btnTitle}</Typography>
          <KeyboardArrowRightIcon className={classes.rightArrow} />
        </div>
        <div className={classes.container1}>
          <div className={classes.imgAndText}>
            <div className={classes.imgAndValue}>
              <img className={classes.imageLeft} src={src} />
              <span className={classes.awardPoints}>1000</span>
            </div>
            <span className={classes.text1}>{text}</span>
          </div>
        </div>
      </div>
      <div className={classes.imageContainer}>
        <img className={classes.rightImage} src={LightTriangle} />
        <img className={classes.rightImage1} src={DarkTriangle} />
      </div>
    </div>
  ) : (
    <div className={classes.card} onMouseEnter={onMouseEnter}>
      <span className={classes.imgWrapper}>
        <img className={clsx(imgClass, classes.img)} src={src} />
      </span>
      <div className={classes.container}>
        <span className={clsx(textClass, classes.data)}>{text}</span>
        <Button
          className={clsx(buttonClass, classes.btn)}
          color="secondary"
          variant={variant || 'outlined'}
          title={btnTitle}
          onClick={onClick}
        />
      </div>
    </div>
  );
};
NoDataCards.defaultProps = defaultProps;
NoDataCards.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(NoDataCards);
