import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import Button from '../../atoms/Button';
import { OK } from '../../../utils/constants';

const style = (theme) => ({
  root: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '284px',
    height: '200px',
  },
  title: {
    padding: '39px 31px 20px 31px',
    fontSize: '15px',
    fontWeight: 300,
    lineHeight: 1.33,
    letterSpacing: '1.06px',
    color: '#737373',
  },
  contentRoot: {
    padding: '8px 31px',
  },
  body: {
    opacity: 0.36,
    fontSize: '13px',
    lineHeight: 1.38,
    letterSpacing: '0.62px',
    fontWeight: 300,
  },
  button: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
});

const ProfileScreenAlert = (props) => {
  const { classes, open, onClose, title, body } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.root,
      }}
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography>
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.contentRoot }}>
        <DialogContentText classes={{ root: classes.body }}>{body}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          title={OK}
          variant={'contained'}
          size="small"
          rootClass={classes.button}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(style, { withTheme: true })(ProfileScreenAlert);
