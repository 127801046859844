import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { detectDeviceName } from '../utils/helper';
import { DEVICE_TYPE } from '../features/landingFlow/constants';

const DeeplinkRedirect = () => {
  const params = useParams();
  useEffect(() => {
    try {
      window.location.href = `mpowered://dropOff?tempUserId=${params.payload}`;
    } catch (error) {
      console.log('Assuming app doesnt exist and user on mobile');
      const deviceType = detectDeviceName();
      if (deviceType === DEVICE_TYPE.ANDROID) {
        window.open(process.env.PLAY_STORE_LINK);
      } else if (deviceType === DEVICE_TYPE.IOS) {
        window.open(process.env.APP_STORE_LINK);
      }
    }
  }, []);

  return <div>Redirecting...</div>;
};

export default DeeplinkRedirect;
