import React, { Fragment } from 'react';
import { Modal, Grid, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import Button from '../../atoms/Button';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { IMAGE_ALTERNATIVE_TXT } from '../../../utils/constants';

const styles = (theme) => ({
  root: {
    position: 'absolute',
    //top: 'calc(100%*242/764)',
    //left: 'calc(100%*40/375)',
    //right: 'calc(100%*40/375)',
    width: 'calc(100vw*284/375)',
    backgroundColor: '#FFFFFF',
    padding: '40px 25px',
    borderRadius: '9px',
    maxWidth: '400px',
  },
  cancelButton: {
    width: '100%',
  },
  confirmButton: {
    width: '100%',
  },
  helperImage: {
    height: 60,
  },
  closeIcon: {
    color: '#484a54',
    cursor: 'pointer',
    fontSize: 24,
    fontWeight: 300,
    height: 16,
    width: 24,
  },
  listContainer: {
    margin: '10px',
    paddingLeft: '9px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  list: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  mList: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 340,
    marginTop: 16,
    margin: 'auto',
  },
  giftDescription: {
    ...theme.typography.body2,
  },
  buttonTextStyle: {
    borderRadius: 6,
  },
  heading1Partner: {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
    // height: 24,
    marginTop: 20,
    textAlign: 'center',
  },
  headingPartner: {
    color: '#4B4B4B',
    fontFamily: 'Inter !important',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    marginBottom: 32,
    marginTop: 32,
    paddingLeft: 4,
    lineHeight: '24px',
    textAlign: 'center',
    height: 48,
  },
  closeIconContainer: {
    height: 16,
  },
});

const defaultProps = {
  isOpen: false,
};

const Dialog = (props) => {
  const {
    classes,
    heading,
    subheading,
    buttonText,
    cancelButtonText,
    helperImage,
    messageList,
    isOpen,
    onClose,
    onConfirm,
    onCancel,
    btnColor,
    btnSize,
    closeIcon,
    subheadingList,
    listItems,
    spacing,
    message,
    title,
    confirmBtnDisabled,
    cancelBtnDisabled,
    headerAlign,
    giftDescription,
    cancelButtonClass,
    confirmButtonClass,
    removePartnerClass,
    cancelVariant,
    removeVariant,
    ...rest
  } = props;

  const handleClose = (event) => {
    event.stopPropagation();
    onClose(event);
  };

  const handleConfirm = (event) => {
    event.stopPropagation();
    onConfirm(event);
  };

  const handleCancel = (event) => {
    event.stopPropagation();
    onCancel(event);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={handleClose}
      className={classes.container}
    >
      <div className={removePartnerClass ? removePartnerClass : classes.root}>
        <Grid container spacing={!spacing ? 2 : spacing}>
          {!closeIcon ? (
            <Grid item xs={12} align={headerAlign || 'left'}>
              <Typography variant={heading ? 'h2' : 'h4'}>{heading || title}</Typography>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ padding: 0, height: 60 }}>
              <Grid
                container
                direction="row"
                justify={removePartnerClass ? 'flex-end' : 'space-between'}
                alignItems="center"
              >
                {!removePartnerClass && (
                  <Grid item>
                    <Typography variant={heading ? 'h2' : 'h4'}>{heading || title}</Typography>
                  </Grid>
                )}
                <Grid item className={classes.closeIconContainer}>
                  <CloseIcon className={classes.closeIcon} onClick={handleClose} />
                </Grid>
              </Grid>
              {removePartnerClass && (
                <Grid item>
                  <div className={classes.heading1Partner}>{heading}</div>
                </Grid>
              )}
            </Grid>
          )}
          {subheading && (
            <Grid item xs={12} style={{ padding: 0 }}>
              {removePartnerClass ? (
                <div className={classes.headingPartner}>{subheading}</div>
              ) : (
                <Typography style={{ paddingLeft: 16 }} variant="body2">
                  {subheading}
                </Typography>
              )}
            </Grid>
          )}
          {giftDescription && (
            <Grid item className={classes.giftDescription} xs={12}>
              <span dangerouslySetInnerHTML={{ __html: giftDescription }} />
            </Grid>
          )}
          {subheadingList && (
            <Grid item xs={12}>
              {subheadingList.map((text, index) => (
                <Typography key={index} variant="body2" className={classes.list}>
                  {text}
                </Typography>
              ))}
            </Grid>
          )}
          {listItems && (
            <Grid item xs={12}>
              <ul className={classes.listContainer}>
                {listItems.map((text, index) => (
                  <li key={index} variant="body2" className={classes.list}>
                    {text}
                  </li>
                ))}
              </ul>
            </Grid>
          )}

          {helperImage ? (
            <Grid item xs={12} align="center">
              <img
                className={classes.helperImage}
                alt={IMAGE_ALTERNATIVE_TXT.HELPER_IMAGE}
                src={helperImage}
              />
            </Grid>
          ) : null}
          {messageList && (
            <Grid item align="center" xs={12}>
              {messageList.map((text, index) => (
                <Typography key={index} variant="body1" className={classes.mList}>
                  {text}
                </Typography>
              ))}
            </Grid>
          )}
          {message && (
            <Grid item align="center" xs={12}>
              <Typography variant="h2">{message}</Typography>
            </Grid>
          )}
          {!cancelButtonText && buttonText && (
            <Grid item xs={12} align="center" className={classes.buttonContainer}>
              <Button
                title={buttonText}
                color="primary"
                variant="contained"
                size={btnSize || 'small'}
                onClick={handleConfirm}
                disabled={confirmBtnDisabled}
              />
            </Grid>
          )}
          {cancelButtonText && buttonText && (
            <Grid container spacing={2} align="center" className={classes.buttonsContainer}>
              <Grid item xs={6} align="center">
                <Button
                  title={cancelButtonText}
                  color={btnColor || 'primary'}
                  variant={cancelVariant || 'outlined'}
                  size="small"
                  className={clsx(
                    cancelButtonClass ? cancelButtonClass : classes.cancelButton,
                    classes.buttonTextStyle,
                  )}
                  onClick={handleCancel}
                  disabled={cancelBtnDisabled}
                />
              </Grid>
              <Grid item xs={6} align="center">
                <Button
                  title={buttonText}
                  color={btnColor || 'primary'}
                  variant={removeVariant || 'contained'}
                  size="small"
                  className={clsx(confirmButtonClass ? confirmButtonClass : classes.confirmButton)}
                  onClick={handleConfirm}
                  disabled={confirmBtnDisabled}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </Modal>
  );
};

export default withStyles(styles, { withTheme: true })(Dialog);
Dialog.defaultProps = defaultProps;
