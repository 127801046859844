import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Container, Grid, Typography } from '@mui/material';
import AwardPointService from '../../service/AwardPoints';
import SnackbarToast from '../../components/organisms/SnackbarToast';
import Loader from '../../components/atoms/Loader';
import {
  // AWARDPOINTS,
  BREADCURMB_PROPS_TRANSACTION_HISTORY,
  LOADER_DEFAULT_TXT,
  SNACKBAR_TYPE,
} from '../../utils/constants';
import AwardPointDescription from '../../components/molecules/AwardPointDescription';
import BreadCrumb from '../../components/molecules/BreadCrumb';
import { useStyles } from './style';
import EarnPointsCard from '../../components/organisms/EarnPointsCard';
import TotalAwardPointsCard from '../../components/molecules/TotalAwardPointsCard';
import { mockTemplates } from '../../features/dynamicRecords/mockTemplate';
import { useSelector } from 'react-redux';

const TransactionDetails = ({ onFooterItemClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [isLoadingAwardPoints, setIsLoadingAwardPoints] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [snackbarType, setSnackbarType] = useState();
  const [pointsHistory, setPointsHistory] = useState([]);
  const [totalAwardPoints, setTotalAwardPoints] = useState();
  const classes = useStyles();

  const { templates } = useSelector((state) => state.templates);
  const { selectedLanguage } = useSelector((state) => state.app);

  const AWARDPOINTS =
    // templates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.AWARDPOINTS||
    mockTemplates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.AWARDPOINTS;

  useEffect(() => {
    fetchTransactionHistory();
    if (onFooterItemClick) {
      onFooterItemClick(4);
    }
  }, []);

  const fetchTransactionHistory = () => {
    setIsLoading(true);
    setIsLoadingHistory(true);
    setIsLoadingAwardPoints(true);
    AwardPointService.getHistory()
      .then((response) => {
        setPointsHistory(response);
        setIsLoadingHistory(false);
      })
      .catch(() => {
        setIsLoadingHistory(false);
        setSnackbarType(SNACKBAR_TYPE.ERROR);
        setSnackbarMessage('Failed to fetch award points');
      });
    AwardPointService.getAwardPointBasicDetails()
      .then((res) => {
        setIsLoadingAwardPoints(false);
        setTotalAwardPoints(res?.totalPoints);
      })
      .catch((e) => {
        setIsLoadingAwardPoints(false);
        setSnackbarType(SNACKBAR_TYPE.ERROR);
        setSnackbarMessage('Failed to fetch award points');
      });
    if (!isLoadingHistory && !isLoadingAwardPoints) {
      setIsLoading(false);
    }
  };

  return (
    <Container className={classes.root}>
      {isLoading && <Loader overlay text={LOADER_DEFAULT_TXT} />}
      <BreadCrumb breadCrumbProps={BREADCURMB_PROPS_TRANSACTION_HISTORY}></BreadCrumb>
      <Typography className={classes.heading}>{AWARDPOINTS.TRANSACTION_HISTORY}</Typography>
      <Grid container>
        <Grid item xs={12} md={6} xl={4}>
          <TotalAwardPointsCard totalAwardPoints={totalAwardPoints} />
        </Grid>
        <EarnPointsCard />
      </Grid>
      {pointsHistory && pointsHistory.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Card className={classes.cardRoot}>
              <CardContent className={classes.cardContent}>
                {pointsHistory?.map((awardPoint, idx) => (
                  <AwardPointDescription awardPoint={awardPoint} key={idx} />
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <SnackbarToast
        message={snackbarMessage}
        open={!!snackbarMessage}
        alertStyle={{ backgroundColor: 'black', color: 'white' }}
        onClose={() => {
          setSnackbarMessage(false);
        }}
        type={snackbarType}
      />
    </Container>
  );
};

TransactionDetails.propTypes = { onFooterItemClick: PropTypes.func };
export default TransactionDetails;
