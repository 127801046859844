import Paper from '@mui/material/Paper';
import { withStyles } from '@mui/styles';
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import MedicationIcon from '../../assets/images/medications.png';
import Loader from '../../components/atoms/Loader';
import NotFound from '../../components/atoms/NotFound';
import SearchBar from '../../components/atoms/Search';
import Button from '../../components/inputs/Button';
import BreadCrumb from '../../components/molecules/BreadCrumb';
import FilterDropDown from '../../components/molecules/FilterDropDown';
import SubTitleCard from '../../components/molecules/PartnerSubTitleCard';
import ReusableTable from '../../components/molecules/ReusableTable';
import SnackbarToast from '../../components/organisms/SnackbarToast';
import PopUpModal from '../../components/PopUpModal';
import withRouter from '../../components/withRoute';
import useClickFooter from '../../hooks/useClickFooter';
import useWidth from '../../hooks/useWidth';
import CirclesService from '../../service/CirclesService';
import MedicationsService from '../../service/MedicationsService';
import RecentService from '../../service/RecentServices';
import {
  ADD_DATA,
  CIRCLES_SERVICE_TYPE,
  dateFilterMenuItems,
  DEFAULT_NULL_REPLACEMENT,
  D_SOURCE,
  medicationsStatusFilterMenuItems,
  MEDICATION_DETAIL_TEXTS,
  MEDICATION_STATUS_LIST,
  NUMBER_OF_RECORDS_PER_PAGE,
  SELF_REPORTING_DATA_SOURCE_LABELS,
  SELF_REPORTING_TEXTS,
  SERVICE_TYPE,
  SNACKBAR_TYPE,
} from '../../utils/constants';
import {
  calculateDateFilterTimeStamp,
  checkOneTimeUser,
  getAnalyticsEventsForRouteName,
  getPartnerName,
  getStatusFilterParamList,
  handleAddSelfReportedButtonalyticalEvents,
  medicationArrayConverter,
  medicationDetailsFormatter,
} from '../../utils/helper';
import {
  medicationDeviceTableHeading,
  medicationTableHeading,
} from '../../utils/labels/tableHeadings';
import { SELF_REPORTED_VALUE_KEYS } from '../../utils/selfReportingUtils';
import { useInitialLoading } from '../../hooks/useInitialLoading';
import { ROUTE_NAMES } from '../../constants/routeNames';
import useAnalyticsLogger from '../../hooks/useAnalyticsLogger';

const { MARK_RESTRICTED_DESC } = SELF_REPORTING_TEXTS;

const styles = (theme) => ({
  container: {
    background: '#F5F5F5',
    position: 'relative',
    minHeight: '100%',
    padding: '32px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '16px 16px',
    },
  },
  filtersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  filterDropDownContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.75rem',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '8px 0px',
    },
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    height: 40,
    marginTop: 24,
    marginBottom: 40,
  },
  tableWrapper: {
    marginTop: 16,
    // width: 948,
    position: 'relative',
  },
  dosageWrapper: {
    borderRadius: '8px',
    padding: '0px 24px',
    flex: 1,
  },
  doseHeading: {
    lineHeight: '24px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
  },
  flexBox: {
    display: 'flex',
    flex: 1,
  },
  dosageFlexContent: {
    flex: '33%',
  },
  column_name: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    width: '100%',
    height: '20px',
    color: '#828282',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    order: 0,
    flex: 'none',
    flexGrow: 0,
    margin: '4px 0px',
  },
  column_value: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    margin: '4px 0px',
  },
  dosageNotes: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    width: '150px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    flex: 'none',
    order: 1,
    flexGrow: 0,
    margin: '4px 0px',
  },
  searchBar: {
    backgroundColor: 'white',
    // width: 160,
    borderRadius: 4,
    border: '1px solid #D0D0D0',
    height: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  searchInput: {
    border: 'none',
    opacity: 1,
    fontWeight: 400,
    fontFamily: 'Inter',
    height: '2.5rem',
  },
  filterDropDownWrapper: {
    flexWrap: 'wrap',
    display: 'flex',
    outline: 'none',
  },
  addDataBtn: {
    textTransform: 'none',
    borderRadius: 40,
    border: '1px solid #A0A4A6',
    Padding: '8px, 24px, 8px, 24px',
    color: '#036FCB',
    fontWeight: 500,
    fontSize: 16,
    fontFamily: 'Inter',
    width: 120,
    height: 40,
    backgroundColor: '#fff',
  },
  dropDownWrapper: {
    width: 170,
  },
  dropDownDateWrapper: {
    width: 190,
    height: 40,
    '&:MuiInput-root': {
      height: 40,
    },
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
  dropDownWrapper: {
    width: 180,
    height: 40,
    '&:MuiInput-root': {
      height: 40,
    },
    paddingRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: 0,
    },
  },
});

export const getConsentUserDetails = () => {
  let consentUserName = '';
  const consentUser = JSON.parse(localStorage.getItem('consentUserDetails'));
  if (consentUser) {
    consentUserName = (consentUser.name && consentUser.name.Name) || DEFAULT_NULL_REPLACEMENT;
  }
  return consentUserName;
};

const Medications = (props) => {
  const { classes, onFooterItemClick } = props;
  const { partner, grantorId, granteeId } = useParams();
  const screenWidth = useWidth();
  const isLarge = screenWidth === 'lg' || screenWidth === 'xl';
  const title = 'Medications';

  const location = useLocation();
  const isCirclesOrRecords = location.pathname.includes('circles');
  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.MEDICATIONS);
  const { logAnalyticsEvent } = useAnalyticsLogger();
  useClickFooter(onFooterItemClick);
  let consentUserName = '';
  let consentRouteFrom = '';
  if (location.pathname.includes('/circles/individual/medication')) {
    consentRouteFrom = 'circleConsent';
    consentUserName = getConsentUserDetails();
  } else if (location.pathname.includes('/records/individual/medication')) {
    consentRouteFrom = 'serviceConsent';
    consentUserName = getConsentUserDetails();
  }
  const isOneTimeUser = checkOneTimeUser();

  let localStorageData = localStorage.getItem('partnerInfo');
  localStorageData = (localStorageData && JSON.parse(localStorageData)) || {};

  const {
    circleId = localStorageData.circleId,
    partnerName = getPartnerName(partner),
    partnerIconUrl = localStorageData.partnerIconUrl,
    partnerSourceUrl = localStorageData.partnerSourceUrl,
    createdObj,
  } = (props.location && props.location.state) || {};

  const getTableHeading = useMemo(() => {
    const tableHeading = medicationTableHeading.map((obj) =>
      obj.label === D_SOURCE ? { ...obj, hide: partner ? true : false } : obj,
    );
    if (isOneTimeUser && !isLarge) {
      return medicationDeviceTableHeading;
    }
    return tableHeading;
  }, [isLarge]);

  const breadCrumbProps = isOneTimeUser
    ? [{ label: 'Records', route: '/oneTimeDashboard' }, { label: title }]
    : !partner && consentRouteFrom === ''
    ? [{ label: 'Records', route: '/records' }, { label: title }]
    : !partner && consentRouteFrom === 'serviceConsent'
    ? [
        { label: 'Records', route: '/records' },
        { label: 'Consents', route: '/records/individual' },
        { label: consentUserName, route: '/records/individual' },
        { label: title },
      ]
    : !partner && consentRouteFrom === 'circleConsent'
    ? [
        { label: 'Circles', route: '/circles' },
        { label: 'Consents', route: '/circles/individual' },
        { label: consentUserName, route: '/circles/individual' },
        { label: title },
      ]
    : [
        { label: 'Circles', route: '/circles' },
        {
          label: partnerName,
          route: `/circles/${partner}/${circleId}`,
          state: { partnerName, partnerIconUrl, partnerSourceUrl },
        },
        { label: title },
      ];

  const [isLoading, setIsLoading] = useState(true);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState(MEDICATION_DETAIL_TEXTS.LOADING_MEDICATIONS);
  const [allMedicationsData, setAllMedicationsData] = useState([]);

  ////////////////////////////////////////////////////////////
  const [modalData, setModalData] = useState([]);
  const [editData, setEditData] = useState({});
  const [modalTitle, setModalTitle] = useState('');
  const [showEditInModal, setShowEditInModal] = useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);

  const [markAsRestrictedInModal, setMarkAsRestrictedInModal] = useState(false);
  const [historicalRecordsParamsObj, setHistoricalRecordsParamsObj] = useState({});
  const [currentObj, setCurrentObj] = useState({});
  const [documents, setDocuments] = useState([]);

  const [hasInfiniteScroll, setHasInfiniteScroll] = useState(true);
  const [initialRender, setInitialRender] = useInitialLoading();
  const pageNumber = useRef(1);
  const changeFiltersValue = useRef(true);
  const timeFilter = useRef('all');
  const statusFilter = useRef('all');
  const searchValue = useRef('');

  const recordsPerPage = NUMBER_OF_RECORDS_PER_PAGE;
  const [totalStatments, setTotalStatements] = useState(0);
  const [totalRequests, setTotalRequests] = useState(0);
  const [dosageInfo, setDosageInfo] = useState([]);

  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [snackbarType, setSnackbarType] = useState(SNACKBAR_TYPE.ERROR);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const fetchAllRecords = async (offset) => {
    const checkIfAhk = partner && partner.toLowerCase().includes('apple');
    const date_filter_ts = calculateDateFilterTimeStamp(timeFilter.current);
    const status_filter = getStatusFilterParamList(
      statusFilter.current,
      medicationsStatusFilterMenuItems,
      MEDICATION_STATUS_LIST,
    );
    const search_parameter = searchValue.current;
    setIsLoading(true);
    if (consentRouteFrom !== '') {
      const params = {
        initiatorId: granteeId,
        grantorId: grantorId,
        count: recordsPerPage.toString(),
        offset: offset,
        date_filter: date_filter_ts,
        status_filter: status_filter,
        search_parameter,
      };
      const fetchAllMedicationStatement =
        MedicationsService.fetchAllMedicationStatementByConsent(params);
      const fetchAllMedications = MedicationsService.fetchAllMedicationRequestsByConsent(params);
      const results = await Promise.all([fetchAllMedicationStatement, fetchAllMedications]);
      const { medicationStatementList, count: statementsCount } = results[0];
      setTotalStatements(statementsCount);
      const { medicationRequestList, count: requestsCount } = results[1];
      setTotalRequests(requestsCount);
      return {
        res: [...JSON.parse(medicationRequestList), ...JSON.parse(medicationStatementList)],
        count: requestsCount + statementsCount,
      };
    }
    const params = {
      sourceUrl: partner ? partnerSourceUrl : '',
      count: recordsPerPage.toString(),
      offset: offset,
      date_filter: date_filter_ts,
      status_filter: status_filter,
      search_parameter,
    };
    if (!partner) {
      // in records page, we need make 2 api calls
      if (pageNumber.current === 1) {
        // first fetch
        const { medicationRequestList, count: requestsCount } =
          await MedicationsService.fetchAllMedications(params);
        setTotalRequests(requestsCount);
        const { medicationStatementList, count: statementsCount } =
          await MedicationsService.fetchAllMedicationsStatements(params);
        setTotalStatements(statementsCount);
        return {
          res: [...JSON.parse(medicationRequestList), ...JSON.parse(medicationStatementList)],
          count: requestsCount + statementsCount,
        };
      } else {
        let requestsList = [],
          statementsList = [],
          totalCount;
        if (totalRequests > offset) {
          const { medicationRequestList, count: requestsCount } =
            await MedicationsService.fetchAllMedications(params);
          requestsList = JSON.parse(medicationRequestList);
          totalCount = requestsCount;
          setTotalRequests(requestsCount);
        }
        if (totalStatments > offset) {
          const { medicationStatementList, count: statementsCount } =
            await MedicationsService.fetchAllMedicationsStatements(params);
          statementsList = JSON.parse(medicationStatementList);
          totalCount = totalCount + statementsCount;
          setTotalStatements(statementsCount);
        }
        setTotalStatements(statementsList.length);
        return { res: [...requestsList, ...statementsList], count: totalCount };
      }
    } else if (partner && checkIfAhk) {
      const { medicationStatementList, count } =
        await MedicationsService.fetchAllMedicationsStatements(params);
      return { res: JSON.parse(medicationStatementList), count };
    } else {
      const { medicationRequestList, count } = await MedicationsService.fetchAllMedications(params);
      return { res: JSON.parse(medicationRequestList), count };
    }
  };

  const updateUsedService = () => {
    try {
      if (partner) {
        const args = {
          circleId: Number(circleId),
          serviceType: CIRCLES_SERVICE_TYPE.MEDICATIONS,
        };
        CirclesService.updateRecentlyUsedServices(args);
      } else {
        RecentService.updateUsedService(SERVICE_TYPE.MEDICATIONS);
      }
    } catch (error) {
      console.error('Error in updating used Service: ', error);
    }
  };

  const updateUi = async (offset, checkForNewlyCreatedRecord = false) => {
    try {
      setIsLoading(true);
      const { res } = await fetchAllRecords(offset);

      let parsedResult = [];
      res.forEach((obj) => parsedResult.push(obj));

      if (checkForNewlyCreatedRecord) {
        if (createdObj && createdObj.id) {
          // check if createdObj exists in all records and add if not
          const ifNewlyCreatedObjExists = parsedResult.find((obj) => obj.id === createdObj.id);
          if (!ifNewlyCreatedObjExists) {
            const originalResLength = parsedResult.length;
            const poppedAry =
              originalResLength > 0 ? parsedResult.splice(0, parsedResult.length - 1) : [];
            parsedResult = [createdObj, ...poppedAry];
          }
        }
      }
      const data = medicationArrayConverter(parsedResult);

      if (pageNumber.current !== 1 && data?.length === 0) {
        setHasInfiniteScroll(false);
        return;
      } else if (data?.length === 0 && pageNumber.current === 1) {
        setHasInfiniteScroll(false);
        setAllMedicationsData([]);
        return;
      } else if (changeFiltersValue.current && data?.length && pageNumber.current === 1) {
        changeFiltersValue.current = false;
        setAllMedicationsData(data);
        return;
      } else if (!changeFiltersValue.current && data?.length && pageNumber.current !== 1) {
        setAllMedicationsData((prevData) => [...prevData, ...data]);
        return;
      }
    } catch (error) {
      console.warn('Error in fetching list: ', error);
      setHasInfiniteScroll(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateUi(0);
  }, []);

  useEffect(() => {
    if (props.location.state) {
      setLoaderText(props.location.state.loaderText);
      setSnackbarMessage(props.location.state.message);
      setShowSnackbar(props.location.state.isOpen);
    }
    isCirclesOrRecords
      ? logAnalyticsEvent(analyticsEvents.CIR_MEDICATIONS_VISITED)
      : logAnalyticsEvent(analyticsEvents.REC_MEDICATIONS_VISITED);
  }, []);

  useLayoutEffect(() => {
    if (partner && !partnerSourceUrl) {
      return props.navigate('/circles');
    }
    fetchAllRecords();
    updateUsedService();

    const { onFooterItemClick } = props;
    if (onFooterItemClick) {
      if (partner || consentRouteFrom === 'circleConsent') {
        onFooterItemClick(2);
      } else {
        onFooterItemClick(3);
      }
    }

    return () => {
      localStorage.removeItem('partnerInfo');
      if (onFooterItemClick) {
        onFooterItemClick(-1);
      }
    };
  }, []);

  const handleChangeFilter = () => {
    changeFiltersValue.current = true;
    setHasInfiniteScroll(true);
    setInitialRender(true);
    pageNumber.current = 1;
    updateUi(0);
    setLoaderText(MEDICATION_DETAIL_TEXTS.LOADING_MEDICATIONS);
  };

  const handlepageNumber = () => {
    pageNumber.current += 1;
    const offsetValue = 10 * (pageNumber.current - 1);
    updateUi(offsetValue);
  };

  const onSearch = (value) => {
    if (value !== searchValue) {
      searchValue.current = value;
      handleChangeFilter();
    }
  };

  const dateFilterOptions = {
    options: dateFilterMenuItems,
    value: timeFilter.current,
    changeHandler: (ev) => {
      timeFilter.current = ev.target.value;
      handleChangeFilter();
    },
    rootContainerStyle: classes.dropDownDateWrapper,
  };

  const statusFilterOptions = {
    options: medicationsStatusFilterMenuItems,
    value: statusFilter.current,
    changeHandler: (ev) => {
      statusFilter.current = ev.target.value;
      handleChangeFilter();
    },
    rootContainerStyle: classes.dropDownWrapper,
  };

  const viewDetailsHandler = async (id) => {
    setIsLoading(true);
    setDetailsLoading(true);
    setShowEditInModal(false);
    setLoaderText(MEDICATION_DETAIL_TEXTS.LOADING_DETAILS);
    const tableRowInfo = allMedicationsData.find((obj) => obj.id === id);
    setCurrentObj(tableRowInfo);
    setModalTitle(tableRowInfo.medication);
    // fetch medication data based on type
    try {
      const conditionalFetch = async (type) => {
        switch (type) {
          case 'MedicationStatement':
            return await MedicationsService.fetchMedicationStatementById(id);

          case 'MedicationDispense':
            return await MedicationsService.fetchMedicationDispenseById(id);

          default:
            return consentRouteFrom !== ''
              ? await MedicationsService.fetchMedicationByIdAndGrantorId(id, grantorId)
              : await MedicationsService.fetchMedicationRequestById(id);
          // if (res && res.medicationRequest) {
          //   return JSON.parse(res.medicationRequest);
          // }
          // return {};
        }
      };
      const result = await conditionalFetch(tableRowInfo.resourceType);
      let medicationObj = result.medicationRequest ? JSON.parse(result.medicationRequest) : result;
      medicationObj = {
        ...medicationObj,
        docArn: result.docArn,
        documentReferenceId: result.documentReferenceId,
      };
      setDocuments(result.docArn);

      const formattedData = medicationDetailsFormatter(medicationObj, partner && true);
      setModalData(formattedData.modalData);
      setDosageInfo({ dosage: medicationObj.dosageInstruction, notes: medicationObj.note });
      setMarkAsRestrictedInModal(formattedData.editData.markAsRestricted);
      setHistoricalRecordsParamsObj({
        resourceType: 'Medication',
        icon: MedicationIcon,
        title: tableRowInfo.medication,
        partner: partner,
        circleId: circleId,
        // code: formattedData.editData.code,
        medicationCategoryType: tableRowInfo.resourceType,
      });
      setOpenPopUp(true);
      setIsLoading(false);

      if (
        SELF_REPORTING_DATA_SOURCE_LABELS.includes(
          tableRowInfo.dataSource && tableRowInfo.dataSource.toLowerCase(),
        )
      ) {
        setShowEditInModal(true);
        setEditData(formattedData.editData);
      }
      setOpenPopUp(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setSnackbarType(SNACKBAR_TYPE.ERROR);
      setSnackbarMessage(MEDICATION_DETAIL_TEXTS.ERROR_LOADING);
      setIsLoading(false);
    } finally {
      setDetailsLoading(false);
    }
  };

  const handleAddDataBtnClick = () => {
    handleAddSelfReportedButtonalyticalEvents(logAnalyticsEvent, 'medications');
    props.navigate('/records/medication/add');
  };

  const onEdit = ({ newRestrictedVal = markAsRestrictedInModal }) => {
    props.navigate('/records/medication/edit', {
      state: {
        medicationObject: {
          ...editData,
          [SELF_REPORTED_VALUE_KEYS.MARKED_AS_RESTRICTED_KEY]: newRestrictedVal.toString(),
        },
      },
    });
  };

  const handleHistoricalData = () => {
    if (!partner) {
      props.navigate('/records/medications/dataSource', {
        state: { circleId, Icon: MedicationIcon, modalData: editData },
      });
    } else {
      props.navigate('/circles/' + partner + '/medications/dataSource', {
        state: { circleId, Icon: MedicationIcon, partnerSourceUrl, modalData: editData },
      });
    }
  };

  const DosageList = (props) => {
    const {
      data: { dosage = [], notes = [] },
    } = props;
    const doseLength = (dosage && dosage.length) || 0;
    return (
      doseLength > 0 && (
        <Paper variant="outlined" className={classes.dosageWrapper}>
          {dosage.map((row, i) => {
            if (!row) {
              return '';
            }
            return (
              <div
                key={i}
                style={{
                  // borderBottom: doseLength !== i ? '1px solid #DBDBDB' : '',
                  paddingBottom: '10px',
                  paddingTop: '0px',
                }}
              >
                <h3 className={classes.doseHeading}>
                  {/* {row.text ||
                    (row.timing && row.timing.code && row.timing.code.text) ||
                    DEFAULT_NULL_REPLACEMENT} */}
                  Dose {i + 1}
                </h3>
                <div className={classes.flexBox}>
                  <div className={classes.dosageFlexContent}>
                    <p className={classes.column_name}>No of pills</p>
                    <p className={classes.column_value}>
                      {(row.timing && row.timing.repeat && row.timing.repeat.frequency) ||
                        DEFAULT_NULL_REPLACEMENT}
                    </p>
                  </div>
                  <div className={classes.dosageFlexContent}>
                    <p className={classes.column_name}>Time</p>
                    <p className={classes.column_value}>
                      {(row.timing &&
                        row.timing.repeat &&
                        row.timing.repeat.timeOfDay &&
                        row.timing.repeat.timeOfDay.length &&
                        row.timing.repeat.timeOfDay[0]) ||
                        DEFAULT_NULL_REPLACEMENT}
                    </p>
                  </div>
                  <div className={classes.dosageFlexContent}>
                    <p className={classes.column_name}>Note</p>
                    <p className={classes.dosageNotes}>
                      {(notes && notes[i] && notes[i].text) || DEFAULT_NULL_REPLACEMENT}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Paper>
      )
    );
  };

  return (
    <div className={classes.container}>
      {(initialRender || detailsLoading) && <Loader overlay text={loaderText} />}
      <SnackbarToast
        message={snackbarMessage}
        open={!!snackbarMessage}
        alertStyle={{ backgroundColor: '#1F1F1F', borderRadius: 4, color: '1F1F1F' }}
        autoHideDuration={4000}
        onClose={() => {
          setSnackbarType(SNACKBAR_TYPE.ERROR);
          setSnackbarMessage(false);
        }}
        type={snackbarType}
      />
      <BreadCrumb breadCrumbProps={breadCrumbProps} />
      <h2 className={classes.title}>{title}</h2>
      {partner && <SubTitleCard icon={partnerIconUrl} title={partnerName} />}
      <div className={classes.filtersContainer}>
        <div className={classes.filterDropDownContainer}>
          <SearchBar
            rootContainerStyle={classes.searchBar}
            searchInput={classes.searchInput}
            onSearch={onSearch}
            placeholder="Search by medication code"
          />
          <FilterDropDown {...dateFilterOptions} filterType="Date:" />
          <FilterDropDown {...statusFilterOptions} filterType="Status:" />
        </div>
        {!partner && consentRouteFrom === '' && (
          <Button onClick={handleAddDataBtnClick} variant="borderedWhite">
            {ADD_DATA}
          </Button>
        )}
      </div>

      <div className={classes.tableWrapper}>
        {!allMedicationsData?.length && !isLoading && <NotFound title={title} />}
        {allMedicationsData?.length > 0 && (
          <ReusableTable
            setOpen={setOpenPopUp}
            data={allMedicationsData}
            paginationObj={{
              handlepageNumber: handlepageNumber,
              loading: isLoading,
              detailsLoading,
              hasInfiniteScroll: hasInfiniteScroll,
            }}
            tableHeading={getTableHeading}
            viewDetailsHandler={viewDetailsHandler}
          />
        )}
      </div>

      {openPopUp && (
        <PopUpModal
          setOpen={setOpenPopUp}
          data={modalData}
          title={modalTitle}
          icon={MedicationIcon}
          markAsRestricted={markAsRestrictedInModal}
          historicalRecordsParamsObj={consentRouteFrom === '' ? historicalRecordsParamsObj : {}}
          showEdit={showEditInModal}
          clinicalRecord="Medication"
          currentObj={currentObj}
          handleEdit={(id) => onEdit(id)}
          documents={documents}
          restrictedDescription={MARK_RESTRICTED_DESC('medication')}
          hideOptions={Boolean(partner || grantorId)}
        >
          <DosageList data={dosageInfo} />
        </PopUpModal>
      )}
    </div>
  );
};
export default withRouter(withStyles(styles, { withTheme: true })(Medications));
