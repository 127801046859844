import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import { CODE_FRAGMENT, LAB_RESULTS_FRAGMENT } from '../utils/fragments';

const FETCH_ALL_DIAGNOSTIC_REPORTS_QUERY = gql`
  query fetchAllDiagnosticReports(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllDiagnosticReports(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      diagnosticList
      count
    }
  }
`;
const FETCH_ALL_DIAGNOSTIC_REPORTS_BY_CONSENT = gql`
  query fetchAllDiagnosticReportsByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllDiagnosticReportsByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      diagnosticList
      count
    }
  }
`;

const FETCH_DIAGNOSTIC_REPORT_DETAILS_BY_ID = gql`
  query fetchDiagnosticReportById($diagnosticReportId: String) {
    fetchDiagnosticReportById(diagnosticReportId: $diagnosticReportId) {
      diagnosticReport
      docArn
      documentReferenceId
    }
  }
`;
const FETCH_DIAGNOSTIC_REPORT_DETAILS_BY_ID_AND_GRANTOR_ID = gql`
  query fetchDiagnosticReportByIdAndGrantorId($diagnosticReportId: String, $grantorId: String) {
    fetchDiagnosticReportByIdAndGrantorId(
      diagnosticReportId: $diagnosticReportId
      grantorId: $grantorId
    ) {
      diagnosticReport
      docArn
      documentReferenceId
    }
  }
`;

const FETCH_RELATED_DIAGNOSTIC_REPORTS = gql`
  query fetchRelatedDiagnosticReport($relatedResourceId: String, $relatedResourceType: String) {
    fetchRelatedDiagnosticReport(
      resourceId: $relatedResourceId
      resourceType: $relatedResourceType
    ) {
      id
      code {
        ...code
      }
    }
  }
  ${CODE_FRAGMENT}
`;

const SAVE_LAB_RESULTS_SELF_REPORTING = gql`
  mutation createDiagnosticSelfReportData($data: AddDiagnosticSelfReportData) {
    createDiagnosticSelfReportData(data: $data) {
      ...labResults
    }
  }
  ${LAB_RESULTS_FRAGMENT}
`;

const UPDATE_LAB_RESULTS_SELF_REPORTING = gql`
  mutation editDiagnosticSelfReportData(
    $data: AddDiagnosticSelfReportData
    $diagnosticReportId: String
  ) {
    editDiagnosticSelfReportData(data: $data, diagnosticReportId: $diagnosticReportId) {
      ...labResults
    }
  }
  ${LAB_RESULTS_FRAGMENT}
`;

const FETCH_HISTORICAL_RECORDS = gql`
  query fetchHistoricalDiagnosticReport(
    $diagnosticReportCode: String = ""
    $count: String = "10"
    $offset: Int = 0
    $dateFilter: Float = 0
  ) {
    fetchHistoricalDiagnosticReport(
      diagnosticReportCode: $diagnosticReportCode
      count: $count
      offset: $offset
      dateFilter: $dateFilter
    ) {
      diagnosticList
      count
    }
  }
`;

const MARK_RESTRICTED = gql`
  mutation updateMarkAsRestrictedDiagnosticReport(
    $diagnosticReportId: String
    $markAsRestricted: String
  ) {
    updateMarkAsRestrictedDiagnosticReport(
      diagnosticReportId: $diagnosticReportId
      markAsRestricted: $markAsRestricted
    ) {
      ...labResults
    }
  }
  ${LAB_RESULTS_FRAGMENT}
`;

const DiagnosticReportsService = {
  fetchAllDiagnosticReports(variables, query = FETCH_ALL_DIAGNOSTIC_REPORTS_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllDiagnosticReports);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllDiagnosticReportsByConsent(args, query = FETCH_ALL_DIAGNOSTIC_REPORTS_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllDiagnosticReportsByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchDiagnosticReportById(id, query = FETCH_DIAGNOSTIC_REPORT_DETAILS_BY_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            diagnosticReportId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchDiagnosticReportById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDiagnosticReportByIdAndGrantorId(
    diagnosticReportId,
    grantorId,
    query = FETCH_DIAGNOSTIC_REPORT_DETAILS_BY_ID_AND_GRANTOR_ID,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            diagnosticReportId,
            grantorId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchDiagnosticReportByIdAndGrantorId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRelatedDiagnosticReports(
    relatedResourceId,
    relatedResourceType,
    query = FETCH_RELATED_DIAGNOSTIC_REPORTS,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            relatedResourceId,
            relatedResourceType,
          },
        })
        .then((response) => {
          resolve(response.data.fetchRelatedDiagnosticReport);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async saveSelfReportedRecord(data, mutation = SAVE_LAB_RESULTS_SELF_REPORTING) {
    const {
      data: { createDiagnosticSelfReportData },
    } = await client.mutate({
      mutation,
      variables: {
        data,
      },
    });

    return createDiagnosticSelfReportData;
  },

  async updateSelfReportedRecord(
    data,
    diagnosticReportId,
    mutation = UPDATE_LAB_RESULTS_SELF_REPORTING,
  ) {
    const {
      data: { editDiagnosticSelfReportData },
    } = await client.mutate({
      mutation,
      variables: {
        data,
        diagnosticReportId,
      },
    });

    return editDiagnosticSelfReportData;
  },

  async fetchHistoricalRecords(args, query = FETCH_HISTORICAL_RECORDS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchHistoricalDiagnosticReport);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateMarkAsRestrictedDiagnosticReport(
    diagnosticReportId,
    markAsRestricted,
    mutation = MARK_RESTRICTED,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            diagnosticReportId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.updateMarkAsRestrictedDiagnosticReport);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default DiagnosticReportsService;
