import React from 'react';
import GenericFilters from './GenericFilters';
import CustomDateFilter from './CustomDateFilter';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '2px',
    [theme.breakpoints.down('md')]: {
      gap: '8px',
    },
  },
});

const Filters = ({ filters = [], handleFilter, classes, filterContainerClass }) => {
  const handleSelect = (e) => {
    handleFilter(e);
  };
  return (
    <div className={classes.wrapper}>
      {filters?.map((filterItem, idx) => {
        if (!['all', 'web'].includes(filterItem?.deviceType?.toLowerCase())) {
          return null;
        }
        if (!filterItem?.datePickerEnabled) {
          return (
            <GenericFilters
              key={idx}
              handleFilterChange={handleSelect}
              filterItem={filterItem}
              filterContainerClass={filterContainerClass}
            />
          );
        }
        return (
          <CustomDateFilter
            key={idx}
            filterItem={filterItem}
            handleChange={handleSelect}
            filterContainerClass={filterContainerClass}
          />
        );
      })}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Filters);
