import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '../../atoms/Button';
import { CONSENT_TYPES, TERMS_AND_CONDITIONS, PRIVACY_POLICY } from '../../../utils/constants';
import colors from '../../../utils/colors';

const styles = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: theme.spacing(3),
    position: 'relative',
    bottom: 40,
    '& .MuiDialog-paper': {
      margin: 0,
    },
  },
  root: {
    width: 640,
    maxWidth: '100vw',
    borderRadius: theme.spacing(0),
    borderTopLeftRadius: theme.spacing(3),
  },
  rootContainer: {
    padding: '32px 24px',
  },
  darkTextBox: {
    backgroundColor: colors.WHITE,
    padding: 0,
    color: '#595959',
    fontWeight: 400,
    lineHeight: '24px',
    fontSize: 14,
    overflowWrap: 'break-word',
    // textAlign: 'justify',
  },
  textContent: {},
  dialogAction: {
    backgroundColor: colors.WHITE,
    padding: '16px 48px',
    borderTop: '1px solid #CCCCCC',
    justifyContent: 'flex-end',
    position: 'fixed',
    bottom: theme.spacing(3),
    height: 80,
    width: 'inherit',
    boxSizing: 'border-box',
    borderBottomLeftRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
    right: theme.spacing(3),
  },
  titleUpdated: {
    color: colors.BLACK_B800,
    padding: 0,
    lineHeight: '40px',
    fontFamily: 'Inter !important',
    fontWeight: 600,
    fontSize: 28,
    paddingBottom: 8,
  },
  topText: {
    fontFamily: 'Inter',
    fontSize: 18,
    lineHeight: '24px',
    color: colors.GRAY_G900,
    marginBottom: 32,
    fontWeight: 600,
    // textAlign: 'justify',
  },
  btn1: {
    backgroundColor: colors.BLUE,
    borderRadius: 40,
    height: 44,
    lineHeight: '24px',
    padding: '6px 0px',
    border: '1px solid #A0A4A6',
    color: colors.WHITE,
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    padding: '0px 32px 0px 32px',
    width: 'fit-content',
    '&:hover': {
      color: colors.WHITE,
      backgroundColor: colors.BTN_HOVER_BLUE,
    },
  },
  paperScrollPaper: {
    maxHeight: 'calc(100vh - 48px)',
    height: 'calc(100vh - 128px)',
  },
});

const PolicyDialog = (props) => {
  const { open, content, rawHTML, onClose, onAgree, consentType, classes } = props;
  const [dialogContent, setDialogContent] = useState({
    TITLE: '',
    OUTLINE: '',
    BODY: '',
    OK: '',
  });
  const getDialogContent = useCallback(() => {
    const dialogTexts = Object.create({
      TITLE: '',
      OUTLINE: '',
      BODY: '',
      OK: '',
    });
    if (consentType === CONSENT_TYPES.TERMS_CONDITION) {
      dialogTexts.TITLE = TERMS_AND_CONDITIONS.TITLE;
      dialogTexts.OUTLINE = TERMS_AND_CONDITIONS.OUTLINE;
      dialogTexts.OK = TERMS_AND_CONDITIONS.OK;
      dialogTexts.ACCEPT = PRIVACY_POLICY.ACCEPT;
    } else if (consentType === CONSENT_TYPES.PRIVACY_POLICY) {
      dialogTexts.TITLE = PRIVACY_POLICY.TITLE;
      dialogTexts.OUTLINE = PRIVACY_POLICY.OUTLINE;
      dialogTexts.OK = PRIVACY_POLICY.OK;
      dialogTexts.ACCEPT = PRIVACY_POLICY.ACCEPT;
    }
    dialogTexts.BODY = `<div style="font-size: 16px margin-bottom: 30px overflow-wrap:break-word;"> ${content}</div>`;

    return dialogTexts;
  }, [consentType, content]);

  useEffect(() => {
    const dialogTexts = getDialogContent();
    setDialogContent(dialogTexts);
  }, [getDialogContent]);

  return (
    <Dialog
      id="dialogbox"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          props.onClose(event, reason);
        }
      }}
      open={open}
      classes={{ container: classes.container, paperScrollPaper: classes.paperScrollPaper }}
      PaperProps={{
        classes: {
          root: classes.root,
          paper: classes.paper,
        },
      }}
    >
      <div className={classes.rootContainer}>
        <DialogTitle id="dialogbox-title" disableTypography className={classes.titleUpdated}>
          {dialogContent.TITLE}
        </DialogTitle>
        <div id="dialogbox-content-container" className={classes.textContent}>
          <DialogContentText id="dialogbox-content-title" className={classes.topText}>
            {dialogContent.OUTLINE}
          </DialogContentText>
          <div id="dialogbox-contentbody-container-1" className={classes.darkTextBox}>
            {rawHTML && <span dangerouslySetInnerHTML={{ __html: dialogContent.BODY }} />}
            {!rawHTML && dialogContent.BODY}
          </div>
        </div>
      </div>
      <DialogActions id="dialogbox-button-container" className={classes.dialogAction}>
        <Button
          id="dialogbox-button"
          onClick={onAgree || onClose}
          title={dialogContent.ACCEPT || dialogContent.OK}
          color={'secondary'}
          variant={'contained'}
          className={classes.btn1}
        />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles, { withTheme: true })(PolicyDialog);
