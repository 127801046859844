// Stopgap hook for "clicking" the appropriate section footer

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const footerIndex = {
  home: 0,
  shop: 1,
  circles: 2,
  records: 3,
  utilities: 4,
};

export default function useClickFooter(footerClickFunc) {
  const location = useLocation();
  const section = location.pathname.split('/')[1];

  useEffect(() => {
    footerClickFunc(footerIndex[section]);
  }, []);
}
