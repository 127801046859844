import React from 'react';
import MUITextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import colors from '../../../../utils/colors';
import RightIcon from '../../../../assets/images/rightIcon.svg';
import VisibilityOffIcon from '../../../../assets/images/VisibilityOff.svg';
import VisibilityOnIcon from '../../../../assets/images/VisibilityOn.svg';
import InputAdornment from '@mui/material/InputAdornment';
import clsx from 'clsx';

const styles = (theme) => ({
  textfieldstyles: {
    fontSize: theme.spacing(1.75),
    '& .MuiTypography-root': {
      color: colors.PRIMARY_GRAY,
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Inter',
      height: theme.spacing(5),
      fontSize: theme.spacing(1.75),
      border: `1px solid ${colors.SUBTLE_GRAY}`,
    },
    '& .MuiOutlinedInput-input': {
      height: theme.spacing(5),
      boxSizing: 'border-box',
    },
  },
  borderOnHover: {
    border: `1px solid ${colors.BLUE} !important`,
  },
  pointer: {
    ...theme.customComponent.clickableItem,
  },
});

const TextField = (props) => {
  const {
    autoComplete,
    autoFocus,
    fullWidth,
    error,
    newEmailError,
    label,
    placeholder,
    onFieldChange,
    required,
    type,
    onKeyPressHandler,
    onShowPassword,
    color,
    variant,
    id,
    name,
    value,
    size,
    defaultValue,
    classes,
    textFieldClass,
    inputFieldClass,
    textFieldStyle,
    disableunderline,
    lightBorder,
    InputProps,
    passwordMatched,
    showPassword,
    disabled,
    errorMsg,
    successMsg,
    passwordMatchedIcon,
    passwordStrength,
    password,
    onBlur,
    ...rest
  } = props;

  const stopClipBoardEvent = (e) => {
    e.preventDefault();
  };

  const handleTextChange = (event) => {
    onFieldChange(name, event.target.value);
  };

  const keyPressHandler = (e) => {
    onKeyPressHandler && onKeyPressHandler(e.key);
  };

  return (
    <MUITextField
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      label={label}
      placeholder={placeholder}
      error={Boolean(error) || Boolean(newEmailError)}
      onChange={handleTextChange}
      onKeyUp={(e) => keyPressHandler(e)}
      required={required}
      variant={variant || 'outlined'}
      color={color || 'primary'}
      id={id}
      name={name}
      value={value}
      type={type}
      size={size || 'small'}
      defaultValue={defaultValue}
      fullWidth={fullWidth}
      className={clsx(classes.textfieldstyles, textFieldStyle)}
      classes={{
        notchedOutline: classes.borderOnHover,
      }}
      onCut={type === 'password' ? stopClipBoardEvent : null}
      onCopy={type === 'password' ? stopClipBoardEvent : null}
      onPaste={type === 'password' ? stopClipBoardEvent : null}
      disabled={disabled}
      helperText={errorMsg || successMsg}
      onBlur={onBlur}
      InputProps={{
        classes: {
          input: inputFieldClass ? inputFieldClass : null,
        },
        className: `${[
          lightBorder ? classes.lightInput : classes.input,
          passwordMatched ? classes.passwordSucces : null,
        ].join(' ')} `,
        endAdornment:
          password === 'password' ? (
            <React.Fragment>
              <InputAdornment position="end">
                <img
                  className={classes.pointer}
                  onClick={() => onShowPassword()}
                  src={type === 'password' ? VisibilityOffIcon : VisibilityOnIcon}
                />
              </InputAdornment>
            </React.Fragment>
          ) : null,
      }}
      {...rest}
    />
  );
};

export default withStyles(styles, { withTheme: true })(TextField);
