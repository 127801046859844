/**
 * Colors are represented in - rrggbbaa -
 * alpha = light opacity = 0.38 = 66
 * beta = medium opacity = 0.60 = 99
 * gamma = high opacity = 0.87 = E6
 */

const colors = {
  PRIMARY: '#FFA400FF',
  PRIMARY_LIGHT: '#FFCE7E',
  WHITE: '#FFFFFFFF',
  BLACK: '#000000FF',
  BLACK_APLHA: '#00000066',
  BLACK_BETA: '#00000099',
  BACKGROUND_COLOR: '#F1F1F1',
  BLACK_B900: '#1B1B1B',
  BLACK_GAMMA: '#000000E6',
  LIGHT_BLUE: '#00A0DFFF',
  DARK_BLUE: '#004987FF',
  NAVY: '#003A64FF',
  MAJENTA: '#DB0032FF',
  ERROR: '#E02020FF',
  SUCCESS: '#6DD400FF',
  GOLDEN_BROWN: '#E08433FF',
  BORDER_LIGHT: '#EBEBEB',
  BORDER_MEDIUM: '#DFD9D9',
  GREY: '#737373',
  LIGHT_GREY: '#f5f4f8',
  PURE_BLUE: '#00a0df',
  LIGHT_GRAYISH_BLUE: '#E2E8F0',
  MUTED: '#F0F1F3',
  GRAY_FORD: '#979797',
  BLUE: '#036FCB',
  RED: '#DB0032',
  ORANGE: '#F2994A',
  GREEN: '#148E3C',
  GRAY_G900: '#595959',
  BLACK_B800: '#1F1F1F',
  BG_COLOR: '#f5f5f5',
  WHITE_CHINESE: '#E0E0E0',
  BLACK_B500: '#333333',
  BLACK_MATTERHORM: '#4B4B4B',
  GRAY_LIGHT: '#999999',
  PRIMARY_GRAY: '#344054',
  SUBTLE_GRAY: '#E4E7EC',
  TITLE_GRAY: '#101828',
  GRAY_SECONDARY: '#667085',
  TRIADIC_BLUE: '#522cbc',
  LIGHT_PINK: '#F7E9FF',
  GREEN_PRIMARY_SUBTITLE: '#E2F1FC',
  BLUE_DISABLE: '#8BC5F5',
  BLUE_HOVER: '#389DEF',
  BACKGROUND_COLOR_NEW: '#F5F7F8',
  GREEN: '#007D32',
  LIGHT_GREEN: '#E6F5E9',
  GRAY_51: '#828282',
};

export default colors;
