import { Box, Paper, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { DEFAULT_NULL_REPLACEMENT } from '../../../../utils/constants';
import colors from '../../../../utils/colors';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { mockTemplates } from '../../mockTemplate';
import objectPath from 'object-path';

const styles = (theme) => ({
  dosageInstructionRoot: {
    borderRadius: theme.spacing(1),
    padding: '0px 24px',
    fontFamily: 'Inter',
  },
  dosageInstructionStyle: {
    paddingBottom: '10px',
    paddingTop: '0px',
  },
  dosageInstructionHeading: {
    lineHeight: '24px',
    fontSize: theme.spacing(2),
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    margin: '16px 0px',
  },
  rowContainer: {
    display: 'flex',
    flex: 1,
  },
  dosageInstructionConent: {
    flex: '33.33%',
  },
  columnNames: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    width: '100%',
    height: '20px',
    color: colors.GRAY_51,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    order: 0,
    flex: 'none',
    flexGrow: 0,
    margin: '4px 0px',
  },
  columnValues: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: colors.BLACK,
    flex: 'none',
    order: 1,
    flexGrow: 0,
    margin: '4px 0px',
  },
  dosageInstructionNotes: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    width: '150px',
    fontWeight: 500,
    fontSize: theme.spacing(2),
    lineHeight: '24px',
    color: colors.BLACK,
    flex: 'none',
    order: 1,
    flexGrow: 0,
    margin: '4px 0px',
  },
});

const GenericDosageList = (props) => {
  const { classes, note, dosageInstruction, resourceType} = props;
  const { categoryName } = useParams();
  const { templates } = useSelector((state) => state.templates);
  const labels = templates?.[categoryName]?.details?.[resourceType]?.list || mockTemplates?.[categoryName]?.details?.[resourceType]?.list;

  const dosageInstructionLength = dosageInstruction?.length ?? 0;
  const dosageFormatting = (obj, objPath) => {
    objectPath.ensureExists(obj, objPath, DEFAULT_NULL_REPLACEMENT);
    return objectPath.get(obj, objPath) ?? DEFAULT_NULL_REPLACEMENT;
  };

  return (
    dosageInstructionLength === 0 ? '' : <Paper variant="outlined" className={classes.dosageInstructionRoot}>
      {dosageInstruction.map((row, i) => {
        if (!row) {
          return '';
        }
        return (
          <Box
            key={i}
            className={classes.dosageInstructionStyle}
          >
            <Typography className={classes.dosageInstructionHeading}>
              {`${labels?.mainHeader} ${i + 1}`}
            </Typography>
            <Box className={classes.rowContainer}>
              <Box className={classes.dosageInstructionConent}>
                <Typography className={classes.columnNames}>{labels.header1}</Typography>
                <Typography className={classes.columnValues}>
                  {dosageFormatting(row, labels?.objectPath1)}
                </Typography>
              </Box>
              <Box className={classes.dosageInstructionConent}>
                <Typography className={classes.columnNames}>{labels.header2}</Typography>
                <Typography className={classes.columnValues}>
                  {dosageFormatting(row, labels?.objectPath2)}
                </Typography>
              </Box>
              <Box className={classes.dosageInstructionConent}>
                <Typography className={classes.columnNames}>{labels.header3}</Typography>
                <Typography className={classes.dosageInstructionNotes}>
                  { note ? dosageFormatting(note?.[i], labels?.objectPath3): DEFAULT_NULL_REPLACEMENT}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Paper>
  );
};

export default withStyles(styles, { withTheme: true })(GenericDosageList);