import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import { CODE_FRAGMENT, IDENTIFIER_FRAGMENT, CONDITIONS_FRAGMENT } from '../utils/fragments';

const FETCH_ALL_CONDITIONS_QUERY = gql`
  query fetchAllConditions(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllConditions(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      conditionList
      count
    }
  }
`;

const FETCH_ALL_CONDITION_BY_CONSENT = gql`
  query fetchAllConditionsByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllConditionsByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      conditionList
      count
    }
  }
`;

const FETCH_CONDITION_DETAILS_BY_ID = gql`
  query fetchConditionById($conditionId: String) {
    fetchConditionById(conditionId: $conditionId) {
      condition
      docArn
      documentReferenceId
    }
  }
`;
const FETCH_RECORD_BY_ID = gql`
  query fetchRecordById($resourceType: String, $resourceId: String, $grantorId: String) {
    fetchRecordById(
      resourceType: $resourceType
      resourceId: $resourceId
      grantorId: $grantorId
    ) {
      record
      docArn
      documentReferenceId
    }
  }
`;
const FETCH_CONDITION_DETAILS_BY_ID_AND_GRANTOR_ID = gql`
  query fetchConditionByIdAndGrantorId($conditionId: String, $grantorId: String) {
    fetchConditionByIdAndGrantorId(conditionId: $conditionId, grantorId: $grantorId) {
      condition
      docArn
      documentReferenceId
    }
  }
`;

const FETCH_RELATED_CONDITIONS = gql`
  query fetchRelatedCondition($relatedResourceId: String, $relatedResourceType: String) {
    fetchRelatedCondition(resourceId: $relatedResourceId, resourceType: $relatedResourceType) {
      id
      code {
        ...code
      }
    }
  }
  ${CODE_FRAGMENT}
`;

const SAVE_CONDITION_SELF_REPORTING = gql`
  mutation createConditionSelfReportData($data: AddConditionSelfReportData) {
    createConditionSelfReportData(data: $data) {
      ...condition
    }
  }
  ${CONDITIONS_FRAGMENT}
`;

const EDIT_CONDITION_SELF_REPORTING = gql`
  mutation editConditionSelfReportData($data: AddConditionSelfReportData, $conditionId: String) {
    editConditionSelfReportData(data: $data, conditionId: $conditionId) {
      ...condition
    }
  }
  ${CONDITIONS_FRAGMENT}
`;

const FETCH_HISTORICAL_RECORDS = gql`
  query fetchHistoricalConditions(
    $conditionCode: String = ""
    $count: String = "10"
    $offset: Int = 0
    $dateFilter: Float = 0
  ) {
    fetchHistoricalConditions(
      conditionCode: $conditionCode
      count: $count
      offset: $offset
      dateFilter: $dateFilter
    ) {
      conditionList
      count
    }
  }
`;
const FETCH_HISTORICAL_RECORDS_FOR_REUSABLE_COMPONENTS = gql`
  query fetchHistoricalRecords($recordRequest: RecordRequest) {
    fetchHistoricalRecords(recordRequest: $recordRequest) {
      recordList
      count
    }
  }
`;

const MARK_RESTRICTED = gql`
  mutation updateMarkAsRestrictedCondition($conditionId: String, $markAsRestricted: String) {
    updateMarkAsRestrictedCondition(
      conditionId: $conditionId
      markAsRestricted: $markAsRestricted
    ) {
      ...condition
    }
  }
  ${CONDITIONS_FRAGMENT}
`;

const ConditionsService = {
  fetchAllConditions(variables, query = FETCH_ALL_CONDITIONS_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllConditions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllConditionsByConsent(args, query = FETCH_ALL_CONDITION_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllConditionsByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchConditionById(id, query = FETCH_CONDITION_DETAILS_BY_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            conditionId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchConditionById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchRecordById(args, query = FETCH_RECORD_BY_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchRecordById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchConditionByIdAndGrantorId(
    conditionId,
    grantorId,
    query = FETCH_CONDITION_DETAILS_BY_ID_AND_GRANTOR_ID,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            conditionId,
            grantorId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchConditionByIdAndGrantorId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRelatedConditions(relatedResourceId, relatedResourceType, query = FETCH_RELATED_CONDITIONS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            relatedResourceId,
            relatedResourceType,
          },
        })
        .then((response) => {
          resolve(response.data.fetchRelatedCondition);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveSelfReportedCondition(data, mutation = SAVE_CONDITION_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
          },
        })
        .then((response) => {
          resolve(response.data.createConditionSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateConditionSelfReportData(data, conditionId, mutation = EDIT_CONDITION_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
            conditionId,
          },
        })
        .then((response) => {
          resolve(response.data.editConditionSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchHistoricalConditions(args, query = FETCH_HISTORICAL_RECORDS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchHistoricalConditions);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchHistoricalRecords(args, query = FETCH_HISTORICAL_RECORDS_FOR_REUSABLE_COMPONENTS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            recordRequest: args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchHistoricalRecords);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateMarkAsRestrictedCondition(conditionId, markAsRestricted, mutation = MARK_RESTRICTED) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            conditionId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.updateMarkAsRestrictedCondition);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default ConditionsService;
