import { Grid, Typography } from '@mui/material';
import React from 'react';
import AvatarImg from './AvatarImg';
import { DrawerBoxComponent } from './DrawerBoxComponent';

export const AppointmentProviderInfo = () => {
  return (
    <DrawerBoxComponent>
      <Grid container>
        <Grid item>
          <AvatarImg sx={{ mt: 0 }} userName="Dr Smith" />
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h3">Dr John Smith</Typography>
            </Grid>
            <Grid item>
              <Typography variant="p">Orthodontal</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DrawerBoxComponent>
  );
};
