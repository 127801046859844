import React from 'react';
import { Avatar } from '@mui/material';

const AvatarImg = ({ userName, sx }) => {
  return (
    <React.Fragment>
      <Avatar
        alt={userName ?? 'Avatar'}
        // src={src}
        sx={{
          width: '50px',
          height: '50px',
          mr: 1.5,
          mt: '10px',
          bgcolor: 'primary.main',
          ...sx,
        }}
      >
        {userName?.[0] ?? '-'}
      </Avatar>
    </React.Fragment>
  );
};

export default AvatarImg;
