import { SERVICES } from './constants';
import MedicationInactive from '../assets/images/prescription.svg';
import MedicationImage from '../assets/images/prescription-mob.svg';
import ConditionImage from '../assets/images/conditions-mob.svg';
import AllergyImage from '../assets/images/allergy.svg';
import SO_IMAGE from '../assets/images/with_shadow.svg';
import ImmunizationInactive from '../assets/images/immunizationPwa.svg';
import ImmunizationImage from '../assets/images/immunization.svg';
import VitalImage from '../assets/images/vital-mob.svg';
import LabsInactive from '../assets/images/labsService.svg';
import Labsactive from '../assets/images/labSmallIcon.svg';
import SecondOpinion from '../assets/images/chat.svg';
import Allergy from '../assets/images/allergyPwa.svg';
import VitalPwa from '../assets/images/vitalPwa.svg';
import VisionInactive from '../assets/images/vision_inactive.svg';
import DentalInactive from '../assets/images/dental_inactive.svg';
import ClaimsInactive from '../assets/images/contract-claim.svg';
import DiagnosesInactive from '../assets/images/diagnoses_inactive.svg';
import ProcedureInactive from '../assets/images/procedurePwa.svg';
import ProcedureSmallIcon from '../assets/images/procedureSmallIcon.svg';
import Visits from '../assets/images/visits.svg';
import VisitsSmall from '../assets/images/visitsSmallIcon.svg';
import Bills from '../assets/images/bill-service.svg';
import Bill_IMAGE from '../assets/images/bills_mob.svg';
import ClaimsImage from '../assets/images/claims-mob.svg';
import Coverage from '../assets/images/coverage.svg';
import CoverageSmallIcon from '../assets/images/coverageSmallIcon.svg';
import PayerProviderInactive from '../assets/images/circles-filled.svg';
import AppointmentsImage from '../assets/images/circles-filled-mob.svg';
import ConsentsInactive from '../assets/images/consents_inactive.svg';
import SmokingStatusIcon from '../assets/images/smokingStatusIcon.svg';
import CarePlanIcon from '../assets/images/carePlan.svg';

const ICONS_IMAGES = [
  { pwaIcon: MedicationInactive, icon: MedicationImage },
  { pwaIcon: DiagnosesInactive, icon: ConditionImage },
  { pwaIcon: Allergy, icon: AllergyImage },
  { pwaIcon: VitalPwa, icon: VitalImage },
  { pwaIcon: ImmunizationInactive, icon: ImmunizationImage },
  { pwaIcon: SecondOpinion, icon: SO_IMAGE },
  { pwaIcon: LabsInactive, icon: Labsactive },
  { pwaIcon: Visits, icon: VisitsSmall },
  { pwaIcon: ProcedureInactive, icon: ProcedureSmallIcon },
  { pwaIcon: SmokingStatusIcon, icon: SmokingStatusIcon },

  // { pwaIcon: DentalInactive, icon: DentalImage },
  // { pwaIcon: VisionInactive, icon: VisionImage },
  // { pwaIcon: EhealthImage, icon: EhealthImage },
  { pwaIcon: Bills, icon: Bill_IMAGE },
  { pwaIcon: ClaimsInactive, icon: ClaimsImage },
  { pwaIcon: Coverage, icon: CoverageSmallIcon },

  { pwaIcon: PayerProviderInactive, icon: AppointmentsImage },
  { pwaIcon: ConsentsInactive, icon: ConsentsInactive },
  { pwaIcon: CarePlanIcon, icon: CarePlanIcon },
];

const INACTIVE_ICONS = [
  MedicationInactive,
  DiagnosesInactive,
  MedicationInactive,
  ProcedureInactive,
  DiagnosesInactive,
  ImmunizationInactive,
  ConsentsInactive,
  DentalInactive,
  VisionInactive,
  LabsInactive,
  null,
  ClaimsInactive,
  null,
  PayerProviderInactive,
  ConsentsInactive,
];
const SERVICES_COPY = {};
let iconIndex = 0;
const get = () => {
  for (const key in SERVICES) {
    const servicesCopy = [...SERVICES[key]];
    for (let i = 0; i < servicesCopy.length; i++) {
      if (servicesCopy[i].active) {
        servicesCopy[i].icon = ICONS_IMAGES[iconIndex].icon;
        servicesCopy[i].pwaIcon = ICONS_IMAGES[iconIndex].pwaIcon;
      } else {
        servicesCopy[i].inactiveIcon = INACTIVE_ICONS[iconIndex];
        servicesCopy[i].pwaIcon = null;
      }
      iconIndex++;
    }

    SERVICES_COPY[key] = servicesCopy;
  }
  iconIndex = 0;
  return SERVICES_COPY;
};

const servicesIconsGetter = { get };

export default servicesIconsGetter;
