import altersIconGetter from './alertsIconGetter';
import circlesIconsGetter from './circlesIconsGetter';
import FooterIcons from './footerIconGetter';
import headerIconsGetter from './headerIconsGetter';
import homeIconsGetter from './homeIconsGetter';
import linkedRecordsIconGetter from './linkedRecordsIconGetter';
import circlesIconGetter from './circlesServicesIconGetter';
import moreServicesModalIconGetter from './moreServicesModalIconGetter';
import onboardingIconGetter from './onboardingIconGetter';
import servicesIconsGetter from './servicesIconsGetter';
import drawerIcons from './shareLinkDrawerIconGetter';
import utilitiesIconsGetter from './utilitiesIconsGetter';
import settingsIconsGetter from './settingsIconsGetter';

export const getHomeIcons = () => homeIconsGetter.get();

export const getServicesIcons = () => servicesIconsGetter.get();

export const getCirclesIcons = () => circlesIconsGetter.getCircles();

export const getCirclesGroupsIcons = () => circlesIconsGetter.getCirclesGroups();

export const getUtilitiesIcons = () => utilitiesIconsGetter.get();

export const getSettingsIcons = () => settingsIconsGetter.get();

export const getAlertIcons = () => altersIconGetter.getAlertIcon();

export const getHeaderMenuIcons = () => headerIconsGetter.getHeaderMenu();

// export const getHeaderIcons = () => headerIconsGetter.getHeader();
export const getHeaderIcons = () => {
  const iconsGetter = headerIconsGetter();
  return iconsGetter.getHeader();
};

export const getMedicareIcons = () => circlesIconGetter.getMedicareIcons();
export const getAhkIcons = () => circlesIconGetter.getAhkIcons();

export const getOnboardingIcons = () => onboardingIconGetter.get();
export const getDrawerIcons = () => drawerIcons.get();

export const getFooterIcons = () => FooterIcons.get();

export const getLinkedRecordIcons = () => linkedRecordsIconGetter.getLinkedRecordsIcon();

export const getMoreServiceModalIcons = () => moreServicesModalIconGetter.getModalIcons();

const IconGetter = {
  getHomeIcons,
  getServicesIcons,
  getCirclesIcons,
  getCirclesGroupsIcons,
  getLinkedRecordIcons,
  getUtilitiesIcons,
  getAlertIcons,
  getHeaderIcons,
  getHeaderMenuIcons,
  getMedicareIcons,
  getAhkIcons,
  getOnboardingIcons,
  getDrawerIcons,
  getMoreServiceModalIcons,
  getFooterIcons,
};

export default IconGetter;
