import gql from 'graphql-tag';

import client from '../components/ApolloClient';
import { CODE_FRAGMENT, PROCEDURES_FRAGMENT } from '../utils/fragments';

const FETCH_ALL_PROCEDURES_QUERY = gql`
  query fetchAllProcedures(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllProcedures(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      procedureList
      count
    }
  }
`;
const FETCH_ALL_PROCEDURES_BY_CONSENT = gql`
  query fetchAllProceduresByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllProceduresByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      procedureList
      count
    }
  }
`;

const FETCH_PROCEDURE_BY_ID = gql`
  query fetchProceDureByID($procedureId: String) {
    fetchProcedureById(procedureId: $procedureId) {
      procedure
      docArn
      documentReferenceId
    }
  }
`;
const FETCH_PROCEDURE_BY_ID_AND_GRANTOR_ID = gql`
  query fetchProcedureByIdAndGrantorId($procedureId: String, $grantorId: String) {
    fetchProcedureByIdAndGrantorId(procedureId: $procedureId, grantorId: $grantorId) {
      procedure
      docArn
      documentReferenceId
    }
  }
`;

const FETCH_RELATED_PROCEDURES = gql`
  query fetchRelatedProcedures($relatedResourceId: String, $relatedResourceType: String) {
    fetchRelatedProcedures(resourceId: $relatedResourceId, resourceType: $relatedResourceType) {
      id
      code {
        ...code
      }
    }
  }
  ${CODE_FRAGMENT}
`;

const SAVE_PROCEDURE_SELF_REPORTING = gql`
  mutation createProcedureSelfReportData($data: AddProcedureSelfReportData) {
    createProcedureSelfReportData(data: $data) {
      ...procedure
    }
  }
  ${PROCEDURES_FRAGMENT}
`;

const EDIT_PROCEDURE_SELF_REPORTING = gql`
  mutation editProcedureSelfReportData($data: AddProcedureSelfReportData, $procedureId: String) {
    editProcedureSelfReportData(data: $data, procedureId: $procedureId) {
      ...procedure
    }
  }
  ${PROCEDURES_FRAGMENT}
`;

const FETCH_HISTORICAL_RECORDS = gql`
  query fetchHistoricalProcedures(
    $procedureCode: String = ""
    $count: String = "10"
    $offset: Int = 0
    $dateFilter: Float = 0
  ) {
    fetchHistoricalProcedures(
      procedureCode: $procedureCode
      count: $count
      offset: $offset
      dateFilter: $dateFilter
    ) {
      procedureList
      count
    }
  }
`;

const MARK_RESTRICTED = gql`
  mutation updateMarkAsRestrictedProcedures($procedureId: String, $markAsRestricted: String) {
    updateMarkAsRestrictedProcedures(
      procedureId: $procedureId
      markAsRestricted: $markAsRestricted
    ) {
      ...procedure
    }
  }
  ${PROCEDURES_FRAGMENT}
`;

const ProceduresService = {
  fetchAllProcedures(variables, query = FETCH_ALL_PROCEDURES_QUERY) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllProcedures);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAllProceduresByConsent(args, query = FETCH_ALL_PROCEDURES_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllProceduresByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchProcedureById(id, query = FETCH_PROCEDURE_BY_ID) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            procedureId: id,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchProcedureById);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchProcedureByIdAndGrantorId(
    procedureId,
    grantorId,
    query = FETCH_PROCEDURE_BY_ID_AND_GRANTOR_ID,
  ) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            procedureId,
            grantorId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchProcedureByIdAndGrantorId);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchRelatedProcedures(args, query = FETCH_RELATED_PROCEDURES) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: args,
        })
        .then((response) => {
          resolve(response.data.fetchRelatedProcedures);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveSelfReportedProcedure(data, mutation = SAVE_PROCEDURE_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
          },
        })
        .then((response) => {
          resolve(response.data.createProcedureSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateProcedureSelfReportData(data, procedureId, mutation = EDIT_PROCEDURE_SELF_REPORTING) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            data,
            procedureId,
          },
        })
        .then((response) => {
          resolve(response.data.editProcedureSelfReportData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchHistoricalRecords(args, query = FETCH_HISTORICAL_RECORDS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchHistoricalProcedures);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateMarkAsRestrictedProcedures(procedureId, markAsRestricted, mutation = MARK_RESTRICTED) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            procedureId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.updateMarkAsRestrictedProcedures);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default ProceduresService;
