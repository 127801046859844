import React from 'react';
import { SvgIcon } from '@mui/material';

const Icon = (props) => {
  return (
    <SvgIcon width="34" height="40" viewBox="0 0 34 40" fill="none" {...props}>
      <path
        opacity="0.4"
        d="M21.7031 9.70312L12.2969 9.70312C11.0938 9.70312 10 10.6875 10 12L10 23.7031L6.28125 23.7031C4.20313 23.7031 3.10938 26.2187 4.64063 27.6406L15.3594 38.3594C16.2344 39.2344 17.7656 39.2344 18.6406 38.3594L29.3594 27.6406C30.7813 26.2187 29.7969 23.7031 27.7188 23.7031L24 23.7031L24 12C24 10.6875 22.9063 9.70312 21.7031 9.70312ZM31 5L3 5C1.6875 5 0.703129 3.90625 0.703129 2.70312C0.703129 1.39062 1.6875 0.296871 3 0.296871L31 0.296874C32.3125 0.296874 33.2969 1.39062 33.2969 2.70312C33.2969 3.90625 32.3125 5 31 5Z"
        fill="black"
      />
    </SvgIcon>
  );
};

export default Icon;
