import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import { DrawerBoxComponent } from './DrawerBoxComponent';
// import { APPOINTMENTS } from '../../shared/Strings';

export const AppointmentPaymentDetails = () => {
  return (
    <DrawerBoxComponent>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h3">Payment Details</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="p">Amount : $70</Typography>
                <StatusIcon
                  htmlColor="green"
                  sx={{
                    height: 12,
                    width: 12,
                    mr: 0.5,
                    ml: 1,
                  }}
                />
                <Typography variant="p">Paid</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="p">Payment ID : 1345783635</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DrawerBoxComponent>
  );
};
