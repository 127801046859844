import React from 'react';
import InputField from './InputField';

function FormComponent(props) {
  return (
    <div data-testid="formComponent">
      {props.inputFieldArray?.map((fields, index) => (
        <InputField
          key={index}
          placeholder={fields.placeholder}
          title={fields.title}
          error={fields.error}
          value={fields.value}
          helperImage={fields.helperImage}
          helperImageText={fields.helperImageText}
          helperImageUrl={fields.helperImageUrl}
          helperText={fields.helperText}
          errorMessage={fields.errorMessage}
          name={fields.name}
          onChange={fields.onChange}
          adornment={fields.adornment}
          adornmentPosition={fields.adornmentPosition}
          type={fields.type}
          hide={fields.hide}
          maxDate={props.maxDate}
          minDate={props.minDate}
        ></InputField>
      ))}
    </div>
  );
}

export default FormComponent;
