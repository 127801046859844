/* eslint-disable no-restricted-imports */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { UserInfoContext } from '../components/Routes';
import UpdateUserEmailModel from '../features/landingFlow/components/UpdateUserEmailModel';
import UpdateUserNameModal from '../features/landingFlow/components/UpdateUserNameModal';
import useAnalyticsLogger from './useAnalyticsLogger';

const useProfile = () => {
  const [showNameUpdateModel, setShowNameUpdateModel] = useState(false);
  const [showEmailUpdateModel, setShowEmailUpdateModel] = useState(false);
  const [callbackFunction, setCallbackFunction] = useState(() => {});
  const [onCloseCallback, setOnCloseCallback] = useState(() => {});
  const { userInfo } = React.useContext(UserInfoContext);
  const { firstName, lastName, email } = userInfo;
  const { logAnalyticsEvent } = useAnalyticsLogger();

  const verifyUserFullName = () => !!firstName && !!lastName;
  const verifyUserEmail = () => !!email;

  const updateUserFullNameIfNotExist = (onSaveHandler, onCloseHandler = () => {}, onSaveEvent) => {
    if (verifyUserFullName()) {
      onSaveHandler();
      return true;
    } else {
      setShowNameUpdateModel(true);
      setCallbackFunction(() => () => {
        onSaveEvent && logAnalyticsEvent(onSaveEvent);
        onSaveHandler();
      });
      setOnCloseCallback(() => () => onCloseHandler());
      return false;
    }
  };

  const renderProfileUpdateModal = () => {
    const onSave = () => {
      callbackFunction();
      setShowNameUpdateModel(false);
    };

    const onClose = () => {
      setShowNameUpdateModel(false);
      if (!verifyUserFullName()) {
        onCloseCallback();
      }
    };

    return <UpdateUserNameModal show={showNameUpdateModel} onClose={onClose} onSave={onSave} />;
  };

  const updateUserEmailIfNotExist = (onSaveHandler, onCloseHandler = () => {}) => {
    if (verifyUserEmail()) {
      onSaveHandler();
      return true;
    } else {
      setShowEmailUpdateModel(true);
      setCallbackFunction(() => () => onSaveHandler());
      setOnCloseCallback(() => () => onCloseHandler());
      return false;
    }
  };

  const renderEmailUpdateModal = (flag = '') => {
    const onSave = () => {
      callbackFunction();
      setShowEmailUpdateModel(false);
    };

    const onClose = () => {
      setShowEmailUpdateModel(false);
      if (!verifyUserEmail()) {
        onCloseCallback();
      }
    };
    return <UpdateUserEmailModel show={showEmailUpdateModel} onClose={onClose} onSave={onSave} />;
  };

  return {
    verifyUserFullName,
    updateUserFullNameIfNotExist,
    renderProfileUpdateModal,
    renderEmailUpdateModal,
    updateUserEmailIfNotExist,
  };
};

export default useProfile;
