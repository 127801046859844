import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ children, loggedIn, isUserCreated = true }) => {
  //const loggedIn = false;
  // const moreProps = {
  //   onFooterItemClick: rest.onFooterItemClick,
  //   onSetFullScreen: rest.onSetFullScreen,
  //   onSetNormalScreen: rest.onSetNormalScreen,
  // };
  console.log('assa', children, loggedIn);

  return !loggedIn ? (
    children
  ) : // <Routes>
  // <Route
  //   {...rest}
  //   // path={rest.path}
  //   element={<Component onFooterItemClick={moreProps.onFooterItemClick} onSetFullScreen={moreProps.onSetFullScreen} onSetNormalScreen={moreProps.onSetNormalScreen} />}
  //   // children={(props) =><Component {...props} {...moreProps} />}
  //   // render={(props) =>
  //   //   !loggedIn ? <Component {...props} {...moreProps} /> : <Navigate to="/home" />
  //   // }
  // />
  // </Routes>
  !isUserCreated ? (
    <Navigate to="/complete-registration" />
  ) : (
    <Navigate to="/home" />
  );
};

export default PublicRoute;
