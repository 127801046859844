import gql from 'graphql-tag';
import client from '../../components/ApolloClient';
import { ASSESSMENT_META_FRAGMENT, ASSESSMENT_RESPONSE_BODY_FRAGMENT } from '../../utils/fragments';

const UPDATE_ASSESSMENT = gql`
  mutation updateAssessment($assessmentRequest: AssessmentRequest) {
    updateAssessment(assessmentRequest: $assessmentRequest) {
      assessmentMeta {
        ...assessmentMeta
      }
      assessmentResponseBody {
        ...assessmentResponseBody
      }
      pageableResponse {
        count
        offset
        total
      }
    }
  }
  ${ASSESSMENT_META_FRAGMENT}
  ${ASSESSMENT_RESPONSE_BODY_FRAGMENT}
`;

const UPDATE_ASSESSMENT_STATUS = gql`
mutation UpdateAssessmentStatus($assessmentRequest: AssessmentRequest!) {
  updateAssessmentStatus(assessmentRequest: $assessmentRequest) {
    statusMessage
  }
}
`;

export const updateAssessmentStatus = async (assessmentInstanceId, status) => {
  try {
    const result = await client.mutate({
      mutation: UPDATE_ASSESSMENT_STATUS,
      variables: {
        assessmentRequest: {
          assessmentInstanceId,
          status,
        },
      },
    });
    return result?.data?.updateAssessmentStatus;
  } catch (error) {
    console.error('Error updating the status', error);
  }
};

export const AssessmentServices = {
  updateAssessment(args, mutation = UPDATE_ASSESSMENT) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            assessmentRequest: {
              ...args,
            },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.updateAssessment);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
