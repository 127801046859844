import React from 'react';
import { withStyles } from '@mui/styles';
import MUITextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import clsx from 'classnames';
import WarningIcon from '../../../assets/images/exclamation-sign-in-a-circle.png';
import DontShowPasswordIcon from '../../../assets/images/password_show.svg';
import ShowPasswordIcon from '../../../assets/images/show_password.svg';
import PasswordConfirmed from '../../../assets/images/password_confirmed.svg';
import SearchIcon from '../../../assets/images/search-icon.svg';
import { ErrorOutline } from '@mui/icons-material';

const styles = (theme) => ({
  input: {},
  lightInput: {
    '&:before': {
      borderColor: '#F5F4F8',
    },
  },
  passwordSucces: {
    color: '#6dd400',
  },
  valid: {
    color: theme.palette.secondary.main,
  },
  label: {},
  errorHelper: {
    color: theme.palette.error.main,
    ...theme.typography.caption,
  },
  successHelper: {
    ...theme.typography.caption,
    color: '#6dd400',
  },
  noHelper: {
    display: 'none',
  },
  pointer: {
    ...theme.customComponent.clickableItem,
  },
  errorOutline: {
    fill: theme.palette.error.main,
  },
  textField: {
    pointerEvents: (props) => (props.disabled ? 'none' : 'auto'),
  },
  searchIcon: {
    marginLeft: '10px',
    height: '12px',
    width: '12px',
  },
});

const defaultProps = {};

const propTypes = {};

const TextField = (props) => {
  const {
    autoFocus,
    classes,
    error,
    errorMsg,
    onChange,
    label,
    placeholder,
    required,
    type,
    fullWidth,
    defaultValue,
    maxLength,
    textFieldClass,
    inputFieldClass,
    showPassword,
    lightBorder,
    InputProps,
    passwordMatched,
    passwordMatchedIcon,
    passwordStrength,
    onShowPassword,
    onFieldChange,
    name,
    disabled,
    successMsg,
    searchIcon,
    disableunderline,
    searchIconColor,
    onKeyPressHandler,
    autoComplete,
    ...rest
  } = props;

  const handleTextChange = (event) => {
    onFieldChange(name, event.target.value);
  };

  const stopClipBoardEvent = (e) => {
    e.preventDefault();
  };

  const keyPressHandler = (e) => {
    onKeyPressHandler && onKeyPressHandler(e.key);
  };

  return (
    <MUITextField
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      classes={{
        root: clsx(textFieldClass, classes.textField),
      }}
      label={label}
      placeholder={placeholder}
      error={Boolean(error)}
      onChange={handleTextChange}
      onKeyPress={(e) => keyPressHandler(e)}
      required={required}
      type={type}
      onCut={type === 'password' ? stopClipBoardEvent : null}
      onCopy={type === 'password' ? stopClipBoardEvent : null}
      onPaste={type === 'password' ? stopClipBoardEvent : null}
      defaultValue={defaultValue}
      InputProps={{
        classes: {
          input: inputFieldClass ? inputFieldClass : null,
        },
        disableunderline: disableunderline,
        className: `${[
          lightBorder ? classes.lightInput : classes.input,
          passwordMatched ? classes.passwordSucces : null,
        ].join(' ')} `,
        endAdornment: passwordMatchedIcon ? (
          <InputAdornment position="end">
            <img src={PasswordConfirmed} />
          </InputAdornment>
        ) : showPassword ? (
          error ? (
            <React.Fragment>
              <InputAdornment position="end">
                <img src={WarningIcon} />
              </InputAdornment>
              <InputAdornment position="end">
                <img
                  className={classes.pointer}
                  onClick={() => onShowPassword()}
                  src={type === 'password' ? DontShowPasswordIcon : ShowPasswordIcon}
                />
              </InputAdornment>
            </React.Fragment>
          ) : passwordStrength ? (
            <React.Fragment>
              <InputAdornment position="end">
                <img
                  className={classes.pointer}
                  onClick={() => onShowPassword()}
                  src={type === 'password' ? DontShowPasswordIcon : ShowPasswordIcon}
                />
              </InputAdornment>
            </React.Fragment>
          ) : (
            <InputAdornment position="end">
              <img
                className={classes.pointer}
                onClick={() => onShowPassword()}
                src={type === 'password' ? DontShowPasswordIcon : ShowPasswordIcon}
              />
            </InputAdornment>
          )
        ) : error ? (
          <InputAdornment position="end">
            <ErrorOutline className={classes.errorOutline} />
          </InputAdornment>
        ) : null,
        startAdornment: searchIcon ? (
          <InputAdornment position="start">
            <img className={classes.searchIcon} color={searchIconColor} src={SearchIcon} />
          </InputAdornment>
        ) : null,
        ...InputProps,
      }}
      InputLabelProps={{
        shrink: false,
        classes: {
          root: classes.label,
        },
      }}
      FormHelperTextProps={{
        classes: {
          root: error ? classes.errorHelper : successMsg ? classes.successHelper : classes.noHelper,
        },
      }}
      disabled={disabled}
      helperText={errorMsg || successMsg}
      {...rest}
    />
  );
};
TextField.defaultProps = defaultProps;
TextField.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(TextField);
