import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes as RouterRoute,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Loader from '../components/atoms/Loader';
import AddEmail from '../pages/AddEmail';
import AddPhone from '../pages/AddPhone';
import Help from '../pages/Help';
import Auth from '../service/Auth';
import FirebaseUtils from '../utils/FirebaseUtils';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AlertService from '../service/Alerts';
import { useContext } from 'react';
import { AlertCountContext } from './RouterWrapper';
import publicIp from 'public-ip';

const App = React.lazy(() => import('./App'));
const Logout = React.lazy(() => import('../pages/Logout'));
const HomeDashboard = React.lazy(() =>
  import('../features/dynamicHome/pages/HomeDashboard/HomeDashboard.js'),
);
// const Login = React.lazy(() => import('../pages/Login/index.js'));
const Login = React.lazy(() => import('../features/landingFlow/pages/Login/index.js'));
const Callback = React.lazy(() => import('../pages/Callback'));
const LandingPage = React.lazy(() => import('../pages/LandingPage/index.js'));
const Signup = React.lazy(() => import('../pages/Signup/index.js'));
// const NewLandingPage = React.lazy(() => import('../pages/NewLandingFlow/LandingPage.jsx'));
const NewLandingPage = React.lazy(() =>
  import('../features/landingFlow/pages/LandingPage/index.js'),
);
const ServicesDashboard = React.lazy(() => import('../pages/ServicesDashboard/index.js'));
const UtilitiesDashboard = React.lazy(() => import('../pages/UtilitiesDashboard/index.js'));
const AccountDetails = React.lazy(() => import('../pages/AccountDetails/index.js'));
// const CirclesDashboard = React.lazy(() => import('../pages/CirclesDashboard/index.js'));
const PayeeInformation = React.lazy(() => import('../pages/PayeeInformation'));
const PaymentForm = React.lazy(() => import('../pages/PaymentForm'));
const ScanBill = React.lazy(() => import('../pages/ScanBill'));
// const BillSummary = React.lazy(() => import('../pages/BillSummary'));
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('../pages/ResetPassword'));
const AwardPoints = React.lazy(() => import('../pages/AwardPoints'));
const AwardPointsNew = React.lazy(() => import('../pages/AwardPointsNew'));
const GiftAwardPoints = React.lazy(() => import('../pages/AwardPoints/GiftAwardPoints'));
const EarnAwardPoints = React.lazy(() => import('../pages/EarnAwardPoints'));
// const NavigtionDrawer = React.lazy(() => import('../components/organisms/NavigationDrawer';
const Rating = React.lazy(() => import('../pages/Rating'));
const MuiRating = React.lazy(() => import('../pages/Rating'));
const RatingSuccess = React.lazy(() => import('../pages/RatingSuccess/'));
const AddCard = React.lazy(() => import('../pages/AddCard'));
const EmailVerification = React.lazy(() => import('../components/EmailVerification'));
const AddBankAccount = React.lazy(() => import('../pages/AddBankAccount'));
const EditCard = React.lazy(() => import('../pages/EditCard'));
const ReferralScreen = React.lazy(() => import('../pages/ReferralScreen'));
const ManagePayment = React.lazy(() => import('../pages/ManagePayment'));
const MakePayment = React.lazy(() => import('../pages/MakePayment'));
const AlertsList = React.lazy(() => import('../pages/AlertsList'));
// import history from '../history';
const EditProfile = React.lazy(() => import('../pages/EditProfile'));
const SelectPaymentMethod = React.lazy(() => import('../pages/SelectPaymentMethod'));
const RatingsDashboard = React.lazy(() => import('../pages/RatingsDashboard/'));
const SecondOpinionDashboard = React.lazy(() => import('../components/SecondOpinionDashboard'));
const Header = React.lazy(() => import('./molecules/Header'));
const Footer = React.lazy(() => import('./molecules/Footer'));
const SideNavigationPane = React.lazy(() => import('./molecules/SideNavigationPane'));
const GiftCard = React.lazy(() => import('../pages/GiftCard'));
const GiftCardReedem = React.lazy(() => import('../pages/GiftCardReedem'));
const SecondOpinions = React.lazy(() => import('../pages/SecondOpinions'));
const TermsAndConditions = React.lazy(() => import('../pages/TermsAndCondition'));
const AwardPointPay = React.lazy(() => import('../pages/AwardPointPay'));
const SettingsDashboard = React.lazy(() => import('../pages/SettingsDashboard'));
const DownloadRecords = React.lazy(() => import('../pages/DownloadRecords'));
const DownloadTerms = React.lazy(() => import('../pages/DownloadTerms'));
const Deactivate = React.lazy(() => import('../pages/Deactivate'));
const Circles = React.lazy(() => import('../pages/Circles'));
const Categories = React.lazy(() => import('../pages/Categories'));
import {
  getComponentLink,
  fetchAndStoreCaseId,
  getAnalyticsEventsForRouteName,
  detectDeviceName,
} from '../utils/helper';
import {
  getUserName,
  calculateAgeFromDOB,
  fireHanselEvent,
  registerHanselEventListener,
} from '../utils/helper';
const ContactDetails = React.lazy(() => import('../pages/ContactDetails'));

const UserAccount = React.lazy(() => import('../pages/UserAccount'));
const BillDetails = React.lazy(() => import('../pages/BillDetails'));
const RatingAfterService = React.lazy(() => import('../pages/RatingAfterService'));
const PhoneVerification = React.lazy(() => import('../pages/PhoneVerification'));
const PhoneVerificationUnAuth = React.lazy(() => import('../pages/PhoneVerificationUnAuth'));
const PageNotFound = React.lazy(() => import('../pages/ErrorScreen'));
const AddAddress = React.lazy(() => import('../pages/AddAddress'));
const ProfileScreen = React.lazy(() => import('../pages/ProfileScreen'));
const ChangePassword = React.lazy(() => import('../pages/ChangePassword'));
const ProceedToSecondOpinion = React.lazy(() => import('../pages/ProceedToSecondOpinion'));
const AddPlan = React.lazy(() => import('../pages/AddPlan'));
const EditPlan = React.lazy(() => import('../pages/EditPlan'));
const IFrameManager = React.lazy(() => import('../pages/IFrameManager'));
const IFrameCallback = React.lazy(() => import('../pages/IFrameCallback'));
// const Coverage = React.lazy(() => import('../pages/Coverage'));
const CoverageSummaryScreen = React.lazy(() => import('../pages/CoverageSummary'));
const AddCoveragePage = React.lazy(() => import('../pages/AddCoveragePage'));

const ReviewedCase = React.lazy(() => import('../pages/ReviewedCase'));
const EditBankAccount = React.lazy(() => import('../pages/EditBankAccount'));
const PolicyDialog = React.lazy(() => import('./molecules/PolicyDialog'));
// const SettingsPage = React.lazy(() => import('../pages/SettingsPage'));
// import DeactivateAccountPage from '../pages/DeactivateAccountPage';
import { ROUTE_NAMES } from '../constants/routeNames';
import useWidth from '../hooks/useWidth';
import CarePlanScreen from '../pages/CarePlan';
import EAMCallback from '../pages/MyPartners/eamCallback';
import NewForgotPassword from '../pages/NewForgotPassword';
import NewReferralScreen from '../pages/ReferralScreen/NewReferralScreen';
import HistoricalRecords from '../pages/SelfReportedScreens/HistoricalRecordsDashboard';
import FindCare from '../pages/Shop/FindCare';
import Shop from '../pages/Shop/index';
import SocialSignin from '../pages/SocialSignin';
import TransactionDetails from '../pages/TransactionDetails';
import UserService, { SET_LAST_LOGIN_QUERY } from '../service/User';
import {
  CDN_URL_FOR_TEMPLATE,
  DEACTIVATE,
  ERROR_MSG_FOR_TEMPLATE_LOADING_FAILURE,
} from '../utils/constants';
const PartnerLabResults = React.lazy(() => import('../pages/PartnerLabResults'));
const PartnerAllergies = React.lazy(() => import('../pages/Allergies'));
const PartnerConditions = React.lazy(() => import('../pages/Conditions'));
const PartnerProcedure = React.lazy(() => import('../pages/Procedures'));
const PartnerMedications = React.lazy(() => import('../pages/Medications'));
const PartnerImmunizations = React.lazy(() => import('../pages/PartnerImmunizations'));
const PartnerClaims = React.lazy(() => import('../pages/Claims'));
const PartnerVitals = React.lazy(() => import('../pages/PartnerVitals'));
const ServiceBills = React.lazy(() => import('../pages/ServiceBills'));
const ServiceConsents = React.lazy(() => import('../pages/Consents'));
const IndividualProfile = React.lazy(() => import('../pages/InMyCare/IndividualProfile'));
const IndividualDashboard = React.lazy(() => import('../pages/InMyCare/IndividualDashboard'));

const VitalDetails = React.lazy(() => import('../pages/VitalDetails'));
const VitalAllData = React.lazy(() => import('../pages/VitalAllData'));
const VisitsDashboard = React.lazy(() => import('../pages/Visits'));
const Socialhistorydashboard = React.lazy(() => import('../pages/SocialHistoryPage'));

// self reported components
const AddEditCondition = React.lazy(() => import('../pages/SelfReporting/AddEditCondition'));
const AddEditProcedure = React.lazy(() => import('../pages/SelfReporting/AddEditProcedure'));
const AddEditImmunization = React.lazy(() => import('../pages/SelfReporting/AddEditImmunization'));
const AddEditAllergy = React.lazy(() => import('../pages/SelfReporting/AddEditAllergy'));
const AddEditMedication = React.lazy(() => import('../pages/SelfReporting/AddEditMedication'));
const AddEditDiagnosticReport = React.lazy(() =>
  import('../pages/SelfReporting/AddEditDiagnosticReport'),
);
const AddEditCoverage = React.lazy(() => import('../pages/SelfReporting/AddEditCoverage'));
const SelfReporting = React.lazy(() =>
  import('../features/dynamicRecords/Container/SelfReporting'),
);

const CarePlan = React.lazy(() => import('../pages/CarePlan'));
const RedeemGiftCard = React.lazy(() => import('../pages/RedeemGiftCard'));
const CareTeam = React.lazy(() => import('../pages/CareTeam'));
const FamilyMemberHistory = React.lazy(() => import('../pages/FamilyMemberHistory'));
const MarketCategory = React.lazy(() => import('../pages/MarketCategory'));
const Feedback = React.lazy(() => import('../pages/Feedback'));
const Support = React.lazy(() => import('../pages/Support'));
const OneTimeExpiredDashboard = React.lazy(() => import('../pages/OneTimeAccessExpired'));
import useAnalyticsLogger from '../hooks/useAnalyticsLogger';
// import SelfReportedLogs from '../pages/SelfReportedScreens/SelfReportedLogs';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsUserCreated } from '../store/features/socialLoginSlice';
import NavigationDrawer from './organisms/NavigationDrawer';
import ReusableCategoriesContainer from '../features/dynamicRecords/Container/ReusableCategoriesContainer';
import { SOCIAL_SIGN_IN } from '../features/landingFlow/constants';
import api from '../features/landingFlow/api';
import { updateThroughAutoLogin } from '../store/features/signupSlice';
import axios from 'axios';
import { updateTemplate } from '../store/features/dynamicTemplate';
import { GET_ALERTS_COUNT } from '../pages/HomeDashboard';
import DeeplinkRedirect from '../pages/DeeplinkRedirect';
import AssessmentContainer from '../features/assessments/screens/AssessmentContainer';
import DeepLinkHandler from '../features/assessments/screens/DeepLinkHandler';
const CommunicationPreference = React.lazy(() =>
  import('../pages/CommunicationPreference/index.js'),
);

export const auth = new Auth();
export const UserInfoContext = React.createContext({});
export const DeactivateContext = React.createContext({});

const styles = (theme) => ({
  rootContainer: {
    display: 'flex',
    flexGrow: 1,
    height: 'calc(100% - 83px)',
    top: '83px',
    position: 'fixed',
    overflow: 'auto',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 83px - 56px)',
    },
  },
  rootContainerFullScreen: {
    top: 0,
    height: 'calc(100% - 56px)',
  },
  sideNavContainer: {
    width: '15%',
    marginRight: '50px',
  },
  largeContentContainer: {
    width: '85%',
    overflow: 'auto',
  },
  smallContentContainer: {
    width: '100%',
  },
  noLoggedInRootContainer: {
    top: 0,
    height: '100%',
  },
  progress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: 100,
    color: 'blue',
  },
});

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

const requestNotificationPermission = () => {
  if (!('Notification' in window)) {
    //No support for push notifications
  } else if (Notification.permission === 'granted') {
    FirebaseUtils.notificationMessaging();
  } else if (Notification.permission !== 'denied' || Notification.permission === 'default') {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        FirebaseUtils.notificationMessaging();
      } else {
        console.log('Unable to get permission to notify.');
      }
    });
  }
};

const Routes = (props) => {
  const { classes } = props;
  const [reason, setReason] = useState(DEACTIVATE.REASON_1);
  const [isLoggedIn, updateLoginStatus] = useState(false);
  const [isSocialLoginSuccess, setSocialLoginSuccess] = useState(false);
  const [isLoading, updateLoading] = useState(true);
  const [activeFooterItemId, setActiveFooterItemId] = useState(-1);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [isPhoneVerified, setPhoneVerified] = useState(true);
  const userInfoObject = {
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    avatarUrl: '',
    dob: null,
    gender: null,
    isDependent: localStorage.getItem('dependentFhirId') ? true : false,
    hasDependent: false,
    dependentProfiles: [],
    activeProfile: {},
    relationship: 'Primary',
  };
  const [userInfo, setUserInfo] = useState(userInfoObject);
  const { isUserCreated, userSchemaObj } = useSelector((state) => state.socialLogin);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { logAnalyticsEvent } = useAnalyticsLogger();
  const isNewFlow = true;
  const checkOneTimeStatus = auth.checkOneTimeStatus();
  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.SIGNUP);
  const { setAlertsCount } = useContext(AlertCountContext);

  const fetchAlertsCount = () => {
    AlertService.getUnreadAlertsCount(GET_ALERTS_COUNT)
      .then((res) => {
        const alertsCountList = res.notificationCount.notificationCountList;
        let totalCount = 0;
        alertsCountList.forEach((obj) => {
          totalCount += obj.count;
        });
        setAlertsCount(totalCount);
      })
      .catch(() => {
        setAlertsCount(0);
      });
  };

  useEffect(() => {
    auth.checkIfUserAlreadyLoggedIn();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (isLoggedIn === true) {
      const socialLogin = JSON.parse(localStorage.getItem('socialLogin'));
      fetchAlertsCount();
      dispatch(
        updateIsUserCreated({
          isUserCreated: socialLogin?.isUserCreated ?? true,
          userSchemaObj: socialLogin?.userSchemaObj ?? {},
        }),
      );

      if ('serviceWorker' in navigator) {
        requestNotificationPermission();
      }
      UserService.fetchUserInfo()
        .then((result) => {
          const { tags, id } = result;
          localStorage.setItem('userId', id);
          try {
            Hansel.getUser().putAttribute(
              'member_outcome',
              tags.length > 0 ? tags.toString() : String(null),
            );
          } catch (e) {
            console.log('Error: ', e);
          }

          setUserInfo({ ...userInfo, ...result });
        })
        .catch((error) => {
          console.error(error);
        });
      UserService.setLastLoginTime(SET_LAST_LOGIN_QUERY);
      UserService.fetchUserTimeZone(userTimeZone);
    }

    return () => {
      setUserInfo(userInfoObject);
    };
  }, [isLoggedIn, isSocialLoginSuccess]);

  //this is to get the templates from CDN for dynamic categories
  const getTemplateForDynamicCategories = useCallback(() => {
    // dispatch(updateTemplate());
    axios
      .get(process.env.CDN_URL_FOR_TEMPLATE)
      .then((res) => {
        dispatch(updateTemplate(res?.data));
      })
      .catch((error) => {
        console.log(
          ERROR_MSG_FOR_TEMPLATE_LOADING_FAILURE,
          '\n url',
          process.env.CDN_URL_FOR_TEMPLATE,
          error,
        );
      });
  }, []);

  //  useEffect to check one time user logged in status
  useEffect(() => {
    if (checkOneTimeStatus?.hasAccess) {
      auth.checkIfUserAlreadyLoggedIn(false, true);
      getTemplateForDynamicCategories();
    }
  }, [checkOneTimeStatus?.hasAccess]);

  const parseParams = (str) => {
    const pieces = str.split('&'),
      data = {};
    let i, parts;
    // process each query pair
    for (i = 0; i < pieces.length; i++) {
      parts = pieces[i].split('=');
      if (parts.length < 2) {
        parts.push('');
      }
      data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return data;
  };

  const handleEAMRedirection = () => {
    const params = new URLSearchParams(window.location.search);
    const otherParamObj = parseParams(location.hash.substring(1));

    if (params.has('code')) {
      localStorage.setItem('EAMCode', params.get('code'));
    }
    if (params.has('state')) {
      localStorage.setItem('EAMState', params.get('state'));
    }
    const code = params.has('code') ? params.get('code') : null;
    const eamState = params.has('state') ? params.get('state') : otherParamObj['state'];
    let link = '';
    if (code && eamState) {
      link = `circles/auth/callback?code=${code}&state=${eamState}`;
    }

    return link;
  };

  useEffect(() => {
    FirebaseUtils.init();
    // registering listener for hansel events
    registerHanselEventListener();

    // Clearing LocalStorage when onetimeaccess route is accessed to clear active user session if any
    const requestId = searchParams.get('requestId');
    requestId && auth.clearStorage();
  }, []);

  const handleOnUpdate = (link, isUserPhoneVerified) => {
    updateLoginStatus(true);
    setPhoneVerified(isUserPhoneVerified);
    const path = link.split('#');
    //need to revisit this logic
    navigate(path[0]);
  };

  const redeemReferralPromoCode = async (referralPromoObj) => {
    const referralCodeData = auth.getReferralPromoCode();
    const code = referralCodeData?.code;
    const isReferralCode = referralCodeData?.isReferral;
    const isPromoCode = referralCodeData?.isPromoCode;
    if (code) {
      let ipAddress = '0.0.0.0';
      try {
        ipAddress = await publicIp.v4();
      } catch (e) {
        console.log(e);
      }
      const obj = {
        userId: referralPromoObj?.id,
        ipAddress: ipAddress,
      };
      try {
        if (isReferralCode) {
          const redeemedReferalCodeResponse = await api.redeemReferralCode({
            ...obj,
            username: referralPromoObj?.email,
            userFullName: `${referralPromoObj?.firstName} ${referralPromoObj?.lastName}`,
            referralCode: code,
          });
        } else if (isPromoCode) {
          const redeemedPromoCodeResponse = await api.redeemPromoCode({
            ...obj,
            promoCode: code,
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleOnCompleteRegistration = async (link, isUserCreated, userSchemaObj) => {
    const referralCodeData = auth.getReferralPromoCode();
    updateLoginStatus(true);
    dispatch(updateIsUserCreated({ isUserCreated: false, userSchemaObj: userSchemaObj }));
    const socialProvider = localStorage.getItem('isSocialLogin');
    const socialSignupFrom = localStorage.getItem('socialSignupFrom');
    if (userSchemaObj && socialProvider) {
      if (socialProvider === SOCIAL_SIGN_IN.APPLE) {
        socialSignupFrom === 'SIGNUP_SCREEN'
          ? logAnalyticsEvent(analyticsEvents.SIGNUP_APPLE_COMPLETED_V4)
          : logAnalyticsEvent(analyticsEvents.LOGIN_APPLE_COMPLETED_V4);
      } else if (socialProvider === SOCIAL_SIGN_IN.GOOGLE) {
        socialSignupFrom === 'SIGNUP_SCREEN'
          ? logAnalyticsEvent(analyticsEvents.SIGNUP_GOOGLE_COMPLETED_V4)
          : logAnalyticsEvent(analyticsEvents.LOGIN_GOOGLE_COMPLETED_V4);
      } else if (socialProvider === SOCIAL_SIGN_IN.FACEBOOK) {
        socialSignupFrom === 'SIGNUP_SCREEN'
          ? logAnalyticsEvent(analyticsEvents.SIGNUP_FACEBOOK_COMPLETED_V4)
          : logAnalyticsEvent(analyticsEvents.LOGIN_FACEBOOK_COMPLETED_V4);
      }
    }

    if (!isUserCreated) {
      const socialSignup = {
        id: userSchemaObj?.id,
        email: userSchemaObj?.email,
        firstName: userSchemaObj?.firstName,
        lastName: userSchemaObj?.lastName,
        password: null,
        isSocialLogin: true,
        deviceType: detectDeviceName(),
        // referralPromoCode: referralCodeData?.code,
      };
      api
        .createSocialSignupAccount(socialSignup)
        .then(async (res) => {
          if (res === 'success') {
            redeemReferralPromoCode(socialSignup);
            hasUserCreated();
            logAnalyticsEvent(analyticsEvents.SIGNUP_COMPLETED_V4);
            logAnalyticsEvent(analyticsEvents.SIGNUP_AUTO_LOGIN_V4);
            dispatch(updateThroughAutoLogin(true));
            await auth.handleAuthentication(null, true);
            setSocialLoginSuccess(true);
            navigate('/home');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      updateLoginStatus(true);
      const path = link.split('#');
      //need to revisit this logic
      navigate(path[0]);
    }
  };

  const hasPhoneBeenVerified = () => {
    setPhoneVerified(true);
  };

  const hasUserCreated = () => {
    dispatch(updateIsUserCreated({ isUserCreated: true, userSchemaObj: {} }));
  };

  const onFooterItemClick = (footerId) => {
    setActiveFooterItemId(footerId);
  };

  const handleOnSetFullScreen = () => {
    setShowFullScreen(true);
  };

  const handleOnSetNormalScreen = () => {
    setShowFullScreen(false);
  };

  useEffect(() => {
    const result = auth.isLoggedIn();
    const eamRedirectionLink = handleEAMRedirection();
    if (result.status === 'true') {
      updateLoginStatus(true);
      updateLoading(false);
      getTemplateForDynamicCategories();
    } else if (result.status === 'false') {
      updateLoginStatus(false);
      setTimeout(() => updateLoading(false), 1000);
    } else {
      const componentLink = eamRedirectionLink || getComponentLink(window.location.href);
      fetchAndStoreCaseId(window.location.href);
      localStorage.removeItem('isLoggedIn');
      auth.login();
    }
  });

  const screenWidth = useWidth();

  const isLarge = screenWidth === 'lg' || screenWidth === 'xl';

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      <DeactivateContext.Provider value={{ reason, setReason }}>
        {isLoggedIn && isUserCreated && isPhoneVerified && <NavigationDrawer />}
        {isLoading ? (
          <div>loading</div>
        ) : (
          <Suspense
            fallback={
              <div height="100%">
                <Loader />
              </div>
            }
          >
            {!isPhoneVerified && isLoggedIn && (
              <Navigate
                to={{
                  pathname: '/phoneverification',
                  setPhoneVerified: hasPhoneBeenVerified,
                }}
              />
            )}
            {/* {!isUserCreated && isLoggedIn && (
              <Navigate
                to={{
                  pathname: '/complete-registration',
                  setIsUserCreated: hasUserCreated,
                }}
              />
            )} */}
            <App>
              {isLoggedIn && isUserCreated && isPhoneVerified && !showFullScreen && (
                <Header auth={auth} />
              )}
              <div
                className={
                  showFullScreen
                    ? clsx(classes.rootContainer, classes.rootContainerFullScreen)
                    : isLoggedIn && isUserCreated
                    ? classes.rootContainer
                    : clsx(classes.rootContainer, classes.noLoggedInRootContainer)
                }
              >
                {isLoggedIn && isUserCreated && isPhoneVerified && isLarge && !showFullScreen && (
                  <div className={classes.sideNavContainer}>
                    <SideNavigationPane
                      activeState={activeFooterItemId}
                      onFooterItemClick={onFooterItemClick}
                    />
                  </div>
                )}
                <div
                  className={
                    isLoggedIn && isUserCreated && isPhoneVerified && isLarge && !showFullScreen
                      ? classes.largeContentContainer
                      : classes.smallContentContainer
                  }
                >
                  <RouterRoute>
                    <Route
                      exact
                      path="/signup"
                      element={
                        <PublicRoute loggedIn={isLoggedIn} isUserCreated={isUserCreated}>
                          {isNewFlow ? (
                            <NewLandingPage type="signup" />
                          ) : (
                            <Signup
                              onSetFullScreen={handleOnSetFullScreen}
                              onSetNormalScreen={handleOnSetNormalScreen}
                            />
                          )}
                        </PublicRoute>
                      }
                    />
                    <Route
                      exact
                      path="/deeplinkRedirect/:stage/:payload"
                      element={
                        <PublicRoute>
                          <DeeplinkRedirect />
                        </PublicRoute>
                      }
                    />
                    <Route
                      exact
                      path="/assessmentDeepLink"
                      element={
                        <PublicRoute>
                          <DeepLinkHandler />
                        </PublicRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/auth/callback"
                      element={
                        <PublicRoute loggedIn={false}>
                          <EAMCallback loggedIn={false} />
                        </PublicRoute>
                      }
                    />
                    <Route exact path="/logout" element={<Logout loggedIn={isLoggedIn} />} />
                    {/* <PublicRoute exact path="/" component={LandingPage} loggedIn={isLoggedIn} />
                     */}
                    <Route
                      exact
                      path="/login"
                      element={
                        isLoggedIn && isUserCreated ? (
                          <Navigate to={ROUTE_NAMES.HOME} />
                        ) : isLoggedIn && !isUserCreated ? (
                          <Navigate to="/complete-registration" />
                        ) : isNewFlow ? (
                          <NewLandingPage type="login" />
                        ) : (
                          <Login />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/"
                      element={
                        isLoggedIn && isUserCreated ? (
                          <Navigate to={ROUTE_NAMES.HOME} />
                        ) : isLoggedIn && !isUserCreated ? (
                          <NewLandingPage
                            type="complete_registration"
                            userSchemaObj={userSchemaObj}
                          />
                        ) : isNewFlow ? (
                          <NewLandingPage type="login" />
                        ) : (
                          <Login />
                        )
                      }
                    />
                    {/* One time access route starts*/}
                    <Route
                      exact
                      path="/oneTimeAccess"
                      element={
                        checkOneTimeStatus?.hasAccess ? (
                          <Navigate to="/oneTimeDashboard" />
                        ) : (
                          <NewLandingPage type="oneTimeAccess" />
                        )
                      }
                    />
                    <Route
                      exact
                      path="/oneTimeDashboard"
                      element={
                        <PrivateRoute loggedIn={checkOneTimeStatus?.hasAccess}>
                          <IndividualDashboard onFooterItemClick={() => undefined} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/oneTimeAccessExpired"
                      element={
                        <PrivateRoute loggedIn={checkOneTimeStatus?.isExpired}>
                          <OneTimeExpiredDashboard />
                        </PrivateRoute>
                      }
                    />
                    {/* One time access route ends*/}
                    <Route
                      exact
                      path="/home"
                      element={
                        !isUserCreated && isLoggedIn ? (
                          <NewLandingPage
                            type="complete_registration"
                            userSchemaObj={userSchemaObj}
                          />
                        ) : (
                          <PrivateRoute loggedIn={isLoggedIn}>
                            <HomeDashboard onFooterItemClick={onFooterItemClick} />
                          </PrivateRoute>
                        )
                      }
                    />

                    <Route
                      exact
                      path={ROUTE_NAMES.MARKET_CATEGORY}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <MarketCategory onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={ROUTE_NAMES.SHOP}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Shop onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      exact
                      path="/shop/findCare/*"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <FindCare onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      exact
                      path="/callback"
                      element={
                        <Callback
                          {...props}
                          handleOnCompleteRegistration={handleOnCompleteRegistration}
                          onUpdate={handleOnUpdate}
                        />
                      }
                    />
                    {/* New route */}
                    <Route
                      exact
                      path="/records/new/:categoryName"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ReusableCategoriesContainer onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      exact
                      path="/records/new/:categoryName/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <SelfReporting onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/:categoryName/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <SelfReporting onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      exact
                      path="/circles/:partner/:circleId/new/:categoryName"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ReusableCategoriesContainer onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/:partner/new/:categoryName"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ReusableCategoriesContainer onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/new/:categoryName/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ReusableCategoriesContainer onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/new/:categoryName/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <ReusableCategoriesContainer onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    {/* //////////// */}
                    <Route
                      exact
                      path="/circles/individual/coverage/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CoverageSummaryScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/coverage/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <CoverageSummaryScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/family_member_history/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <FamilyMemberHistory onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/family_member_history/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <FamilyMemberHistory onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/smoking_status/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Socialhistorydashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/smoking_status/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <Socialhistorydashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/:partner/bill_pay"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ServiceBills onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/bill_pay/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ServiceBills onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/bill_pay/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <ServiceBills onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/care_plan/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <CarePlanScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/care_plan/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CarePlanScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/care_team/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CareTeam onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/care_team/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <CareTeam onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/clinical_vitals/:grantorId/:granteeId/:vitalType/allData"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <VitalAllData onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/clinical_vitals/:grantorId/:granteeId/:vitalType/allData"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <VitalAllData onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/clinical_vitals/:grantorId/:granteeId/:vitalType"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VitalDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/clinical_vitals/:grantorId/:granteeId/:vitalType"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <VitalDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/clinical_vitals/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerVitals onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/clinical_vitals/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <PartnerVitals onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/procedures/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerProcedure onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/procedures/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <PartnerProcedure onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/labs/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerLabResults onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/labs/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <PartnerLabResults onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/visits/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VisitsDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/visits/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <VisitsDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/claims/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerClaims onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/claims/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <PartnerClaims onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/immunizations/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerImmunizations onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/immunizations/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <PartnerImmunizations onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      exact
                      path="/circles/individual/conditions/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerConditions onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    /> */}
                    {/* <Route
                      exact
                      path="/records/individual/conditions/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <PartnerConditions onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    /> */}
                    <Route
                      exact
                      path="/circles/individual/medications/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerMedications onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/medications/:grantorId/:granteeId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <PartnerMedications onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/refer"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ReferralScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={ROUTE_NAMES.REFER}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <NewReferralScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <UtilitiesDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/accountDetails/:profileOtp"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AccountDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={ROUTE_NAMES.ACCOUNT_DETAILS}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AccountDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/feedback"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Feedback onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/support"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Support onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/support/:categoryName"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ReusableCategoriesContainer onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      exact
                      path="/settings"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <SettingsDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/privacyPolicy"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PolicyDialog onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/termsConditions"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PolicyDialog onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/deactivate"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Deactivate onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/communicationPreference"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CommunicationPreference onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/downloadRecords"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <DownloadRecords onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/downloadTerms"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <DownloadTerms onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/consents/:consentType"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ServiceConsents onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/consents/"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ServiceConsents onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/:serviceCategory/historicalRecords"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <HistoricalRecords onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual/profile"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <IndividualProfile onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual/profile"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <IndividualProfile onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/individual"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <IndividualDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/individual"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <IndividualDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/:parameters"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Circles onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      exact
                      path="/circles/:serviceCategory/historicalRecords"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <HistoricalRecords onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.COVERAGE}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CoverageSummaryScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.CLAIMS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerClaims onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.CLAIMS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerClaims onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.CONDITIONS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerConditions onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    /> */}
                    <Route
                      exact
                      path="/records/conditions/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddEditCondition onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      exact
                      path={`/records/${ROUTE_NAMES.CONDITIONS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn || checkOneTimeStatus?.hasAccess}>
                          <PartnerConditions onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    /> */}
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.MEDICATIONS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerMedications onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/medication/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddEditMedication onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.MEDICATIONS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerMedications onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/procedures/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddEditProcedure onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.PROCEDURES}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerProcedure onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.PROCEDURES}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerProcedure onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.VISITS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VisitsDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.SOCIAL_HISTORY}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Socialhistorydashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.ALLERGIES}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerAllergies onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    /> */}
                    <Route
                      exact
                      path="/records/allergies/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddEditAllergy onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      exact
                      path={`/records/${ROUTE_NAMES.ALLERGIES}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerAllergies onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    /> */}
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.IMMUNIZATIONS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerImmunizations onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/immunization/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddEditImmunization onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.IMMUNIZATIONS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerImmunizations onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/labResult/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddEditDiagnosticReport onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/:partner/vitals/:vitalType/alldata"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VitalAllData onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/vitals/:vitalType/alldata"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VitalAllData onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/:partner/vitals/:vitalType"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VitalDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/vitals/:vitalType"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VitalDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.CARE_PLANS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CarePlan onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.CARE_PLANS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CarePlan onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/careplandashboard"
                      element={<Navigate to="/records/care_plan" />}
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.CARE_TEAMS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CareTeam onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.CARE_TEAMS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CareTeam onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/careteamdashboard"
                      element={<Navigate to="/records/care_team" />}
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.FAMILY_HISTORY}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <FamilyMemberHistory onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.FAMILY_HISTORY}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <FamilyMemberHistory onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="/familymemberhistorydashboard"
                      element={<Navigate to={`/records/${ROUTE_NAMES.FAMILY_HISTORY}`} />}
                    />
                    <Route
                      path="/consentsdashboard"
                      element={<Navigate to="/records/consents" />}
                    />
                    <Route
                      exact
                      path={`/circles/:partner/${ROUTE_NAMES.VITALS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerVitals onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.VITALS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerVitals onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles/:partner/:circleId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Categories onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.BILL}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ServiceBills onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/bill/:billId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <BillDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/reviewedcase/:caseId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ReviewedCase />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/payee"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PayeeInformation />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/newforgotpassword"
                      element={
                        <PublicRoute loggedIn={isLoggedIn}>
                          <NewForgotPassword />
                        </PublicRoute>
                      }
                    />
                    <Route
                      exact
                      path="/resetpassword"
                      element={
                        <PublicRoute loggedIn={isLoggedIn}>
                          <ResetPassword />
                        </PublicRoute>
                      }
                    />
                    <Route
                      exact
                      path="/scanbill"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ScanBill
                            onFooterItemClick={onFooterItemClick}
                            onSetNormalScreen={handleOnSetNormalScreen}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/payment"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PaymentForm />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/ratebillpay"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <MuiRating />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/rated"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <RatingSuccess />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/awardpoints"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AwardPointsNew onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/awardpoints/giftawardpoints"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <GiftAwardPoints onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/awardpoints/transactionDetails"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <TransactionDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/transactionDetails"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <TransactionDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/awardpoints"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AwardPointsNew onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/awardpoints/transactionDetails"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <TransactionDetails onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/awardpoints/earnPoints"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <EarnAwardPoints onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/addCard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddCard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/editCard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <EditCard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/payment/manage"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ManagePayment onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/alerts"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AlertsList onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/makePayment"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <MakePayment />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/editprofile"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <EditProfile />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/profile"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ProfileScreen />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/selectpayment"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <SelectPaymentMethod />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/ratingsdashboard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <RatingsDashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/secondopinion"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <SecondOpinionDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/ratesecondopinion"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Rating />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/secondopiniondashboard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <SecondOpinions onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/socialhistorydashboard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Socialhistorydashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/visitsdashboard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VisitsDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/medicationsdashboard"
                      element={<Navigate to={`/records/${ROUTE_NAMES.MEDICATIONS}`} />}
                    />
                    <Route
                      exact
                      path="/bill"
                      element={<Navigate to={`/records/${ROUTE_NAMES.BILLS}`} />}
                    />
                    <Route
                      exact
                      path="/clinicalvitalsdashboard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerVitals onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      exact
                      path="/conditionsdashboard"
                      element={<Navigate to="/records/conditions" />}
                    /> */}
                    {/* <Route
                      exact
                      path="/allergiesdashboard"
                      element={<Navigate to={`/records/${ROUTE_NAMES.ALLERGIES}`} />}
                    /> */}
                    <Route
                      exact
                      path="/immunizationsdashboard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerImmunizations onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/proceduresdashboard"
                      element={<Navigate to="/records/procedures" />}
                    />

                    {/* ///////// */}
                    <Route
                      exact
                      path="/labresultsdashboard"
                      element={<Navigate to="/records/lab_results" />}
                    />
                    <Route
                      exact
                      path="/claimsdashboard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PartnerClaims onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/giftcard"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <GiftCard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/giftcardreedem"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <GiftCardReedem />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/utilities/awardpoints/redeem_gift_card"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <RedeemGiftCard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/termsandconditions"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <TermsAndConditions />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/initiatepayment/:billId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AwardPointPay />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/useraccount"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <UserAccount />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/rateyourservice"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <RatingAfterService />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/phoneverification"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <PhoneVerification
                            hasPhoneBeenVerified={hasPhoneBeenVerified}
                            phoneVerified={isPhoneVerified}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={ROUTE_NAMES.COMPLETE_REGISTRATION}
                      element={
                        <PublicRoute loggedIn={isLoggedIn}>
                          {isNewFlow ? (
                            <NewLandingPage
                              type="complete_registration"
                              userSchemaObj={userSchemaObj}
                            />
                          ) : (
                            <SocialSignin userSchemaObj={userSchemaObj} />
                          )}
                        </PublicRoute>
                      }
                    />
                    <Route
                      exact
                      path="complete-registration"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          {isNewFlow ? (
                            <NewLandingPage
                              type="complete_registration"
                              userSchemaObj={userSchemaObj}
                              setIsUserCreated={hasUserCreated}
                            />
                          ) : (
                            <SocialSignin
                              userSchemaObj={userSchemaObj}
                              setIsUserCreated={hasUserCreated}
                            />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/userphoneverification"
                      element={
                        <PublicRoute loggedIn={isLoggedIn}>
                          <PhoneVerificationUnAuth
                            phoneVerified={isPhoneVerified}
                            onSetFullScreen={handleOnSetFullScreen}
                            onSetNormalScreen={handleOnSetNormalScreen}
                          />
                        </PublicRoute>
                      }
                    />
                    <Route
                      exact
                      path="/secondopinion/create"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <IFrameManager />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/secondopinion/update"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <IFrameManager />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/secondopinion/callback"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <IFrameCallback
                            onSetFullScreen={handleOnSetFullScreen}
                            onSetNormalScreen={handleOnSetNormalScreen}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/changepassword"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ChangePassword />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/addplan"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddPlan />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/editplan/:coverageId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <EditPlan />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/coverage/:type"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddEditCoverage onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.COVERAGE}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CoverageSummaryScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/coveragesummary/:shopNow"
                      element={<Navigate to={`/records/coveragesummary/shopNowFlyout`} />}
                    />
                    <Route
                      exact
                      path="/records/coverageSummary/:shopNow"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <CoverageSummaryScreen onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/coveragesummary"
                      element={<Navigate to={`/records/${ROUTE_NAMES.COVERAGE}`} />}
                    />
                    <Route
                      exact
                      path="/contact"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ContactDetails />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/contact/addphone"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddPhone />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/contact/addemail"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddEmail />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/contact/addaddress"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddAddress />
                        </PrivateRoute>
                      }
                    />
                    <Route exact path="/emailVerification" element={<EmailVerification />} />
                    <Route
                      exact
                      path="/proceedtosecondopinion"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ProceedToSecondOpinion onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/help"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Help onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/addBankAccount"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddBankAccount />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/editbankaccount/:paymentSystemId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <EditBankAccount />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/addcoveragepage/:vendor/:planType"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AddCoveragePage onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.VISITS}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <VisitsDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path={`/records/${ROUTE_NAMES.SOCIAL_HISTORY}`}
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Socialhistorydashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records/:tab?"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ServicesDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/records"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <ServicesDashboard onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/circles"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <Circles onFooterItemClick={onFooterItemClick} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/assessments/:instantId"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AssessmentContainer
                            onFooterItemClick={onFooterItemClick}
                            onSetFullScreen={handleOnSetFullScreen}
                            onSetNormalScreen={handleOnSetNormalScreen}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/assessments/"
                      element={
                        <PrivateRoute loggedIn={isLoggedIn}>
                          <AssessmentContainer
                            onFooterItemClick={onFooterItemClick}
                            onSetFullScreen={handleOnSetFullScreen}
                            onSetNormalScreen={handleOnSetNormalScreen}
                          />
                        </PrivateRoute>
                      }
                    />
                    <Route path="*" element={<PageNotFound />} />
                  </RouterRoute>
                </div>
              </div>

              {isLoggedIn && isPhoneVerified && !isLarge && (
                <Footer activeState={activeFooterItemId} onFooterItemClick={onFooterItemClick} />
              )}
            </App>
          </Suspense>
        )}
      </DeactivateContext.Provider>
    </UserInfoContext.Provider>
  );
};
export default withStyles(styles, { withTheme: true })(Routes);
