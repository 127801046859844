import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import MpoweredCoinTransparent from '../../../assets/images/mpoweredCoinTransparent.svg';
import { useStyles } from './style';
import { getRewardDetails } from './helper';
import { getCommaSeparatedDateWithTime } from '../../../utils/helper';

const AwardPointDescription = ({ awardPoint }) => {
  const classes = useStyles();
  const { icon, bonusType } = getRewardDetails(awardPoint.transactionType, awardPoint.details);
  const points = awardPoint.points < 0 ? `- ${-awardPoint.points}` : `+ ${awardPoint.points}`;
  const dateTime = getCommaSeparatedDateWithTime(awardPoint.creationDate);

  return (
    <div className={classes.awardsPointWrapper}>
      <div className={classes.leftContent}>
        <img src={icon} />
        <div>
          <Typography className={classes.cardHeading}>{bonusType}</Typography>
          <Typography className={classes.cardSubHeading}>{awardPoint.remarks}</Typography>
        </div>
      </div>
      <div className={classes.rightContent}>
        <div className={classes.awardPointsDiv}>
          <img src={MpoweredCoinTransparent} />
          <div
            className={[classes.points, awardPoint.points > 0 ? classes.plusPoints : ''].join(' ')}
          >
            {points}
          </div>
        </div>
        <div className={classes.dateTimeDiv}>{dateTime}</div>
      </div>
    </div>
  );
};

AwardPointDescription.propTypes = {
  awardPoint: PropTypes.shape({
    creationDate: PropTypes.string,
    points: PropTypes.number,
    remarks: PropTypes.string,
  }),
};
export default AwardPointDescription;
