import React, { useState } from 'react';
import { withStyles } from '@mui/styles';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { KeyboardArrowDown } from '@mui/icons-material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import clsx from 'clsx';
import { Fragment } from 'react';
import { useRef } from 'react';
import CustomStaticDatePicker from '../CustomStaticDatePicker';

const style = (theme) => ({
  // dropdown css
  dropDownWrapper: {
    minWidth: 160,
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    // paddingRight: '6px',
    color: '#4B4B4B',
    borderRadius: 8,
    backgroundColor: '#fff',
    // boxShadow: '0px 2px 10px rgba(0,0,0,0.08)',
    '& .MuiInputBase-root': {
      height: '100%',
      width: '100%',
      fontSize: 13,
      fontWeight: 600,
      letterSpacing: 0.62,
    },
    '& .MuiSelect-root': {
      height: '100%',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'end',
        gap: '8px 0px',
      },
    },
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  listTag: {
    flex: 1,
    height: 40,
  },
  selectRoot: {
    fontSize: '16px',
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },
  dropDownMenuList: {
    fontSize: '16px',
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiMenuItem-root': {
      paddingRight: '12px',
    },
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 40,
    paddingLeft: 12,
    paddingRight: 12,
  },
  img: { width: 8, height: 8, marginRight: 8 },
  muiIcon: { width: 12, height: 12, marginRight: 4 },
  label: {
    height: 24,
    padding: 0,
    paddingLeft: 5,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  filterType: {
    lineHeight: 0,
    paddingLeft: 8,
    minWidth: 'max-content',
    fontSize: 14,
    fontWeight: 600,
    color: '#737373',
  },
  dropDownContainer: { display: 'flex', alignItems: 'center', height: '40px' },
});

const FilterDropDown = (props) => {
  const {
    classes,
    changeHandler,
    rootContainerStyle,
    filterType,
    datePickerEnabled,
    handleCustomDate,
  } = props;
  const [value, setValue] = useState(props.value);
  const [customDate, setCustomDate] = useState(new Date());
  const [isOpen, setOpen] = useState(false);
  const [isCalenderOpen, setIsCalenderOpen] = useState(false);

  const menuItemRef = useRef(null);
  const dateRef = useRef(null);

  const onChange = (val) => {
    if (val.target.value === 'custom_date') {
      dateRef.current.style.display = 'none';
      return;
    }
    setValue(val);
    changeHandler(val);
    dateRef.current.style.display = 'none';
  };

  const handleDateSelect = (selectedDate) => {
    if (handleCustomDate && datePickerEnabled) {
      handleCustomDate(selectedDate?.$d);
      setCustomDate(selectedDate);
      setOpen(false);
      setIsCalenderOpen(false);
      onChange({ target: { name: '', value: 'custom_date' } });
    }
  };
  const handleMouseEnterInCustomDate = () => {
    setIsCalenderOpen(true);
    dateRef.current.style.position = 'fixed';
    // dateRef.current.style.zIndex = 999;
    // dateRef.current.style.top = `${props.options?.length * 40}px`;
    // dateRef.current.style.right = '-53px';
    dateRef.current.style.display = 'block';
    dateRef.current.style.border = 'none';
  };
  const handleMouseEnterInOtherItems = () => {
    setIsCalenderOpen(false);
    dateRef.current.style.display = 'none';
  };

  return (
    <Fragment>
      <FormControl className={clsx([classes.dropDownWrapper, rootContainerStyle])}>
        {props.inputLabel && <InputLabel id="inputLabel">{props.inputLabel}</InputLabel>}
        <div className={classes.dropDownContainer}>
          <p className={classes.filterType}>{filterType || ''}</p>
          <Select
            MenuProps={{
              classes: { select: classes.selectRoot, list: classes.dropDownMenuList },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              MenuListProps: {
                disablePadding: true,
              },
              getContentAnchorEl: null,
            }}
            onClick={() => {
              !isCalenderOpen && setOpen(!isOpen);
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue="10"
            disableunderline
            open={isOpen}
            // disableGutters={true}
            value={value}
            onChange={onChange}
            {...props}
            IconComponent={KeyboardArrowDown}
          >
            {props?.options?.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  onMouseEnter={handleMouseEnterInOtherItems}
                  //   disableGutters={true}
                  value={option.value}
                  className={classes.listTag}
                >
                  <div className={classes.labelWrapper}>
                    {option.icon && (
                      <span>
                        <img src={option.icon} alt="icon" className={classes.img} />
                      </span>
                    )}
                    <p className={classes.label}>{option.label || option.value}</p>
                  </div>
                </MenuItem>
              );
            })}
            {datePickerEnabled && (
              <MenuItem
                className={classes.listTag}
                value={'custom_date'}
                onMouseEnter={handleMouseEnterInCustomDate}
                ref={menuItemRef}
              >
                <div className={classes.labelWrapper}>
                  <DateRangeIcon style={{ color: '#1F1F1F', fontSize: 14 }} />
                  <p className={classes.label}>Custom</p>
                </div>
              </MenuItem>
            )}
            <CustomStaticDatePicker
              customDate={customDate}
              handleDateSelect={handleDateSelect}
              ref={dateRef}
            />
          </Select>
        </div>
      </FormControl>
    </Fragment>
  );
};
export default withStyles(style)(FilterDropDown);
