import client from '../components/ApolloClient';
import gql from 'graphql-tag';

const FETCH_BILL_BY_CONSENT = gql`
  query fetchAllBillsByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllBillsByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      billsList
      count
    }
  }
`;
const FETCH_BILL_BY_DUE_DATE = gql`
  query fetchBillByDueDay($dueDays: Int) {
    fetchBillByDueDay(dueDays: $dueDays) {
      totalDueDays
      status
      totalDueBills
    }
  }
`;
const MARK_RESTRICTED_BILLS = gql`
  mutation markAsRestrictedBillsRequest($billId: String, $markAsRestricted: String) {
    markAsRestrictedBillsRequest(billId: $billId, markAsRestricted: $markAsRestricted) {
      patientName
      meta {
        security {
          display
        }
      }
    }
  }
`;
const FETCH_ALL_BILLS = gql`
  query fetchAllBills(
    $sourceUrl: String = ""
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
    $sort_order: SortOrder = ASC
    $nextPageId: String = ""
  ) {
    fetchAllBills(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
      sortOrder: $sort_order
      nextPageId: $nextPageId
    ) {
      paidBills {
        billId
        date
        providerName
        totalDue
        serviceType
        status
        paymentStatus
      }
      unPaidBills {
        billId
        date
        providerName
        totalDue
        serviceType
        status
        paymentStatus
      }
      totalBills
      totalExpenditureBills
      totalDueBills
      nextPageId
    }
  }
`;

export default {
  fetchAllBills(variables, query = FETCH_ALL_BILLS) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...variables,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllBills);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchBillByDueDay(dueDays, query = FETCH_BILL_BY_DUE_DATE) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            dueDays,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchBillByDueDay);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchAllBillsByConsent(args, query = FETCH_BILL_BY_CONSENT) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query,
          variables: {
            ...args,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchAllBillsByConsent);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getMetaData(query, grantorId) {
    const fetchIdheaderObject = grantorId ? { fetch_id: grantorId } : {};

    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          context: {
            headers: fetchIdheaderObject,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.billSummary);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createBill(query, billFilePath) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            billFilePath: billFilePath,
          },
        })
        .then((response) => {
          resolve(response.data.createBill);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateBill(query, billId, amount, paymentTo, dueDate) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            billId: parseInt(billId),
            billDetails: {
              dueDate: dueDate,
              providerName: paymentTo,
              totalDue: parseInt(amount),
            },
          },
        })
        .then((response) => {
          resolve(response.data.updateBill);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updatePayment(query, paymentId) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            paymentId: paymentId,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markBillStatus(query, billId, billStatus) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            billId: billId,
            billStatus: billStatus,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchBillDetails(query, billId) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          variables: {
            billId: billId,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchBillInfo);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  markAsRestrictedBillsRequest(billId, markAsRestricted, mutation = MARK_RESTRICTED_BILLS) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            billId,
            markAsRestricted,
          },
        })
        .then((response) => {
          resolve(response.data.markAsRestrictedBillsRequest);
        })
        .catch((err) => reject(err));
    });
  },
};
