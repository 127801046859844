import React from 'react';
import { Typography } from '@mui/material';
import UserCircle from '../../assets/images/defaultAvatarBlack.svg';

const MemberSection = ({ item, classes }) => {
  return (
    <div className={classes.memberCardWrapper}>
      <div className={classes.memberPictureWrapper}>
        {!item.imgUrl && item.member_name ? (
          <div style={{ backgroundColor: item.bgColor }} className={classes.initialWrapper}>
            <Typography className={classes.initialName}>
              {item && item.member_name[0].toUpperCase()}
            </Typography>
          </div>
        ) : (
          <img
            src={item.imgUrl}
            alt="member-pic"
            className={classes.memberPicture}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = UserCircle;
            }}
          />
        )}
      </div>
      <div className={classes.memberNameWrapper}>
        <Typography className={classes.memberName}>{item.member_name || '-'}</Typography>
        <Typography className={classes.specialty}>
          {item.specialty}
          <span className={classes.dot}></span>
          <span className={classes.partnerName}>{item.partnerName}</span>
        </Typography>
      </div>
    </div>
  );
};

export default MemberSection;
