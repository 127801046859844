import { withStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import colors from '../../../../utils/colors';
import { Box, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '../TextField';
import clsx from 'clsx';
import Button from '../../../../components/atoms/Button';
import InfoIcon from '@mui/icons-material/Info';
import { UPDATE_EMAIL_CONSTANTS } from '../../constants';
import { validateEmail } from '../../../../utils/validations';
import ProfileService from '../../../../service/ProfileService';
import { UserInfoContext } from '../../../../components/Routes';
import { EDIT_EMAIL_ID } from '../../../../service/AccountDetailsService';
import { removeTypeNameFromObjectArray } from '../../../../utils/helper';
import { ERROR_MSGS } from '../../../../utils/constants';
import { ROUTE_NAMES } from '../../../../constants/routeNames';
import useAnalyticsLogger from '../../../../hooks/useAnalyticsLogger';
import { getAnalyticsEventsForRouteName } from '../../../../utils/helper';

const styles = (theme) => ({
  parentContainer: {
    width: '100%',
    height: '100%',
  },
  container: {
    backgroundColor: colors.WHITE,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    position: 'absolute',
    width: '476px',
    top: '25%',
    left: '78%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  heading: {
    color: colors.BLACK_B500,
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  subHeading: {
    color: colors.GRAY_SECONDARY,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  close: {
    color: colors.BLACK,
    fontFamily: 'Material Icons Round',
    fontWeight: 400,
    fontSize: 22,
    lineHeight: '24px',
  },
  textFieldContainer: { width: '100%' },
  required: { color: 'red', fontSize: 12, fontWeight: 500 },
  textFieldTitle: {
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    color: colors.PRIMARY_GRAY,
    letterSpacing: '0px',
    lineHeight: '16px',
    marginBottom: theme.spacing(0.5),
  },
  infoIconStyle: {
    height: '12px',
    width: '12px',
    paddingRight: '2px',
  },
  textFieldStyle: {
    width: '100%',
  },
  errMessage: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    color: '#FF0000',
  },
  spacing: {
    width: '100%',
    height: theme.spacing(2),
  },
  saveButton: {
    textTransform: 'none',
    padding: '24px 24px',
    borderRadius: 40,
    width: '100%',
    color: colors.WHITE,
    backgroundColor: colors.BLUE,
    fontSize: '16px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.BLUE_HOVER,
      color: colors.WHITE,
    },
  },
});

const { TITLE, SUB_TITLE, EMAIL, EMAIL_PLACEHOLDER, SAVE } = UPDATE_EMAIL_CONSTANTS;

const UpdateUserEmailModal = ({ classes, ...props }) => {
  const { show, onClose, onSave } = props;
  const { userInfo, setUserInfo } = React.useContext(UserInfoContext);
  const [newEmailError, setNewEmailError] = useState(false);
  const [email, setEmail] = useState('');
  const { logAnalyticsEvent } = useAnalyticsLogger();
  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.HOME);

  const [error, setError] = useState(null);

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClose = () => {
    onClose();
    logAnalyticsEvent(analyticsEvents.EMAIL_POPUP_CLOSED);
    resetEmail();

    if (error) {
      resetErrors();
      resetEmail();
    }
  };

  const handleOnSavePress = async () => {
    const validateEmailId = validateOnBlur(EMAIL, email);
    setIsLoading(true);

    try {
      if (validateEmailId) {
        const updateEmail = [
          {
            emailAddress: email,
            primary: true,
            active: true,
            verified: true,
          },
        ];
        const res = await ProfileService.editEmail(
          EDIT_EMAIL_ID,
          removeTypeNameFromObjectArray(updateEmail),
        );
        setUserInfo((prevState) => ({
          ...prevState,
          email,
        }));
        onSave();
        logAnalyticsEvent(analyticsEvents.EMAIL_POPUP_SAVED);
      }
    } catch (err) {
      console.error(err);
      // setError(true);
      setNewEmailError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const validateOnBlur = (key, value) => {
    const res = validateEmail(value);
    if (!res.isValid || res.errorMessage) {
      setError(res.errorMessage);
      return false;
    }
    return true;
  };

  const resetErrors = () => {
    setError(null);
    setNewEmailError(false);
  };

  const resetEmail = () => {
    setEmail('');
  };

  const onChange = (key, value) => {
    setEmail(value);
    setError(null);
    setNewEmailError(false);
  };

  useEffect(() => {
    setDisabled(error || !email || isLoading || newEmailError);
  }, [error, email, isLoading, newEmailError]);

  useEffect(() => {
    if (!show) {
      if (error) {
        resetEmail();
        resetErrors();
      } else if (!email) {
        resetErrors();
        setNewEmailError(false);
      }
    }
  }, [show]);

  return (
    <Modal open={show} disableAutoFocus={true}>
      <Box className={classes.parentContainer}>
        <Box className={classes.container}>
          <Box>
            <Box className={classes.headingContainer}>
              <Box>
                <Typography className={classes.heading}>{TITLE} </Typography>
                <Typography className={classes.subHeading}>{SUB_TITLE} </Typography>
              </Box>
              <CloseIcon className={classes.close} onClick={handleOnClose} />
            </Box>
            <Box className={classes.textFieldContainer}>
              <Typography variant="h6" className={classes.textFieldTitle}>
                {EMAIL}
                {/* <span className={classes.required}>*</span> */}
              </Typography>
              <TextField
                autoComplete="off"
                textFieldStyle={classes.textFieldStyle}
                id={EMAIL}
                name={EMAIL}
                color="primary"
                variant="outlined"
                size="small"
                type="text"
                value={email}
                placeholder={EMAIL_PLACEHOLDER}
                onFieldChange={onChange}
                error={error}
                newEmailError={newEmailError}
                autoFocus={true}
                onBlur={() => email.emailId && validateOnBlur(EMAIL, email)}
              />
              {error && (
                <Typography variant="h6" className={classes.errMessage}>
                  {/* <InfoIcon className={classes.infoIconStyle}></InfoIcon> */}
                  {error}
                </Typography>
              )}
              {newEmailError && (
                <Typography variant="h6" className={classes.errMessage}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {ERROR_MSGS.EMAIL_ALREADY_EXISTS}
                </Typography>
              )}

              <Box className={classes.spacing} />
            </Box>
            <Box>
              <Button
                disableFocusRipple
                inactive={disabled}
                color={'primary'}
                title={SAVE}
                variant="contained"
                className={clsx([classes.saveButton])}
                onClick={() => !disabled && handleOnSavePress()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default withStyles(styles, { withTheme: true })(UpdateUserEmailModal);
