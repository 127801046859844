import client from '../components/ApolloClient';

export default {
  getAlerts(query) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          resolve(res.data.alerts);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  markAlertsAsRead(query, messageIdList) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            messageIdList,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getUnreadAlertsCount(query) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
