import { Box, Button, Card } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import colors from '../../../utils/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    border: 'none',
    border: '1px solid #E4E4E4',
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
    minHeight: 447,
    height: '100%',
    width: '100%',
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    marginTop: 65,
    height: 150,
    width: 146,
  },
  heading: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
    marginTop: 40,
    textAlign: 'center',
  },
  paraContainer: {
    height: 40,
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
  },
  paraText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'center',
    width: 474,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  btn: {
    backgroundColor: colors.BLUE,
    borderRadius: 40,
    height: 48,
    width: 420,
    color: colors.WHITE,
    fontFamily: 'Inter',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#389DEF',
    },
  },
}));

const PartnerCard = ({
  data,
  handleAddToCircle,
  imageSize,
  rootContainer,
  btnStyle,
  headingStyle,
  contentStyle,
  imageStyle,
  btnWrapper,
}) => {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={clsx([rootContainer, classes.root])}>
      <div className={classes.imgContainer}>
        <img src={data.IMG_URL} alt="AddPartner" className={imageStyle || classes.img} />
      </div>
      <div className={headingStyle || classes.heading}>{data.HEADING_TEXT}</div>
      <div className={classes.paraContainer}>
        <p className={contentStyle || classes.paraText}>{data.PARA_TEXT}</p>
      </div>
      {handleAddToCircle && (
        <div className={btnWrapper || classes.btnContainer}>
          <Button
            variant="contained"
            color="primary"
            disableRipple
            className={classes.btn}
            onClick={handleAddToCircle}
          >
            {data.BTN_TEXT}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default PartnerCard;
