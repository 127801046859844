import axiosInstance from './axiosInstance';
import { auth } from '../Routes';

const get = async (url, params, useBaseApiPath, otherConfig) => {
  return _makeRequest('GET', url, params, null, useBaseApiPath, otherConfig);
};
const post = async (url, params, data, useBaseApiPath, otherConfig, otherHeaders) => {
  return _makeRequest('POST', url, params, data, useBaseApiPath, otherConfig, otherHeaders);
};

const put = async (url, params, data, useBaseApiPath) => {
  return _makeRequest('PUT', url, params, data, useBaseApiPath);
};

const remove = async (url, params, data, useBaseApiPath) => {
  return _makeRequest('DELETE', url, params, data, useBaseApiPath);
};

const _makeRequest = async (
  type,
  url,
  params,
  data,
  useBaseApiPath = true,
  otherConfig = {},
  otherHeaders = {},
) => {
  const requestType = type ? type.toUpperCase() : 'GET';

  const extraConfig = otherConfig ? otherConfig : {};

  const apiUrl = url;

  const config = {
    url: apiUrl,
    params,
    method: requestType,
    ...extraConfig,
  };

  if (requestType !== 'GET') {
    config.data = data;
  }

  const dependentFhirId = localStorage.getItem('dependentFhirId');
  const dependentFhirIdObject = dependentFhirId ? { fetch_id: dependentFhirId } : {};

  const headers = {
    Accept: 'application/json',
    authorization: `Bearer ${auth.getAccessToken()}`,
    'Content-Type': 'multipart/form-data',
  };

  config['headers'] = {
    ...headers,
    ...dependentFhirIdObject,
    ...otherHeaders,
  };

  return new Promise((resolve, reject) => {
    axiosInstance(config)
      .then((response) => {
        if (config.responseType === 'blob') resolve(response);
        else resolve(response.data);
      })
      .catch((error) => {
        if (error && error.response) {
          reject(error);
        }
      });
  });
};

const AxiosApiService = {
  get,
  post,
  put,
  delete: remove,
};

export default AxiosApiService;
