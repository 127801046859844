import React from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Link, Box, Snackbar } from '@mui/material';
import { IMAGE_ALTERNATIVE_TXT /*, LOG_IN*/ } from '../../../../utils/constants';
import AppStore from '../../../../assets/images/appstore.png';
import PlayStore from '../../../../assets/images/playstore.png';
import LogoAlternate from '../../../../assets/images/logoalternate.png';
import withRouter from '../../../../components/withRoute';
import Carousel from '../../../../components/Carousel/Carousel';
import colors from '../../../../utils/colors';
import { useSelector } from 'react-redux';
import { mockTemplates } from '../../../dynamicRecords/mockTemplate';

const styles = (theme) => ({
  leftContainer: {
    boxSizing: 'border-box',
    backgroundColor: colors.BACKGROUND_COLOR_NEW,
    padding: '40px 0px 40px 40px',
    maxWidth: '557px',
    width: '100%',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    [theme.breakpoints.down('lg')]: {
      width: '40%',
    },
  },
  landingPageImgWrapper: {
    marginTop: '24px',
    maxWidth: '300px',
    height: '32px',
  },
  landingPageLeftContainerLogo: {
    width: '100%',
    height: '100%',
  },
  carouselWrapper: {
    marginTop: '4vw',
    width: '100%',
  },
  linkContainer: {
    position: 'absolute',
    bottom: '0px',
  },
  linkWrapper: {
    marginBottom: '5%',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '4px',
    letterSpacing: '0px',
  },
  appleStoreTextLink: {
    letterSpacing: '0px',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 400,
  },
  googleStoreTextLink: {
    letterSpacing: '0px',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: 400,
  },
  imgLinkContainer: {
    marginBottom: '6%',
  },
  imgForAppStore: {
    width: '135px',
    height: '40px',
  },
  imgForGoogleStore: {
    width: '135px',
    height: '40px',
    marginLeft: '20px',
  },
});

const LeftContainer = ({ classes, ...props }) => {
  const { templates } = useSelector((state) => state.templates);
  const { selectedLanguage } = useSelector((state) => state.app);
  let logo;
  let DOWNLOAD_TEXT;

  const LOG_IN =
    // templates?.texts?.[selectedLanguage]?.LOGIN_SCREEN?.LOG_IN||
    mockTemplates?.texts?.[selectedLanguage]?.LOGIN_SCREEN?.LOG_IN;


  if (window.location.hostname.startsWith('demo.mpowered')) {
    logo = templates?.HOME_SCREEN_LOGO || mockTemplates?.HOME_SCREEN_LOGO;
    DOWNLOAD_TEXT =
      templates?.texts?.[selectedLanguage]?.LOGIN_SCREEN?.LOG_IN?.DEMO_DOWNLOAD ||
      mockTemplates?.texts?.[selectedLanguage]?.LOGIN_SCREEN?.LOG_IN?.DEMO_DOWNLOAD;
  } else {
    logo = LogoAlternate;
    DOWNLOAD_TEXT =
      templates?.texts?.[selectedLanguage]?.LOGIN_SCREEN?.LOG_IN?.DOWNLOAD ||
      mockTemplates?.texts?.[selectedLanguage]?.LOGIN_SCREEN?.LOG_IN?.DOWNLOAD;
  }

  return (
    <Box className={classes.leftContainer}>
      <Box className={classes.landingPageImgWrapper}>
        <img
          src={logo}
          alt={IMAGE_ALTERNATIVE_TXT.LOGO}
          className={classes.landingPageLeftContainerLogo}
        />
      </Box>
      <Box className={classes.carouselWrapper}>
        <Carousel />
      </Box>
      <Box className={classes.linkContainer}>
        <Typography variant="h4" className={classes.linkWrapper}>
          {DOWNLOAD_TEXT}
          <Link
            href="https://apps.apple.com/us/app/mpoweredhealth/id1525925137"
            target="_blank"
            rel="noopener"
            variant="h4"
            className={classes.appleStoreTextLink}
          >
            {LOG_IN.IOS}
          </Link>
          {LOG_IN.AND}
          <Link
            href="https://play.google.com/store/apps/details?id=com.mpoweredhealth"
            target="_blank"
            rel="noopener"
            variant="h4"
            className={classes.googleStoreTextLink}
          >
            {LOG_IN.ANDROID}
          </Link>
        </Typography>
        <Box className={classes.imgLinkContainer}>
          <Link
            href="https://apps.apple.com/us/app/mpoweredhealth/id1525925137"
            target="_blank"
            rel="noopener"
          >
            <img src={AppStore} alt="App Store" className={classes.imgForAppStore} />
          </Link>
          <Link
            href="https://play.google.com/store/apps/details?id=com.mpoweredhealth"
            target="_blank"
            rel="noopener"
          >
            <img src={PlayStore} alt="Play Store" className={classes.imgForGoogleStore} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default withRouter(withStyles(styles, { withTheme: true })(LeftContainer));
