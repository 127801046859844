import { makeStyles } from '@mui/styles';
import colors from '../../utils/colors';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.BG_COLOR,
    minHeight: 'calc( 100vh - 83px)',
    padding: theme.spacing(4),
    minWidth: '100%',
  },
  cardRoot: {
    boxShadow: 'rgb(228 228 228) 0px 0px 6px 1px',
    borderRadius: '12px',
    justifyContent: 'space-between',
    border: 'none',
    marginBottom: '24px',
  },
  cardContent: {
    padding: '0!important',
  },
  heading: {
    color: colors.BLACK_B500,
    fontFamily: 'Inter',
    fontSize: theme.spacing(3.5),
    fontWeight: 600,
    lineHeight: '40px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
  },
  cardRoot: {
    marginTop: 24,
  },
}));
