import gql from 'graphql-tag';
import client from '../components/ApolloClient';
import {
  CONSENT_CREATE_RESPONSE_FRAGMENT,
  FETCH_BY_GRANTEE_CONSENT_LIST,
  FETCH_BY_GRANTEE_CONTENT_LIST,
  FETCH_BY_GRANTOR_CONSENT_LIST,
  RELATED_PERSON_FRAGMENT,
} from '../utils/fragments';

const FETCH_ALL_CONSENT_QUERY = gql`
  query {
    fetchAllUserConsents {
      consentType
      version
      htmlContent
    }
  }
`;

const ACCEPT_ALL_CONSENTS_MUTATION = gql`
  mutation {
    acceptAllConsents {
      consentType
      accepted
    }
  }
`;

const ACCEPT_CONSENT_MUTATION = gql`
  mutation acceptConsent($consentType: ConsentType) {
    acceptConsent(consentType: $consentType) {
      consentType
      accepted
    }
  }
`;

const CREATE_CONSENT_MUTATION = gql`
  mutation createConsent($grantee_id: String, $grantor_id: String) {
    createConsent(grantee_id: $grantee_id, grantor_id: $grantor_id) {
      ...consentCreateResponse
    }
  }
  ${CONSENT_CREATE_RESPONSE_FRAGMENT}
`;

const FETCH_UNAUTH_CONSENT_QUERY = gql`
  query fetchConsentByType($consentType: ConsentType) {
    fetchConsentByType(consentType: $consentType) {
      htmlContent
      version
    }
  }
`;

const FETCH_BY_GRANTEE = gql`
  query fetchByGrantee(
    $count: Int
    $offset: Int
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchByGrantee(
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      ...granteeResponse
    }
  }
  ${FETCH_BY_GRANTEE_CONSENT_LIST}
`;

const FETCH_RELATED_PERSON_BY_GRANTEE = gql`
  query findRelatedPersonByGrantee($count: Int, $offset: Int) {
    findRelatedPersonByGrantee(count: $count, offset: $offset) {
      age
      legalMinor
      status
      isDependent
      initiatorId
      grantorId
      id
      dependentFhirId
      relatedPerson {
        ...relatedPerson
      }
      consentSummary {
        ...granteeContent
      }
      workflowReinitiateFlag
    }
  }
  ${RELATED_PERSON_FRAGMENT}
  ${FETCH_BY_GRANTEE_CONTENT_LIST}
`;

const FETCH_BY_GRANTOR = gql`
  query fetchByGrantor(
    $count: Int
    $offset: Int
    $dateFilter: Float = 0
    $statusFilter: String = ""
    $searchParameter: String = ""
  ) {
    fetchByGrantor(
      count: $count
      offset: $offset
      dateFilter: $dateFilter
      statusFilter: $statusFilter
      searchFilter: $searchParameter
    ) {
      ...grantorResponse
    }
  }
  ${FETCH_BY_GRANTOR_CONSENT_LIST}
`;

const ConsentService = {
  async fetchAllConsents(query = FETCH_ALL_CONSENT_QUERY) {
    const {
      data: { fetchAllUserConsents },
    } = await client.query({
      query,
      fetchPolicy: 'no-cache',
    });
    return fetchAllUserConsents;
  },

  async acceptAllConsent(mutation = ACCEPT_ALL_CONSENTS_MUTATION) {
    const {
      data: { acceptAllConsents },
    } = await client.mutate({
      mutation,
    });
    return acceptAllConsents;
  },

  async acceptConsent(consentType, mutation = ACCEPT_CONSENT_MUTATION) {
    const {
      data: { acceptConsent },
    } = await client.mutate({
      mutation,
      variables: {
        consentType,
      },
    });
    return acceptConsent;
  },
  async createConsent(args, mutation = CREATE_CONSENT_MUTATION) {
    const { grantee_id, grantor_id } = args;
    const {
      data: { createConsent },
    } = await client.mutate({
      mutation,
      variables: {
        grantee_id,
        grantor_id,
      },
    });

    return createConsent;
  },

  async fetchConsentByType(consentType, query = FETCH_UNAUTH_CONSENT_QUERY) {
    const { data } = await client.query({
      query,
      variables: {
        consentType,
      },
    });
    return data.fetchConsentByType;
  },

  async fetchByGrantee(args, query = FETCH_BY_GRANTEE) {
    const { data } = await client.query({
      query,
      variables: {
        ...args,
      },
      fetchPolicy: 'no-cache',
    });
    return data.fetchByGrantee;
  },
  async findRelatedPersonByGrantee(args, query = FETCH_RELATED_PERSON_BY_GRANTEE) {
    const { data } = await client.query({
      query,
      variables: {
        ...args,
      },
      fetchPolicy: 'no-cache',
    });
    return data.findRelatedPersonByGrantee;
  },
  async fetchByGrantor(args, query = FETCH_BY_GRANTOR) {
    const { data } = await client.query({
      query,
      variables: {
        ...args,
      },
      fetchPolicy: 'no-cache',
    });
    return data.fetchByGrantor;
  },
};

export default ConsentService;
