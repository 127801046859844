import React, { Component } from 'react';
import Button from '../../components/atoms/Button';
import { withStyles } from '@mui/styles';
import { Typography, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '../../components/atoms/TextField';
import { FORGOT_PSWD, OTP_VERIFICATION_MODAL, SIGNUP } from '../../utils/constants';
import {
  validateEmail,
  validatePassword,
  validateRePassword,
  validateUSPhone,
} from '../../utils/validations';
import {
  getAnalyticsEventsForRouteName,
  getUSFormatPhoneNo,
  rankPassword,
} from '../../utils/helper';
import gql from 'graphql-tag';
import client from '../../components/ApolloClient';
import Loader from '../../components/atoms/Loader';
import withRouter from '../../components/withRoute';
import SnackbarToast from '../../components/organisms/SnackbarToast';
import { ROUTE_NAMES } from '../../constants/routeNames';
import LeftContainer from '../../features/landingFlow/pages/LeftContainer';
import colors from '../../utils/colors';
import { OTP_SCREEN } from '../../features/landingFlow/constants';
import api from '../../features/landingFlow/api';

const CHECK_USER_AND_GENERATE_OTP = gql`
  query getOtp($loginRequest: LoginRequest) {
    getOtp(loginRequest: $loginRequest) {
      verificationSid
      phoneNumber
      userId
    }
  }
`;

const VALIDATE_OTP_UNAUTH_QUERY = gql`
  mutation validateOTPUnAuth($validateOTPRequest: ValidateOTPRequest, $userId: String) {
    validateOTPUnAuth(validateOTPRequest: $validateOTPRequest, userId: $userId) {
      phoneVerificationStatus
    }
  }
`;

const RESET_PASSWORD_QUERY = gql`
  mutation forgetPassword($changePasswordRequest: ChangePasswordRequest) {
    forgetPassword(changePasswordRequest: $changePasswordRequest) {
      statusCode
      responseMessage
    }
  }
`;
const GENERATE_OTP_UNAUTH = gql`
  mutation generateOTPUnAuth($phone: String) {
    generateOTPUnAuth(phone: $phone) {
      verificationSid
    }
  }
`;

const styles = (theme) => ({
  root: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
  },
  cover: {
    alignItems: 'center',
    direction: 'column',
  },
  heading: {
    fontSize: '28px',
    fontWeight: 600,
    color: '#000000',
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    height: 40,
    lineHeight: '40px',
    marginBottom: 16,
  },
  infoIconStyle: {
    height: '12px',
    width: '12px',
  },
  infoIconError: {
    height: '12px',
    width: '12px',
    position: 'relative',
    top: 2,
  },
  blueColor: {
    color: colors.BLUE,
  },
  appStoreLink: {
    color: '#036FCB',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '32px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  phoneHelperText: {
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    color: '#666666',
  },
  textFieldTitle: {
    marginTop: 16,
    fontSize: '12px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    lineHeight: '16px',
    color: '#000000',
  },
  textFieldStyle: {
    borderRadius: 4,
    border: '1px solid #D0D0D0',
    backgroundColor: '#FFFFFF',
  },
  helperText: {
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    color: '#FF0000',
  },
  image: {
    marginTop: 16,
    paddingRight: 20,
  },
  button: {
    padding: 10,
  },
  leftContainerBox: { flex: '0.35' },
  mainPageBox: {
    backgroundColor: colors.WHITE,
    [theme.breakpoints.only('xs')]: {
      width: 'calc(100% - 60px)',
    },
    [theme.breakpoints.only('sm')]: {
      width: 'calc(100% - 300px)',
    },
    [theme.breakpoints.only('md')]: {
      width: 'calc(100% - 100px)',
    },
    [theme.breakpoints.only('lg')]: {
      width: '480px',
    },
    [theme.breakpoints.only('xl')]: {
      width: '480px',
    },
    display: 'flex',
    flexDirection: 'column',
    marginTop: '150px',
  },
  landingImageBox: {
    paddingLeft: 72,
    marginTop: 80,
  },
  leftSideTextBlack: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#101828',
  },
  leftSideTextBlue: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#00A0DF',
  },
  leftSideTextBlack2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#343B40',
  },
  appInfo: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '32px',
    color: '#333333',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  appDescription: {
    paddingTop: 40,
    paddingLeft: 60,
    paddingRight: 60,
    color: '#8C8C8C',
    lineHeight: '24px',
    fontSize: 16,
    fontWeight: 'normal',
  },
  landingImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    objectFit: 'cover',
    maxWidth: 640,
    maxHeight: 616,
  },
  mainPage: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    backgroundColor: colors.WHITE,
    flex: 1,
    display: 'flex',
    paddingLeft: '100px',
  },
  alternateLogo: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    paddingLeft: 72,
    paddingRight: 72,
    maxWidth: '100%',
  },
  logoAlternateContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    paddingTop: 72,
    maxWidth: '60%',
  },
  linkContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start-flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    paddingLeft: 72,
    flex: 1,
  },
  createAccountButtom: {
    height: 48,
    padding: '8px 24px',
    borderRadius: 40,
    backgroundColor: '#036FCB',
    fontSize: 16,
    lineHeight: '24px',
    fontFamily: 'Inter',
    fontStyle: 'normal,',
    fontWeight: 500,
    marginTop: 40,
  },
});

const otpdivstyle = {
  paddingTop: '150px',
};
const boxstyle = {
  flex: 1,
  display: 'flex',
};
const textFieldTitle = {
  fontSize: '12px',
  fontWeight: 500,
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  lineHeight: '16px',
};
const inputboxstyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '40px',
  border: '1px solid #CCCCCC',
  height: '100px',
  width: '100px',
  marginRight: '20px',
  borderRadius: '4px',
  textAlign: 'center',
  marginTop: 32,
};
const buttonstyle = {
  height: 48,
  padding: '8px 24px',
  borderRadius: 40,
  fontSize: 16,
  lineHeight: '24px',
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  fontWeight: 500,
  marginTop: 32,
};
const spanstyle = {
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  fontFamily: 'Inter',
  color: '#666666',
};
const otpbuttonstyle = {
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#0060BA',
};
const phonenostyle = {
  color: '#0000FF',
  display: 'inline',
};
const headertextstyle = {
  marginTop: 16,
  display: 'inline',
  fontFamily: 'Inter',
  fontStyle: 'normal,',
};
const textsizestyle = {
  marginTop: 32,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  fontFamily: 'Inter',
  fontStyle: 'normal,',
};
const helperText = {
  marginTop: 5,
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  color: '#FF0000',
};

let otp = new Array(4).fill('');

class NewForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen1: true,
      screen2: false,
      email: '',
      emailErrorMsg: '',
      emailError: false,
      otpError: false,
      validateOTPDisabled: true,
      resendTime: 59,
      timerFlag: true,
      otpErrorMessage: '',
      password: '',
      rePassword: '',
      passwordError: false,
      rePasswordError: false,
      showPassword: false,
      showPasswordRe: false,
      passwordMatched: false,
      passwordStrength: 0,
      verifiedPhoneNo: '',
      verificationSID: '',
      userId: '',
      isLoading: false,
      loaderText: 'Loading',
      openSnackbar: false,
      snackbarMessage: '',
      isEmailOrMobile: '',
    };
  }

  analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.FORGOT_PASSWORD);

  checkUserAndGetOtp = (emailOrMobile) => {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: CHECK_USER_AND_GENERATE_OTP,
          variables: {
            loginRequest: { userName: emailOrMobile },
          },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          this.setState({
            openSnackbar: true,
            snackbarMessage: FORGOT_PSWD.OTP_SENT,
            screen1: false,
            screen2: true,
            resendTime: 59,
            userId: res?.data?.getOtp?.userId,
            verifiedPhoneNo: emailOrMobile,
            verificationSID: res?.data?.getOtp?.verificationSid,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            emailError: true,
            emailErrorMsg: FORGOT_PSWD.INVALID,
          });
        });
    });
  };

  verifyOtp = () => {
    const otpValue = otp[0] + otp[1] + otp[2] + otp[3];
    this.props.logAnalyticsEvent(this.analyticsEvents.OTP_VERIFICATION_INITIATED_2022R4);
    this.setState({
      isLoading: true,
    });
    api
      .validateOTPByMobileOrEmail({
        otp: otpValue,
        email: this.state.isEmailOrMobile === 'email' ? this.state.verifiedPhoneNo : null,
        phoneNumber: this.state.isEmailOrMobile === 'mobile' ? this.state.verifiedPhoneNo : null,
        verificationSid: this.state.verificationSID,
      })
      .then((res) => {
        const status = res.verificationStatus;
        if (status === OTP_SCREEN.PHONE_VERIFICATION_STATUS.APPROVED) {
          this.props.logAnalyticsEvent(this.analyticsEvents.OTP_VERIFICATION_COMPLETED_2022R4);
          this.setState({
            screen2: false,
            isLoading: false,
          });
        } else {
          this.props.logAnalyticsEvent(this.analyticsEvents.OTP_WRONG_ERROR_2022R4);
          this.setState({
            otpError: true,
            isLoading: false,
            otpErrorMessage: FORGOT_PSWD.OTP_NOT_MATCH,
          });
        }
      })
      .catch((error) => {
        this.props.logAnalyticsEvent(this.analyticsEvents.OTP_VERIFICATION_ERROR);
        console.log(error);
        this.setState({
          otpError: true,
          isLoading: false,
          otpErrorMessage: FORGOT_PSWD.OTP_NOT_MATCH,
        });
      });
  };

  validatePasswords = () => {
    const isPasswordValid = this.passwordValidation();
    const isConfirmPasswordValid = this.rePasswordValidation();
    return isPasswordValid && isConfirmPasswordValid;
  };

  updatePasswordChanges = () => {
    if (this.validatePasswords()) {
      this.setState({
        isLoading: true,
      });
      return new Promise((resolve, reject) => {
        client
          .mutate({
            mutation: RESET_PASSWORD_QUERY,
            variables: {
              changePasswordRequest: {
                phone: this.state.isEmailOrMobile === 'mobile' ? this.state.verifiedPhoneNo : ' ',
                newPassword: this.state.password,
                email: this.state.isEmailOrMobile === 'email' ? this.state.verifiedPhoneNo : ' ',
                currentPassword: '',
              },
            },
          })
          .then((response) => {
            if (response.data.forgetPassword.statusCode === '200 OK') {
              this.props.logAnalyticsEvent(this.analyticsEvents.PASSWORD_RESET_COMPLETED_2022R4);
              this.props.navigate('/home');
            } else {
              this.setState({
                passwordError: true,
                isLoading: false,
                passwordErrorMsg: FORGOT_PSWD.SOMETHING_ERROR,
              });
            }
          })
          .catch((error) => {
            this.setState({
              passwordError: true,
              isLoading: false,
              passwordErrorMsg: FORGOT_PSWD.SOMETHING_ERROR,
            });
          });
      });
    }
  };

  showPassword = (type) => {
    const passwordField = `showPassword${type}`;

    this.setState((prevState, props) => {
      return { [passwordField]: !prevState[passwordField] };
    });
  };

  emailValidation = () => {
    const { email } = this.state;
    const { isValid } = validateEmail(email);

    if (!isValid) {
      this.setState({
        emailError: true,
      });
      return false;
    }
    return true;
  };

  numberValidation = () => {
    const { email } = this.state;
    const usFormatPhoneNo = getUSFormatPhoneNo(email);
    const { isValid } = validateUSPhone(usFormatPhoneNo);
    if (!isValid) {
      this.setState({
        emailError: true,
      });
      return false;
    }
    return true;
  };
  handleFieldChange = (name, value) => {
    this.setState({
      emailError: false,
      emailErrorMsg: '',
      email: value,
      isEmailOrMobile: '',
    });
  };

  handlePasswordChange = (name, value) => {
    const nameError = `${name}Error`;
    const nameErrorMsg = `${name}ErrorMsg`;
    const caretStart = event.target.selectionStart;

    this.setState(
      {
        [name]: value,
        [nameError]: false,
        [nameErrorMsg]: '',
        error: '',
      },
      () => {
        if (name === 'phoneno' && phoneLength > caretStart) {
          event.target.selectionStart = event.target.selectionEnd = caretStart;
        }
      },
    );

    if (name === 'password' || name === 'rePassword') {
      this.setState((prevState, props) => ({
        passwordMatched:
          prevState[name] !== '' ? prevState.password === prevState.rePassword : false,
        showPasswordRe:
          prevState[name] !== ''
            ? prevState.password === prevState.rePassword
              ? false
              : prevState['showPasswordRe']
            : prevState['showPasswordRe'],
      }));
    }

    if (name === 'password') {
      this.setState((prevState, props) => {
        return prevState.passwordMatched
          ? {
              rePasswordError: false,
              rePasswordErrorMsg: '',
            }
          : null;
      });
      this.setState({
        passwordStrength: rankPassword(value),
      });
    }
  };
  passwordValidation = () => {
    const { password, passwordStrength } = this.state;

    const { isValid, errorMessage } = validatePassword(password, passwordStrength);
    if (!isValid) {
      this.setState({
        passwordError: true,
        passwordErrorMsg: errorMessage,
      });

      return false;
    }
    return true;
  };

  rePasswordValidation = () => {
    const { password, rePassword } = this.state;

    const { isValid, errorMessage } = validateRePassword(password, rePassword);
    if (!isValid) {
      this.setState({
        rePasswordError: true,
        rePasswordErrorMsg: errorMessage,
      });

      return false;
    }
    return true;
  };

  verifyEmailOrPhone = async () => {
    if (this.state?.email?.length === 0) {
      this.setState({
        emailError: true,
        emailErrorMsg: FORGOT_PSWD.MOBILE_OR_EMAIL_REQUIRED,
      });
      return;
    }
    const isEmailValid = this.emailValidation();
    if (isEmailValid) {
      this.setState({
        emailError: false,
        isEmailOrMobile: 'email',
      });
      this.checkUserAndGetOtp(this.state.email);
    } else {
      const isMobileValid = this.numberValidation();
      if (isMobileValid) {
        this.setState({
          emailError: false,
          isEmailOrMobile: 'mobile',
        });
        this.checkUserAndGetOtp(this.state.email);
      } else {
        this.setState({
          emailError: true,
          emailErrorMsg: FORGOT_PSWD.INVALID,
        });
      }
    }
  };

  componentDidMount() {
    setInterval(() => {
      this.setState({ resendTime: this.state.resendTime - 1 });
    }, 1000);
  }
  componentWillUnmount() {
    otp = new Array(4).fill('');
    clearInterval();
  }

  handleOTPChange = (element, index) => {
    this.setState({
      otpError: false,
    });

    if (isNaN(element.value)) {
      return false;
    }
    otp[index] = element.value;
    //Array size is fixed to 4 so we cannot find the exact length of array after changing the values so we are converting the array into string.
    const otpString = otp.toString();
    const otpStringLength = otpString.length;

    //we are having an array of length 4 and when we convert the array into string so 3 commas are already there for sepating the values and 4 values from boxes so that altogether length of that string should be 7.
    if (otpStringLength === 7) {
      this.setState({
        validateOTPDisabled: false,
      });
    } else {
      this.setState({
        validateOTPDisabled: true,
      });
    }

    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
    if (!element.value) {
      element.previousSibling.focus();
    }
  };

  resendOTP = () => {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: CHECK_USER_AND_GENERATE_OTP,
          variables: {
            loginRequest: { userName: this.state.email },
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          this.props.logAnalyticsEvent(this.analyticsEvents.OTP_VERIFICATION_RESENT_2022R4);
          this.setState({
            verificationSID: response?.data?.getOtp?.verificationSid,
            userId: response?.data?.getOtp?.userId,
            resendTime: 59,
          });
        })
        .catch((error) => {
          this.props.logAnalyticsEvent(this.analyticsEvents.OTP_RESENT_ERROR_2022R4);
          this.setState({
            resendTime: 59,
          });
          console.log('Error during resent OTP', error);
        });
    });
  };
  handleResend = () => {
    this.setState({
      openSnackbar: true,
      snackbarMessage: FORGOT_PSWD.OTP_RESENT,
    });
    this.props.logAnalyticsEvent(this.analyticsEvents.RESEND_OTP_INITIATED_2022R4);
    this.resendOTP();
  };

  render() {
    const { classes } = this.props;
    const {
      emailError,
      email,
      validateOTPDisabled,
      screen1,
      screen2,
      isLoading,
      otpError,
      resendTime,
      otpErrorMessage,
      passwordError,
      rePasswordError,
      passwordErrorMsg,
      rePasswordErrorMsg,
      showPassword,
      showPasswordRe,
      passwordStrength,
      verifiedPhoneNo,
      loaderText,
      openSnackbar,
      snackbarMessage,
    } = this.state;
    return (
      <Box className={classes.root}>
        {isLoading && <Loader overlay text={loaderText} />}
        <LeftContainer />
        {screen1 ? (
          <Box className={classes.mainPage}>
            <Box className={classes.mainPageBox}>
              <Typography variant="h2" className={classes.heading}>
                {FORGOT_PSWD.FORGOT_PSWD}
              </Typography>

              <Typography variant="h6" className={classes.textFieldTitle}>
                {FORGOT_PSWD.REGISTERED_EMAIL_PHONE}
              </Typography>

              <TextField
                className={classes.textFieldStyle}
                id="email"
                name="email"
                color="secondary"
                variant="outlined"
                size="small"
                type="text"
                placeholder="Enter your email or mobile number"
                onFieldChange={this.handleFieldChange}
              />
              {emailError ? (
                <Typography variant="h6" className={classes.helperText}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {this.state.emailErrorMsg}
                </Typography>
              ) : (
                <Typography variant="h6" className={classes.phoneHelperText}>
                  <InfoIcon className={classes.infoIconError}></InfoIcon> {FORGOT_PSWD.CONFIRM_OTP}
                </Typography>
              )}
              <Button
                color="secondary"
                title={FORGOT_PSWD.BUTTON_TEXT}
                variant="contained"
                onClick={() => this.verifyEmailOrPhone()}
                className={classes.createAccountButtom}
              />
            </Box>
          </Box>
        ) : screen2 ? (
          <Box className={classes.mainPage}>
            <Box style={boxstyle}>
              <div style={otpdivstyle}>
                <div>
                  <h2>{OTP_VERIFICATION_MODAL.ENTER_OTP}</h2>
                  <p style={headertextstyle}>
                    {OTP_VERIFICATION_MODAL.FOUR_DIGIT_CODE}
                    {this.state.isEmailOrMobile === 'email' ? (
                      <span className={classes.blueColor}>{verifiedPhoneNo}</span>
                    ) : (
                      <span className={classes.blueColor}>{`****** ${verifiedPhoneNo.substring(
                        6,
                      )}`}</span>
                    )}
                  </p>
                  <p>{'\n'}</p>

                  {otp.map((data, index) => {
                    return (
                      <input
                        style={inputboxstyle}
                        type="text"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => this.handleOTPChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                      />
                    );
                  })}
                  {otpError ? (
                    <Typography variant="h6" style={helperText}>
                      <InfoIcon className={classes.infoIconError}></InfoIcon>
                      {otpErrorMessage}
                    </Typography>
                  ) : null}

                  <br />
                  <br />
                  <Button
                    id="Change Password"
                    title={FORGOT_PSWD.CHANGE_PASSWORD}
                    style={buttonstyle}
                    onClick={() => this.verifyOtp()}
                    variant="contained"
                    color="secondary"
                    disabled={validateOTPDisabled}
                  />
                </div>
                <br />
                <p style={textsizestyle}>{OTP_VERIFICATION_MODAL.DID_NOT}</p>
                <p>
                  {resendTime > 0 ? (
                    <span style={spanstyle}>
                      {`${OTP_VERIFICATION_MODAL.NEW_CODE} ${resendTime}`}
                    </span>
                  ) : (
                    <b>
                      <a type="button" onClick={() => this.handleResend()} style={otpbuttonstyle}>
                        {OTP_VERIFICATION_MODAL.RESEND_OTP}
                      </a>
                    </b>
                  )}
                </p>
              </div>
            </Box>
          </Box>
        ) : (
          <Box className={classes.mainPage}>
            <Box className={classes.mainPageBox}>
              <Typography variant="h2" className={classes.heading}>
                {OTP_VERIFICATION_MODAL.NEW_PASSWORD}
              </Typography>
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGNUP.PASSWORD}
              </Typography>
              <TextField
                className={classes.textFieldStyle}
                id="password-field"
                name="password"
                lightBorder={false}
                variant="outlined"
                size="small"
                color="secondary"
                placeholder={SIGNUP.PASSWORD_PLACEHOLDER}
                type={showPassword ? 'text' : 'password'}
                onFieldChange={this.handlePasswordChange}
                onShowPassword={() => this.showPassword('')}
                showPassword={true}
                passwordStrength={passwordStrength === 4}
              />
              {passwordError ? (
                <Typography variant="h6" className={classes.helperText}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {passwordErrorMsg}
                </Typography>
              ) : null}
              <Typography variant="h6" className={classes.textFieldTitle}>
                {SIGNUP.CONFIRM_PASSWORD}
              </Typography>
              <TextField
                className={classes.textFieldStyle}
                id="repassword"
                name="rePassword"
                lightBorder={false}
                variant="outlined"
                size="small"
                color="secondary"
                placeholder={SIGNUP.PASSWORD_PLACEHOLDER}
                type={showPasswordRe ? 'text' : 'password'}
                onFieldChange={this.handlePasswordChange}
                onShowPassword={() => this.showPassword('Re')}
                showPassword={true}
              />
              {rePasswordError ? (
                <Typography variant="h6" className={classes.helperText}>
                  <InfoIcon className={classes.infoIconStyle}></InfoIcon>
                  {rePasswordErrorMsg}
                </Typography>
              ) : null}

              <Button
                color="secondary"
                title={SIGNUP.CHANGE_PASSWORD}
                variant="contained"
                onClick={() => this.updatePasswordChanges()}
                className={classes.createAccountButtom}
              />
            </Box>
          </Box>
        )}
        <SnackbarToast
          open={openSnackbar}
          message={snackbarMessage}
          duration={3000}
          onClose={() => this.setState({ openSnackbar: false })}
          alertStyle={{ backgroundColor: '#000000' }}
        />
      </Box>
    );
  }
}
export default withRouter(withStyles(styles, { withTheme: true })(NewForgotPassword));
