import { Grid, Typography } from '@mui/material';
import React from 'react';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import { DrawerBoxComponent } from './DrawerBoxComponent';
// import { APPOINTMENTS } from '../../shared/Strings';

const data = [
  {
    condition: 'Smoking',
    value: 'Yes',
  },
  {
    condition: 'Drinking',
    value: 'Yes',
  },
  {
    condition: 'Recreational Drugs',
    value: 'Yes',
  },
];

export const AppointmentMedicalHistory = () => {
  return (
    <DrawerBoxComponent>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h3">Medical History</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={1} direction="column">
            {data?.map((item, index) => (
              <Grid item key={index}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    <Typography variant="p" color="#33333">
                      {item?.condition} : {item?.value}
                    </Typography>
                  </Grid>
                  {/* {item.description && ( */}
                  <Grid item>
                    <Typography variant="p" color="#33333">
                      <StatusIcon
                        htmlColor="black"
                        sx={{
                          height: 10,
                          width: 10,
                          mr: 0.5,
                          ml: 1,
                        }}
                      />
                      2 times a day
                    </Typography>
                  </Grid>
                  {/* )} */}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </DrawerBoxComponent>
  );
};
