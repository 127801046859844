import CongratsIcon from '../assets/images/csr.svg';
import BudgetingIcon from '../assets/images/budgeting.svg';
// import IntroductionIcon from '../assets/images/introduction.svg';
import BadgeIcon from '../assets/images/badge.svg';
import FeedbackIcon from '../assets/images/get-feedback.svg';
import SettingIcon from '../assets/images/user-settings.svg';
import { replaceImageProperty } from './helper';
import { ONBOARDING } from '../utils/constants';
const ICONS = [CongratsIcon, BudgetingIcon, BadgeIcon, SettingIcon];

const get = () => replaceImageProperty(ONBOARDING.CARDS, ICONS, []);
const onboardingIconGetter = { get };

export default onboardingIconGetter;
