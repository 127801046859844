import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '../../atoms/Button';
import { Box, Typography } from '@mui/material';
import colors from '../../../utils/colors';
import FirebaseUtils from '../../../utils/FirebaseUtils';
import useAnalyticsLogger from '../../../hooks/useAnalyticsLogger';

const styles = () => ({
  root: {
    backgroundColor: colors.WHITE,
    boxSizing: 'border-box',
    display: 'flex',
    height: 142,
    borderRadius: 16,
    overflow: 'hidden',
    width: 336,
  },
  titleStyle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: ' 24px',
    color: colors.TITLE_GRAY,
  },
  circleContainer: {
    position: 'absolute',
    width: 114,
    height: 142,
  },
  contentContainer: {
    boxSizing: 'border-box',
    padding: 16,
    paddingRight: 0,
    width: 222,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentStyle: {
    fontWeight: 400,
    fontSize: 14,
    color: colors.GRAY_SECONDARY,
  },
  image: {
    position: 'absolute',
    width: 131,
    height: 96,
    right: 0,
  },
  imageContainer: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    width: 131,
    height: 142,
  },
  viewButton: {
    border: '1px solid #A0A4A6',
    color: '#036FCB',
    height: 24,
    borderRadius: 20,
    fontSize: '14px',
    padding: '0px 16px',
    width: 'fit-content',
  },
});

const SvgCircle = ({ color }) => {
  return (
    <svg width="128" height="156" fill={color} viewBox="0 0 100 156">
      <circle r="114" cx="100" cy="112" />
    </svg>
  );
};

const ShoppingCard = ({ classes, ...props }) => {
  const {logAnalyticsEvent} = useAnalyticsLogger();
  function handleClick(
    link,
    code,
    searchPlaceHolder,
    enableTracking,
    trackEventName,
    trackParamName,
    heading,
  ) {
    if (enableTracking && trackEventName && trackParamName) {
      logAnalyticsEvent(trackEventName, {
        [trackParamName]: code,
      });
    }
    props.handleButtonClick(link, code, searchPlaceHolder, heading);
  }

  return (
    <div className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box>
          <Typography className={classes.titleStyle}>{props.heading}</Typography>
          <Typography className={classes.contentStyle}>{props.content}</Typography>
        </Box>
        <Button
          className={classes.viewButton}
          title="View"
          onClick={() =>
            handleClick(
              props.link,
              props.code ?? '',
              props.searchPlaceHolder,
              props.enableTracking,
              props.trackEventName,
              props.trackParamName,
              props.heading,
            )
          }
        />
      </Box>

      <div className={classes.imageContainer}>
        <img className={classes.image} src={props.icon} />
      </div>
    </div>
  );
};

export default withStyles(styles)(ShoppingCard);
