import React from 'react';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  container: {
    maxWidth: 343,
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    padding: '10px 0px 10px 16px',
    // boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: 12,
    background: 'white',
    marginBottom: 20,
  },
  imageWrapperSpan: {
    marginRight: '8px',
    height: '32px',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // border: '1px solid #ddd',
  },
  icon: { height: '32px', width: 'auto', borderRadius: '14px', objectFit: 'contain' },
  titleWrapper: {
    color: '#4B4B4B',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
  },
});
const SubTitleCard = (props) => {
  const { icon, title, classes } = props;
  return (
    <div className={classes.container}>
      <span className={classes.imageWrapperSpan}>
        <img src={icon} alt="icon" className={classes.icon} />
      </span>
      <span className={classes.titleWrapper}>{title}</span>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SubTitleCard);
