import { createSlice } from '@reduxjs/toolkit';

function setInitialLanguage() {
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  if(!selectedLanguage){
    localStorage.setItem('selectedLanguage', selectedLanguage ?? 'en_US');
    return 'en_US';
  }
  return selectedLanguage;
}

const initialState = {
  selectedLanguage: setInitialLanguage(),
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
  },
});

export const { setSelectedLanguage } = appSlice.actions;

export default appSlice.reducer;
