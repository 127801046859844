import React, { useState } from 'react';
import { Box, Container, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NotInterested } from '@mui/icons-material';
import CustomizedSwitches from '../../../atoms/SelfReporting/ToggleSwitch';
import colors from '../../../../utils/colors';
import { SELF_REPORTING_TEXTS } from '../../../../utils/constants';

const { MARK_RESTRICTED, MARK_RESTRICTED_DESC } = SELF_REPORTING_TEXTS;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.SUBTLE_GRAY}`,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(3),
    maxWidth: '100%',
  },
  notInterested: {
    color: colors.WHITE,
    backgroundColor: 'red',
    borderRadius: 5,
    marginRight: theme.spacing(1),
    '&:focus': {
      border: '1px solid red',
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  paraText: {
    color: colors.GRAY_51,
    fontFamily: 'Inter',
    fontSize: theme.spacing(1.75),
    fontWeight: 400,
    lineHeight: '20px',
    marginTop: theme.spacing(1),
    letterSpacing: theme.spacing(0),
  },
  heading: {
    fontFamily: 'Inter',
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
    lineHeight: '16px',
    color: '#4B4B4B',
    letterSpacing: theme.spacing(0),
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  imgStyle: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
}));

const SwitchCard = (props) => {
  const [switchOnOff, setSwitchOnOff] = useState(props.enabled || false);
  const classes = useStyles();
  const { headerText = MARK_RESTRICTED, Icon, onChange, description } = props;

  const handleChange = () => {
    if (onChange) {
      onChange(!switchOnOff);
    }
    setSwitchOnOff(!switchOnOff);
  };

  return (
    <Box className={classes.root}>
      <Paper className={classes.headerContainer}>
        <Box className={classes.header}>
          {Icon ? (
            <img src={Icon} alt="bellIcon" className={classes.imgStyle} />
          ) : (
            <NotInterested className={classes.notInterested} />
          )}
          <Typography className={classes.heading}>{headerText}</Typography>
        </Box>
        <CustomizedSwitches switchOnOff={switchOnOff} onChange={handleChange} />
      </Paper>
      <Typography className={classes.paraText}>{description || MARK_RESTRICTED_DESC()}</Typography>
    </Box>
  );
};

export default SwitchCard;
