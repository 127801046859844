import client from '../components/ApolloClient';
import gql from 'graphql-tag';
import { GRANTEE_FRAGMENT, GRANTOR_FRAGMENT, LOGIN_RESPONSE } from '../utils/fragments';

const CREATE_NEW_USER = gql`
  mutation createNewUser(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $birthDate: String
    $ipAddress: String
    $referralPromoCode: String
    $deviceType: String
  ) {
    createNewUser(
      userCreateRequest: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        birthDate: $birthDate
        ipAddress: $ipAddress
        referralPromoCode: $referralPromoCode
        deviceType: $deviceType
      }
    ) {
      id
      email
      phone
      userId
    }
  }
`;
const CREATE_NEW_USER_ACCOUNT = gql`
  mutation createNewUserAccount(
    $email: String!
    $phone: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $birthDate: String
    $ipAddress: String
    $referralPromoCode: String
    $deviceType: String
  ) {
    createNewUserAccount(
      userCreateRequest: {
        email: $email
        phone: $phone
        password: $password
        firstName: $firstName
        lastName: $lastName
        birthDate: $birthDate
        ipAddress: $ipAddress
        referralPromoCode: $referralPromoCode
        deviceType: $deviceType
      }
    ) {
      id
      email
      phone
      userId
      loginResponse {
        ...loginResponse
      }
    }
  }
  ${LOGIN_RESPONSE}
`;
const checkSessionQuery = gql`
  mutation {
    checkSession
  }
`;

export const GENERATE_OTP_BY_PHONE_MUTATION = gql`
  mutation generateOTPByPhone($validateOTPRequest: ValidateOTPRequest) {
    generateOTPByPhone(validateOTPRequest: $validateOTPRequest) {
      phoneVerificationStatus
      verificationSid
    }
  }
`;

export const GET_USER_TIMEZONE = gql`
  mutation setTimeZoneForPatient($timeZoneRequest: TimeZoneRequest) {
    setTimeZoneForPatient(timeZoneRequest: $timeZoneRequest)
  }
`;

export const REDEEM_REFERRAL_POINTS_MUTATION = gql`
  mutation redeemReferralCode($redeemReferralRequest: RedeemReferralRequest) {
    redeemReferralCode(redeemReferralRequest: $redeemReferralRequest) {
      redeemStatus
      message
    }
  }
`;

export const REDEEM_PROMO_POINTS_MUTATION = gql`
  mutation redeemPromoCode($userId: String, $promoCode: String, $ipAddress: String) {
    redeemPromoCode(userId: $userId, promoCode: $promoCode, ipAddress: $ipAddress) {
      redeemStatus
      message
    }
  }
`;
export const GET_ACCESS_TOKEN_WITH_REFRESH_TOKEN = gql`
  query getRefreshTokenForConsumer {
    getRefreshTokenForConsumer {
      access_token
      refresh_token
      statusCode
      expires_in
      errorMessage
      userId
      phoneNumber
      refresh_expires_in
      scope
      session_state
      token_type
    }
  }
`;
export const LOGIN_WITH_CREDENTIAL_MUTATION = gql`
  mutation userLogin($loginRequest: LoginRequest) {
    userLogin(loginRequest: $loginRequest) {
      ...loginResponse
    }
  }
  ${LOGIN_RESPONSE}
`;

export const checkIfMobileExistsQuery = gql`
  query checkIfPhoneExist($phone: String) {
    checkIfPhoneExist(phone: $phone) {
      errorCode
      message
    }
  }
`;
export const SET_LAST_LOGIN_QUERY = gql`
  query {
    setLastLoginTime {
      userId
    }
  }
`;

const GET_USER_INFO = gql`
  query {
    fetchUserInfo {
      firstName
      lastName
      email
      phone
      avatarUrl
      id
      tags
    }
  }
`;

const CREATE_ACCOUNT_NEW = gql`
  mutation createAccountNew($userCreateRequest: UserCreateRequest) {
    createAccountNew(userCreateRequest: $userCreateRequest)
  }
`;

const CREATE_ACCOUNT = gql`
  mutation createAccount($userCreateRequest: UserCreateRequest) {
    createAccount(userCreateRequest: $userCreateRequest) {
      phoneVerificationStatus
      verificationSid
    }
  }
`;

//One time access queries
const VALIDATE_ONE_TIME_ACCESS = gql`
  query validateOneTimeAccessLink($requestId: String) {
    validateOneTimeAccessLink(requestId: $requestId) {
      status
      grantorName
      email
      phoneNumber
      consentId
      linkExpiryDate
    }
  }
`;
const TRACK_NO_OF_LINK_CLICKS = gql`
  mutation trackNoOfLinkClicks($requestId: String) {
    trackNoOfLinkClicks(requestId: $requestId)
  }
`;
const FETCH_CONSENTS = gql`
  query fetchConsents($Id: Float) {
    fetchConsents(Id: $Id) {
      initiator
      status
      scope
      categories
      grantor {
        ...grantor
      }
      grantorState
      grantedDate
      grantExpiryDate
      grantee {
        ...grantee
      }
      mphContentClassIds
    }
  }
  ${GRANTOR_FRAGMENT}
  ${GRANTEE_FRAGMENT}
`;

const GENERATE_AND_SEND_QUERY = gql`
  mutation generateAndSendOTP($oneTimeAccessOTPRequest: OneTimeAccessOTPRequest) {
    generateAndSendOTP(oneTimeAccessOTPRequest: $oneTimeAccessOTPRequest) {
      verificationSid
      verificationStatus
    }
  }
`;

const VERIFY_ONE_TIME_OTP_QUERY = gql`
  mutation validateOneTimeAccessOTP($oneTimeAccessOTPRequest: OneTimeAccessOTPRequest) {
    validateOneTimeAccessOTP(oneTimeAccessOTPRequest: $oneTimeAccessOTPRequest) {
      verificationStatus
    }
  }
`;

export const LOGIN_WITH_ONE_TIME_ACCESS = gql`
  mutation oneTimeAccessUserLogin($loginRequest: OneTimeAccessLoginRequest) {
    oneTimeAccessUserLogin(loginRequest: $loginRequest) {
      access_token
      expires_in
      refresh_expires_in
      refresh_token
      token_type
      session_state
      scope
      statusCode
      errorMessage
    }
  }
`;

export default {
  signupUser(
    firstName,
    lastName,
    email,
    password,
    phoneno,
    ipAddress,
    referralPromoCode,
    deviceType,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: CREATE_NEW_USER,
          variables: {
            email: email,
            phone: phoneno,
            firstName: firstName,
            lastName: lastName,
            password: password,
            ipAddress,
            referralPromoCode,
            deviceType,
          },
          errorPolicy: 'none',
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  CreateNewUserAccount(
    firstName,
    lastName,
    email,
    password,
    phoneno,
    ipAddress,
    referralPromoCode,
    deviceType,
  ) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: CREATE_NEW_USER_ACCOUNT,
          variables: {
            email: email,
            phone: phoneno,
            firstName: firstName,
            lastName: lastName,
            password: password,
            ipAddress,
            referralPromoCode,
            deviceType,
          },
          errorPolicy: 'none',
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUserInfo(query = GET_USER_INFO, primary=false) {
    const context = primary ? {
      headers:{
        fetch_id: null,
      }
    } :{};

    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
          context
        })
        .then((response) => {
          resolve(response.data.fetchUserInfo);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchUserTimeZone(timezone) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: GET_USER_TIMEZONE,
          variables: {
            timeZoneRequest: { timeZone: timezone },
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchKeycloakUserSchema(query) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.fetchKeycloakUserSchema);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyEmail(query, verificationCode) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            verificationCode: verificationCode,
          },
        })
        .then((response) => {
          resolve(response.data.verifyEmail);
        })
        .catch((error) => {
          reject(error.graphQLErrors[0].extensions);
        });
    });
  },

  isPhoneVerified(isPhoneVerifiedQuery, field) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: isPhoneVerifiedQuery,
          variables: field,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.isUserVerified.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  markLoginStatus(markLoginStatusQuery) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: markLoginStatusQuery,
        })
        .then((response) => {
          resolve(response.data.isUserVerified);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  generateOTP(generateOTPQuery) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: generateOTPQuery,
        })
        .then((response) => {
          resolve(response.data.generateOTP);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  generateOTPByPhone(args, mutation = GENERATE_OTP_BY_PHONE_MUTATION) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            validateOTPRequest: args,
          },
        })
        .then((response) => {
          resolve(response.data.generateOTPByPhone);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  loginWithCredentials({ username: userName, password }) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: LOGIN_WITH_CREDENTIAL_MUTATION,
          variables: {
            loginRequest: {
              userName,
              password,
              realm: 'consumer',
            },
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  createAccount(userDetailsObj, mutation = CREATE_ACCOUNT) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            userCreateRequest: {
              id: userDetailsObj.id,
              phone: userDetailsObj.phone,
              email: userDetailsObj.email,
              firstName: userDetailsObj.firstName,
              lastName: userDetailsObj.lastName,
              password: userDetailsObj.password,
              isSocialLogin: userDetailsObj.isSocialLogin,
              ipAddress: userDetailsObj.ipAddress,
              deviceType: userDetailsObj.deviceType,
            },
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createAccountNew(userDetailsObj, mutation = CREATE_ACCOUNT_NEW) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation,
          variables: {
            userCreateRequest: {
              id: userDetailsObj.id,
              phone: userDetailsObj.phone,
              email: userDetailsObj.email,
              firstName: userDetailsObj.firstName,
              lastName: userDetailsObj.lastName,
              password: userDetailsObj.password,
              isSocialLogin: userDetailsObj.isSocialLogin,
              ipAddress: userDetailsObj.ipAddress,
              deviceType: userDetailsObj.deviceType,
            },
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyOTP(verifyOTPQuery, validateOTPRequest) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: verifyOTPQuery,
          variables: {
            validateOTPRequest: validateOTPRequest,
          },
        })
        .then((response) => {
          resolve(response.data.validateOTP);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  verifyOTPUnAuth(verifyOTPQueryUnAuth, validateOTPRequest, userId) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: verifyOTPQueryUnAuth,
          variables: {
            validateOTPRequest: validateOTPRequest,
            userId: userId,
          },
        })
        .then((response) => {
          resolve(response.data.validateOTPUnAuth);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  verifyOTPUnAuthNew(verifyOTPQueryUnAuthNew, validateOTPRequest) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: verifyOTPQueryUnAuthNew,
          variables: {
            validateOTPRequest: validateOTPRequest,
          },
        })
        .then((response) => {
          resolve(response.data.validateOTPUnAuthNew);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  generateOTPUnAuth(generateOTPQueryUnAuth, phone) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: generateOTPQueryUnAuth,
          variables: {
            phone: phone,
          },
        })
        .then((response) => {
          resolve(response.data.generateOTPUnAuth);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  redeemReferralCode(redeemReferralRequest) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: REDEEM_REFERRAL_POINTS_MUTATION,
          variables: {
            redeemReferralRequest,
          },
        })
        .then((response) => {
          resolve(response.data.redeemReferralCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  redeemPromoCode({ userId, promoCode, ipAddress }) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: REDEEM_PROMO_POINTS_MUTATION,
          variables: {
            userId,
            promoCode,
            ipAddress,
          },
        })
        .then((response) => {
          resolve(response.data.redeemPromoCode);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  checkSession() {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: checkSessionQuery,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkIfEmailExist(checkIfEmailExistQuery, emailId) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: checkIfEmailExistQuery,
          variables: {
            emailId: emailId,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkIfMobileExists(phone) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: checkIfMobileExistsQuery,
          variables: {
            phone: phone,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  checkIfUserExists(checkIfUserExistsQuery, userName) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: checkIfUserExistsQuery,
          variables: {
            userName: userName,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getAccessTokenWithRefreshToken() {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: GET_ACCESS_TOKEN_WITH_REFRESH_TOKEN,
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePhoneNumber(updatePhoneNumberQuery, userWithNewPhoneNo) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: updatePhoneNumberQuery,
          variables: {
            id: userWithNewPhoneNo.id,
            firstName: userWithNewPhoneNo.firstName,
            lastName: userWithNewPhoneNo.lastName,
            phone: userWithNewPhoneNo.phone,
            email: userWithNewPhoneNo.email,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateUser(query, firstName, lastName, email, password) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            firstName,
            lastName,
            email,
            password,
          },
        })
        .then((response) => {
          resolve(response.data.updateAccount);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  twoFactorAuth(query, args) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            verificationSid: args.verificationSid,
            otp: args.otp,
            password: args.password,
          },
        })
        .then((response) => {
          resolve(response.data.authenticateUser);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deactivate(query, args) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: query,
          variables: {
            deactivationRequest: {
              reasonForDeactivation: args.reasonForDeactivation,
            },
          },
        })
        .then((response) => {
          resolve(response.data.deactivateUserProfile.status);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUserExtension(query) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.getUserExtension);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setLastLoginTime(query) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: query,
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          resolve(response.data.setLastLoginTime);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //One time access API calls
  validateOneTimeAccess(requestId) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: VALIDATE_ONE_TIME_ACCESS,
          fetchPolicy: 'no-cache',
          variables: {
            requestId,
          },
        })
        .then((response) => {
          resolve(response.data.validateOneTimeAccessLink);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  trackLinkCLicks(requestId) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: TRACK_NO_OF_LINK_CLICKS,
          fetchPolicy: 'no-cache',
          variables: {
            requestId,
          },
        })
        .then((response) => {
          resolve(response.data.validateOneTimeAccessLink);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateAndSendOTP(args) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: GENERATE_AND_SEND_QUERY,
          variables: {
            oneTimeAccessOTPRequest: {
              phoneNumber: args.phoneNumber,
              email: args.email,
            },
          },
        })
        .then((response) => {
          resolve(response.data.generateAndSendOTP);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  verifyOneTimeOTP(oneTimeAccessOTPRequest) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: VERIFY_ONE_TIME_OTP_QUERY,
          variables: {
            oneTimeAccessOTPRequest: oneTimeAccessOTPRequest,
          },
        })
        .then((response) => {
          resolve(response.data.validateOneTimeAccessOTP);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  oneTimeAccessUserLogin(loginRequest) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: LOGIN_WITH_ONE_TIME_ACCESS,
          variables: {
            loginRequest: loginRequest,
          },
        })
        .then((response) => {
          resolve(response.data.oneTimeAccessUserLogin);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchConsents(Id) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: FETCH_CONSENTS,
          variables: {
            Id: parseFloat(Id),
          },
        })
        .then((response) => {
          resolve(response.data.fetchConsents);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
