import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Typography, Link, Box } from '@mui/material';
import { IMAGE_ALTERNATIVE_TXT, LOG_IN } from '../../utils/constants';
import LogoAlternate from '../../assets/images/logoalternate.png';
import { fireHanselEvent } from '../../utils/helper';
import { OTPBox } from './OTPBox';
import FillDetails from './FillDetails';
import UserService from '../../service/User';
import Appstore from '../../assets/images/appstore.png';
import Playstore from '../../assets/images/playstore.png';
import Description from '../../components/typography/Description';
import withRouter from '../../components/withRoute';
import Carousel from '../../components/Carousel/Carousel';

const publicIp = require('public-ip');

const styles = (theme) => ({
  leftContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    height: '100%',
    width: '44.44%',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 640,
  },
  cover: {
    alignItems: 'center',
    direction: 'column',
    justify: 'center',
  },
  image: {
    marginTop: 16,
    paddingRight: 20,
  },
  button: {
    padding: 10,
    borderRadius: 25,
  },
  landingImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: 0,
    objectFit: 'cover',
    maxWidth: 640,
    maxHeight: 616,
  },
  root: {
    overflow: 'scroll',
    height: '100vh',
    display: 'flex',
    flexDirection: 'row',
  },
  alternateLogo: {
    paddingLeft: 72,
    paddingRight: 72,
    maxWidth: '100%',
  },
  logoAlternateContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    paddingTop: 72,
    maxWidth: '60%',
  },
  leftContainerside: {
    flex: 0.35,
    justifyContent: 'center',
  },
  imagebox: {
    paddingLeft: 72,
    marginTop: 72,
  },
  leftSideTextBlack: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#101828',
  },
  leftSideTextBlue: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#00A0DF',
  },
  leftSideTextBlack2: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 64,
    lineHeight: '80px',
    color: '#343B40',
  },
  linkContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start-flex',
    justifyContent: 'flex-end',
    marginBottom: '30px',
    paddingLeft: 72,
    flex: 1,
  },
  appInfo: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '32px',
    color: '#333333',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
  appStoreLink: {
    color: '#036FCB',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '32px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
  },
});

class SocialSignin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OTP: false,
      userSchemaObj: {},
      verificationSid: '',
      phoneNo: '',
      firstName: '',
      lastName: '',
      email: '',
      id: '',
      isSocialLogin: '',
      isReferral: false,
      isPromoCode: false,
      code: '',
    };
    this.setOTP = this.setOTP.bind(this);
  }

  setOTP({
    verificationSid,
    phone,
    firstName,
    lastName,
    email,
    id,
    isSocialLogin,
    isReferral,
    isPromoCode,
    code,
  }) {
    this.setState({
      OTP: true,
      verificationSid,
      phoneNo: phone,
      firstName,
      lastName,
      email,
      id,
      isSocialLogin,
      isReferral,
      isPromoCode,
      code,
    });
  }

  componentDidMount() {
    fireHanselEvent('MPH_SIGNUP_PAGE_LOAD', {});
  }

  static getDerivedStateFromProps(props, state) {
    if (props.userSchemaObj) {
      return { ...state, userSchemaObj: { data: props.userSchemaObj, type: 'SS' } };
    } else if (props.location && props.location.state.userSchemaObj) {
      return { ...state, userSchemaObj: { data: props.location.state.userSchemaObj, type: 'NS' } };
    }
    return state;
  }

  onOTPSuccess = async (value) => {
    if (!value) {
      return '';
    }
    // redeem referral points and redirect to home on success
    let ipAddress = '0.0.0.0';
    try {
      ipAddress = await publicIp.v4();
    } catch (error) {
      console.warn('Error occurred while fetching ip address', error);
    }

    try {
      if (this.state.isReferral) {
        const redeemReferralResponse = await UserService.redeemReferralCode({
          userId: this.state.id,
          username: this.state.phoneNo || this.state.email,
          userFullName: this.state.firstName + this.state.lastName,
          referralCode: this.state.code,
          ipAddress: ipAddress,
        });
        if (redeemReferralResponse.redeemStatus) {
          this.props.navigate('/login', {
            state: {
              signupCompletionFlag: true,
            },
          });
        }
      } else if (this.state.isPromoCode) {
        const redeemPromoCodeResponse = await UserService.redeemPromoCode({
          userId: this.state.id,
          promoCode: this.state.code,
          ipAddress: ipAddress,
        });
        if (redeemPromoCodeResponse.redeemStatus) {
          this.props.navigate('/login', {
            state: {
              signupCompletionFlag: true,
            },
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    this.props.navigate('/login', {
      state: {
        signupCompletionFlag: true,
      },
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Box className={classes.leftContainer}>
          <Box className={classes.leftContainerside}>
            <Box className={classes.logoAlternateContainer}>
              <img
                src={LogoAlternate}
                alt={IMAGE_ALTERNATIVE_TXT.LOGO}
                className={classes.alternateLogo}
              />
            </Box>
            {/* <Description>
              One app to help you consolidate all your health records, track your medications,
              manage your healthcare expenses, shop for plans and more..
            </Description> */}
          </Box>
          <Box className={classes.imagebox}>
            {/* <Typography className={classes.leftSideTextBlack}>{LOG_IN.ALL_YOUR}</Typography>
            <Typography className={classes.leftSideTextBlue}>{LOG_IN.HEALTHCARE}</Typography>
            <Typography className={classes.leftSideTextBlack2}> {LOG_IN.IN_ONE_APP}</Typography> */}
            <Carousel />
          </Box>

          <Box className={classes.linkContainer}>
            <Typography variant="h4" className={classes.appInfo}>
              {LOG_IN.DOWNLOAD}
              <Link
                href="https://apps.apple.com/us/app/mpoweredhealth/id1525925137"
                target="_blank"
                rel="noopener"
                variant="h4"
                className={classes.appStoreLink}
              >
                {LOG_IN.IOS}
              </Link>
              {LOG_IN.AND}
              <Link
                href="https://play.google.com/store/apps/details?id=com.mpoweredhealth"
                target="_blank"
                rel="noopener"
                variant="h4"
                className={classes.appStoreLink}
              >
                {LOG_IN.ANDROID}
              </Link>
            </Typography>
            <Box>
              <Link
                href="https://apps.apple.com/us/app/mpoweredhealth/id1525925137"
                target="_blank"
                rel="noopener"
              >
                <img src={Appstore} alt="App Store" className={classes.image} />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.mpoweredhealth"
                target="_blank"
                rel="noopener"
              >
                <img src={Playstore} alt="Play Store" className={classes.image} />
              </Link>
            </Box>
          </Box>
        </Box>
        {this.state.OTP === true ? (
          <OTPBox
            setUser={this.props.location.setIsUserCreated}
            phoneNo={this.state.phoneNo}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            id={this.state.id}
            isSocialLogin={this.state.isSocialLogin}
            verificationSid={this.state.verificationSid}
            onOTPSuccess={this.onOTPSuccess}
          />
        ) : (
          <FillDetails setOTP={this.setOTP} userSchemaObj={this.state.userSchemaObj} />
        )}
      </Box>
    );
  }
}
export default withRouter(withStyles(styles, { withTheme: true })(SocialSignin));
