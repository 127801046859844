import colors from '../../utils/colors';

const styles = (theme) => ({
  listCustom: {
    border: `1px solid ${colors.SUBTLE_GRAY}`,
    borderRadius: '12px',
  },
  listItemWrapper: {
    padding: '0px 12px',
    borderBottom: `1px solid ${colors.SUBTLE_GRAY}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  listItem: {
    fontSize: '14px',
    color: colors.PRIMARY_GRAY,
    lineHeight: '20px',
  },
  statusContainer: { display: 'flex', alignItems: 'center', marginBottom: 12 },
  statusColumn: { display: 'flex', alignItems: 'center', marginRight: 4 },
  column_value: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#344054',
    flex: 'none',
    margin: '4px 0px',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  status_value: {
    boxSizing: 'border-box',
    fontSize: '14px',
    lineHeight: '0px',
  },
  statusIcon: { width: 12, height: 12 },
});

export default styles;
