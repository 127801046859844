import { useEffect, useState } from 'react';

export const useInitialLoading = () => {
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setInitialRender(false);
    }, 3000);
  }, [initialRender]);

  return [initialRender, setInitialRender];
};
