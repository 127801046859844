import React from 'react';
import { withStyles } from '@mui/styles';
import { CARE_PLAN } from '../../utils/constants';
import CarePlanDetailsSection from './CarePlanDetailsSection';

const styles = (theme) => ({
  detailsBody: {
    marginTop: '17px',
  },
  sectionWrapper: {
    marginBottom: '32px',
  },
});

function CarePlanDetails({ classes, ...rest }) {
  const sections = CARE_PLAN.WORDINGS.DETAILS_MODAL.SECTIONS.map((section) => ({
    title: section.TITLE,
    body: rest[section.PROP_KEY],
  }));

  return (
    <div className={classes.detailsBody}>
      {sections.map((section) => (
        <div className={classes.sectionWrapper} key={section.title}>
          <CarePlanDetailsSection title={section.title} body={section.body} />
        </div>
      ))}
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(CarePlanDetails);
