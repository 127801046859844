import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  cardHeading: {
    fontSize: '18px',
  },
  cardSubHeading: {
    fontSize: 14,
    fontWeight: 500,
    color: '#666666',
  },
  leftContent: {
    '& img': {
      paddingRight: 10,
    },
    display: 'flex',
    alignItems: 'center',
  },
  rightContent: {
    marginRight: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  awardPointsDiv: {
    '& img': {
      paddingRight: 10,
    },
    display: 'flex',
    alignItems: 'center',
  },
  points: {
    fontSize: 18,
    fontWeight: 600,
  },
  plusPoints: {
    color: '#007D32',
  },
  dateTimeDiv: {
    fontSize: 12,
    fontWeight: 600,
    color: '#667085',
  },
  awardsPointWrapper: {
    display: 'flex',
    padding: '16px 24px',
    borderBottom: '1px solid #E4E7EC',
    width: '100%',
    justifyContent: 'space-between',
    '&:last-child': {
      border: 'none'
    }
  },
}));
