import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { formatDateInMMDDYYYYWithComma } from '../../utils/helper';
import CustomStaticDatePicker from '../../components/molecules/CustomStaticDatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const useStyle = makeStyles((theme) => ({
  container: {
    minWidth: 160,
    padding: '0px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: '12px',
    borderRadius: 8,
    backgroundColor: '#fff',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      paddingRight: '6px',
    },
  },
  dateWrapper: {
    position: 'absolute',
    left: '0',
    top: '2.5rem',
    zIndex: 999,
    [theme.breakpoints.down('sm')]: {
      left: 0,
      top: '2.5rem',
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
  selectedItem: {
    padding: 0,
    margin: 0,
    color: 'rgb(148 144 144 / 90%)',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '5px',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.5px',
    fontFamily: 'Inter',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
      paddingRight: '12px',
    },
  },
  arrow: {
    '& svg': {
      color: '#969090',
      fontSize: '1.3rem',
      marginTop: '0.3rem',
    },
  },
}));

const CustomDateFilter = (props) => {
  const classes = useStyle(props);
  const { handleChange, filterItem, filterContainerClass, searchValue, placeholder } = props;
  const { options, label, defaultActiveIndex, width } = filterItem || {};

  const placeholderVal = placeholder || 'Search by date';

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(placeholderVal);
  const [customDate, setCustomDate] = useState(new Date());
  const dateRef = useRef(null);
  const closeRef = useRef(null);

  const handleOpen = () => {
    if (dateRef.current.style.display === 'block') {
      dateRef.current.style.display = 'none';
    } else {
      dateRef.current.style.display = 'block';
    }
  };

  const handleDateSelect = (selectedDate) => {
    setOpen(true);
    const date = new Date(selectedDate?.$d);
    handleChange(`${date.getTime()}`);
    setCustomDate(selectedDate);
    setSelectedItem(formatDateInMMDDYYYYWithComma(selectedDate?.$d));
  };

  const handleClear = () => {
    setSelectedItem(placeholderVal);
    setCustomDate(new Date());
    setOpen(false);
    handleChange('');
  };

  useEffect(() => {
    if (!searchValue.current) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [searchValue.current]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRef.current &&
        !dateRef.current.contains(event.target) &&
        closeRef.current &&
        !closeRef.current.contains(event.target)
      ) {
        dateRef.current.style.display = 'none';
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={[classes.container, filterContainerClass].join(' ')}
      style={{ width: width + 'px' }}
      ref={closeRef}
    >
      <div className={classes.wrapper} onClick={handleOpen}>
        <div style={{ color: open ? '#000' : '' }} className={classes.selectedItem}>
          {selectedItem}
        </div>
        <div className={classes.arrow}>
          {open ? <CloseIcon onClick={handleClear} /> : <CalendarMonthIcon />}{' '}
        </div>
      </div>
      <CustomStaticDatePicker
        rootClass={classes.dateWrapper}
        customDate={customDate}
        handleDateSelect={handleDateSelect}
        ref={dateRef}
      />
    </div>
  );
};

export default CustomDateFilter;
