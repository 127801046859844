import gql from 'graphql-tag';
import client from '../../components/ApolloClient';
import { DEFAULT_NULL_REPLACEMENT, D_SOURCE, SELF_REPORTING_LABELS } from '../../utils/constants';
import { CODE_FRAGMENT } from '../../utils/fragments';
import {
  carePlanCategory,
  extractRestricted,
  findDataSource,
  getCommaSeparatedDate,
} from '../../utils/helper';

const FETCH_CARE_PLANS = gql`
  query fetchAllCarePlans(
    $sourceUrl: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllCarePlans(
      sourceUrl: $sourceUrl
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      carePlanList
      count
    }
  }
`;
const FETCH_CARE_PLANS_BY_CONSENT = gql`
  query fetchAllCarePlansByConsent(
    $initiatorId: String
    $grantorId: String
    $count: String = "10"
    $offset: Int = 0
    $date_filter: Float = 0
    $status_filter: String = ""
    $search_parameter: String = ""
  ) {
    fetchAllCarePlansByConsent(
      initiatorId: $initiatorId
      grantorId: $grantorId
      count: $count
      offset: $offset
      dateFilter: $date_filter
      statusFilter: $status_filter
      searchFilter: $search_parameter
    ) {
      carePlanList
      count
    }
  }
`;

const FETCH_CARE_PLAN_BY_ID = gql`
  query fetchCarePlanById($carePlanId: String) {
    fetchCarePlanById(carePlanId: $carePlanId) {
      id
      status
      title
      created
      intent
      description
      category {
        ...code
      }
      period {
        start
        end
      }
      meta {
        security {
          system
          code
          display
          version
        }
        source
        lastUpdated
        versionId
      }
      activity {
        detail {
          code {
            text
            coding {
              display
            }
          }
        }
      }
      goal {
        display
        identifier {
          type {
            text
          }
        }
      }
      author {
        display
      }
      addresses {
        display
        identifier {
          type {
            text
          }
        }
      }
      careTeam {
        reference
        identifier {
          type {
            text
          }
        }
        display
      }
      identifier {
        system
        value
      }
      contributor {
        display
      }
      note {
        text
      }
    }
  }
  ${CODE_FRAGMENT}
`;

export function fetchAllCarePlans(variables = {}) {
  return new Promise((resolve, reject) => {
    client
      .query({ query: FETCH_CARE_PLANS, variables, fetchPolicy: 'no-cache' })
      .then((response) => {
        let carePlanList = JSON.parse(response.data.fetchAllCarePlans.carePlanList);
        carePlanList = carePlanList.map((eachPlan) => {
          return {
            ...eachPlan,
            dataSource: findDataSource(eachPlan.identifier),
            // recordedBy: (eachPlan.author && eachPlan.author.display) || DEFAULT_NULL_REPLACEMENT,
            // recordedOn: formatDateInMMDDYYYYWithComma(eachPlan.created) || DEFAULT_NULL_REPLACEMENT,
            startDate: eachPlan?.period?.start
              ? getCommaSeparatedDate(eachPlan.period.start)
              : DEFAULT_NULL_REPLACEMENT,
            endDate: eachPlan?.period?.end
              ? getCommaSeparatedDate(eachPlan.period.end)
              : DEFAULT_NULL_REPLACEMENT,
          };
        });
        resolve({ carePlanList, totalCount: response.data.fetchAllCarePlans.count });
      })
      .catch((err) => reject(err));
  });
}
export function fetchAllCarePlansByConsent(variables = {}) {
  return new Promise((resolve, reject) => {
    client
      .query({ query: FETCH_CARE_PLANS_BY_CONSENT, variables, fetchPolicy: 'no-cache' })
      .then((response) => {
        let carePlanList = JSON.parse(response.data.fetchAllCarePlansByConsent.carePlanList);
        carePlanList = carePlanList.map((eachPlan) => ({
          ...eachPlan,
          dataSource: findDataSource(eachPlan.identifier),
          recordedBy: eachPlan?.author?.display || DEFAULT_NULL_REPLACEMENT,
          recordedOn:
            (eachPlan?.created && getCommaSeparatedDate(eachPlan.created)) ||
            DEFAULT_NULL_REPLACEMENT,
          startDate: eachPlan?.period?.start
            ? getCommaSeparatedDate(eachPlan.period.start)
            : DEFAULT_NULL_REPLACEMENT,
          endDate: eachPlan?.period?.end
            ? getCommaSeparatedDate(eachPlan.period.end)
            : DEFAULT_NULL_REPLACEMENT,
        }));
        resolve({ carePlanList, totalCount: response.data.fetchAllCarePlansByConsent.count });
      })
      .catch((err) => reject(err));
  });
}

export function fetchCarePlanById(
  carePlanId,
  grantorId,
  granteeId,
  isForCircle,
  query = FETCH_CARE_PLAN_BY_ID,
) {
  const variables = {
    carePlanId: Number(carePlanId).toString(),
  };
  if (grantorId) {
    variables.grantorId = grantorId;
    variables.initiatorId = granteeId;
  }
  return new Promise((resolve, reject) => {
    client
      .query({
        query,
        variables,
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        let planDetails = null;
        if (response.data?.fetchCarePlanById) {
          planDetails = response.data.fetchCarePlanById;
        }
        planDetails = { ...planDetails, dataSource: findDataSource(planDetails.identifier) };
        const detailsForModal = {
          id: carePlanId,
          title: planDetails.title ? planDetails.title : 'Care plan',
          careTeam: planDetails.careTeam,
          body: {
            problems: planDetails.addresses
              ? planDetails.addresses
                  .filter((address) => address?.display || address?.identifier?.type?.text)
                  .map((address, index) => {
                    const displayObj = JSON.parse(address.display);
                    return {
                      id: index,
                      value: displayObj ? displayObj.code : address.identifier.type.text,
                      status: displayObj && displayObj['problem-status'],
                    };
                  })
              : [],
            goals: planDetails.goal
              ? planDetails.goal
                  .map((goal) => {
                    try {
                      const parsedGoal = JSON.parse(goal.display);
                      return parsedGoal;
                    } catch (err) {
                      return null;
                    }
                  })
                  .filter((parsedGoal) => parsedGoal)
                  .map((parsedGoal, index) => {
                    return {
                      id: index,
                      value: parsedGoal?.description,
                      status: parsedGoal?.status,
                    };
                  })
              : [],
            activities:
              planDetails.activity
                ?.filter(
                  (activity) =>
                    activity?.detail?.code?.text || activity?.detail?.code?.coding?.[0].display,
                )
                .map((activity, index) => ({
                  id: index,
                  value:
                    activity?.detail?.code?.coding?.[0]?.display ?? activity?.detail?.code?.text,
                })) ?? [],
          },
          columns: [
            {
              column_name: SELF_REPORTING_LABELS.STATUS,
              value: planDetails?.status || DEFAULT_NULL_REPLACEMENT,
              show_status_icon: true,
            },
            {
              column_name: SELF_REPORTING_LABELS.RECORDED_ON,
              value:
                (planDetails?.created && getCommaSeparatedDate(planDetails.created)) ||
                DEFAULT_NULL_REPLACEMENT,
            },
            {
              column_name: SELF_REPORTING_LABELS.RECORDED_BY,
              value: (planDetails.author && planDetails.author.display) || DEFAULT_NULL_REPLACEMENT,
            },
            {
              column_name: SELF_REPORTING_LABELS.FACILITY,
              value: planDetails.contributor
                ? planDetails.contributor.map((contributor) => contributor.display).join(', ')
                : DEFAULT_NULL_REPLACEMENT,
            },
            {
              column_name: SELF_REPORTING_LABELS.CATEGORY,
              value:
                planDetails &&
                planDetails.category &&
                planDetails.category.length &&
                carePlanCategory(planDetails),
            },
            {
              column_name: SELF_REPORTING_LABELS.START_DATE,
              value:
                (planDetails?.period?.start && getCommaSeparatedDate(planDetails.period.start)) ||
                DEFAULT_NULL_REPLACEMENT,
            },
            {
              column_name: SELF_REPORTING_LABELS.END_DATE,
              value:
                (planDetails?.period?.end && getCommaSeparatedDate(planDetails.period.end)) ||
                DEFAULT_NULL_REPLACEMENT,
            },
            {
              column_name: SELF_REPORTING_LABELS.INTENT,
              value: planDetails?.intent || DEFAULT_NULL_REPLACEMENT,
            },
            {
              column_name: SELF_REPORTING_LABELS.DESCRIPTION,
              value: planDetails?.description || DEFAULT_NULL_REPLACEMENT,
              width: 'fullWidth',
            },
            {
              column_name: SELF_REPORTING_LABELS.NOTE,
              value: planDetails?.note?.[0]?.text || DEFAULT_NULL_REPLACEMENT,
              width: 'fullWidth',
            },
          ],
          markAsRestricted:
            (planDetails?.meta?.security?.length && extractRestricted(planDetails.meta.security)) ||
            false,
        };

        if (!isForCircle) {
          detailsForModal.columns.unshift({
            column_name: D_SOURCE,
            value: planDetails?.dataSource,
          });
        }
        resolve(detailsForModal);
      })
      .catch((err) => reject(err));
  });
}

export function markAsRestricted(carePlanId, markAsRestricted) {
  const mutation = gql`
    mutation markAsRestrictedCarePlan($carePlanId: String, $markAsRestricted: String) {
      markAsRestrictedCarePlan(carePlanId: $carePlanId, markAsRestricted: $markAsRestricted) {
        id
        status
        title
        created
        intent
        description
        category {
          ...code
        }
        period {
          start
          end
        }
        activity {
          detail {
            code {
              text
              coding {
                display
              }
            }
          }
        }
        goal {
          display
          identifier {
            type {
              text
            }
          }
        }
        author {
          display
        }
        addresses {
          display
          identifier {
            type {
              text
            }
          }
        }
        careTeam {
          reference
          identifier {
            type {
              text
            }
          }
          display
        }
        identifier {
          system
          value
        }
        contributor {
          display
        }
      }
    }
    ${CODE_FRAGMENT}
  `;

  return new Promise((resolve, reject) => {
    client
      .mutate({ mutation, variables: { carePlanId, markAsRestricted } })
      .then((response) => resolve(response))
      .catch((err) => reject(err));
  });
}
