import React, { useState } from 'react';
import { Grid, InputBase } from '@mui/material';
import { withStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import { SEARCH_DEBOUNCE_TIME, SEARCH_HERE } from '../../../utils/constants';

const style = (theme) => ({
  root: {
    backgroundColor: '#ffff',
    boxShow: '0px 0px 0px 0px',
    border: '2px solid #ECF0F1',
  },
  searchInput: {
    opacity: '0.6',
    padding: '0px 8px',
    // top:'-20px',
    fontSize: '1rem',
    border: '3px solid #B7BDBF',
    width: '100%',
    height: '3rem',
    borderRadius: '5px',
    '&.hover': {
      backgroundColor: '#ffff',
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1),
    },
  },
});

const sxStyles={
  inputBase:{ minWidth: {lg: 340, xl: 340}}
};

let searchTimer = undefined;

const Search = (props) => {
  const {
    classes,
    rootContainerStyle,
    searchInput,
    onSearch,
    placeholder,
    value,
    addDebounce = true,
  } = props;

  const [inputValue, setInputValue] = useState(value);

  const onKeyDown = (event) => {
    const {
      target: { value },
      keyCode,
    } = event;
    if (keyCode === 13) {
      clearTimeout(searchTimer);
      onSearch(inputValue);
    }
  };

  const onChange = (e) => {
    const val = e.target.value;
    setInputValue(val);

    if (addDebounce) {
      if (searchTimer) {
        clearTimeout(searchTimer);
      }
      searchTimer = setTimeout(() => {
        onSearch(val);
      }, SEARCH_DEBOUNCE_TIME);
    } else {
      onSearch(val);
    }
  };

  return (
    <Grid sx={sxStyles.inputBase} item className={rootContainerStyle && rootContainerStyle}>
      <InputBase
        placeholder={placeholder || SEARCH_HERE}
        className={[classes.searchInput, searchInput].join(' ')}
        onChange={(e) => onChange(e)}
        onKeyDown={(e) => onKeyDown(e)}
        value={inputValue}
        startAdornment={
          <SearchIcon
          // fontSize="medium"
          />
        }
      />
    </Grid>
  );
};

export default withStyles(style)(Search);
