import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import Button from '../../components/atoms/Button';
import { Typography, Box } from '@mui/material';
// import { SHOP } from '../../utils/constants';
import { useNavigate, useLocation, useParams } from 'react-router';
import { getConsentUserDetails } from '../Medications';
import ShopService from '../../service/ShopService';
import Loader from '../../components/atoms/Loader';
import ShoppingCard from '../../components/organisms/ShoppingCard';
import FirebaseUtils from '../../utils/FirebaseUtils';
import { getAnalyticsEventsForRouteName, renderTextorImage } from '../../utils/helper';
import { ROUTE_NAMES } from '../../constants/routeNames';
import useAnalyticsLogger from '../../hooks/useAnalyticsLogger';
import { useSelector } from 'react-redux';
import { mockTemplates } from '../../features/dynamicRecords/mockTemplate';

const styles = () => ({
  container: {
    backgroundColor: '#F5F5F5',
    minHeight: '100vh',
    padding: '32px',
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    marginTop: 24,
    color: '#1B1B1B',
  },
  subTitle: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    color: '#1B1B1B',
    marginTop: 40,
  },
  billCardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 12,
  },
  card: {
    display: 'flex',
    backgroundColor: '#ffffff',
    borderRadius: 16,
    boxShadow: '0px 2px 6px rgba(0,0,0,0.15)',
    marginBottom: 16,
    marginRight: 16,
    overflow: 'hidden',
  },
  contentContainer: {
    height: 142,
    width: 336,
    display: 'flex',
    alignItems: 'flex-end',
  },
  contentSection: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 142,
    width: 205,
    padding: 16,
    paddingRight: 0,
  },
  cardTitle: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: ' 24px',
    color: '#101828',
  },
  cardContent: {
    fontWeight: 400,
    fontSize: 14,
    color: '#667085',
  },
  image: { width: 32, height: 32 },
  noOfBilldDueText: {
    paddingTop: 24,
    fontWeight: 500,
    fontSize: 14,
    paddingLeft: 30,
    lineHeight: ' 20px',
    color: '#667085',
  },
  btnStyle: {
    border: '1px solid #A0A4A6',
    color: '#036FCB',
    height: 24,
    width: 'fit-content',
    borderRadius: 20,
    fontSize: '14px',
  },
  imageStyle: {
    width: 131,
    height: 96,
  },
});

const Shops = ({ classes, ...props }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { templates } = useSelector((state) => state.templates);
  const { selectedLanguage } = useSelector((state) => state.app);

  const { partner, grantorId, granteeId } = useParams();
  const location = useLocation();
  const { logAnalyticsEvent } = useAnalyticsLogger();
  let consentRouteFrom = '';
  let consentUserName = '';
  if (location.pathname.includes('/circles/individual/bill_pay')) {
    consentRouteFrom = 'circleConsent';
    consentUserName = getConsentUserDetails();
  } else if (location.pathname.includes('/records/individual/bill_pay')) {
    consentRouteFrom = 'serviceConsent';
    consentUserName = getConsentUserDetails();
  }
  const onButtonClick = (url, code = '', placeholder, title) => {
    navigate(url, { state: { code: code, placeholder, title } });
  };

  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.SHOP);

  useEffect(() => {
    const { onFooterItemClick } = props;
    if (onFooterItemClick && consentRouteFrom !== 'circleConsent') {
      onFooterItemClick(1);
    } else {
      onFooterItemClick(2);
    }
  }, []);

  const fetchCategories = () => {
    setIsLoading(true);
    ShopService.fetchAllCategories('shop')
      .then((response) => {
        const sortedItems = response?.items?.sort((a, b) => a.rank - b.rank);
        setCategories(response);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  if (isLoading) {
    return <Loader overlays="true" text="Loading categories..." />;
  }

  const ShopCard = (props) => {
    function handleButtonClick() {
      if (props.enableTracking && props.trackEventName) {
        logAnalyticsEvent(props.trackEventName, {
          [props.trackParamName]: props.code,
        });
      }
      if (props?.link === '/shop/findCare') {
        localStorage.removeItem('organization');
      }
      onButtonClick(props?.link);
    }

    return (
      <div className={classes.contentContainer}>
        <div className={classes.contentSection}>
          <div>
            <Typography className={classes.cardTitle}>{props.heading}</Typography>
            <Typography className={classes.cardContent}>{props.content}</Typography>
          </div>
          <Button className={classes.btnStyle} title={'View'} onClick={handleButtonClick} />
        </div>
        <img className={classes.imageStyle} src={props.icon} />
      </div>
    );
  };

  const SHOP =     
    // templates?.texts?.[selectedLanguage]?.SHOP_SCREEN?.SHOP||
    mockTemplates?.texts?.[selectedLanguage]?.SHOP_SCREEN?.SHOP;

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{categories?.title}</Typography>
      <div className={classes.billCardContainer}>
        {renderTextorImage(
          SHOP?.CARD,
          // templates?.texts?.[selectedLanguage] ||
          mockTemplates?.texts?.[selectedLanguage],
        )?.map((card, index) => (
          <div className={classes.card} key={index}>
            <ShopCard
              enableTracking
              trackEventName={analyticsEvents.CMP_CATEGORY_INITIATED}
              trackParamName={analyticsEvents.CMP_CATEGORY_INITIATED.paramName}
              code={card.code}
              heading={renderTextorImage(
                card?.HEADING,
                // templates?.texts?.[selectedLanguage] ||
                mockTemplates?.texts?.[selectedLanguage],
              )}
              content={renderTextorImage(
                card?.DESCRIPTION,
                // templates?.texts?.[selectedLanguage] ||
                mockTemplates?.texts?.[selectedLanguage],
              )}
              circleColor={renderTextorImage(
                card?.COLOR,
                // templates?.texts?.[selectedLanguage] ||
                mockTemplates?.texts?.[selectedLanguage],
              )}
              // icon={renderTextorImage(card?.IMG_URL)}
              icon={card.IMAGE}
              link={renderTextorImage(
                card?.URL,
                // templates?.texts?.[selectedLanguage] ||
                mockTemplates?.texts?.[selectedLanguage],
              )}
            />
          </div>
        ))}
        {categories?.items?.map((category, index) => (
          <div className={classes.card} key={index}>
            <ShoppingCard
              enableTracking
              trackEventName={analyticsEvents.CMP_CATEGORY_INITIATED}
              trackParamName={analyticsEvents.CMP_CATEGORY_INITIATED.paramName}
              heading={category.title}
              content={category.description}
              icon={category.imageUrl}
              link={'/shop/marketCategory'}
              handleButtonClick={onButtonClick}
              circleColor={category.color ?? '#FFF3DE'}
              code={category.code}
              searchPlaceHolder={category.searchPlaceHolder}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(Shops);
