import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StyledCard = styled('div')(({ theme }) => ({
  borderRadius: '12px',
  width: '256px',
  paddingRight: '16px',
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  boxShadow: '0px 2px 6px 0px rgb(0 0 0 / 15%)',
  backgroundColor: '#fff',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    height: 'auto',
  },
}));

const LeftContent = styled('div')(({ theme }) => (props) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '72px',
  padding: '20px 16px',
  boxSizing: 'border-box',
  backgroundColor: props.bgColor,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const Icon = styled('img')(({ theme }) => ({
  height: '40px',
  width: '40px',
  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    height: '24px',
    width: '24px',
  },
}));

const Arrow = styled(KeyboardArrowRight)({
  color: '#828282',
});

const Caption = styled('div')(({ theme }) => ({
  letterSpacing: '0px',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  // marginLeft: '16px',
  letterSpacing: '0px',
  color: '#000000',
  width: '128px',

  [theme.breakpoints.down('sm')]: {
    marginTop: 0,
    padding: 0,
  },
}));

const RecentlyVisitedCard = ({
  icon,
  link,
  caption,
  onServiceClick,
  serviceType,
  bgColor,
  analyticsEvents,
  logAnalyticsEvent,
}) => {
  const navigate = useNavigate();

  const handleLink = () => {
    if (link === '/scanbill' && isIOS()) {
      const inputEl = document.getElementById('ios-input-camera');

      if (inputEl) {
        inputEl.click();
      }
    } else if (link.includes('https')) {
      window.open(link);
    } else if (onServiceClick) {
      onServiceClick(link, serviceType);
    } else {
      logAnalyticsEvent(analyticsEvents.RECENTLY_VISITED_SERVICES, {
        [analyticsEvents.RECENTLY_VISITED_SERVICES.paramName]: caption,
      });
      navigate(link);
    }
  };

  return (
    <StyledCard onClick={handleLink}>
      <LeftContent bgColor={bgColor}>
        <Icon src={icon} />
      </LeftContent>
      <Caption>{caption}</Caption>
      <Arrow />
    </StyledCard>
  );
};

export default RecentlyVisitedCard;
