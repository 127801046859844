export const ROUTE_NAMES = {
  SHOP: '/shop',
  MARKET_CATEGORY: '/shop/:category',
  COMPLETE_REGISTRATION: '/complete-registration-signup',
  LOGIN: '/login',
  HOME: '/home',
  UTILITIES: '/utilities',
  REFER: '/utilities/refer',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/newforgotpassword',
  CIRCLES: '/circles',
  MANAGE_PAYMENT: '/payment/manage',
  ADD_CARD: '/addCard',
  ADD_BANK_ACCOUNT: '/addBankAccount',
  RECORDS: '/records',
  ACCOUNT_DETAILS: '/utilities/accountDetails',
  PHONE_VERIFICATION: '/phoneverification',
  LANDING_PAGE_CAROUSEL: '/',
  CONDITIONS: 'conditions',
  PROCEDURES: 'procedures',
  LAB_RESULTS: 'labs',
  MEDICATIONS: 'medications',
  ALLERGIES: 'allergies',
  IMMUNIZATIONS: 'immunizations',
  VITALS: 'vitals',
  VISITS: 'visits',
  SOCIAL_HISTORY: 'smoking_status',
  FAMILY_HISTORY: 'family_member_history',
  COVERAGE: 'coverage',
  CLAIMS: 'claims',
  BILL: 'bills',
  CARE_PLANS: 'care_plan',
  CARE_TEAMS: 'care_team',
  CONSENTS: '/records/consents/',
  CONSENT_GIVEN: 'consentGiven',
  CONSENT_RECEIVE: 'consentReceive',
  CLINICAL_VITALS: 'clinical_vitals',
  BILLS: 'bill_pay',
  ASSESSMENTS: 'assessments_dashboard',
  DOCUMENTS: 'documents',
  PROFILE_NAME: 'profileName',
  APPOINTMENTS: 'appointments',
  SUPPORT_HISTORY: 'support_history',
  PRIOR_AUTHORIZATION: 'prior_authorization',

};
