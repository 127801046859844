import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import colors from '../../../../utils/colors';
import { MAIN_CONTAINER, CONGRATS_POPUP } from '../../constants';
import { SnackbarProvider } from 'notistack';

import useClickFooter from '../../../../hooks/useClickFooter';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  PreviewFooter,
  MPButton,
  Questionnaire,
  WelcomeScreen,
  AssessmentCompletionModal,
  ViewAssessment,
} from '@premkumarmph/assessment-library';
import { AssessmentServices, updateAssessmentStatus } from '../../api';
import { DEFAULT_NULL_REPLACEMENT, SNACKBAR_TYPE } from '../../../../utils/constants';
import SnackbarToast from '../../../../components/organisms/SnackbarToast';
import useHandleValidationForAssessments from './useHandleValidationForAssessments';
import { useRef } from 'react';
import formSubmitted from '../../../../assets/images/formSubmited.gif';
import Loader from '../../../../components/atoms/Loader';
import { Typography } from '@mui/material';
import GenericServices from '../../../dynamicRecords/Services/GenericServices';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  assessmentLandingPage: {},
  mphWelcomeScreenHeader: {
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: theme.spacing(3),
    lineHeight: theme.spacing(4.5),
    color: colors.TITLE_GRAY,
  },
  rightbar: {
    width: '100%',
    height: '100%',
  },
  topbar: {
    height: '128px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  typo: {
    padding: 24,
    fontFamily: 'Inter',
    fontSize: 16,
    lineHeight: '24px',
    color: colors.TITLE_GRAY,
    fontWeight: 700,
  },
  questionsContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    left: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topButtonContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  btnStyle: {
    border: '1px solid #E4E7EC',
    padding: '10px 32px',
    borderRadius: theme.spacing(5),
    gap: theme.spacing(1),
    top: theme.spacing(3),
    right: theme.spacing(3),
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: theme.spacing(2),
    color: colors.BLUE,
  },
  questionNumber: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(2),
    color: colors.GRAY_SECONDARY,
    marginBottom: theme.spacing(2),
    fontStyle: 'normal',
    minWidth: 650,
  },
  questions: {
    minWidth: 650,
  },
}));

const styles = {
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
    color: colors.TITLE_GRAY,
    marginBottom: 16,
    textAlign: 'center',
  },
  orgTitle: {
    marginTop: 8,
    fontWeight: 600,
    lineHeight: '40px',
    fontFamily: 'Inter',
    color: '#101828',
    fontSize: 28,
    marginBottom: 55,
  },
  subHeader: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
    color: colors.TITLE_GRAY,
    textAlign: 'center',
  },
  startBtn: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 24,
    borderRadius: 40,
    padding: '10px 32px',
    backgroundColor: '#036fcb',
    height: 48,
  },
  button: {
    border: '1px solid #E4E7EC',
  },
  logo: {
    width: 255,
    height: 62,
    marginBottom: 0,
  },
  btnConainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backbutton: {
    border: '1px solid #E4E7EC',
  },
  radio: {
    label: {
      '& .MuiFormControlLabel-label': {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#344054',
        letterSpacing: 0,
      },
    },
  },
  checkbox: {
    label: {
      '& .MuiFormControlLabel-label': {
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
        color: '#344054',
        letterSpacing: 0,
      },
    },
  },
  longtext: {
    '& .MuiInputBase-root': {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 0,
      color: colors.GRAY_SECONDARY,
    },
    '& ::placeholder': {
      letterSpacing: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E4E7EC',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.GRAY_SECONDARY}`,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.BLUE}`,
    },
  },
  shorttext: {
    '& .MuiInputBase-root': {
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: 0,
      color: colors.GRAY_SECONDARY,
    },
    '& ::placeholder': {
      letterSpacing: '0px',
      fontFamily: 'Inter',
      fontSize: 14,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E4E7EC',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.GRAY_SECONDARY}`,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.BLUE}`,
    },
  },
  questionTitle: {
    color: '#101828',
  },
  selectbox: {
    menuItem: {
      paddingLeft: '15px',
      fontFamily: 'Inter',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '20px',
    },
    main: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #E4E7EC',
      },
      '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.GRAY_SECONDARY}`,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${colors.BLUE}`,
      },
    },
  },
};

const AssessmentContainer = ({ onFooterItemClick, onSetNormalScreen, onSetFullScreen }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const assessmentInstance = location?.state?.assessmentInstanceId;
  useClickFooter(onFooterItemClick);
  const { instantId, grantorId } = useParams();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState();
  const [isAssessmentLandingPage, setIsAssessmentLandingPage] = useState(true);
  const [currentQuestion, setCurrentQuestion] = useState([]);
  const offset = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalAnsweredQuestions, setTotalAnsweredQuestions] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [questionsList, setQuestionsList] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [pageableResponse, setPageableResponse] = useState({});
  const [questionOrder, setQuestionOrder] = useState([]);
  const [groupHeader, setGroupHeader] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [questionIndex, setQuestionIndex] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const assessmentInstanceId = Number(instantId || assessmentInstance);
  const [answerIdAndValue, setAnswerIdAndValue] = useState({
    questionId: '',
    groupId: null,
    answers: [],
    groupQuestions: [],
  });
  const ZERO = 0;
  const { disabledButtonList } = useHandleValidationForAssessments({
    questionsList,
    answerIdAndValue,
    currentQuestionIndex: ZERO,
  });

  const qtnId = questionsList?.assessmentDefinition?.[0]?.id;
  const type = questionsList?.assessmentDefinition?.[0]?.type;

  const [openViewDetails, setOpenViewDetails] = useState(false);
  const [viewDetails, setViewDetails] = useState({});

  const handleViewDetailsInAssessment = async (id = assessmentInstanceId) => {
    try {
      setIsLoading(true);
      const { record } = await GenericServices.fetchRecordById({
        resourceType: 'assessments',
        resourceId: id?.toString() ?? '',
        grantorId: grantorId?.toString() ?? '',
      });
      const resourceObj = JSON.parse(record);

      const handleAnswers = (subQ) => {
        return subQ?.answers
          ?.filter((subAnswer) => subAnswer?.answered && subAnswer?.question)
          .map((subAnswered) => subAnswered);
      };

      const handleAnsweredId = (question) => {
        return question?.answers
          ?.filter((answer) => answer.answered)
          .map((answered) => answered?.id);
      };

      const handleChoices = (question) => {
        return question?.answers?.map((answer) => ({
          ...answer,
          value: answer?.text || DEFAULT_NULL_REPLACEMENT,
        }));
      };

      const handleAnswerValue = (question) => {
        if (
          question?.answerType?.toLowerCase() === 'shorttext' ||
          question?.answerType?.toLowerCase() === 'longtext'
        ) {
          return question?.answers?.[0]?.answered === true
            ? question?.answers?.[0]?.answeredValue
            : DEFAULT_NULL_REPLACEMENT;
        }
        return handleAnsweredId(question)?.length === 0 ? DEFAULT_NULL_REPLACEMENT : null;
      };

      const handleNRQ = (question) => {
        const answeredIds = handleAnswers(question);
        const nrqQuestions = answeredIds?.map((nrqQuestion) => ({
          selectedChoice: nrqQuestion?.id,
          question: {
            ...nrqQuestion?.question,
            answerId: handleAnsweredId(nrqQuestion?.question),
            choices: handleChoices(nrqQuestion?.question),
            answerValue: handleAnswerValue(nrqQuestion?.question),
          },
        }));
        return nrqQuestions;
      };

      const displayAssessment = {
        url: resourceObj?.assessmentResponse?.assessmentMeta?.orgImageURL,
        status: resourceObj?.assessmentResponse?.assessmentMeta?.status,
        statusColorCode: resourceObj?.assessmentDisplay?.statusColorCode,
        completedDate: resourceObj?.assessmentDisplay?.completedDate,
        name: resourceObj?.assessmentResponse?.assessmentMeta?.assessmentName,
        assessmentDefinition:
          resourceObj?.assessmentResponse?.assessmentResponseBody?.assessmentQuestionnaires.map(
            (question) => ({
              ...question,
              subQuestion: question?.questions?.map((subQ) => ({
                ...subQ,
                answerId: handleAnsweredId(subQ),
                choices: handleChoices(subQ),
                answerValue: handleAnswerValue(subQ),
                settings: {
                  nextReferenceQuestion: handleNRQ(subQ),
                },
              })),
              answerId: handleAnsweredId(question),
              choices: handleChoices(question),
              answerValue: handleAnswerValue(question),
              settings: {
                nextReferenceQuestion: handleNRQ(question),
              },
            }),
          ),
      };
      setViewDetails(displayAssessment);
      setIsOpen(false);
      setOpenViewDetails(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllAnsweredNRQ = (
    questionObjArr,
    parentID,
    tempObj,
    tempMap,
    tempIndex,
    answeredId = null,
  ) => {
    if (questionObjArr?.[0]) {
      if (questionObjArr?.[0]?.question?.type === 'group') {
        questionObjArr?.[0]?.question?.subQuestion?.map((item, key) => {
          tempObj[parentID + '_' + item?.id] = item;
          tempIndex[parentID + '_' + item?.id] = tempIndex[parentID]
            ? `${tempIndex[parentID]}.${key + 1}`
            : key + 1;
          if (tempMap) {
            tempMap.set(parentID + '_' + item?.id, item);
          }
          return null;
        });
      } else {
        questionObjArr?.map((item, key) => {
          if (answeredId === item?.selectedChoice) {
            tempObj[parentID + '_' + item?.question?.id] = item?.question;
            tempIndex[parentID + '_' + item?.question?.id] = tempIndex[parentID]
              ? `${tempIndex[parentID]}.${key + 1}`
              : key + 1;
            if (tempMap) {
              tempMap.set(parentID + '_' + item?.question?.id, item?.question);
            }
          }
          return null;
        });
      }
    }
  };

  const groupQuestion = () => {
    const tempObj = {};
    const tempKeysMap = new Map();
    const tempIndex = {};
    questionsList?.assessmentDefinition[ZERO].subQuestion?.map((item, index) => {
      tempObj[item.id] = item;
      tempIndex[item.id] = index + 1;
      tempKeysMap.set(item?.id?.toString(), item);
      if (item?.answerId?.length && item?.settings?.nextReferenceQuestion?.length) {
        item?.answerId?.map((ansId) => {
          if (ansId === item?.settings?.nextReferenceQuestion?.[0]?.selectedChoice) {
            getAllAnsweredNRQ(
              item?.settings?.nextReferenceQuestion,
              item?.id,
              tempObj,
              tempKeysMap,
              tempIndex,
              ansId,
            );
            return null;
          }
          return null;
        });
      }
      return null;
    });

    setQuestionList(tempObj);
    const group_header = {
      id: questionsList?.assessmentDefinition[ZERO]?.subQuestion?.[0]?.id,
      title:
        questionsList?.assessmentDefinition[ZERO]?.title ??
        questionsList?.assessmentDefinition[ZERO]?.questionDescription,
    };
    if (!questionsList?.assessmentDefinition[ZERO]?.subQuestion?.length) {
      group_header['empty_one'] = questionsList?.assessmentDefinition[ZERO]?.title;
    }
    setGroupHeader(group_header);
    setQuestionOrder([...tempKeysMap.keys()]);
    setQuestionIndex({ ...tempIndex });
  };

  const allOtherQuestions = () => {
    const tempObj = {
      [questionsList?.assessmentDefinition[ZERO]?.id]: questionsList?.assessmentDefinition[ZERO],
    };
    const tempHeader = {};
    const tempIndex = {
      [questionsList?.assessmentDefinition[ZERO]?.id]: 1,
    };
    questionsList?.assessmentDefinition[ZERO]?.answerId?.map((answerdId) => {
      getAllAnsweredNRQ(
        questionsList?.assessmentDefinition[ZERO]?.settings?.nextReferenceQuestion,
        questionsList?.assessmentDefinition[ZERO]?.id,
        tempObj,
        null,
        tempIndex,
        answerdId,
      );
      return null;
    });

    setQuestionList(tempObj);
    if (
      questionsList?.assessmentDefinition[ZERO]?.settings?.nextReferenceQuestion?.[ZERO]?.question
        ?.type === 'group'
    ) {
      tempHeader.id =
        questionsList?.assessmentDefinition[ZERO]?.id +
        '_' +
        questionsList?.assessmentDefinition[ZERO]?.settings?.nextReferenceQuestion?.[ZERO]?.question
          ?.subQuestion?.[ZERO]?.id;
      tempHeader.title =
        questionsList?.assessmentDefinition[ZERO]?.settings?.nextReferenceQuestion?.[
          ZERO
        ]?.question?.title;
    }
    setQuestionOrder([...Object.keys(tempObj)]);
    setGroupHeader(tempHeader);
    setQuestionIndex({ ...tempIndex });
  };

  useEffect(() => {
    if (questionsList?.assessmentDefinition?.[ZERO]) {
      questionsList?.assessmentDefinition?.[ZERO]?.subQuestion
        ? groupQuestion()
        : allOtherQuestions();
    }
  }, [isAssessmentLandingPage, questionsList, currentQuestionIndex]);

  const handleStartAssessment = async () => {
    const status = MAIN_CONTAINER?.STATUS?.IN_PROGRESS?.toUpperCase();
    const res = await updateAssessmentStatus(assessmentInstanceId, status);
    if (res?.statusMessage === `Status updated successfully to ${status}`) {
      setCurrentQuestionIndex(0);
      updateAssessments('', 0);
      setIsAssessmentLandingPage(false);
    }
  };

  const handleSaveAndExit = async () => {
    updateAssessments('finish', currentQuestionIndex);
    navigate(-1);
  };

  const handleBack = async () => {
    updateAssessments(
      'previous',
      currentQuestionIndex >= 1 ? currentQuestionIndex - 1 : currentQuestionIndex,
    );
  };

  const handleNext = async () => {
    updateAssessments(
      'next',
      currentQuestionIndex <= totalQuestions - 1 ? currentQuestionIndex + 1 : currentQuestionIndex,
    );
  };
  const handleSubmit = async () => {
    updateAssessments('finish', currentQuestionIndex + 1);
    setIsOpen(true);
  };

  useEffect(() => {
    onSetFullScreen();

    localStorage.removeItem('assessmentSubmissionId');

    if (metaData?.status?.toLowerCase() === 'pending') {
      setIsAssessmentLandingPage(true);
    } else if (metaData?.status?.toLowerCase() === 'in-progress') {
      setIsAssessmentLandingPage(false);
    } else {
      setIsAssessmentLandingPage(true);
    }

    return () => onSetNormalScreen();
  }, [metaData]);

  const handleExit = () => {
    navigate(-1);
  };

  const markAnswer = (oId, qId, type) => {
    Object.keys(questionList)?.map((item) => {
      if (questionList?.[item]?.id === qId) {
        switch (type) {
          case 'singleSelect':
            questionList[item].answerId = [];
            questionList[item].answerId.push(+oId);
            break;

          case 'checkbox':
            if (questionList?.[item]?.answerId?.find((el) => el === +oId)) {
              questionList?.[item]?.answerId?.splice(
                questionList?.[item]?.answerId?.findIndex((el) => el === +oId),
                1,
              );
            } else {
              if (!questionList?.[item]?.answerId) {
                questionList[item].answerId = [];
              }
              questionList?.[item]?.answerId?.push(+oId);
            }
            break;

          case 'longtext':
            if (oId?.length <= 500) {
              questionList[item].answerValue = oId;
            }
            break;

          case 'shorttext':
            if (oId?.length <= 100) {
              questionList[item].answerValue = oId;
            }
            break;

          case 'selectbox':
            if (oId) {
              questionList[item].answerId = [oId];
            }
            break;
          default:
            break;
        }
      }
      return null;
    });

    setQuestionList({ ...questionList });
  };

  const findingIndex = (tempOrder, qId) => {
    return tempOrder.findIndex((el) => el === qId?.toString());
  };

  const splitIndex = (item) => {
    return item?.split('_')?.length > 1;
  };

  const checkNRQ = (oId, qId, type) => {
    let checkBoxFlagId;
    let newCheckBoxId;
    let checkBoxDeleteFlag = false;
    let uniqueId = null;
    Object.keys(questionList)?.map((item) => {
      uniqueId = item?.split('_');

      const uniqueId_idx =
        uniqueId?.findIndex((el) => el === qId?.toString()) >= 0
          ? uniqueId?.findIndex((el) => el === qId?.toString())
          : Infinity;

      const flag = uniqueId_idx < uniqueId?.length - 1 ?? false;

      if (!groupHeader?.id && groupHeader?.title && groupHeader?.isAlone) {
        setGroupHeader({});
      }

      if (flag) {
        if (questionList[uniqueId.join('_')].answerId) {
          questionList[uniqueId.join('_')].answerId = [];
        }
        if (questionList[uniqueId.join('_')].answerValue) {
          questionList[uniqueId.join('_')].answerValue = null;
        }
        if (type === 'checkbox') {
          newCheckBoxId = questionList?.[uniqueId?.[0]]?.settings?.nextReferenceQuestion?.filter(
            (item) => item?.selectedChoice?.toString() === oId?.toString(),
          )?.[0]?.question?.id;
          checkBoxFlagId = uniqueId?.[1];
        }
        if (type !== 'checkbox' || newCheckBoxId?.toString() === checkBoxFlagId?.toString()) {
          delete questionList[uniqueId.join('_')];
          delete questionIndex[uniqueId.join('_')];
          const index = questionOrder.findIndex((el) => el === uniqueId.join('_'));
          if (type === 'checkbox') {
            Object.keys(questionIndex)?.map((item, index) => {
              if (item?.split('_')?.length > 1) {
                if (item?.split('_')?.[0] === uniqueId?.[0]) {
                  questionIndex[item] = `${questionIndex?.[qId]}.${index}`;
                }
              }
              return null;
            });
          }
          checkBoxDeleteFlag = true;
          setQuestionOrder([...questionOrder.toSpliced(index, 1)]);
          setQuestionList({ ...questionList });
          setQuestionIndex({ ...questionIndex });
        }
      }
      return null;
    });

    markAnswer(oId, qId, type);

    if (type !== 'checkbox' || !checkBoxDeleteFlag) {
      const newObj = {};
      const tempIndex = {};
      const temp = questionList?.[qId]?.settings?.nextReferenceQuestion?.filter(
        (quest) => quest?.selectedChoice === +oId,
      );

      if (temp?.length > 0) {
        const tempOrder = questionOrder;
        temp?.map((item, key) => {
          if (item?.question?.type !== 'group') {
            newObj[qId + '_' + item?.question?.id] = item?.question;

            tempIndex[qId + '_' + item?.question?.id] = questionIndex[qId]
              ? `${questionIndex[qId]}.${key +
              1 +
              Object.keys(questionIndex)?.filter((item) => item?.split('_')?.length > 1)?.length
              }`
              : key + 1;

            tempOrder.splice(
              findingIndex(tempOrder, qId) +
              1 +
              key +
              Object.keys(questionIndex)?.filter((item) => item?.split('_')?.length > 1)?.length,
              0,
              qId + '_' + item?.question?.id,
            );
          } else {
            const group_header = {
              id: item?.question?.subQuestion?.[0]?.id,
              title: item?.question?.title,
            };

            if (!item?.question?.subQuestion?.[0]?.id) {
              group_header['isAlone'] = questionList?.[qId]?.id;
            }

            setGroupHeader(group_header);

            item?.question?.subQuestion?.map((subItems, subKey) => {
              newObj[qId + '_' + subItems?.id] = subItems;
              tempIndex[qId + '_' + subItems?.id] = questionIndex[qId]
                ? `${questionIndex[qId]}.${key + 1 + Object.keys(questionIndex)?.filter(splitIndex(item))?.length
                }`
                : subKey + 1;
              tempOrder.splice(
                findingIndex(tempOrder, qId) +
                1 +
                subKey +
                Object.keys(questionIndex)?.filter(splitIndex(item))?.length,
                0,
                qId + '_' + subItems?.id,
              );
              return null;
            });
          }
          return null;
        });

        setQuestionList({
          ...questionList,
          ...newObj,
        });

        setQuestionIndex({ ...questionIndex, ...tempIndex });

        setQuestionOrder([...new Set(tempOrder)]);
      }
    }
  };

  const answerIDs = (structure) => {
    if (
      structure?.answerType?.toLowerCase() === 'shorttext' ||
      structure?.answerType?.toLowerCase() === 'longtext'
    ) {
      return structure?.answers?.[0]?.id ?? null;
    } else {
      return structure?.answerId;
    }
  };

  const formattingChoices = (question) => {
    return question?.answers?.map((choice, index) => {
      return {
        ...choice,
        id: choice?.id,
        value: choice?.text,
      };
    });
  };

  const formattingAnswerIdOfChoices = (question) => {
    return question?.answers
      ?.map((choice, index) => (choice?.answered ? choice?.id : null))
      ?.filter((id) => id !== null);
  };
  const formattingAnswerOfChoices = (question) => {
    return question?.answers
      ?.map((choice, index) => (choice?.answered ? choice?.answeredValue : null))
      ?.filter((text) => text !== null)?.[0];
  };

  const formattingNRQ = (nrqQuestion, id = '') => {
    const val = nrqQuestion?.answers
      ?.map((choice, index) => {
        if (choice?.question) {
          const question = choice?.question;
          return {
            selectedChoice: choice?.id,
            question: {
              ...question,
              id: question?.id,
              isNRQ: true,
              selectedChoiceId: choice?.id,
              type: question?.type,
              title: question?.title,
              questionType: {
                type: question?.answerType,
              },
              choices: formattingChoices(question),
              actions: null,
              answerId: formattingAnswerIdOfChoices(question),
              answerValue: formattingAnswerOfChoices(question),
              mandatory: question?.mandatory,
              editIds: [],
              deleteIds: [],
              minRiskScore: null,
              maxRiskScore: null,
            },
          };
        } else {
          return null;
        }
      })
      ?.filter((q) => q !== null);
    return val;
  };

  const formattingQuestion = (question) => {
    return {
      ...question,
      id: question?.id,
      type: question?.type,
      title: question?.title,
      questionType: {
        type: question?.answerType,
      },
      choices: formattingChoices(question),
      settings: {
        required: question?.mandatory,
        nextReferenceQuestion: formattingNRQ(question),
      },
      actions: question?.actions,
      answerId: formattingAnswerIdOfChoices(question),
      answerValue: formattingAnswerOfChoices(question),
      editIds: null,
      deleteIds: null,
      mandatory: question?.mandatory,
      minRiskScore: null,
      maxRiskScore: null,
      placeholder: 'Enter here',
    };
  };

  const formattingGroupQuestions = (question, type) => {
    const questions = question?.questions?.map((question, index) => {
      return formattingQuestion(question);
    });
    const groupQuestion = {
      ...questions,
      id: question?.id,
      type: type,
      subQuestion: questions,
      title: question?.title,
      choices: formattingChoices(question),
      settings: {
        required: question?.mandatory,
        nextReferenceQuestion: formattingNRQ(question),
      },
      actions: question?.actions,
      answerId: formattingAnswerIdOfChoices(question),
      answerValue: formattingAnswerOfChoices(question),
      editIds: null,
      deleteIds: null,
      mandatory: question?.mandatory,
      minRiskScore: null,
      maxRiskScore: null,
    };
    return groupQuestion;
  };

  const formatData = (questions) => {
    return questions?.map((question, index) => {
      if (question?.type !== 'group') {
        return formattingQuestion(question);
      } else {
        return formattingGroupQuestions(question, question?.type);
      }
    });
  };
  const selectedQuestion = questionsList?.assessmentDefinition?.[0];

  const standAloneQuestionsPayloadFun = (structure, value) => {
    let answers = [];
    if (
      structure?.answerType?.toLowerCase() === 'shorttext' ||
      structure?.answerType?.toLowerCase() === 'longtext'
    ) {
      answers = [{ answerId: null, answerValue: value, nextReference: {} }];
    } else {
      const answerIdArray = answerIDs(structure);
      answers = answerIdArray?.map((item) => {
        return {
          answerId: item,
          answerValue: '',
          nextReference: {},
        };
      });
    }
    return answers;
  };

  const standAloneQuestionsPayload = (structure, value) => {
    const answers = standAloneQuestionsPayloadFun(structure, value);
    setAnswerIdAndValue({
      questionId: structure?.id,
      answers: answers,
      groupQuestions: [],
    });
  };

  const nrqOfStandAloneQuestionPayloadFun = (
    structure,
    value,
    selectedQuestion,
    answerIdAndValue,
  ) => {
    let finalRes;
    if (
      structure?.answerType?.toLowerCase() === 'shorttext' ||
      structure?.answerType?.toLowerCase() === 'longtext'
      ) {
      const obj =
       value?.length > 0 
       ? {
        questionId: structure?.id,
        answers: [{ answerId: null, answerValue: value, nextReference: {} }],
        groupId: '',
        groupQuestions: [],
      }
        : {};
      finalRes = {
        ...answerIdAndValue,
        questionId: selectedQuestion?.id,
        answers: [
          {
            ...answerIdAndValue?.answers?.[0],
            answerId: selectedQuestion?.answerId?.[0],
            nextReference: obj,
          },
        ],
      };
    } else {
      const answerIdArray = answerIDs(structure);
      const answers = answerIdArray?.map((item) => {
        return {
          answerId: item,
          answerValue: '',
          nextReference: {},
        };
      });
      const obj =
        structure?.answerId?.length > 0
          ? {
            questionId: structure?.id,
            answers: answers,
            groupQuestions: [],
          }
          : {};
      finalRes = {
        ...answerIdAndValue,
        questionId: selectedQuestion?.id,
        answers: [
          {
            ...answerIdAndValue?.answers?.[0],
            answerId: selectedQuestion?.answerId?.[0],
            nextReference: obj,
          },
        ],
      };
    }
    return finalRes;
  };

  const nrqOfStandAloneQuestionPayload = (structure, value, selectedQuestion, answerIdAndValue) => {
    const finalRes = nrqOfStandAloneQuestionPayloadFun(
      structure,
      value,
      selectedQuestion,
      answerIdAndValue,
    );
    setAnswerIdAndValue(finalRes);
  };

  const groupQuestionsPayloadFun = (structure, answerIdAndValue = answerIdAndValue) => {
    const answerIds = structure?.answerId;
    let answers = answerIds?.map((id) => {
      return {
        answerId: id,
        answerValue: '',
        nextReference: {},
      };
    });
    if (
      structure?.questionType?.type?.toLowerCase() === 'shorttext' ||
      structure?.questionType?.type?.toLowerCase() === 'longtext'
    ) {
      answers = [
        {
          answerId: null,
          answerValue: structure?.answerValue,
          nextReference: {},
        },
      ];
    }
    const currentQuestionObjForGroupQuestions = {
      questionId: structure?.id,
      answers: answers,
      groupQuestions: [],
      groupId: '',
    };
    let updatedValue = [...answerIdAndValue?.groupQuestions];
    let isQtnAnswered = false;
    if (updatedValue?.length > 0) {
      const listOfQuestions = updatedValue?.map((item, idx) => {
        if (item?.questionId === currentQuestionObjForGroupQuestions?.questionId) {
          isQtnAnswered = true;
          return currentQuestionObjForGroupQuestions;
        }
        return item;
      });
      if (isQtnAnswered) {
        updatedValue = listOfQuestions;
      } else {
        updatedValue = [...listOfQuestions, currentQuestionObjForGroupQuestions];
      }
    } else {
      updatedValue = [currentQuestionObjForGroupQuestions];
    }
    return updatedValue;
  };

  const groupQuestionsPayload = (structure) => {
    const updatedValue = groupQuestionsPayloadFun(structure, answerIdAndValue);
    setAnswerIdAndValue({
      questionId: '',
      answers: [],
      groupId: questionsList?.assessmentDefinition?.[0]?.id ?? '',
      groupQuestions: updatedValue,
    });
  };

  const findChoiceQuestion = (answers, structure, value) => {
    if (
      structure?.answerType?.toLowerCase() === 'shorttext' ||
      structure?.answerType?.toLowerCase() === 'longtext'
    ) {
      return answers?.map((answer) => {
        if (answer.answerId === structure.selectedChoiceId) {
          const obj =
            value?.length > 0
              ? {
                questionId: structure?.id,
                answers: [{ answerId: null, answerValue: value, nextReference: {} }],
                groupId: '',
                groupQuestions: [],
              }
              : {};
          return { ...answer, nextReference: obj };
        } else {
          return answer;
        }
      });
    } else {
      return answers?.map((answer) => {
        if (answer.answerId === structure.selectedChoiceId) {
          const answerIdArray = answerIDs(structure);
          const answers = answerIdArray?.map((item) => {
            return {
              answerId: item,
              answerValue: '',
              nextReference: {},
            };
          });
          const obj =
            structure?.answerId?.length > 0
              ? {
                questionId: structure?.id,
                answers: answers,
                groupId: '',
                groupQuestions: [],
              }
              : {};
          return { ...answer, nextReference: obj };
        } else {
          return answer;
        }
      });
    }
  };

  const findParentQuestion = (question, structure) => {
    const q = question?.answerId?.map((id) => {
      if (id === structure?.selectedChoiceId) {
        return question;
      } else {
        return null;
      }
    });
    return q?.length ? q?.[0] : null;
  };

  const findSelectedId = (answerIds, structure) => {
    const isFound = answerIds?.find((answer) => answer?.answerId === structure?.selectedChoiceId);
    return isFound?.answerId ? true : false;
  };

  const nrqOfGroupQuestionsPayloadFun = (
    structure,
    value,
    question = {},
    answerIdAndValue = answerIdAndValue,
    firstTime = false,
  ) => {
    let parentQuestionFound = false;
    let updatedValue = answerIdAndValue?.groupQuestions?.map((parentQuestion) => {
      const isFound = findSelectedId(parentQuestion?.answers, structure);
      if (isFound) {
        const val = findChoiceQuestion(parentQuestion?.answers, structure, value);
        parentQuestionFound = true;
        return { ...parentQuestion, answers: val };
      }
      return parentQuestion;
    });

    if (!parentQuestionFound && selectedQuestion && !firstTime) {
      const parentQuestion = selectedQuestion?.subQuestion
        ?.map((question) => {
          const q = findParentQuestion(question, structure);
          return q;
        })
        ?.filter((item) => item !== null)?.[0];
      const parentQuestionPayload = {
        questionId: parentQuestion?.id,
        answers: [
          {
            answerId: parentQuestion?.answerId?.[0],
            answerValue: parentQuestion?.answerValue,
            nextReference: {},
          },
        ],
      };
      const nrqUpdatePayload = nrqOfStandAloneQuestionPayloadFun(
        structure,
        value,
        parentQuestion,
        parentQuestionPayload,
      );
      updatedValue = [...answerIdAndValue?.groupQuestions, nrqUpdatePayload];
    } else if (!parentQuestionFound) {
      const nrqUpdatePayload = nrqOfStandAloneQuestionPayloadFun(
        structure,
        value,
        question,
        answerIdAndValue,
      );
      updatedValue = [nrqUpdatePayload];
    }
    return updatedValue;
  };

  const nrqOfGroupQuestionsPayload = (structure, value) => {
    const updatedValue = nrqOfGroupQuestionsPayloadFun(structure, value, '', answerIdAndValue);
    setAnswerIdAndValue({
      questionId: '',
      answers: [],
      groupId: questionsList?.assessmentDefinition?.[0]?.id ?? '',
      groupQuestions: updatedValue,
    });
  };

  const getValue = (structure, value, type) => {
    if (selectedQuestion?.type?.toLowerCase() === 'standalone') {
      structure?.isNRQ
        ? nrqOfStandAloneQuestionPayload(structure, value, selectedQuestion, answerIdAndValue)
        : standAloneQuestionsPayload(structure, value);
    } else {
      structure?.isNRQ
        ? nrqOfGroupQuestionsPayload(structure, value)
        : groupQuestionsPayload(structure);
    }
  };

  const nrqQuestion = (question) => {
    let answers = [];
    answers = question?.answers?.map((item) => {
      if (item?.answered === true) {
        return {
          answerId: item?.answeredValue? null: item?.id,
          answerValue: item?.answeredValue ?? '',
          nextReference: {},
        };
      } else {
        return null;
      }
    })?.filter((item) => item !== null);
    const obj = {
      questionId: question?.id,
      answers: answers,
      groupQuestions: [],
    };
    return obj;
  };

  const initialStandAloneQuestion = (structure) => {
    let answers = [];
    answers = structure?.answers?.map((item) => {
      if (item?.answered === true) {
        const nrqReference = item?.question ? nrqQuestion(item?.question) : {};
        return {
          answerId: item?.answeredValue? null: item?.id,
          answerValue: item?.answeredValue ?? '',
          nextReference: nrqReference ?? {},
        };
      } else {
        return null;
      }
    })?.filter((item) => item !== null);
    return answers;
  };

  const initialPayloadToStandAloneQuestion = (question) => {
    const answers = initialStandAloneQuestion(question, '');
    const obj = {
      questionId: question?.id,
      answers: answers,
      groupQuestions: [],
    };
    setAnswerIdAndValue(obj);
  };

  const initialPayload = (groupquestion) => {
    if (groupquestion?.type?.toLowerCase() === 'standalone') {
      initialPayloadToStandAloneQuestion(groupquestion);
    } else {
      const payload = groupquestion?.subQuestion
        ?.map((question) => {
          const questionPayload = groupQuestionsPayloadFun(question, { groupQuestions: [] });
          if (
            questionPayload?.[0]?.answers?.[0]?.answerId ||
            questionPayload?.[0]?.answers?.[0]?.answerValue
          ) {
            const nrqQuestion = question?.settings?.nextReferenceQuestion?.[0]?.question;
            const nrqQuestionPayload = nrqOfGroupQuestionsPayloadFun(
              nrqQuestion,
              nrqQuestion?.answerId?.[0],
              question,
              questionPayload?.[0],
              true,
            );
            const nrqAnswer = nrqQuestionPayload?.[0]?.answers?.[0]?.nextReference;
            const updatedPayload =
              nrqAnswer?.answers?.[0]?.answerId || nrqAnswer?.answers?.answerVale
                ? nrqQuestionPayload
                : questionPayload;
            return updatedPayload?.[0];
          }
          return null;
        })
        ?.filter((item) => item !== null);
      setAnswerIdAndValue({
        questionId: '',
        groupId: payload?.length > 0 ? groupquestion?.id : '',
        answers: [],
        groupQuestions: payload,
      });
    }
  };

  const updateAssessments = async (isNextOrBackOrFinish = '', currentQuestionIndex = -1) => {
    try {
      setIsLoading(true);
      const res = await AssessmentServices.updateAssessment({
        assessmentInstanceId: assessmentInstanceId,
        assessmentRequestBody: {
          questionId: type === 'standalone' ? qtnId : '',
          groupId: type === 'group' ? qtnId : '',
          answers: answerIdAndValue?.answers,
          groupQuestions: answerIdAndValue?.groupQuestions,
        },
        pageableRequest: {
          count: 1,
          offset: currentQuestionIndex,
        },
        deviceType: 'WEB',
        status: '',
        assessentName: '',
        internalRequest: true,
      });

      setAnswerIdAndValue({
        questionId: '',
        groupId: null,
        answers: [],
        groupQuestions: [],
      });
      const formattedData = formatData(res?.assessmentResponseBody?.assessmentQuestionnaires);
      initialPayload(formattedData?.[0]);
      setQuestionsList({ assessmentDefinition: formattedData });
      setIsLoading(false);
      setMetaData(res?.assessmentMeta);
      setPageableResponse(res?.pageableResponse);
      setTotalQuestions(res?.assessmentMeta?.numberOfQuestions);
      setCurrentQuestionIndex(res?.pageableResponse?.offset);
      setTotalAnsweredQuestions(res?.assessmentResponseBody?.answeredQuestionsCount);
    } catch (e) {
      setIsLoading(false);
      console.log('Error', e);
    }
  };

  useEffect(() => {
    updateAssessments();
  }, []);

  const calculateFunc = () => {
    return (100 / totalQuestions) * totalAnsweredQuestions;
  };

  if (isLoading) {
    return <Loader text={MAIN_CONTAINER.LOADING_TEXT} />;
  }

  return (
    <div className={classes.root}>
      {isAssessmentLandingPage ? (
        <div className={classes.assessmentLandingPage}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <WelcomeScreen
              title={metaData?.assessmentName}
              logo={metaData?.orgImageURL}
              orgName={metaData?.orgName}
              subheader={MAIN_CONTAINER.LANDING_PAGE_SUB_HEADING}
              handleStartAssessment={handleStartAssessment}
              handleExit={handleExit}
              styles={styles}
            />
          </SnackbarProvider>
        </div>
      ) : (
        <div className={classes.rightbar}>
          <div className={classes.topbar}>
            <Typography className={classes.typo}>{metaData?.assessmentName}</Typography>
            <div className={classes.topButtonContainer}>
              <MPButton
                text={MAIN_CONTAINER.SAVE_EXIT_BTN}
                className={classes.btnStyle}
                handleClick={handleSaveAndExit}
              />
            </div>
          </div>
          <div className={classes.questionsContainer}>
            <div className={classes.questionNumber}>
              STEP{' '}
              {currentQuestionIndex === pageableResponse?.count?.length
                ? currentQuestionIndex
                : currentQuestionIndex + 1}{' '}
              OF {totalQuestions}
            </div>
            <div className={classes.questions}>
              <Questionnaire
                question={questionList}
                questionOrder={questionOrder}
                styles={styles}
                checkNRQ={checkNRQ}
                getValue={getValue}
                groupHeader={groupHeader}
              />
            </div>
          </div>
          <PreviewFooter
            handleBack={handleBack}
            handleNext={handleNext}
            handleSubmit={handleSubmit}
            disabled={disabledButtonList}
            calculateFunc={calculateFunc}
            questionIndex={currentQuestionIndex}
            answeredQuestion={totalAnsweredQuestions}
            submitFlag={currentQuestionIndex === totalQuestions - 1}
            setPreviewToggle={currentQuestion === totalQuestions - 1}
            submitButtonLabel={
              currentQuestion === totalQuestions - 1 ? MAIN_CONTAINER.FOOTER_SUBMIT_BTN : null
            }
            styles={styles}
          />
          {isOpen && (
            <AssessmentCompletionModal
              onClose={() => {
                navigate('/records/new/assessments_dashboard');
              }}
              imgURL={formSubmitted}
              heading={CONGRATS_POPUP.HEADING}
              subHeading={CONGRATS_POPUP.SUBHEADING}
              handleViewDetails={() => handleViewDetailsInAssessment(assessmentInstanceId)}
              open={isOpen}
            />
          )}

          {openViewDetails && (
            <ViewAssessment
              dateField={viewDetails.completedDate}
              dateHeader="Completed on"
              getStatusColor={() => viewDetails.statusColorCode}
              data={viewDetails}
              handleClose={() => {
                navigate('/records/new/assessments_dashboard');
              }}
              styles={{
                logo: { width: '100%', maxHeight: '48px', maxWidth: '200px', marginBottom: '8px' },
              }}
            />
          )}
          <SnackbarToast
            open={!!snackbarMessage}
            message={snackbarMessage ?? ''}
            duration={4000}
            onClose={() => setSnackbarMessage('')}
            type={SNACKBAR_TYPE.ERROR}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AssessmentContainer;
