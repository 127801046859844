import React, { useState } from 'react';
import { Typography, TextField, Box, InputAdornment, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { IMAGE_ALTERNATE_TEXT } from '../../shared/Strings';
import { getTodaysDate } from '../../../../utils/helper';

const textFieldTitle = {
  mt: 2,
  lineHeight: 0,
  fontSize: '12px',
  fontWeight: 500,
};
const finderText = {
  fontSize: '14px',
  color: '#000000',
};

const useStyles = makeStyles((theme) => ({
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: '#FFFFFF',
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #E6E8ED',
    color: '#4A4A4A',
    borderRadius: 8,
  },
  reactdatepickerwrapper: {
    width: '100%',
  },
}));

function InputField(props) {
  let classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {!props.hide && (
        <Box>
          <Typography variant="h6" sx={textFieldTitle}>
            {props.title}
          </Typography>

          <TextField
            variant="outlined"
            type={props.type === 'password' ? (showPassword ? 'text' : 'password') : props.type}
            placeholder={props.placeholder}
            value={props.value}
            name={props.name}
            onChange={props.onChange}
            margin="normal"
            fullWidth
            size="small"
            color="primary"
            style={{ background: '#FFFFFF' }}
            inputProps={{
              max: props.maxDate ? '' : getTodaysDate(),
              min: props.minDate ? '' : getTodaysDate(),
            }}
            InputProps={{
              ...(props.adornment &&
                props.adornmentPosition === 'start' && {
                  startAdornment: (
                    <InputAdornment position={props.adornmentPosition}>
                      {props.adornment}
                    </InputAdornment>
                  ),
                }),
              ...(props.type === 'password' && {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }),
            }}
          />

          {props.errorMessage ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon fontSize="small" color="error" />
              <Typography variant="h6" color="error" sx={{ fontSize: '12px', ml: 0.5 }}>
                {props.errorMessage}
              </Typography>
            </Box>
          ) : props.helperText ? (
            <Box sx={{ display: 'flex', alignItems: 'center', pb: 1 }}>
              <InfoIcon fontSize="small" style={{ color: '#666666' }} />
              <Typography variant="h6" sx={{ fontSize: '12px', ml: 0.5, color: '#666666' }}>
                {props.helperText}
              </Typography>
            </Box>
          ) : null}
          {props.helperImage ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                cursor: 'pointer',
              }}
            >
              <Tooltip
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                arrow
                title={
                  <img
                    style={{
                      height: 136,
                      width: 215,
                    }}
                    src={props.helperImageUrl}
                    alt={IMAGE_ALTERNATE_TEXT.TOOLTIP}
                  />
                }
                placement="top"
              >
                <Typography style={finderText}>{props.helperImageText}</Typography>
              </Tooltip>
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
}

export default InputField;
