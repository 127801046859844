import AllergyIcon from '../assets/images/allergy-transparent.png';
import ImmunizationIcon from '../assets/images/shield-transparent.png';
import ClinicalVitalsIcon from '../assets/images/vital-transparent.png';
import MedicationsIcon from '../assets/images/medications.png';

import { MORE_SERVICES_MODAL_ICONS } from './constants';
import { replaceImagePropertyV2 } from './helper';

const MODAL_ICONS = [MedicationsIcon, AllergyIcon, ImmunizationIcon, ClinicalVitalsIcon];

const getModalIcons = () => replaceImagePropertyV2(MORE_SERVICES_MODAL_ICONS, MODAL_ICONS);

const moreServicesModalIconGetter = { getModalIcons };

export default moreServicesModalIconGetter;
