import React, { useEffect, useState } from 'react';
import Loader from '../../components/atoms/Loader';
import { ADD_PARTNER } from '../../utils/constants';
import CircleService from '../../service/CirclesService';
import { useNavigate } from 'react-router-dom';
import { detectDeviceName, getEAMErrorObject } from '../../utils/helper';
import withRouter from '../../components/withRoute';
import { DEVICE_TYPE } from '../../features/landingFlow/constants';
import { updatePartnerAuthFlowStatus, getPartnerAuthFlowStatus } from '../../features/circles/api';

const parseParams = (str) => {
  const pieces = str.split('&'),
    data = {};
  let i, parts;
  // process each query pair
  for (i = 0; i < pieces.length; i++) {
    parts = pieces[i].split('=');
    if (parts.length < 2) {
      parts.push('');
    }
    data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
  }
  return data;
};

const EAMCallbackComponent = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loaderText, setLoaderText] = useState(ADD_PARTNER.ADDING_PARTNER);

  const navigate = useNavigate();
  const refreshTokenForPartner = localStorage.getItem('partnerRefreshToken') || null;
  const circleIdToBeAdded = localStorage.getItem('circleIdToBeAdded');
  const partnerToBeAdded = localStorage.getItem('partnerToBeAdded')?.replaceAll(' ', '-');

  const addPartnerEAM = (code, eamState) =>
    CircleService.fetchPartnerPatient(
      code,
      eamState,
      process.env.REDIRECT_URL_EAM,
      Number(circleIdToBeAdded),
    )
      .then((res) => {
        if (res.isPartnerAdded) {
          localStorage.setItem('isPartnerAddedFromEamCallback', res.isPartnerAdded);
        }
        updatePartnerAuthFlowStatus(Number(circleIdToBeAdded), 'COMPLETED');
        localStorage.setItem('profileDetailsFromPartner', JSON.stringify(res));
        ///this was the normal flow redirection after add partner
        navigate(`/circles?status=success`);
      })
      .catch((err) => {
        const errorObject = getEAMErrorObject(err);
        const errorCode = errorObject && errorObject.code;
        navigate(`/circles?status=error&errorCode=${errorCode}`);
      });

  const reAuthenticatePartner = (code, eamState) => {
    CircleService.eamCallbackWithCircle(
      code,
      eamState,
      process.env.REDIRECT_URL_EAM,
      Number(circleIdToBeAdded),
    ).then((res) => {
      localStorage.removeItem('partnerRefreshToken');
      localStorage.removeItem('circleIdToBeAdded');
      localStorage.removeItem('partnerToBeAdded');
      navigate(`/circles/${partnerToBeAdded}/${circleIdToBeAdded}`);
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const otherParamObj = parseParams(location.hash.substring(1));
    console.log('');
    const code = localStorage.getItem('EAMCode')
      ? localStorage.getItem('EAMCode')
      : params.get('code');
    const eamState = localStorage.getItem('EAMState')
      ? localStorage.getItem('EAMState')
      : params.get('state') || otherParamObj['state'];

    const deviceType = detectDeviceName();
    if (code && eamState) {
      setLoaderText(ADD_PARTNER.ADDING_PARTNER);
      if (deviceType === DEVICE_TYPE.WEB) {
        if (refreshTokenForPartner) {
          reAuthenticatePartner(code, eamState);
        } else {
          addPartnerEAM(code, eamState);
        }
      } else {
        setIsLoading(false);
        localStorage.removeItem('EAMCode');
        localStorage.removeItem('EAMState');
        window.location.replace(
          `cms.mpowered://cms-oauth-redirect/?code=${code}&state=${eamState}`,
        );
      }
    } else {
      if (deviceType === DEVICE_TYPE.WEB) {
        navigate('/circles?status=error');
      } else {
        setIsLoading(false);
        setLoaderText('An Error Occurred While Adding Partner');
        window.location.replace(`cms.mpowered://cms-oauth-redirect/?code=${null}&state=${null}`);
      }
    }
  }, []);

  return <div>{isLoading && <Loader text={loaderText} />}</div>;
};

export default withRouter(EAMCallbackComponent);
