import React from 'react';
import Button from '@mui/material/Button';

function SecondaryBtnComponent({ type, onClick, disabled, title, sx, ...props }) {
  return (
    <Button
      data-testid="secondarybtn"
      sx={{
        borderRadius: 40,
        borderColor: 'gray',
        backgroundColor: '#ffffff',
        textTransform: 'none',
        ...sx,
      }}
      variant="outlined"
      size="large"
      color="primary"
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {title}
    </Button>
  );
}

export default SecondaryBtnComponent;
