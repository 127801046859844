import React from 'react';
import { Box, Card, CardActions, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from '../../../../utils/colors';
import useGetScreenMode from '../../../../hooks/useGetScreenMode';
import { checkOneTimeUser } from '../../../../utils/helper';
import { useNavigate, useParams } from 'react-router-dom';
import UserCircle from '../../../../assets/images/defaultAvatarBlack.svg';
import LaunchIcon from '@mui/icons-material/Launch';
import { CARE_TEAM, DEFAULT_NULL_REPLACEMENT } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  optionsContainer: {
    marginTop: theme.spacing(2.5),
  },
  heading1: {
    color: colors.BLACK,
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: '20px',
    margintTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  careTeamCardGrid: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  careTeamCardRoot: {
    border: `1px solid #E4E4E4`,
    borderRadius: theme.spacing(1.5),
    backgroundColor: colors.WHITE,
    display: 'flex',
    justifyContent: 'space-between',
    width: 280,
    cursor: 'pointer',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  careTeamIconWrapper: {
    paddingRight: theme.spacing(1),
  },
  icon: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  typo1: {
    fontFamily: 'Inter',
    fontSize: theme.spacing(1.75),
    color: colors.PRIMARY_GRAY,
  },
  typo2: {
    fontFamily: 'Inter',
    fontWeight: 400,
    color: colors.GRAY_SECONDARY,
  },
  launchIcon: {
    color: colors.GRAY_SECONDARY,
    fontSize: theme.spacing(2.75),
  },
}));

const ViewProfile = (props) => {
  const { viewProfileData } = props;
  const classes = useStyles();
  const screenMode = useGetScreenMode();
  const isOneTimeUser = checkOneTimeUser();
  const { partner, grantorId, granteeId, circleId } = useParams();
  const navigate = useNavigate();
  const handleRedirectToCareTeam = (linkObj) => {
    let url = '/records/new/care_team';
    if (screenMode.isCircle && !screenMode.involvesConsent && !isOneTimeUser) {
      url = `/circles/${partner}/${circleId}/new/care_team`;
    } else if (screenMode.involvesConsent || isOneTimeUser) {
      url = `/${
        screenMode.isCircle ? 'circles' : 'records'
      }/individual/new/care_team/${grantorId}/${granteeId}`;
    }
    const careTeamId = linkObj?.resource_id;
    if (careTeamId) {
      navigate(url, { state: { careTeamId } });
    }
  };

  return (
    <Box className={classes.optionsContainer}>
      <Box>
        {!!viewProfileData.length && (
          <Typography className={classes.heading1}>{CARE_TEAM?.WORDINGS?.CARE_TEAM}</Typography>
        )}
        <Grid container>
          {viewProfileData?.map((data, index) => {
            return (
              <Grid className={classes.careTeamCardGrid} item key={index}>
                <Card className={classes.careTeamCardRoot}>
                  <CardContent className={classes.cardContent}>
                    <Box className={classes.careTeamIconWrapper}>
                      <img
                        src={data?.iconurl}
                        alt="icon"
                        className={classes.icon}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = UserCircle;
                        }}
                      />
                    </Box>
                    <Box className={classes.boxContainer}>
                      <Typography className={classes.typo1}>
                        {data?.title || DEFAULT_NULL_REPLACEMENT}
                      </Typography>
                      <Typography className={classes.typo2}>
                        {data?.subtitle || DEFAULT_NULL_REPLACEMENT}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <IconButton onClick={() => handleRedirectToCareTeam(data)}>
                      <LaunchIcon className={classes.launchIcon} />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewProfile;
