// import Button from '../../components/atoms/Button';

import React, { useEffect, useMemo, useState } from 'react';
import gql from 'graphql-tag';
import User from '../../service/User';
import {
  OTP_VERIFICATION_MODAL,
  PHONE_NO_START_ADORNMENT,
  PHONE_VERIFICATION_TEXTS,
  SIGN_UP_DETAILS,
} from '../../utils/constants';
import { Box, InputAdornment, Snackbar, TextField, Typography, Button } from '@mui/material';
import UserService from '../../service/User';
import { useTimer } from '../../utils/useTimer';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import { validateUSPhone } from '../../utils/validations';
import { getUSFormatPhoneNo } from '../../utils/helper';
import InfoIcon from '@mui/icons-material/Info';
import Loader from '../../components/atoms/Loader';

const validateOTPQuery = gql`
  mutation validateOTP($validateOTPRequest: ValidateOTPRequest) {
    validateOTP(validateOTPRequest: $validateOTPRequest) {
      phoneVerificationStatus
      verificationSid
    }
  }
`;
const generateOTPQueryUnAuth = gql`
  mutation generateOTPUnAuth($phone: String) {
    generateOTPUnAuth(phone: $phone) {
      verificationSid
    }
  }
`;
const validateOTPQueryUnAuth = gql`
  mutation validateOTPUnAuth($validateOTPRequest: ValidateOTPRequest, $userId: String) {
    validateOTPUnAuth(validateOTPRequest: $validateOTPRequest, userId: $userId) {
      phoneVerificationStatus
    }
  }
`;
const updatePhoneNumberQuery = gql`
  mutation updatePhoneNumber(
    $id: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String!
  ) {
    updatePhoneNumber(
      userSchemaRequest: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
      }
    )
  }
`;

const otpdivstyle = {
  position: 'absolute',
  marginTop: 76,
  marginLeft: 80,
};
const btnstyle = {
  borderRadius: '40px',
  marginRight: 16,
  height: 48,
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: ' 24px',
};
const boxstyle = {
  background: '#f7f7f7',
  flex: 1,
  display: 'flex',
};
const closeIconStyle = {
  marginTop: 32,
  marginRight: 32,
};
const textFieldTitle = {
  fontSize: '12px',
  fontWeight: 500,
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  lineHeight: '16px',
};
const inputboxstyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '28px',
  lineHeight: '40px',
  border: '1px solid #CCCCCC',
  height: '100px',
  width: '100px',
  marginRight: '20px',
  borderRadius: '4px',
  textAlign: 'center',
  marginTop: 32,
};
const modalButtonsStyle = {
  marginTop: 32,
  display: 'flex',
  flexDirection: 'row',
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  marginBottom: 10,
};
const modalElementStyle = {
  display: 'flex',
  marginLeft: '32px',
  flexDirection: 'column',
  borderRadius: '4px',
  marginTop: 20,
};
const chnagePNInput = {
  width: 256,
};
const modalHeadingStyle = {
  display: 'flex',
  marginLeft: '32px',
  justifyContent: 'space-between',
};
const headingStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  fontWeight: 600,
  fontSize: ' 28px',
  lineHeight: ' 32px',
};
const modalDiv1style = {
  borderRadius: '24px',
  position: 'absolute',
  width: 'calc(100vw*284/375)',
  backgroundColor: '#FFFFFF',
  padding: '40px 25px',
  maxWidth: '400px',
};
const buttonstyle = {
  width: '100%',
  height: 48,
  padding: '8px 24px',
  borderRadius: 40,
  fontSize: 16,
  lineHeight: '24px',
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  fontWeight: 500,
  marginTop: 32,
};
const editIconStyle = { fontSize: '1.3rem', marginLeft: 3, marginTop: 20 };
const spanstyle = {
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 400,
  fontFamily: 'Inter',
  color: '#666666',
};
const otpbuttonstyle = {
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#0060BA',
};
const phonenostyle = {
  color: '#0000FF',
  display: 'inline',
};
const headertextstyle = {
  marginTop: 16,
  display: 'inline',
  fontFamily: 'Inter',
  fontStyle: 'normal,',
};
const textsizestyle = {
  marginTop: 32,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  fontFamily: 'Inter',
  fontStyle: 'normal,',
};
const helperText = {
  marginTop: 5,
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Inter',
  fontStyle: 'normal,',
  color: '#FF0000',
};
const infoIconError = { height: '12px', width: '12px' };

const OTPBox = (props) => {
  const [isSocialLogin, setIsSocialLogin] = useState(props.isSocialLogin);
  const [userId, setUserId] = useState(props.id);
  const [otp, setOtp] = useState(new Array(4).fill(''));
  const [resendOTPrequest, setResendOTPrequest] = useState(false);
  const [resendedVSid, setResendedVSid] = useState('');
  const [popupChangePhoneNo, setPopupChangePhoneNo] = useState(false);
  const [newPhoneNo, setNewPhoneNo] = useState(getUSFormatPhoneNo(props.phoneNo));
  const [mobileNo, setMobileNo] = useState(props.phoneNo);
  const [validateOTPDisabled, setValidateOTPDisabled] = useState(true);
  const [phoneNoError, setPhoneNoError] = useState(false);
  const [phoneNoErrorMessage, setphoneNoErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const mobile_no_with_bracket = useMemo(() => {
    return mobileNo && mobileNo.replace(/\s+/g, '');
  }, [mobileNo]);
  const displayPhoneNo = useMemo(() => {
    return mobile_no_with_bracket && mobile_no_with_bracket.replace(/[()]/g, '');
  }, [mobile_no_with_bracket]);
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resendLimitExceed, setResendLimitExceed] = useState(false);
  const [resendCnt, setResendCnt] = useState(0);
  const [resendTime, setResendTime] = useTimer({
    startTime: 59,
    multiplier: 1,
    direction: 'down',
    endTime: 0,
    timeOut: 1000,
  });

  const { logAnalyticsEvent } = useAnalyticsLogger();
  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.COMPLETE_REGISTRATION);

  const handleResend = () => {
    //call generate otp for resend otp
    setResendCnt(resendCnt + 1);
    setOpenSnackbar(true);
    setSnackbarMessage('Verification code resent');
    setResendTime(59);

    generateOTPByPhone({ phoneNumber: mobileNo });

    // OTP Time In Seconds
  };
  useEffect(() => {
    if (resendCnt >= 3) {
      setResendLimitExceed(true);
    }
  }, [resendCnt]);

  const handlePopupChangePhoneNo = () => {
    setPopupChangePhoneNo(!popupChangePhoneNo);
  };

  const handleOnCloseSnackbar = () => {
    setSnackbarMessage('');
    setOpenSnackbar(false);
  };

  const numberValidation = (phoneno) => {
    const intMobileNO = getUSFormatPhoneNo(phoneno);
    const { isValid, errorMessage } = validateUSPhone(intMobileNO);
    if (!isValid) {
      return false;
    }
    return true;
  };
  const handleSaveChanges = () => {
    if (numberValidation(newPhoneNo)) {
      const mobile_no_with_brackate = newPhoneNo.replace(/\s+/g, '');
      const newPhoneNoWithoutElements = mobile_no_with_brackate.replace(/[()]/g, '');
      const userWithNewPhoneNo = {
        id: props.id,
        firstName: props.firstName,
        lastName: props.lastName,
        email: props.email,
        phone: newPhoneNoWithoutElements,
      };

      logAnalyticsEvent(analyticsEvents.CHANGE_NUMBER_INITIATED_2022R4);
      User.updatePhoneNumber(updatePhoneNumberQuery, userWithNewPhoneNo)
        .then((result) => {
          logAnalyticsEvent(analyticsEvents.CHANGE_NUMBER_COMPLETED_2022R4);
          setMobileNo(newPhoneNoWithoutElements);
          handlePopupChangePhoneNo();
          generateOTPByPhone({ phoneNumber: newPhoneNoWithoutElements });
          setOpenSnackbar(true);
          setSnackbarMessage('Verification code sent');
        })
        .catch((error) => {
          //check error if its phone number exist
          logAnalyticsEvent(analyticsEvents.CHANGE_NUMBER_ERROR_2022R4);
          setPhoneNoError(true);
          setphoneNoErrorMessage('Phone number already exist');
          console.log(error);
        });
    } else {
      logAnalyticsEvent(analyticsEvents.CHANGE_NUMBER_ERROR_2022R4);
      setPhoneNoError(true);
      setphoneNoErrorMessage('Enter valid 10 digit phone number');
    }
  };

  const generateOTPByPhone = (args) => {
    User.generateOTPUnAuth(generateOTPQueryUnAuth, args.phoneNumber)
      .then((res) => {
        setResendOTPrequest(true);
        setResendedVSid(res.verificationSid);
      })
      .catch((error) => console.log(error));
  };
  const handleNewPhoneNo = (element) => {
    setPhoneNoError(false);
    setphoneNoErrorMessage('');
    let value = element.value;
    if (value.length >= newPhoneNo.length) {
      value = getUSFormatPhoneNo(value);
    }
    setNewPhoneNo(value);
  };

  const handleChange = (element, index) => {
    setOtpError(false);

    if (isNaN(element.value)) {
      return false;
    }
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    //Array size is fixed to 4 so we cannot find the exact length of array after changing the values so we are converting the array into string.
    const otpString = [...otp.map((d, idx) => (idx === index ? element.value : d))].toString();
    const otpStringLength = otpString.length;

    //we are having an array of length 4 and when we convert the array into string so 3 commas are already there for sepating the values and 4 values from boxes so that altogether length of that string should be 7.
    if (otpStringLength === 7) {
      setValidateOTPDisabled(false);
    } else {
      setValidateOTPDisabled(true);
    }

    if (element.value && element.nextSibling) {
      element.nextSibling.focus();
    }
    if (!element.value) {
      element.previousSibling.focus();
    }
  };

  const handleOTPValue = (value) => {
    setIsLoading(true);
    const validateOTPRequest = {
      otp: value,
      updateDatabase: true,
      verificationSid: '',
    };
    if (resendOTPrequest) {
      validateOTPRequest.verificationSid = resendedVSid;
    } else {
      validateOTPRequest.verificationSid = props.verificationSid;
    }

    if (!isSocialLogin) {
      UserService.verifyOTPUnAuth(validateOTPQueryUnAuth, validateOTPRequest, userId)
        .then((result) => {
          const status = result.phoneVerificationStatus;
          if (status === PHONE_VERIFICATION_TEXTS.PHONE_VERIFICATION_STATUS.APPROVED) {
            //props.setUser();
            props.onOTPSuccess(true);
          } else {
            setOtpError(true);
            setOtpErrorMessage('OTP does not match');
            setOtp(['', '', '', '']);
          }
        })
        .catch((error) => {
          setOtpError(true);
          setOtpErrorMessage('OTP does not match');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      User.verifyOTP(validateOTPQuery, validateOTPRequest)
        .then((result) => {
          const status = result.phoneVerificationStatus;
          if (status === PHONE_VERIFICATION_TEXTS.PHONE_VERIFICATION_STATUS.APPROVED) {
            props.setUser();
            props.onOTPSuccess(true);
          } else {
            setOtpError(true);
            setOtpErrorMessage('OTP does not match');
            setOtp(['', '', '', '']);
          }
        })
        .catch((error) => {
          setOtpError(true);
          setOtpErrorMessage('OTP does not match');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleRedirection = () => {
    const otpValue = otp.join('');
    handleOTPValue(otpValue);
  };

  return (
    <Box style={boxstyle}>
      {isLoading && <Loader overlay loader text={null} />}
      <div style={otpdivstyle}>
        <div>
          <h2>{OTP_VERIFICATION_MODAL.ENTER_OTP}</h2>
          <p style={headertextstyle}>
            {OTP_VERIFICATION_MODAL.FOUR_DIGIT_CODE} <p style={phonenostyle}>{displayPhoneNo}</p>
            <EditIcon
              color="primary"
              size="small"
              style={editIconStyle}
              onClick={handlePopupChangePhoneNo}
            ></EditIcon>
          </p>
          <p>{'\n'}</p>

          {otp.map((data, index) => {
            return (
              <input
                style={inputboxstyle}
                type="text"
                name="otp"
                maxLength="1"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
              />
            );
          })}
          {otpError ? (
            <Typography variant="h6" style={helperText}>
              <InfoIcon style={infoIconError}></InfoIcon>
              {otpErrorMessage}
            </Typography>
          ) : null}

          <br />
          <br />
          <Button
            id="ssignup-button"
            style={buttonstyle}
            onClick={handleRedirection}
            variant="contained"
            color="secondary"
            disabled={validateOTPDisabled}
          >
            {SIGN_UP_DETAILS.CREATE_ACCOUNT}
          </Button>
        </div>
        <br />

        {!resendLimitExceed ? (
          <div>
            <p style={textsizestyle}>{OTP_VERIFICATION_MODAL.DID_NOT}</p>
            <p>
              {resendTime !== 0 ? (
                <span style={spanstyle}> {`${OTP_VERIFICATION_MODAL.NEW_CODE} ${resendTime}`}</span>
              ) : (
                <b>
                  <a type="button" onClick={handleResend} style={otpbuttonstyle}>
                    {OTP_VERIFICATION_MODAL.RESEND_OTP}
                  </a>
                </b>
              )}
            </p>
          </div>
        ) : (
          <p style={textsizestyle}>Didn’t receive it yet? Try another mobile number</p>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleOnCloseSnackbar}
          message={snackbarMessage}
        />
      </div>
      <Modal
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        open={popupChangePhoneNo}
        onClose={handlePopupChangePhoneNo}
      >
        <div style={modalDiv1style}>
          <div style={modalHeadingStyle}>
            <h3 style={headingStyle}>{OTP_VERIFICATION_MODAL.EDIT_PHONE_NUMBER}</h3>
            <Close onClick={handlePopupChangePhoneNo} style={closeIconStyle} />
          </div>

          <div style={modalElementStyle}>
            <Typography variant="h6" style={textFieldTitle}>
              {OTP_VERIFICATION_MODAL.PHONE_NUMBER}
            </Typography>

            <TextField
              id="changephoneno-field"
              name="changephoneno"
              color="secondary"
              variant="outlined"
              size="medium"
              type="text"
              value={newPhoneNo || ''}
              style={chnagePNInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{PHONE_NO_START_ADORNMENT}</InputAdornment>
                ),
              }}
              onChange={(e) => handleNewPhoneNo(e.target)}
              error={phoneNoError}
              helperText={phoneNoErrorMessage}
            />

            <div style={modalButtonsStyle}>
              <Button
                style={btnstyle}
                id="changePhoneNo-button"
                onClick={handlePopupChangePhoneNo}
                title="Cancel"
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                style={btnstyle}
                id="changePhoneNo-button"
                onClick={handleSaveChanges}
                title="Save changes"
                variant="contained"
                color="secondary"
              >
                Save changes
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </Box>
  );
};

export { OTPBox };
