import { ROUTE_NAMES } from './routeNames';

export const CONSOLIDATED_EVENTS = {
  [ROUTE_NAMES.SIGNUP]: {
    SIGNUP1_INITIATED_2022R4: {
      eventName: 'SIGNUP1_INITIATED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP1_COMPLETED_2022R4: {
      eventName: 'SIGNUP1_COMPLETED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP1_EMAIL_EXISTS_2022R4: {
      eventName: 'SIGNUP1_EMAIL_EXISTS_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP1_LOGIN_INITIATED_2022R4: {
      eventName: 'SIGNUP1_LOGIN_INITIATED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_GOOGLE_INITIATED_2022R4: {
      eventName: 'SIGNUP_GOOGLE_INITIATED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_GOOGLE_COMPLETED_2022R4: {
      eventName: 'SIGNUP_GOOGLE_COMPLETED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    LOGIN_GOOGLE_COMPLETED_2022R4: {
      eventName: 'LOGIN_GOOGLE_COMPLETED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_APPLE_INITIATED_2022R4: {
      eventName: 'SIGNUP_APPLE_INITIATED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_APPLE_COMPLETED_2022R4: {
      eventName: 'SIGNUP_APPLE_COMPLETED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    LOGIN_APPLE_COMPLETED_2022R4: {
      eventName: 'LOGIN_APPLE_COMPLETED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_LINKEDIN_INITIATED_2022R4: {
      eventName: 'SIGNUP_LINKEDIN_INITIATED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_LINKEDIN_COMPLETED_2022R4: {
      eventName: 'SIGNUP_LINKEDIN_COMPLETED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    LOGIN_LINKEDIN_COMPLETED_2022R4: {
      eventName: 'LOGIN_LINKEDIN_COMPLETED_2022R4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_APPLE_INITIATED_V4: {
      eventName: 'SIGNUP_APPLE_INITIATED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_GOOGLE_INITIATED_V4: {
      eventName: 'SIGNUP_GOOGLE_INITIATED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_FACEBOOK_INITIATED_V4: {
      eventName: 'SIGNUP_FACEBOOK_INITIATED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_APPLE_COMPLETED_V4: {
      eventName: 'SIGNUP_APPLE_COMPLETED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    LOGIN_APPLE_COMPLETED_V4: {
      eventName: 'LOGIN_APPLE_COMPLETED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_GOOGLE_COMPLETED_V4: {
      eventName: 'SIGNUP_GOOGLE_COMPLETED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    LOGIN_GOOGLE_COMPLETED_V4: {
      eventName: 'LOGIN_GOOGLE_COMPLETED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_FACEBOOK_COMPLETED_V4: {
      eventName: 'SIGNUP_FACEBOOK_COMPLETED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    LOGIN_FACEBOOK_COMPLETED_V4: {
      eventName: 'LOGIN_FACEBOOK_COMPLETED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_SEND_OTP_INITIATED_V4: {
      eventName: 'SIGNUP_SEND_OTP_INITIATED_V4',
      eventGroup: 'OTP_SCREEN',
    },
    SIGNUP_SEND_OTP_SENT_V4: { eventName: 'SIGNUP_SEND_OTP_SENT_V4', eventGroup: 'OTP_SCREEN' },
    SIGNUP_SEND_OTP_ERROR_V4: { eventName: 'SIGNUP_SEND_OTP_ERROR_V4', eventGroup: 'OTP_SCREEN' },
    SIGNUP_RESEND_OTP_ERROR_V4: {
      eventName: 'SIGNUP_RESEND_OTP_ERROR_V4',
      eventGroup: 'OTP_SCREEN',
    },
    SIGNUP_RESEND_OTP_SUCCESS_V4: {
      eventName: 'SIGNUP_RESEND_OTP_SUCCESS_V4',
      eventGroup: 'OTP_SCREEN',
    },
    SIGNUP_COMPLETED_V4: { eventName: 'SIGNUP_COMPLETED_V4', eventGroup: 'CREATE_ACCOUNT_SCREEN' },
    SIGNUP_PHONE_VERIFY_INITIATED_V4: {
      eventName: 'SIGNUP_PHONE_VERIFY_INITIATED_V4',
      eventGroup: 'OTP_SCREEN',
    },
    SIGNUP_EMAIL_VERIFY_INITIATED_V4: {
      eventName: 'SIGNUP_EMAIL_VERIFY_INITIATED_V4',
      eventGroup: 'OTP_SCREEN',
    },

    SIGNUP_PP_CLICKED_V4: {
      eventName: 'SIGNUP_PP_CLICKED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_TNC_CLICKED_V4: {
      eventName: 'SIGNUP_TNC_CLICKED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_SCREEN1_SUCCESS_V4: {
      eventName: 'SIGNUP_SCREEN1_SUCCESS_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_PHONE_OTP_VERIFY_SUCCESS_V: {
      eventName: 'SIGNUP_PHONE_OTP_VERIFY_SUCCESS_V',
      eventGroup: 'OTP_SCREEN',
    },
    SIGNUP_OTP_VERIFY_SUCCESS_V4: {
      eventName: 'SIGNUP_OTP_VERIFY_SUCCESS_V4',
      eventGroup: 'OTP_SCREEN',
    },

    SIGNUP_ENTER_CODE_CLICKED_V4: {
      eventName: 'SIGNUP_ENTER_CODE_CLICKED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_EMAIL_INPUT_CLICKED_V4: {
      eventName: 'SIGNUP_EMAIL_INPUT_CLICKED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_PASSWORD_INPUT_CLICKED_V4: {
      eventName: 'SIGNUP_PASSWORD_INPUT_CLICKED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_CONTINUE_BTN_CLICKED_V4: {
      eventName: 'SIGNUP_CONTINUE_BTN_CLICKED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_LOGIN_BTN_CLICKED_V4: {
      eventName: 'SIGNUP_LOGIN_BTN_CLICKED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_AUTO_LOGIN_V4: {
      eventName: 'SIGNUP_AUTO_LOGIN_V4 ',
      eventGroup: 'AUTO_LOGIN',
    },
  },

  [ROUTE_NAMES.COMPLETE_REGISTRATION]: {
    SIGNUP2_INITIATED_2022R4: {
      eventName: 'SIGNUP2_INITIATED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_COMPLETED_2022R4: {
      eventName: 'SIGNUP2_COMPLETED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_PROMOCODE_ADDED_2022R4: {
      eventName: 'SIGNUP2_PROMOCODE_ADDED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_REFERRALCODE_ADDED_2022R4: {
      eventName: 'SIGNUP2_REFERRALCODE_ADDED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_INVALID_CODE_2022R4: {
      eventName: 'SIGNUP2_INVALID_CODE_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_CODE_ERROR_2022R4: {
      eventName: 'SIGNUP2_CODE_ERROR_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_PROMOCODE_APPLIED_2022R4: {
      eventName: 'SIGNUP2_PROMOCODE_APPLIED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_REFERRALCODE_APPLIED_2022R4: {
      eventName: 'SIGNUP2_REFERRALCODE_APPLIED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_WITHOUT_CODE_2022R4: {
      eventName: 'SIGNUP2_WITHOUT_CODE_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_PHONE_EXISTS_2022R4: {
      eventName: 'SIGNUP2_PHONE_EXISTS_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP2_SUBMIT_ERROR_2022R4: {
      eventName: 'SIGNUP2_SUBMIT_ERROR_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },

    SIGNUP_OTP_INITIATED_2022R4: {
      eventName: 'SIGNUP_OTP_INITIATED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP_OTP_COMPLETED_2022R4: {
      eventName: 'SIGNUP_OTP_COMPLETED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP_OTP_ERROR_2022R4: {
      eventName: 'SIGNUP_OTP_ERROR_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    RESEND_OTP_INITIATED_2022R4: {
      eventName: 'RESEND_OTP_INITIATED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    OTP_VERIFICATION_RESENT_2022R4: {
      eventName: 'OTP_VERIFICATION_RESENT_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    OTP_RESENT_ERROR_2022R4: {
      eventName: 'OTP_RESENT_ERROR_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    OTP_WRONG_ERROR_2022R4: {
      eventName: 'OTP_WRONG_ERROR_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    OTP_VERIFICATION_ERROR: {
      eventName: 'OTP_VERIFICATION_ERROR',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    OTP_VERIFICATION_INITIATED_2022R4: {
      eventName: 'OTP_VERIFICATION_INITIATED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    OTP_VERIFICATION_COMPLETED_2022R4: {
      eventName: 'OTP_VERIFICATION_COMPLETED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },

    PHONE_VERIFI_INITIATED_SIGNUP_2022R4: {
      eventName: 'PHONE_VERIFI_INITIATED_SIGNUP_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    PHONE_VERIFI_COMPLETED_SIGNUP_2022R4: {
      eventName: 'PHONE_VERIFI_COMPLETED_SIGNUP_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },

    SIGNUP_PROMOCODE_REDEEMED: {
      eventName: 'SIGNUP_PROMOCODE_REDEEMED',
      eventGroup: 'COMPLETE_REGISTRATION',
    },

    SIGNUP_COMPLETED: { eventName: 'SIGNUP_COMPLETED', eventGroup: 'COMPLETE_REGISTRATION' },

    CHANGE_NUMBER_INITIATED_2022R4: {
      eventName: 'CHANGE_NUMBER_INITIATED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    CHANGE_NUMBER_COMPLETED_2022R4: {
      eventName: 'CHANGE_NUMBER_COMPLETED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    CHANGE_NUMBER_ERROR_2022R4: {
      eventName: 'CHANGE_NUMBER_ERROR_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },

    SIGNUP_REFERRALCODE_REDEEMED_2022R4: {
      eventName: 'SIGNUP_REFERRALCODE_REDEEMED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    SIGNUP_PROMOCODE_REDEEMED_2022R4: {
      eventName: 'SIGNUP_PROMOCODE_REDEEMED_2022R4',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
    AUTOLOGIN_INITIATED_AFTER_SIGNUP: {
      eventName: 'AUTOLOGIN_INITIATED_AFTER_SIGNUP',
      eventGroup: 'COMPLETE_REGISTRATION',
    },
  },
  [ROUTE_NAMES.LOGIN]: {
    LANDING_SIGNUP_INITIATED: {
      eventName: 'LANDING_SIGNUP_INITIATED',
      eventGroup: 'LOG_IN',
    },
    PASSWORD_RESET_INITIATED_2022R4: {
      eventName: 'PASSWORD_RESET_INITIATED_2022R4',
      eventGroup: 'LOG_IN',
    },

    FETCH_USER_PROFILE_INITIATED_2022R4: {
      eventName: 'FETCH_USER_PROFILE_INITIATED_2022R4',
      eventGroup: 'LOG_IN',
    },
    FETCH_USER_PROFILE_ERROR_2022R4: {
      eventName: 'FETCH_USER_PROFILE_ERROR_2022R4',
      eventGroup: 'LOG_IN',
    },
    FETCH_USER_PROFILE_COMPLETED_2022R4: {
      eventName: 'FETCH_USER_PROFILE_COMPLETED_2022R4',
      eventGroup: 'LOG_IN',
    },

    SIGNUP_GOOGLE_INITIATED_2022R4: {
      eventName: 'SIGNUP_GOOGLE_INITIATED_2022R4',
      eventGroup: 'LOG_IN',
    },
    SIGNUP_GOOGLE_COMPLETED_2022R4: {
      eventName: 'SIGNUP_GOOGLE_COMPLETED_2022R4',
      eventGroup: 'LOG_IN',
    },
    LOGIN_GOOGLE_COMPLETED_2022R4: {
      eventName: 'LOGIN_GOOGLE_COMPLETED_2022R4',
      eventGroup: 'LOG_IN',
    },

    SIGNUP_APPLE_INITIATED_2022R4: {
      eventName: 'SIGNUP_APPLE_INITIATED_2022R4',
      eventGroup: 'LOG_IN',
    },
    SIGNUP_APPLE_COMPLETED_2022R4: {
      eventName: 'SIGNUP_APPLE_COMPLETED_2022R4',
      eventGroup: 'LOG_IN',
    },
    LOGIN_APPLE_COMPLETED_2022R4: {
      eventName: 'LOGIN_APPLE_COMPLETED_2022R4',
      eventGroup: 'LOG_IN',
    },

    SIGNUP_LINKEDIN_INITIATED_2022R4: {
      eventName: 'SIGNUP_LINKEDIN_INITIATED_2022R4',
      eventGroup: 'LOG_IN',
    },
    SIGNUP_LINKEDIN_COMPLETED_2022R4: {
      eventName: 'SIGNUP_LINKEDIN_COMPLETED_2022R4',
      eventGroup: 'LOG_IN',
    },

    SIGNUP_APPLE_INITIATED_V4: {
      eventName: 'SIGNUP_APPLE_INITIATED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_GOOGLE_INITIATED_V4: {
      eventName: 'SIGNUP_GOOGLE_INITIATED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },
    SIGNUP_FACEBOOK_INITIATED_V4: {
      eventName: 'SIGNUP_FACEBOOK_INITIATED_V4',
      eventGroup: 'CREATE_ACCOUNT_SCREEN',
    },

    SIGNUP_CREATE_ACCOUNT_INITIATED_V4: {
      eventName: 'SIGNUP_CREATE_ACCOUNT_INITIATED_V4',
      eventGroup: 'LANDING_SCREEN',
    },
    PASSWORD_RESET_INITIATED_V4: { eventName: 'PASSWORD_RESET_INITIATED_V4', eventGroup: 'LOGIN' },

    LOGIN_FETCH_PROFILE_INITIATED_V4: {
      eventName: 'LOGIN_FETCH_PROFILE_INITIATED_V4',
      eventGroup: 'LOG_IN',
    },
    LOGIN_FETCH_PROFILE_COMPLETED_V4: {
      eventName: 'LOGIN_FETCH_PROFILE_COMPLETED_V4',
      eventGroup: 'LOG_IN',
    },
    LOGIN_FETCH_PROFILE_ERROR_V4: {
      eventName: 'LOGIN_FETCH_PROFILE_ERROR_V4',
      eventGroup: 'LOG_IN',
    },
  },
  [ROUTE_NAMES.FORGOT_PASSWORD]: {
    PASSWORD_RESET_COMPLETED_2022R4: {
      eventName: 'PASSWORD_RESET_COMPLETED_2022R4',
      eventGroup: 'FORGOT_PASSWORD',
    },

    OTP_RESENT_ERROR_2022R4: {
      eventName: 'OTP_RESENT_ERROR_2022R4',
      eventGroup: 'FORGOT_PASSWORD',
    },
    OTP_WRONG_ERROR_2022R4: { eventName: 'OTP_WRONG_ERROR_2022R4', eventGroup: 'FORGOT_PASSWORD' },
    OTP_VERIFICATION_INITIATED_2022R4: {
      eventName: 'OTP_VERIFICATION_INITIATED_2022R4',
      eventGroup: 'FORGOT_PASSWORD',
    },
    OTP_VERIFICATION_COMPLETED_2022R4: {
      eventName: 'OTP_VERIFICATION_COMPLETED_2022R4',
      eventGroup: 'FORGOT_PASSWORD',
    },

    RESEND_OTP_INITIATED_2022R4: {
      eventName: 'RESEND_OTP_INITIATED_2022R4',
      eventGroup: 'FORGOT_PASSWORD',
    },
    OTP_VERIFICATION_RESENT_2022R4: {
      eventName: 'OTP_VERIFICATION_RESENT_2022R4',
      eventGroup: 'FORGOT_PASSWORD',
    },
    OTP_RESENT_ERROR_2022R4: {
      eventName: 'OTP_RESENT_ERROR_2022R4',
      eventGroup: 'FORGOT_PASSWORD',
    },
  },

  [ROUTE_NAMES.HOME]: {
    HOME_ACCESS_RECORD_BTN_CLKD: {
      eventName: 'HOME_ACCESS_RECORD_BTN_CLKD',
      eventGroup: 'HOME_DASHBOARD',
    },
    SHOP_FOR_COVERAGE_BTN_CLKD: {
      eventName: 'SHOP_FOR_COVERAGE_BTN_CLKD',
      eventGroup: 'HOME_DASHBOARD',
    },
    REFER_FRIEND_BTN_CLKD: { eventName: 'REFER_FRIEND_BTN_CLKD', eventGroup: 'HOME_DASHBOARD' },

    ALERT_ICON_CLICKED: { eventName: 'ALERT_ICON_CLICKED', eventGroup: 'HOME_DASHBOARD' },
    CIRCLE_DASHBOARD_INITIATED: {
      eventName: 'CIRCLE_DASHBOARD_INITIATED',
      eventGroup: 'CIRCLES',
    },
    WALLET_ICON_CLICKED: { eventName: 'WALLET_ICON_CLICKED', eventGroup: 'HOME_DASHBOARD' },

    RECENTLY_VISITED_SERVICES: {
      eventName: 'RECENTLY_VISITED_SERVICES',
      paramName: 'recentServiceCategory',
      eventGroup: 'HOME_DASHBOARD',
    },

    ACCOUNT_DETAILS_CLICKED: {
      eventName: 'ACCOUNT_DETAILS_CLICKED',
      eventGroup: 'HOME_DASHBOARD',
    },
    FEEDBACK_ICON_CLICKED: { eventName: 'FEEDBACK_ICON_CLICKED', eventGroup: 'HOME_DASHBOARD' },
    SUPPORT_ICON_CLICKED: { eventName: 'SUPPORT_ICON_CLICKED', eventGroup: 'HOME_DASHBOARD' },
    PRIVACY_POLICY_CLICKED: { eventName: 'PRIVACY_POLICY_CLICKED', eventGroup: 'HOME_DASHBOARD' },
    TERM_AND_CONDITION_CLICKED: {
      eventName: 'TERM_AND_CONDITION_CLICKED',
      eventGroup: 'HOME_DASHBOARD',
    },
    LOGOUT_CLICKED: { eventName: 'LOGOUT_CLICKED', eventGroup: 'HOME_DASHBOARD' },

    HOME_BUTTON_CLICKED: { eventName: 'HOME_BUTTON_CLICKED', eventGroup: 'HOME_DASHBOARD' },
    SHOP_BUTTON_CLICKED: { eventName: 'SHOP_BUTTON_CLICKED', eventGroup: 'HOME_DASHBOARD' },
    CIRLCE_BUTTON_CLICKED: { eventName: 'CIRLCE_BUTTON_CLICKED', eventGroup: 'HOME_DASHBOARD' },
    RECORD_BUTTON_CLICKED: { eventName: 'RECORD_BUTTON_CLICKED', eventGroup: 'HOME_DASHBOARD' },
    UTILITIES_BUTTON_CLICKED: {
      eventName: 'UTILITIES_BUTTON_CLICKED',
      eventGroup: 'UTILITIES',
    },
    EMAIL_POPUP_CLOSED: { eventName: 'EMAIL_POPUP_CLOSED', eventGroup: '' },
    EMAIL_POPUP_SAVED: { eventName: 'EMAIL_POPUP_SAVED', eventGroup: '' },

    AUTOLOGIN_SUCCESS_AFTER_SIGNUP: {
      eventName: 'AUTOLOGIN_SUCCESS_AFTER_SIGNUP',
      eventGroup: 'HOME_DASHBOARD',
    },
  },
  [ROUTE_NAMES.SHOP]: {
    CMP_CATEGORY_INITIATED: {
      eventName: 'CMP_Category_Initiated',
      paramName: 'category',
      eventGroup: 'SHOP',
    },
  },
  [ROUTE_NAMES.MARKET_CATEGORY]: {
    CMP_PARTNER_INITIATED: {
      eventName: 'CMP_Partner_Initiated',
      paramName: 'partner',
      eventGroup: 'SHOP',
    },
  },
  [ROUTE_NAMES.REFER]: {
    REFERFRIEND_SHARE_LINK_CLICKED: {
      eventName: 'REFERFRIEND_SHARE_LINK_CLICKED',
      eventGroup: 'SHOP',
    },
    REFERFRIEND_SHARE_LINK_ERROR: { eventName: 'REFERFRIEND_SHARE_LINK_ERROR', eventGroup: 'SHOP' },
  },
  [ROUTE_NAMES.CIRCLES]: {
    CIR_ORG_DASHBOARD_VISITED: { eventName: 'CIR_ORG_DASHBOARD_VISITED', eventGroup: 'CIRCLES' },
    CIRCLE_DASHBOARD_INITIATION_ERROR: {
      eventName: 'CIRCLE_DASHBOARD_INITIATION_ERROR',
      eventGroup: 'CIRCLES',
    },
    CIRCLE_DASHBOARD_ORG_CARD_CLICKED: {
      eventName: 'CIRCLE_DASHBOARD_ORG_CARD_CLICKED',
      paramName: 'circle',
      eventGroup: 'CIRCLES',
    },
    ORGANIZATION_CLICKED_ON_DELETE: {
      eventName: 'ORGANIZATION_CLICKED_ON_DELETE',
      paramName: 'circle',
      eventGroup: 'CIRCLES',
    },
    ORGANIZATION_DELETE_CANCEL: {
      eventName: 'ORGANIZATION_DELETE_CANCEL',
      paramName: 'circle',
      eventGroup: 'CIRCLES',
    },
    ORGANIZATION_DELETE_CONFIRM: {
      eventName: 'ORGANIZATION_DELETE_CONFIRM',
      paramName: 'circle',
      eventGroup: 'CIRCLES',
    },
    CLICKED_ADD_IN_CIRCLE: { eventName: 'CLICKED_ADD_IN_CIRCLE', eventGroup: 'CIRCLES' },
    ADD_TO_CIRCLE_CANCELLED: { eventName: 'ADD_TO_CIRCLE_CANCELLED', eventGroup: 'CIRCLES' },
    EAM_PARTNER_INITIATED: {
      eventName: 'EAM_PARTNER_INITIATED',
      paramName: 'circle',
      eventGroup: 'CIRCLES',
    },
    EAM_PARTNER_COMPLETED: {
      eventName: 'EAM_PARTNER_COMPLETED',
      paramName: 'circle',
      eventGroup: 'CIRCLES',
    },
    EAM_PARTNER_ERROR: {
      eventName: 'EAM_PARTNER_ERROR',
      paramName: 'circle',
      eventGroup: 'CIRCLES',
    },
    SEARCHED_ORG_NAME: { eventName: 'SEARCHED_ORG_NAME', paramName: 'name', eventGroup: 'CIRCLES' },
    CLICKED_ADD_TO_CIRCLE_ORG: { eventName: 'CLICKED_ADD_TO_CIRCLE_ORG', eventGroup: 'CIRCLES' },
    CLICKED_ADD_TO_CIRCLE_INDV: { eventName: 'CLICKED_ADD_TO_CIRCLE_INDV', eventGroup: 'CIRCLES' },
    INDV_MINOR_CLICKED: { eventName: 'INDV_MINOR_CLICKED', eventGroup: 'ADD_AN_INDIVIDUAL' },
    INDV_MAJOR_CLICKED: { eventName: 'INDV_MAJOR_CLICKED', eventGroup: 'ADD_AN_INDIVIDUAL' },
    INDV_LG_YES: { eventName: 'INDV_LG_YES', eventGroup: 'ADD_AN_INDIVIDUAL' },
    INDV_LG_NO: { eventName: 'INDV_LG_NO', eventGroup: 'ADD_AN_INDIVIDUAL' },
    INDV_HPA_YES: { eventName: 'INDV_HPA_YES', eventGroup: 'ADD_AN_INDIVIDUAL' },
    INDV_HPA_NO: { eventName: 'INDV_HPA_NO', eventGroup: 'ADD_AN_INDIVIDUAL' },
    SEARCH_ORG_SCREEN_CLOSE: { eventName: 'SEARCH_ORG_SCREEN_CLOSE', eventGroup: 'CIRCLES' },
    REPORT_HERE_CLICK: { eventName: 'REPORT_HERE_CLICK', eventGroup: 'CIRCLES' },
    SELECT_ORG_VISITED: { eventName: 'SELECT_ORG_VISITED', eventGroup: 'CIRCLES' },

    CIR_ORG_TAB_VISITED: { eventName: 'CIR_ORG_TAB_VISITED', eventGroup: 'CIRCLES' },
    CIR_IND_TAB_VISITED: { eventName: 'CIR_IND_TAB_VISITED', eventGroup: 'CIRCLES' },

    CIR_MEDICAL_TAB_VISITED: { eventName: 'CIR_MEDICAL_TAB_VISITED', eventGroup: 'CIRCLES' },

    CIR_CARE_TAB_VISITED: { eventName: 'CIR_CARE_TAB_VISITED', eventGroup: 'CIRCLES' },

    CIR_FINANCIAL_TAB_VISITED: { eventName: 'CIR_FINANCIAL_TAB_VISITED', eventGroup: 'CIRCLES' },

    CIR_FIND_CARE_CLICKED: { eventName: 'CIR_FIND_CARE_CLICKED', eventGroup: 'CIRCLES' },
    CIR_FIND_CARE_VISITED: { eventName: 'CIR_FIND_CARE_VISITED', eventGroup: 'CIRCLES' },
    CIR_FIND_CARE_ERROR: { eventName: 'CIR_FIND_CARE_ERROR', eventGroup: 'CIRCLES' },

    CIR_CONDITIONS_CLICKED: { eventName: 'CIR_CONDITIONS_CLICKED', eventGroup: 'CIRCLES' },
    CIR_PROCEDURES_CLICKED: { eventName: 'CIR_PROCEDURES_CLICKED', eventGroup: 'CIRCLES' },
    CIR_LAB_RESULTS_CLICKED: { eventName: 'CIR_LAB_RESULTS_CLICKED', eventGroup: 'CIRCLES' },
    CIR_MEDICATIONS_CLICKED: { eventName: 'CIR_MEDICATIONS_CLICKED', eventGroup: 'CIRCLES' },
    CIR_ALLERGIES_CLICKED: { eventName: 'CIR_ALLERGIES_CLICKED', eventGroup: 'CIRCLES' },
    CIR_IMMUNIZATIONS_CLICKED: { eventName: 'CIR_IMMUNIZATIONS_CLICKED', eventGroup: 'CIRCLES' },
    CIR_VITALS_CLICKED: { eventName: 'CIR_VITALS_CLICKED', eventGroup: 'CIRCLES' },
    CIR_VISITS_CLICKED: { eventName: 'CIR_VISITS_CLICKED', eventGroup: 'CIRCLES' },
    CIR_FAMILY_HISTORY_CLICKED: { eventName: 'CIR_FAMILY_HISTORY_CLICKED', eventGroup: 'CIRCLES' },
    CIR_SOCIAL_HISTORY_CLICKED: { eventName: 'CIR_SOCIAL_HISTORY_CLICKED', eventGroup: 'CIRCLES' },

    CIR_COVERAGE_CLICKED: { eventName: 'CIR_COVERAGE_CLICKED', eventGroup: 'CIRCLES' },
    CIR_CLAIMS_CLICKED: { eventName: 'CIR_CLAIMS_CLICKED', eventGroup: 'CIRCLES' },
    CIR_BILLS_CLICKED: { eventName: 'CIR_BILLS_CLICKED', eventGroup: 'CIRCLES' },

    CIR_CARE_PLANS_CLICKED: { eventName: 'CIR_CARE_PLANS_CLICKED', eventGroup: 'CIRCLES' },
    CIR_CARE_TEAMS_CLICKED: { eventName: 'CIR_CARE_TEAMS_CLICKED', eventGroup: 'CIRCLES' },

    ADD_ORG_PROFILE_UPD_YES: { eventName: 'ADD_ORG_PROFILE_UPD_YES', eventGroup: 'CIRCLES' },
    ADD_ORG_PROFILE_UPD_NO: { eventName: 'ADD_ORG_PROFILE_UPD_NO', eventGroup: 'CIRCLES' },

    SEARCH_ORG_FOUND: { eventName: 'SEARCH_ORG_FOUND', eventGroup: 'CIRCLES' },
    SEARCH_ORG_FOUND_SELECTED: { eventName: 'SEARCH_ORG_FOUND_SELECTED', eventGroup: 'CIRCLES' },
    SEARCH_ORG_FOUND_CLOSE: { eventName: 'SEARCH_ORG_FOUND_CLOSE', eventGroup: 'CIRCLES' },
    SEARCH_ORG_NOT_FOUND_REPORTHERE: {
      eventName: 'SEARCH_ORG_NOT_FOUND_REPORTHERE',
      eventGroup: 'CIRCLES',
    },
    SEARCH_ORG_NOT_FOUND: { eventName: 'SEARCH_ORG_NOT_FOUND', eventGroup: 'CIRCLES' },
    INDV_ENTER_NAME_SAVE: { eventName: 'INDV_ENTER_NAME_SAVE', eventGroup: 'ADD_AN_INDIVIDUAL' },
    INDV_ENTER_NAME_CANCEL: {
      eventName: 'INDV_ENTER_NAME_CANCEL',
      eventGroup: 'ADD_AN_INDIVIDUAL',
    },
  },
  [ROUTE_NAMES.UTILITIES]: {
    FEEDBACK_UTILITY_CLICKED: { eventName: 'FEEDBACK_UTILITY_CLICKED', eventGroup: 'UTILITIES' },
    VISITED_PAYMENT_METHODS: { eventName: 'VISITED_PAYMENT_METHODS', eventGroup: 'UTILITIES' },
  },
  [ROUTE_NAMES.MANAGE_PAYMENT]: {
    ADD_PAYMENT_METHOD_BTN_CLKD: {
      eventName: 'ADD_PAYMENT_METHOD_BTN_CLKD',
      eventGroup: 'MANAGE_PAYMENT',
    },
  },
  [ROUTE_NAMES.ADD_CARD]: {
    SAVE_CARD_INITIATED: { eventName: 'SAVE_CARD_INITIATED', eventGroup: 'ADD_CARD' },
    SAVE_CARD_COMPLETED: { eventName: 'SAVE_CARD_COMPLETED', eventGroup: 'ADD_CARD' },
    SAVE_CARD_ERROR: { eventName: 'SAVE_CARD_ERROR', eventGroup: 'ADD_CARD' },
  },
  [ROUTE_NAMES.ADD_BANK_ACCOUNT]: {
    ADD_BANK_ACCOUNT_INITIATED: {
      eventName: 'ADD_BANK_ACCOUNT_INITIATED',
      eventGroup: 'ADD_BANK_ACCOUNT',
    },
    ADD_BANK_ACCOUNT_COMPLETED: {
      eventName: 'ADD_BANK_ACCOUNT_COMPLETED',
      eventGroup: 'ADD_BANK_ACCOUNT',
    },
    ADD_BANK_ACCOUNT_ERROR: { eventName: 'ADD_BANK_ACCOUNT_ERROR', eventGroup: 'ADD_BANK_ACCOUNT' },
  },
  [ROUTE_NAMES.RECORDS]: {
    USER_VISITED_COVERAGE_SUMMARY: {
      eventName: 'USER_VISITED_COVERAGE_SUMMARY',
      eventGroup: 'RECORDS',
    },

    REC_MEDICAL_TAB_VISITED: { eventName: 'REC_MEDICAL_TAB_VISITED', eventGroup: 'RECORDS' },
    REC_CARE_TAB_VISITED: { eventName: 'REC_CARE_TAB_VISITED', eventGroup: 'RECORDS' },
    REC_FINANCIAL_TAB_VISITED: { eventName: 'REC_FINANCIAL_TAB_VISITED', eventGroup: 'RECORDS' },
    REC_LEGAL_TAB_VISITED: { eventName: 'REC_LEGAL_TAB_VISITED', eventGroup: 'RECORDS' },
  },
  [ROUTE_NAMES.CONDITIONS]: {
    CIR_CONDITIONS_VISITED: { eventName: 'CIR_CONDITIONS_VISITED', eventGroup: 'CONDITIONS' },
    REC_CONDITIONS_VISITED: { eventName: 'REC_CONDITIONS_VISITED', eventGroup: 'CONDITIONS' },
  },
  [ROUTE_NAMES.DOCUMENTS]: {
    CIR_DOCUMENTS_VISITED: { eventName: 'CIR_DOCUMENTS_VISITED', eventGroup: 'DOCUMENTS' },
    REC_DOCUMENTS_VISITED: { eventName: 'REC_DOCUMENTS_VISITED', eventGroup: 'DOCUMENTS' },
  },
  [ROUTE_NAMES.APPOINTMENTS]: {
    CIR_APPOINTMENTS_VISITED: { eventName: 'CIR_APPOINTMENTS_VISITED', eventGroup: 'APPOINTMENTS' },
    REC_APPOINTMENTS_VISITED: { eventName: 'REC_APPOINTMENTS_VISITED', eventGroup: 'APPOINTMENTS' },
  },
  [ROUTE_NAMES.PRIOR_AUTHORIZATION]: {
    CIR_PRIOR_AUTHORIZATION_VISITED: { eventName: 'CIR_PRIOR_AUTHORIZATION_VISITED', eventGroup: 'PRIOR_AUTHORIZATION' },
    REC_PRIOR_AUTHORIZATION_VISITED: { eventName: 'REC_PRIOR_AUTHORIZATION_VISITED', eventGroup: 'PRIOR_AUTHORIZATION' },
  },
  [ROUTE_NAMES.PROCEDURES]: {
    CIR_PROCEDURES_VISITED: { eventName: 'CIR_PROCEDURES_VISITED', eventGroup: 'PROCEDURES' },
    REC_PROCEDURES_VISITED: { eventName: 'REC_PROCEDURES_VISITED', eventGroup: 'PROCEDURES' },
  },
  [ROUTE_NAMES.LAB_RESULTS]: {
    CIR_LAB_RESULTS_VISITED: { eventName: 'CIR_LAB_RESULTS_VISITED', eventGroup: 'LAB_RESULTS' },
    REC_LAB_RESULTS_VISITED: { eventName: 'REC_LAB_RESULTS_VISITED', eventGroup: 'LAB_RESULTS' },
  },
  [ROUTE_NAMES.MEDICATIONS]: {
    CIR_MEDICATIONS_VISITED: { eventName: 'CIR_MEDICATIONS_VISITED', eventGroup: 'MEDICATIONS' },
    REC_MEDICATIONS_VISITED: { eventName: 'REC_MEDICATIONS_VISITED', eventGroup: 'MEDICATIONS' },
  },
  [ROUTE_NAMES.ALLERGIES]: {
    CIR_ALLERGIES_VISITED: { eventName: 'CIR_ALLERGIES_VISITED', eventGroup: 'ALLERGIES' },
    REC_ALLERGIES_VISITED: { eventName: 'REC_ALLERGIES_VISITED', eventGroup: 'ALLERGIES' },
  },
  [ROUTE_NAMES.CONSENTS]: {
    RECORDS_SHARE_RECORDS_CLICKED: {
      eventGroup: 'SHARE_RECORDS',
      eventName: 'RECORDS_SHARE_RECORDS_CLICKED',
    },
  },
  [ROUTE_NAMES.IMMUNIZATIONS]: {
    CIR_IMMUNIZATIONS_VISITED: {
      eventName: 'CIR_IMMUNIZATIONS_VISITED',
      eventGroup: 'IMMUNIZATIONS',
    },
    REC_IMMUNIZATIONS_VISITED: {
      eventName: 'REC_IMMUNIZATIONS_VISITED',
      eventGroup: 'IMMUNIZATIONS',
    },
  },
  [ROUTE_NAMES.VITALS]: {
    CIR_VITALS_VISITED: { eventName: 'CIR_VITALS_VISITED', eventGroup: 'VITALS' },
    REC_VITALS_VISITED: { eventName: 'REC_VITALS_VISITED', eventGroup: 'VITALS' },
  },
  [ROUTE_NAMES.VISITS]: {
    CIR_VISITS_VISITED: { eventName: 'CIR_VISITS_VISITED', eventGroup: 'VISITS' },
    REC_VISITS_VISITED: { eventName: 'REC_VISITS_VISITED', eventGroup: 'VISITS' },
  },
  [ROUTE_NAMES.SOCIAL_HISTORY]: {
    CIR_SOCIAL_HISTORY_VISITED: {
      eventName: 'CIR_SOCIAL_HISTORY_VISITED',
      eventGroup: 'SOCIAL_HISTORY',
    },
    REC_SOCIAL_HISTORY_VISITED: {
      eventName: 'REC_SOCIAL_HISTORY_VISITED',
      eventGroup: 'SOCIAL_HISTORY',
    },
  },
  [ROUTE_NAMES.FAMILY_HISTORY]: {
    CIR_FAMILY_HISTORY_VISITED: {
      eventName: 'CIR_FAMILY_HISTORY_VISITED',
      eventGroup: 'FAMILY_HISTORY',
    },
    REC_FAMILY_HISTORY_VISITED: {
      eventName: 'REC_FAMILY_HISTORY_VISITED',
      eventGroup: 'FAMILY_HISTORY',
    },
  },
  [ROUTE_NAMES.COVERAGE]: {
    COVERAGE_EHEALTH_SELECTED: {
      eventName: 'COVERAGE_EHEALTH_SELECTED',
      eventGroup: 'COVERAGE',
    },
    SELECTED_MEDICARE_PLAN: { eventName: 'SELECTED_MEDICARE_PLAN', eventGroup: 'COVERAGE' },
    SELECTED_INDIVIDUAL_FAMILY_PLAN: {
      eventName: 'SELECTED_INDIVIDUAL_FAMILY_PLAN',
      eventGroup: 'COVERAGE',
    },
    CIR_COVERAGE_VISITED: { eventName: 'CIR_COVERAGE_VISITED', eventGroup: 'COVERAGE' },
    REC_COVERAGE_VISITED: { eventName: 'REC_COVERAGE_VISITED', eventGroup: 'COVERAGE' },
  },
  [ROUTE_NAMES.CLAIMS]: {
    CIR_CLAIMS_VISITED: { eventName: 'CIR_CLAIMS_VISITED', eventGroup: 'CLAIMS' },
    REC_CLAIMS_VISITED: { eventName: 'REC_CLAIMS_VISITED', eventGroup: 'CLAIMS' },
  },
  [ROUTE_NAMES.BILLS]: {
    CIR_BILLS_VISITED: { eventName: 'CIR_BILLS_VISITED', eventGroup: 'BILLS' },
    REC_BILLS_VISITED: { eventName: 'REC_BILLS_VISITED', eventGroup: 'BILLS' },
  },
  [ROUTE_NAMES.SUPPORT_HISTORY]: {
    CIR_SUPPORT_HISTORY_VISITED: { eventName: 'CIR_SUPPORT_HISTORY_VISITED', eventGroup: 'SUPPORT_HISTORY' },
    REC_SUPPORT_HISTORY_VISITED: { eventName: 'REC_SUPPORT_HISTORY_VISITED', eventGroup: 'SUPPORT_HISTORY' },
  },
  [ROUTE_NAMES.CARE_PLANS]: {
    CIR_CARE_PLANS_VISITED: { eventName: 'CIR_CARE_PLANS_VISITED', eventGroup: 'CARE_PLANS' },
    REC_CARE_PLANS_VISITED: { eventName: 'REC_CARE_PLANS_VISITED', eventGroup: 'CARE_PLANS' },
  },
  [ROUTE_NAMES.CARE_TEAMS]: {
    CIR_CARE_TEAMS_VISITED: { eventName: 'CIR_CARE_TEAMS_VISITED', eventGroup: 'CARE_TEAM' },
    REC_CARE_TEAMS_VISITED: { eventName: 'REC_CARE_TEAMS_VISITED', eventGroup: 'CARE_TEAM' },
  },
  [ROUTE_NAMES.CONSENT_RECEIVE]: {
    REC_CONSENT_RECEIVE_VISITED: {
      eventName: 'REC_CONSENT_RECEIVE_VISITED',
      eventGroup: 'CONSENT_RECEIVE',
    },
  },
  [ROUTE_NAMES.CONSENT_GIVEN]: {
    REC_CONSENT_GIVEN_VISITED: {
      eventName: 'REC_CONSENT_GIVEN_VISITED',
      eventGroup: 'CONSENT_GIVEN',
    },
  },

  [ROUTE_NAMES.ACCOUNT_DETAILS]: {
    EDIT_EMAIL_INITIATED: { eventName: 'EDIT_EMAIL_INITIATED', eventGroup: 'ACCOUNT_DETAILS' },
    EDIT_EMAIL_COMPLETED: { eventName: 'EDIT_EMAIL_COMPLETED', eventGroup: 'ACCOUNT_DETAILS' },
    EDIT_EMAIL_ERROR: { eventName: 'EDIT_EMAIL_ERROR', eventGroup: 'ACCOUNT_DETAILS' },
    EDIT_EMAIL_EXIST_ERROR: { eventName: 'EDIT_EMAIL_EXIST_ERROR', eventGroup: 'ACCOUNT_DETAILS' },

    EDIT_PHONE_NUMBER_INITIATED: {
      eventName: 'EDIT_PHONE_NUMBER_INITIATED',
      eventGroup: 'ACCOUNT_DETAILS',
    },
    EDIT_PHONE_NUMBER_COMPLETED: {
      eventName: 'EDIT_PHONE_NUMBER_COMPLETED',
      eventGroup: 'ACCOUNT_DETAILS',
    },
    EDIT_PHONE_NUMBER_ERROR: {
      eventName: 'EDIT_PHONE_NUMBER_ERROR',
      eventGroup: 'ACCOUNT_DETAILS',
    },
    EDIT_PHONENO_STORED_ERROR: {
      eventName: 'EDIT_PHONENO_STORED_ERROR',
      eventGroup: 'ACCOUNT_DETAILS',
    },

    EDIT_DATE_INITIATED: { eventName: 'EDIT_DATE_INITIATED', eventGroup: 'ACCOUNT_DETAILS' },
    EDIT_DATE_COMPLETED: { eventName: 'EDIT_DATE_COMPLETED', eventGroup: 'ACCOUNT_DETAILS' },

    EDIT_GENDER_COMPLETED: { eventName: 'EDIT_GENDER_COMPLETED', eventGroup: 'ACCOUNT_DETAILS' },

    EDIT_ADDRESS_INITIATED: { eventName: 'EDIT_ADDRESS_INITIATED', eventGroup: 'ACCOUNT_DETAILS' },
    EDIT_ADDRESS_COMPLETED: { eventName: 'EDIT_ADDRESS_COMPLETED', eventGroup: 'ACCOUNT_DETAILS' },
    EDIT_ADDRESS_ERROR: { eventName: 'EDIT_ADDRESS_ERROR', eventGroup: 'ACCOUNT_DETAILS' },
    EDIT_ADDRESS_STORED_ERROR: {
      eventName: 'EDIT_ADDRESS_STORED_ERROR',
      eventGroup: 'ACCOUNT_DETAILS',
    },
  },

  [ROUTE_NAMES.PHONE_VERIFICATION]: {
    FETCH_CODE_DETAILS_INITIATED: {
      eventName: 'FETCH_CODE_DETAILS_INITIATED',
      eventGroup: 'PHONE_VERIFICATION',
    },
    FETCH_CODE_DETAILS_COMPLETED: {
      eventName: 'FETCH_CODE_DETAILS_COMPLETED',
      eventGroup: 'PHONE_VERIFICATION',
    },
    FETCH_CODE_DETAILS_ERROR: {
      eventName: 'FETCH_CODE_DETAILS_ERROR',
      eventGroup: 'PHONE_VERIFICATION',
    },
  },
  [ROUTE_NAMES.LANDING_PAGE_CAROUSEL]: {
    LANDING1_HEALTHCARE_V4: {
      eventName: 'LANDING1_HEALTHCARE_V4',
      eventGroup: 'LANDING_SCREEN',
    },
    LANDING2_GET_RECORDS_V4: {
      eventName: 'LANDING2_GET_RECORDS_V4',
      eventGroup: 'LANDING_SCREEN',
    },
    LANDING3_SHARE_RECORDS_V4: {
      eventName: 'LANDING3_SHARE_RECORDS_V4',
      eventGroup: 'LANDING_SCREEN',
    },
    LANDING4_SHOP_V4: { eventName: 'LANDING4_SHOP_V4', eventGroup: 'LANDING_SCREEN' },
  },
  [ROUTE_NAMES.CLINICAL_VITALS]: {
    eventName: '',
    eventGroup: '',
  },
  [ROUTE_NAMES.BILLS]: {
    eventName: '',
    eventGroup: '',
  },
  [ROUTE_NAMES.ASSESSMENTS]: {
    eventName: '',
    eventGroup: '',
  },
  [ROUTE_NAMES.PROFILE_NAME]: {
    INDV_ENTER_NAME_SAVE: { eventName: 'INDV_ENTER_NAME_SAVE', eventGroup: [] },
    INDV_ENTER_NAME_CANCEL: { eventName: 'INDV_ENTER_NAME_CANCEL', eventGroup: [] },
  },
};
