import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { useStyles } from './style';
import { AWARDPOINTS } from '../../../utils/constants';
import mpoweredCoin from '../../../assets/images/mpoweredCoinWithBG.svg';
import clsx from 'clsx';
import { mockTemplates } from '../../../features/dynamicRecords/mockTemplate';
import { useSelector } from 'react-redux';

const TotalAwardPointsCard = ({
  totalAwardPoints,
  pointsHistory,
  showTransactionButton,
  awardPointInDollar = '',
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { templates } = useSelector((state) => state.templates);
  const { selectedLanguage } = useSelector((state) => state.app);

  const AWARDPOINTS =
    // templates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.AWARDPOINTS||
    mockTemplates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.AWARDPOINTS;

  return (
    <Card className={clsx([classes.card])}>
      <CardContent className={classes.cardContent}>
        <div className={classes.imgSection}>
          <img src={mpoweredCoin} alt="awardpoints" className={classes.imgStyle} />
          <div className={classes.awardPointsSection}>
            <Typography className={classes.totalAwardPoints}>
              {AWARDPOINTS.TOTAL_AWARD_POINTS}
            </Typography>
            <Typography className={classes.awardPoints}>
              {totalAwardPoints}
              {awardPointInDollar && (
                <span className={classes.awardPointInDollar}>{awardPointInDollar}</span>
              )}
            </Typography>
          </div>
        </div>
        {showTransactionButton && (
          <Button
            variant="outlined"
            className={classes.btn}
            disabled={pointsHistory && pointsHistory.length === 0}
            onClick={() => navigate('/awardpoints/transactionDetails')}
          >
            {AWARDPOINTS.TRANSACTION_HISTORY}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

TotalAwardPointsCard.propTypes = {
  totalAwardPoints: PropTypes.number,
  showTransactionButton: PropTypes.bool,
};
TotalAwardPointsCard.defaultProps = { showTransactionButton: false };
export default TotalAwardPointsCard;
