import React from 'react';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: '6%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const PageTemplate = (props) => {
  const { classes, children } = props;
  return (
    <div id="page-container" className={classes.root}>
      {children}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(PageTemplate);
