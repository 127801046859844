import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import Proptypes from 'prop-types';
import { EMPTY_FUNCTION } from '../../../utils/constants';
import clsx from 'clsx';

const styles = (theme) => ({
  labelOvr: {
    ...theme.customComponent.body2RegularBlack,
    color: 'inherit',
  },
  root: {},
});

const propTypes = {
  /**
   * Callback called on button click
   */
  onClick: Proptypes.func,
  /**
   * Size of the button.
   */
  size: Proptypes.oneOf(['small', 'medium', 'large']),
  /**
   * Can be simple text or MUI's Typography element. This is passed as the label of the button
   */
  text: Proptypes.oneOfType([Proptypes.string, Proptypes.oneOf(['Typography'])]),
  /**
   * Props to be forwarded to the button's label
   */
};

const defaultProps = {
  onClick: EMPTY_FUNCTION,
  text: '',
  TextProps: {},
  size: 'medium',
};

export const TextButton = (props) => {
  const { classes, className, onClick, size, style, text, TextProps, ...rest } = props;

  const combinedLabelClasses = clsx(classes.labelOvr, TextProps.className);

  return (
    <MuiButton
      style={{ ...style }}
      classes={{
        label: combinedLabelClasses,
      }}
      className={className}
      disableRipple
      disableFocusRipple
      onClick={onClick}
      {...rest}
    >
      {text}
    </MuiButton>
  );
};

TextButton.propTypes = propTypes;
TextButton.defaultProps = defaultProps;

export default withStyles(styles, { withTheme: true })(TextButton);
