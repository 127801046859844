import {
  // appointments,
  APPOINTMENT_SCHEDULE_DETAILS,
  dateParse_appointment,
} from '../../../../utils/helper';
import React, { useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { AppointmentDateTimeSummary } from './AppointmentDateTimeSummary';
import { AppointmentMedicalHistory } from './AppointmentMedicalHistory';
import { AppointmentPaymentDetails } from './AppointmentPaymentDetails';
import { AppointmentProviderInfo } from './AppointmentProviderInfo';
import { AppointmentVisitDetailsSummary } from './AppointmentVisitDetailsSummary';
import { AppointmentVisitStatus } from './AppointmentVisitStatus';
import BtnComponent from './BtnComponent';
import { DrawerBoxComponent } from './DrawerBoxComponent';
import SecondaryBtnComponent from './SecondaryBtnComponent';
import { AppointmentVisitLink } from './AppointmentVisitLink';
import ConfirmationModal from './ConfirmationModal';
import { BottomDrawerComponent } from './BottomDrawerComponent';
import { AppointmentReshedule } from './AppointmentReschedule';
import { useEffect } from 'react';

const AppointmentDetails = ({ setShowPage, appointmentStatus, setShowModal }) => {
  const handleRedirection = () => {
    setShowPage(false);
  };
  const handledirection = () => {
    setShowModal(false);
  };

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <AppointmentProviderInfo />
        </Grid>
        <Grid item>
          <AppointmentVisitDetailsSummary />
        </Grid>
        <Grid item>
          <AppointmentDateTimeSummary />
        </Grid>
        {appointmentStatus === 'Cancelled' ? (
          <Grid item>
            <AppointmentVisitStatus />
          </Grid>
        ) : (
          <Grid item>
            <AppointmentVisitLink />
          </Grid>
        )}
        <Grid item>
          <AppointmentMedicalHistory />
        </Grid>
        <Grid item>
          <AppointmentPaymentDetails />
          <Grid item>
            <DrawerBoxComponent sx={{ bgcolor: 'inherit', pl: 0 }}>
              {appointmentStatus !== 'Cancelled' ? (
                <Grid container spacing={2}>
                  <Grid item>
                    <SecondaryBtnComponent
                      title="Cancel Visit"
                      disabled={true}
                      onClick={handledirection}
                    />
                  </Grid>
                  <Grid item>
                    <BtnComponent
                      title="Reschedule Visit"
                      onClick={handleRedirection}
                      disabled={false}
                    />
                  </Grid>
                </Grid>
              ) : (
                <BtnComponent title="Refund details" disabled={true} />
              )}
            </DrawerBoxComponent>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const AppointmentDetailsPage = ({ setShowPage, getFinalPopUpData, handleClickOpen }) => {
  React.useEffect(() => {
    return () => {
      setShowPage(true);
    };
  }, [setShowPage]);

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <AppointmentProviderInfo />
        </Grid>
        <Grid item>
          <AppointmentVisitDetailsSummary />
        </Grid>
        <Grid item>
          <AppointmentMedicalHistory />
        </Grid>
        <Grid item>
          <AppointmentReshedule
            getFinalPopUpData={getFinalPopUpData}
            handleClickOpen={handleClickOpen}
          />
        </Grid>
        <Grid item>
          <DrawerBoxComponent sx={{ bgcolor: 'inherit', pl: 1 }}>
            <Grid container spacing={2}>
              <Grid item>
                <SecondaryBtnComponent
                  title="Cancel Visit"
                  name="Cancel"
                  disabled={false}
                  onClick={handleClickOpen}
                />
              </Grid>
              <Grid item>
                <BtnComponent
                  title="Reschedule Visit"
                  name="Success"
                  onClick={handleClickOpen}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </DrawerBoxComponent>
        </Grid>
      </Grid>
    </>
  );
};

export const Appointments = ({ setDrawerOpen, drawerOpen }) => {
  const [showpage, setShowPage] = useState(true);
  const [isOpen, setIsOpen] = useState({
    successModalOpen: false,
    cancelModalOpen: false,
  });
  const [popUpData, setPopUpData] = useState({});

  const detailsDate =
    dateParse_appointment(popUpData?.date ?? '') +
    ',' +
    ' ' +
    popUpData?.time +
    popUpData?.timeFormat;

  const handleClickOpen = (e) => {
    if (e.target.name === 'Success' && popUpData.date && popUpData.startTime) {
      setIsOpen({
        successModalOpen: true,
        cancelModalOpen: false,
      });
      setDrawerOpen(false);
    } else if (e.target.name === 'Cancel') {
      setIsOpen({
        successModalOpen: false,
        cancelModalOpen: true,
      });
      setDrawerOpen(false);
    } else {
      setIsOpen({
        successModalOpen: false,
        cancelModalOpen: false,
      });
    }
  };

  const viewDetailsClick = () => {
    setDrawerOpen(true);
    setIsOpen({
      successModalOpen: false,
      cancelModalOpen: false,
    });
    setShowPage(true);
  };

  const handleCloseAppointment = () => {
    setIsOpen({
      successModalOpen: false,
      cancelModalOpen: false,
    });
  };

  useEffect(() => {
    return () => {
      setShowPage(false);
    };
  }, []);

  const getFinalPopUpData = (dateAndTime) => {
    setPopUpData({
      startTime: dateAndTime?.startTime,
      endTime: dateAndTime?.endTime,
      date: `${dateAndTime.date}T${dateAndTime?.startTime?.slice(0, 8)}Z`,
      slotId: dateAndTime.slotId,
      //   appointmentId: selectedTableId,
    });
  };
  return (
    <>
      <BottomDrawerComponent
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        setShowPage={setShowPage}
      >
        {showpage ? (
          <AppointmentDetails setShowPage={setShowPage} />
        ) : (
          <AppointmentDetailsPage
            handleClickOpen={handleClickOpen}
            getFinalPopUpData={getFinalPopUpData}
            setShowPage={setShowPage}
          />
        )}
      </BottomDrawerComponent>
      <ConfirmationModal
        success
        open={isOpen.successModalOpen}
        onClose={handleCloseAppointment}
        heading={APPOINTMENT_SCHEDULE_DETAILS.HEADING}
        detailsDate={detailsDate}
        viewDetailsClick={viewDetailsClick}
        setShowPage={setShowPage}
      />
      <ConfirmationModal
        cancel
        open={isOpen.cancelModalOpen}
        onClose={handleCloseAppointment}
        heading={APPOINTMENT_SCHEDULE_DETAILS.CANCEL}
        detailsDate={detailsDate}
        title={APPOINTMENT_SCHEDULE_DETAILS.QUESTION}
        description={APPOINTMENT_SCHEDULE_DETAILS.DESCRIPTION}
        setShowPage={setShowPage}
      />
    </>
  );
};
