import { replaceImagePropertyWithObject } from './helper';
import BillPay from '../assets/images/bill_pay_alert.svg';
import SecondOpinion from '../assets/images/second_opinion_alert.svg';
import AwardPoints from '../assets/images/award_point_alert.svg';
import Rating from '../assets/images/star.svg';
import HealthKit from '../assets/images/Icon-Apple-Health-Small.png';
import Cms from '../assets/images/notepad-blue.svg';
import DefaultIcon from '../assets/images/notifications.svg';
import { ALERT_LINK } from './constants';

const ALERT_TYPES_ICON = [SecondOpinion, BillPay, AwardPoints, Rating, HealthKit, Cms, DefaultIcon];

const getAlertIcon = () => {
  return replaceImagePropertyWithObject(ALERT_LINK, ALERT_TYPES_ICON);
};

const alertIconsGetter = {
  getAlertIcon,
};

export default alertIconsGetter;
