import React from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import MemberSection from '../../../../../pages/CareTeam/MemberSection';
import AssociateCarePlan from '../../../../../pages/CareTeam/AssociateCarePlan';

const styles = (theme) => ({
  membersWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '29px',
  },
  associateWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
  },
  memberCardWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  associateCardWrapper: {
    padding: '16px',
    border: '1px solid #E4E7EC',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    width: '100%',
  },
  memberPictureWrapper: {
    fontFamily: 'Inter',
  },
  memberPicture: {
    height: '40px',
    width: '40px',
    borderRadius: '40px',
  },
  memberName: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    color: '#101828',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  specialty: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
    letterSpacing: '0px',
  },
  dot: {
    width: 4,
    height: 4,
    backgroundColor: '#667085',
    marginLeft: 8,
    marginRight: 8,
    borderRadius: '40px',
  },
  partnerName: {},
  memberNameWrapper: {
    marginLeft: '8px',
  },
  heading: {
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: '16px',
    color: '#101828',
    marginBottom: '16px',
  },

  associateCarePlan: {
    marginTop: '40px',
  },
  headingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  readMoreAndLess: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    color: '#036FCB',
    marginLeft: 4,
    cursor: 'pointer',
    transition: 'all 0.8s ease-in-out',
  },
  listItems: {
    fontFamily: 'Inter',
    lineHeight: '20px',
    fontWeight: 500,
    fontSize: '14px',
    color: '#344054',
    transition: 'all 0.8s ease-in-out',
  },
  associateLogo: {
    height: 24,
    width: 24,
  },
  contentSection: {
    marginTop: 16,
  },
  associateHeading: {
    marginLeft: 12,
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    color: '#101828',
    lineHeight: '24px',
  },
  associateSubHeading: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '14px',
    color: '#667085',
  },
  list: {
    padding: '0px 0px 0px 16px',
    marginTop: '8px',
    transition: 'height 4s ease-in-out',
    marginBottom: '0px',
  },
  assocImgWrapper: { display: 'flex', alignItems: 'center' },
  infoIcon: { width: 18, height: 16, marginRight: 4 },
  infoIconWrapper: { display: 'flex', letterSpacing: '0.3px', alignItems: 'center' },
  initialName: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: 600,
    color: '#FFF',
  },
  initialWrapper: {
    height: 40,
    width: 40,
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  careTeamDetailsContainer: { margin: '12px 0px 40px 0px' },
});

const CareTeamDetails = (props) => {
  const { classes, membersDetails, assoc_care_plan_Details, screenMode } = props;
  return (
    <div className={classes.careTeamDetailsContainer}>
      {membersDetails?.members?.length > 0 ? (
        <div>
          <Typography className={classes.heading}>{membersDetails.title}</Typography>
          <div className={classes.membersWrapper}>
            {membersDetails.members.map((item) => (
              <MemberSection key={item.id} item={item} classes={classes} />
            ))}
          </div>
        </div>
      ) : null}
      {assoc_care_plan_Details?.assoc_care_plan?.length > 0 ? (
        <div className={classes.associateCarePlan}>
          <Typography className={classes.heading}>{assoc_care_plan_Details.title}</Typography>
          <div className={classes.associateWrapper}>
            {assoc_care_plan_Details.assoc_care_plan.map((item) => (
              <AssociateCarePlan
                key={item.id}
                item={item}
                classes={classes}
                screenMode={screenMode}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(CareTeamDetails);
