import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ProgressBar from '../../components/molecules/ProgressBar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { HELP_PAGE_ITEMS, HELP_PAGE } from '../../utils/constants';
import { fireHanselEvent } from '../../utils/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 16px 0px 16px',
  },
  rootContainer: {
    marginBottom: '16px',
  },
  heading: {
    marginLeft: '29px',
    alignSelf: 'center',
  },
  headingContainer: {
    padding: '0 !important',
    '& .MuiAccordion-root': {
      boxShadow: 'none',
    },
  },
  expansionDetails: {
    flexDirection: 'column',
    borderTop: theme.customComponent.card.border,
    padding: '16px 0px 16px 0px !important',
    maxHeight: '40vh',
    overflow: 'auto',
  },
  detailBody: {
    marginTop: '16px',
    marginBottom: '16px',
    whiteSpace: 'pre-wrap',
    ...theme.customComponent.body1RegularLight,
  },
  panelRoot: {
    ...theme.customComponent.card,
    marginBottom: '16px',
    padding: '0px 16px 0px 19px',
    '& .MuiPaper-root': {
      boxShadow: 'unset',
    },
  },
}));

const Help = (props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    fireHanselEvent('MPH_HELP', {});
  }, []);

  useEffect(() => {
    const { onFooterItemClick } = props;
    if (onFooterItemClick) {
      onFooterItemClick(4);
    }
  }, []);
  const handleChange = (panelIdx) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIdx : false);
    if (isExpanded) {
      switch (event.target.innerText) {
        case 'Second Opinions':
          fireHanselEvent('MPH_HELP_SECONDOPINION', {});
          break;
        case 'Referral':
          fireHanselEvent('MPH_HELP_REFER', {});
          break;
        case 'Add coverage':
          fireHanselEvent('MPH_HELP_COVERAGE', {});
          break;
      }
    }
  };

  return (
    <div className={classes.root}>
      <ProgressBar heading={HELP_PAGE.HEADING} backButtonOnFull />
      {HELP_PAGE_ITEMS.map((helperItem, idx) => {
        const { BODY, ICON_SOURCE, TITLE } = helperItem;

        return (
          <div className={classes.rootContainer}>
            <Accordion
              className={classes.panelRoot}
              key={idx}
              expanded={expanded === idx}
              onChange={handleChange(idx)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon htmlColor="#adacb4" />}
                aria-controls={`panel${idx}a-content`}
                id={`panel${idx}a-header`}
                className={classes.headingContainer}
              >
                {/* {ICON_SOURCE && (  //scan FAQ's 
                  <img id={`${TITLE}-help-icon`} src={ICON_SOURCE} height={32} width={32} />
                )} */}
                {ICON_SOURCE && (
                  <img
                    id={`${TITLE}-help-icon`}
                    src={require(`../../assets/images/${ICON_SOURCE}`)}
                    height={32}
                    width={32}
                  />
                )}
                <Typography className={classes.heading}>{TITLE}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.expansionDetails}>
                {BODY &&
                  BODY.map((item, idx) => (
                    <React.Fragment key={idx}>
                      <Typography>{item.QUERY}</Typography>
                      <Typography className={classes.detailBody}>{item.SUGGESTION}</Typography>
                    </React.Fragment>
                  ))}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
};

export default Help;
