import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  // Button,
  Container,
  Typography,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import colors from '../../utils/colors';
import BreadCrumb from '../../components/molecules/BreadCrumb';
import {
  // AWARDPOINTS,
  // REFER_AND_EARN,
  BREADCRUMB_PROPS_REFERRAL,
  FIREBASE_EVENT_NAMES,
  SHARE_REFERRAL,
  // REFERRAL_STEPS,
} from '../../utils/constants';
import Loader from '../../components/atoms/Loader';
import gql from 'graphql-tag';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import LADY_IMAGE from '../../assets/images/REFER_AND_EARN_LADY_IMAGE.png';
import Referral from '../../service/Referral';
import { fireHanselEvent, getAnalyticsEventsForRouteName } from '../../utils/helper';
import { logUserEvent } from '../../utils/UserEvent';
import blueCircle from '../../assets/images/Referral/blueCircle.png';
import greenCross from '../../assets/images/Referral/greenCross.png';
import redCross from '../../assets/images/Referral/redCross.png';
import referralSnake from '../../assets/images/Referral/referralSnake.png';
import Button from '../../components/inputs/Button';
import withRouter from '../../components/withRoute';
import Drawer from '../../components/atoms/Drawer';
import { ROUTE_NAMES } from '../../constants/routeNames';
import useAnalyticsLogger from '../../hooks/useAnalyticsLogger';
import { UserInfoContext } from '../../components/Routes';
import useClickFooter from '../../hooks/useClickFooter';
import useProfile from '../../hooks/useProfile';
import { RWebShare } from 'react-web-share';
import ProfileService from '../../service/ProfileService';
import { mockTemplates } from '../../features/dynamicRecords/mockTemplate';
import { useSelector } from 'react-redux';

export const FETCH_REFERRAL_CODE = gql`
  query {
    fetchReferralCode {
      code
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.BG_COLOR,
    minHeight: 'calc( 100vh - 83px)',
    padding: theme.spacing(4),
    minWidth: '100%',
  },
  heading: {
    height: theme.spacing(5),
    lineHeight: '40px',
    fontFamily: 'Inter',
    fontSize: theme.spacing(3.5),
    fontWeight: 600,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  iconRoot: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  alternativeLabel: {
    marginTop: '0px !important',
    fontSize: 14,
    opacity: 1,
    paddingBottom: 16,
    paddingRight: 30,
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyItems: 'left',

    '&.MuiStepLabel-active': {
      color: colors.BLUE,
    },
  },
  cardSection: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  middleCard: {
    height: 280,
    display: 'flex',
    flexWrap: 'wrap',
  },
  middleCardFirst: {
    position: 'relative',
    flex: 1,
    overflow: 'hidden',
    borderRadius: 16,
  },
  stepperHeading: {
    fontWeight: 600,
    fontSize: 18,
    lineHight: '24px',
    color: '#101828',
    paddingLeft: 24,
  },
  card1: {
    backgroundColor: colors.WHITE,
    borderRadius: theme.spacing(1),
    border: 'none',
    height: '104px',
    minWidth: '400px',
  },
  inviteHeading: {
    paddingTop: 32,
    color: ' #101828',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 28,
    lineHeight: '40px',
  },
  cardContent1: {
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    height: '104px',
    padding: '16px 0 16px 16px',
  },
  rightSection: {
    position: 'relative',
    top: -theme.spacing(2),
    right: theme.spacing(0),
  },
  cardHeading1: {
    color: '#344054',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: theme.spacing(2.25),
    lineHeight: '24px',
  },
  referralCode: {
    paddingTop: 24,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: '20px',
    color: '#667085',
  },
  referralCodeStyle: {
    color: '#00A0DF',
    fontWeight: '600',
    fontSize: 28,
    lineHeight: '40px',
  },
  referralBtn: {
    marginTop: 32,
    borderRadius: 40,
    padding: '12px 14px',
    width: 348,
    height: 48,
    fontSize: 16,
    backgroundColor: ' #036FCB',
    '& .MuiButton-label': {
      height: 48,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '24px',
      color: '#FFFFFF',
    },
  },
  redCircle: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  cardText: {
    color: '#667085',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: theme.spacing(1.75),
    lineHeight: '20px',
    paddingTop: theme.spacing(1),
  },
  stepText: {
    color: '#344054',
    textAlign: 'left',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: '24px',
  },
  img1: {
    position: 'absolute',
    top: theme.spacing(5.5),
    left: theme.spacing(5.5),
  },
  svg: {
    position: 'absolute',
  },
  button: {
    position: 'absolute',
    right: 40,
    backgroundColor: '#FFFFFF',
    borderRadius: 40,
    color: '#036FCB',
    width: 189,
    height: 48,
    fontWeight: 500,
    fontSize: '16px',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  horizontal: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-100% + 30px)',
    right: 'calc(100% )',
    color: '#E4ECE7',
  },
  completed: {
    '& $line': {
      borderColor: '#E4ECE7',
      borderStyle: 'solid',
      color: '#E4ECE7',
    },
  },
  line: {
    textAlign: 'left',
    marginTop: 3,
    borderColor: '#E4ECE7',
    borderStyle: 'dashed',
    borderLeft: -1,
    color: '#E4ECE7',
    borderBottom: 0,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#036FCB',
    zIndex: 1,
    color: '#FFFFFF',
    width: 28,
    height: 28,
    display: 'flex',
    borderRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 16,
  },

  active: {
    background: '#036FCB',
    color: '#fff',
    borderRadius: 40,
  },
  completed: {
    background: '#036FCB',
    color: '#fff',
    borderRadius: 40,
  },
});
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: completed ? <Check /> : '1',
    2: completed ? <Check /> : '2',
    3: completed ? <Check /> : '3',
  };

  return (
    <div className={classes.tabActive}>
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        {icons[String(props.icon)]}
      </div>
    </div>
  );
}

const NewReferralScreen = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [referralCodeText, setReferralCodeText] = useState('');
  const [referaalURL, setReferralURL] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const analyticsEvents = getAnalyticsEventsForRouteName(ROUTE_NAMES.REFER);
  const { logAnalyticsEvent } = useAnalyticsLogger();
  const { userInfo } = useContext(UserInfoContext);
  const { updateUserFullNameIfNotExist, renderProfileUpdateModal } = useProfile();
  useClickFooter(props.onFooterItemClick);
  const { email, phone } = userInfo;

  const { templates } = useSelector((state) => state.templates);
  const { selectedLanguage } = useSelector((state) => state.app);

  const AWARDPOINTS =
    // templates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.AWARDPOINTS||
    mockTemplates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.AWARDPOINTS;

  const REFER_AND_EARN =
    // templates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.REFER_AND_EARN||
    mockTemplates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.REFER_AND_EARN;

  const REFERRAL_STEPS =
    // templates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.REFERRAL_STEPS||
    mockTemplates?.texts?.[selectedLanguage]?.UTITLITIES_SCREEN?.REFERRAL_STEPS;

  useEffect(() => {
    (!!email || !!phone) &&
      updateUserFullNameIfNotExist(
        () => getReferralCodeDetails(),
        () => navigate(-1),
      );
  }, [email, phone]);

  const handleDrawerClick = () => {
    setOpenDrawer(false);
  };

  const referrerName = `${userInfo.firstName} ${userInfo.lastName}`;

  const handleDrawerOpen = async (share_referral_code = '') => {
    if (share_referral_code === 'share_referral_code') {
      logAnalyticsEvent(analyticsEvents.REFERFRIEND_SHARE_LINK_CLICKED);
    }
    logUserEvent(FIREBASE_EVENT_NAMES.REFER_A_FRIEND);

    fireHanselEvent('Click_Share_Referral', {});
    //storing referral code
    await Referral.storeReferralInitiatorRequest(referralCodeText);
    console.log(
      'referral link',
      `${process.env.REFERRAL_DEEP_LINK}?referralCode=${referralCodeText}`,
    );
    if (navigator.share) {
      navigator
        .share({
          title: SHARE_REFERRAL.TITLE,
          text: SHARE_REFERRAL.CONTENT(referrerName, referralCodeText),
          url: `${process.env.REFERRAL_DEEP_LINK}?referralCode=${referralCodeText}`,
        })
        .then(() => {})
        .catch((err) => {
          if (share_referral_code === 'share_referral_code') {
            logAnalyticsEvent(analyticsEvents.REFERFRIEND_SHARE_LINK_ERROR);
          }
          console.log('Error sharing', err);
        });
    } else {
      setOpenDrawer(!openDrawer);
    }
  };

  const getReferralCodeDetails = async () => {
    setIsLoading(true);

    try {
      let refCode = null;
      let response = [];
      try {
        response = await Referral.fetchReferralCode(FETCH_REFERRAL_CODE);
      } catch (err) {
        console.error(err);
      }

      if (response?.length > 0) {
        refCode = response[response.length - 1]?.code;
      } else {
        const userId = localStorage.getItem('userId');
        response = await ProfileService.generateReferralCode({
          userFirstName: userInfo.firstName,
          userLastName: userInfo.lastName,
          userId,
        });

        if (response?.code) {
          refCode = response?.code;
        }
      }

      setReferralURL(`${window.location.origin}/signup?referralCode=${refCode}`);
      setReferralCodeText(refCode);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      fireHanselEvent('MPH_REFERFRIEND', {});
    }
  };
  if (isLoading) {
    return <Loader overlay="true" text="Loading.." />;
  }
  const SvgCircle = (props) => {
    return (
      <svg width={props.width} height={props.height} fill={props.color}>
        <circle r={props.r} cx={props.cx} cy={props.cy} />
      </svg>
    );
  };

  return (
    <Container className={classes.root}>
      <BreadCrumb breadCrumbProps={BREADCRUMB_PROPS_REFERRAL}></BreadCrumb>
      <Typography className={classes.heading}>{REFER_AND_EARN.HEADING}</Typography>

      <Grid container spacing={3}>
        <Grid item sm={12} md={12} style={{ position: 'relative', paddingBottom: 60 }}>
          <Box sx={{ position: 'absolute', right: 40 }}>
            <Button
              variant="borderedWhite"
              onClick={() => navigate('/awardpoints/transactionDetails')}
            >
              {REFER_AND_EARN.AWARDPOINT_BUTTON_HEADING}
            </Button>
          </Box>

          {/* <Button
            variant="outlined"
            className={classes.button}
            onClick={() => history.push('awardpoints/transactionDetails')}
          >
            {REFER_AND_EARN.AWARDPOINT_BUTTON_HEADING}
          </Button> */}
        </Grid>
        <Grid item sm={12} md={12}>
          <div
            style={{
              backgroundColor: '#FFFFFF',
              borderRadius: 16,
            }}
          >
            <Grid container className={classes.middleCard} spacing={0}>
              <Grid lg={4} sm={4} md={4} item className={classes.middleCardFirst}>
                <Box style={{ position: 'absolute' }}>
                  <SvgCircle width="258" height="278" r="144" cx="100" cy="194" color={'#00A0DF'} />
                </Box>
                <Box style={{ position: 'absolute', zIndex: 1, paddingLeft: 160 }}>
                  <SvgCircle width="198" height="178" r="63" cx="70" cy="50" color={'#FFA400'} />
                </Box>
                <Box style={{ position: 'absolute', zIndex: 2, paddingLeft: 65, paddingTop: 54 }}>
                  <img src={LADY_IMAGE}></img>
                </Box>
                <Box style={{ position: 'absolute', zIndex: 2, paddingLeft: 340, bottom: 75 }}>
                  <img src={blueCircle}></img>
                </Box>
                <Box style={{ position: 'absolute', zIndex: 2, paddingLeft: 375, top: 65 }}>
                  <img src={referralSnake}></img>
                </Box>
              </Grid>
              <Grid lg={5} sm={5} md={5} item style={{ flex: 1 }}>
                <Typography className={classes.inviteHeading}>
                  {REFER_AND_EARN.INVITE_HEADING}
                </Typography>
                <Typography className={classes.referralCode}>
                  {REFER_AND_EARN.REFERRAL_CODE_TEXT}
                </Typography>
                <Typography className={classes.referralCodeStyle}>{referralCodeText}</Typography>

                {navigator.share ? (
                  <Button
                    className={classes.referralBtn}
                    color="primary"
                    variant="contained"
                    onClick={() => handleDrawerOpen('share_referral_code')}
                  >
                    {REFER_AND_EARN.SHARE_INVITE_CODE}
                  </Button>
                ) : (
                  <RWebShare
                    data={{
                      title: SHARE_REFERRAL.TITLE,
                      text: SHARE_REFERRAL.CONTENT(referrerName, referralCodeText),
                      url: `${process.env.REFERRAL_DEEP_LINK}?referralCode=${referralCodeText}`,
                    }}
                    sites={['facebook', 'mail', 'copy']}
                    style={{ width: '100%' }}
                  >
                    <Button className={classes.referralBtn} color="primary" variant="contained">
                      {REFER_AND_EARN.SHARE_INVITE_CODE}
                    </Button>
                  </RWebShare>
                )}

                <Drawer
                  side="bottom"
                  open={openDrawer}
                  handleDrawerOpen={handleDrawerOpen}
                  onClick={handleDrawerClick}
                />
              </Grid>
              <Grid lg={3} sm={3} md={3} item className={classes.middleCardFirst}>
                <Box style={{ position: 'absolute', right: 0, paddingTop: 196 }}>
                  <SvgCircle width="218" height="188" r="144" cx="184" cy="144" color="#00A0DF" />
                </Box>
                <Box style={{ position: 'absolute', zIndex: 2, right: 0 }}>
                  <SvgCircle width="148" height="178" r="38" cx="130" cy="124" color={'#DB0032'} />
                </Box>
                <Box style={{ position: 'absolute', zIndex: 2, top: 121, right: 115 }}>
                  <img src={redCross}></img>
                </Box>
                <Box style={{ position: 'absolute', zIndex: 2, right: 235, bottom: 50 }}>
                  <img src={greenCross}></img>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item sm={12} md={12}>
          <div style={{ backgroundColor: '#FFFFFF', paddingTop: '24px', borderRadius: 8 }}>
            <Typography className={classes.stepperHeading}>
              {REFER_AND_EARN.INSTRUCTION_HEADING}
            </Typography>
            <Stepper
              style={{ borderRadius: 8, padding: 24 }}
              activeStep={3}
              connector={<ColorlibConnector />}
              alternativeLabel
            >
              {REFERRAL_STEPS.map((label) => (
                <Step completed={false} className={classes.horizontal} key={label}>
                  <StepLabel
                    classes={{
                      root: classes.iconRoot,
                      label: classes.label,
                      alternativeLabel: classes.alternativeLabel,
                    }}
                    StepIconComponent={ColorlibStepIcon}
                  >
                    <Typography className={classes.stepText}> {label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </Grid>
      </Grid>
      {renderProfileUpdateModal()}
    </Container>
  );
};

export default withRouter(NewReferralScreen);
