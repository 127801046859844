import * as React from 'react';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import EventIcon from '@mui/icons-material/Event';
import Typography from '@mui/material/Typography';
import { CONFIRMATION_MODAL } from '../../../../utils/helper';
import SecondaryBtnComponent from './SecondaryBtnComponent';
import BtnComponent from './BtnComponent';

const ConfirmationModal = ({
  onClose,
  setShowModal,
  open,
  viewDetailsClick,
  cancel,
  cancelYesClick,
  detailsDate,
  title,
  heading,
  description,
  message,
  setShowPage,
  success,
  ...props
}) => {
  const marginBottom = { mb: '28px' };

  React.useEffect(() => {
    setShowPage(true);
  }, [setShowPage]);

  return (
    <Dialog
      open={open}
      {...props}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{ sx: { borderRadius: '16px' } }}
    >
      <>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: '0', right: '0' }}>
          <CloseIcon />
        </IconButton>

        {cancel ? (
          <div style={{ padding: '24px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="h1" sx={marginBottom}>
                {heading}
              </Typography>
              <Typography variant="h3" sx={marginBottom}>
                {title}
              </Typography>
              <Typography variant="p" color="#808080" sx={marginBottom}>
                {description}
              </Typography>
            </div>
            <div style={{ display: 'flex' }}>
              <SecondaryBtnComponent
                title={CONFIRMATION_MODAL.BUTTON_TEXT.NO}
                onClick={onClose}
                sx={{ width: '164px', marginRight: '24px' }}
              />
              <BtnComponent
                title={CONFIRMATION_MODAL.BUTTON_TEXT.YES}
                onClick={cancelYesClick}
                sx={{ width: '164px' }}
              />
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '40px 20px',
              }}
            >
              {success ? (
                <CheckCircleIcon sx={{ fontSize: 80, color: '#27AE60' }} />
              ) : (
                <CancelIcon color="error" sx={{ fontSize: 80 }} />
              )}

              <h2>{message}</h2>
              {success && (
                <>
                  <Typography variant="h1" sx={{ mt: 0 }} data-testid="heading">
                    {heading}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: 1,
                    }}
                  >
                    <EventIcon color="disabled" fontSize="medium" />
                    <Typography variant="h6" sx={{ marginLeft: '8px' }}>
                      {detailsDate}
                    </Typography>
                  </Box>
                </>
              )}
              <BtnComponent
                title={CONFIRMATION_MODAL.BUTTON_TEXT.VIEW_DETAILS}
                onClick={viewDetailsClick}
                sx={{ marginTop: '40px' }}
              />
            </div>
          </>
        )}
      </>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ConfirmationModal;
