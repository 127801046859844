import { regex, ERROR_MSGS } from './constants';
import { isEmpty } from './helper';
import tldList from 'tld-list';

export const validateEmail = (email = '') => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });
  const { verifyEmail: EMAIL_REGEX } = regex;

  if (!email || !EMAIL_REGEX.test(email)) {
    result.isValid = false;
    result.errorMessage = email ? ERROR_MSGS.EMAIL.INVALID : ERROR_MSGS.EMAIL.EMPTY;
  } else {
    const tld = email.slice(email.lastIndexOf('.') + 1);
    if (!tldList.includes(tld.toLocaleLowerCase())) {
      result.isValid = false;
      result.errorMessage = ERROR_MSGS.EMAIL.INVALID;
    }
  }

  return result;
};

export const validatePassword = (password = '', passwordStrength = 0, passwordErrorInfo = '') => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });

  if (!password || password.length < 8) {
    result.isValid = false;
    result.errorMessage = password ? ERROR_MSGS.PASSWORD.LEN_ERR_8 : ERROR_MSGS.PASSWORD.EMPTY;
  } else if (passwordStrength < 4) {
    result.isValid = false;
    result.errorMessage = passwordErrorInfo || ERROR_MSGS.PASSWORD.LOW_STRENGTH;
  }

  return result;
};

export const validateRePassword = (password = '', rePassword = '') => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });

  if (!rePassword || rePassword !== password) {
    result.isValid = false;
    result.errorMessage = rePassword
      ? ERROR_MSGS.RE_PASSWORD.MATCH_ERR
      : ERROR_MSGS.RE_PASSWORD.EMPTY;
  }

  return result;
};

export const validateFirstName = (firstName = '') => {
  const result = Object.create({
    isValid: false,
    errorMessage: '',
  });

  const { verifyFirstName: NAME_REGEX } = regex;

  if (!firstName || !firstName.replace(/\s/g, '').length) {
    result.errorMessage =  ERROR_MSGS.FIRST_NAME.EMPTY;
  } else if (!NAME_REGEX.test(firstName)) {
    result.errorMessage = ERROR_MSGS.FIRST_NAME.INVALID;
  } else {
    result.isValid = true;
  }
  return result;
};

export const validateLastName = (lastName = '') => {
  const result = Object.create({
    isValid: false,
    errorMessage: '',
  });
  const { verifyLastName: NAME_REGEX } = regex;
  if (!lastName || !lastName.replace(/\s/g, '').length) {
    result.errorMessage =  ERROR_MSGS.LAST_NAME.EMPTY;
  } else if (!NAME_REGEX.test(lastName)) {
    result.errorMessage = ERROR_MSGS.LAST_NAME.INVALID;
  } else {
    result.isValid = true;
  }
  return result;
};

export const validateDOB = (date) => {
  const result = Object.create({
    isValid: false,
    errorMessage: '',
  });
  const originalDate = new Date(date);
  const month = originalDate.getMonth() + 1;
  const day = originalDate.getDate();
  const year = originalDate.getFullYear();
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;
  const { verifyDOB: DATE_REGEX } = regex;
  if (!formattedDate) {
    result.errorMessage =  ERROR_MSGS.DOB.EMPTY;
  } else if (!DATE_REGEX.test(formattedDate)) {
    result.errorMessage = ERROR_MSGS.DOB.INVALID;
  } else {
    result.isValid = true;
  }
  return result;
};

export const validateFullName = (fullName) => {
  const result = Object.create({
    isValid: false,
    errorMessage: '',
  });
  const { verifyFullName: FULL_NAME_REGEX } = regex;

  if (!fullName || !fullName.replace(/\s/g, '').length) {
    result.errorMessage = ERROR_MSGS.FULL_NAME.EMPTY;
  } else if (!FULL_NAME_REGEX.test(fullName)) {
    result.errorMessage = ERROR_MSGS.FULL_NAME.INVALID ;
  } else {
    result.isValid = true;
  }
  return result;
};
export const validatePreferredName = (name) => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });
  const { verifyPreferredName: FULL_NAME_REGEX } = regex;
  if (!FULL_NAME_REGEX.test(name)) {
    result.isValid = false;
    result.errorMessage = name ? ERROR_MSGS.FULL_NAME.INVALID : ERROR_MSGS.FULL_NAME.EMPTY;
  }
  return result;
};
export const isFirstNameValid = (firstName = '') => {
  return !isEmpty(firstName);
};

export const isLastNameValid = (lastName = '') => {
  return !isEmpty(lastName);
};

export const isDateValid = (date = '') => {
  return !isEmpty(date);
};

export const isZipValid = (zipCode = '') => {
  return !isEmpty(zipCode);
};
export const validateField = (field = '', label) => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });

  if (!field) {
    result.isValid = false;
    result.errorMessage = label ? `${label} ${ERROR_MSGS.REQUIRED}` : ERROR_MSGS.FIELD_REQUIRED;
  }
  return result;
};
export const validateZip = (zipCode = '') => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });
  const { verifyUSZip: ZIP_REGEX } = regex;

  if (!zipCode || !ZIP_REGEX.test(zipCode)) {
    result.isValid = false;
    result.errorMessage = zipCode ? ERROR_MSGS.ZIP_CODE.INVALID : ERROR_MSGS.ZIP_CODE.EMPTY;
  }
  return result;
};
export const validateUSPhone = (phone = '') => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });
  const { verifyUSFormatNumber: USPhone_REGEX } = regex;

  if (!phone || !USPhone_REGEX.test(phone)) {
    result.isValid = false;
    result.errorMessage = phone ? ERROR_MSGS.PHONE_NO.SHOULD_TEN_DIGIT : ERROR_MSGS.PHONE_NO.EMPTY;
  }
  return result;
};
export const validatePhone = (phone = '') => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });
  const { verifyNumber: Phone_REGEX } = regex;

  if (!phone || !Phone_REGEX.test(phone)) {
    result.isValid = false;
    result.errorMessage = phone ? ERROR_MSGS.PHONE_NO.SHOULD_TEN_DIGIT : ERROR_MSGS.PHONE_NO.EMPTY;
  }
  return result;
};
export const validateAllDigits = (
  value = '',
  fieldRequiredErr = ERROR_MSGS.FIELD_REQUIRED,
  invalidErr = ERROR_MSGS.INVALID_VALUE,
) => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });
  const { allDigits: allDigits_Regex } = regex;

  if (!value || !allDigits_Regex.test(value)) {
    result.isValid = false;
    result.errorMessage = value ? invalidErr : fieldRequiredErr;
  }
  return result;
};
export const validateRoutingNumber = (value = '') => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });
  const { routingNumber: routingNo_REGEX } = regex;

  if (!value || !routingNo_REGEX.test(value)) {
    result.isValid = false;
    result.errorMessage = value
      ? ERROR_MSGS.ROUTING_NUMBER.INVALID
      : ERROR_MSGS.ROUTING_NUMBER.EMPTY;
  }
  return result;
};
export const validateAccountNumber = (value = '') => {
  const result = Object.create({
    isValid: true,
    errorMessage: '',
  });
  const { accountNumber: accountNo_REGEX } = regex;

  if (!value || !accountNo_REGEX.test(value)) {
    result.isValid = false;
    result.errorMessage = value
      ? ERROR_MSGS.ACCOUNT_NUMBER.INVALID
      : ERROR_MSGS.ACCOUNT_NUMBER.EMPTY;
  }
  return result;
};

export const validatePositiveNumericValue = (value = '') => {
  return /^\d+$/.test(value);
};
