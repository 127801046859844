import React, { useState } from 'react';
import { withStyles } from '@mui/styles';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { KeyboardArrowDown } from '@mui/icons-material';
import clsx from 'clsx';
import { Fragment } from 'react';
import {  getStatusFilterParamListForReusableComponents } from '../../../../../utils/helper';

const style = (theme) => ({
  dropDownWrapper: {
    minWidth: 160,
    marginRight: '12px',
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#4B4B4B',
    borderRadius: 8,
    backgroundColor: '#fff',
    // boxShadow: '0px 2px 10px rgba(0,0,0,0.08)',
    '& .MuiInputBase-root': {
      height: '100%',
      width: '100%',
      fontSize: 13,
      fontWeight: 600,
      letterSpacing: 0.62,
    },
    '& .MuiSelect-root': {
      height: '100%',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'end',
        gap: '8px 0px',
      },
    },
    '& .MuiSelect-select:focus': {
      background: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  listTag: {
    flex: 1,
    height: 40,
  },
  selectRoot: {
    fontSize: '16px',
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },
  dropDownMenuList: {
    fontSize: '16px',
    paddingLeft: 0,
    paddingRight: 0,
    '& .MuiMenuItem-root': {
      paddingRight: '12px',
    },
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 40,
    paddingLeft: 12,
    paddingRight: 12,
  },
  img: { width: 8, height: 8, marginRight: 8 },
  muiIcon: { width: 12, height: 12, marginRight: 4 },
  label: {
    height: 24,
    padding: 0,
    paddingLeft: 5,
    display: 'flex',
    alignItems: 'center',
    margin: 0,
  },
  filterType: {
    lineHeight: 0,
    paddingLeft: 8,
    minWidth: 'max-content',
    fontSize: 14,
    fontWeight: 600,
    color: '#737373',
  },
  dropDownContainer: { display: 'flex', alignItems: 'center', height: '40px' },
  statusIcon: { width: '8px', height: '8px', borderRadius: '50%', marginRight: '4px' },
  statusColumn: {
    display: 'flex',
    alignItems: 'center',
  },
});

const GenericFilters = (props) => {
  const { classes, handleFilterChange, filterItem, filterContainerClass } = props;
  const { options, label, requestKey, defaultActiveIndex, width } = filterItem;
  const [isOpen, setOpen] = useState(false);

  const onChange = (val) => {
    const { name } = val.target;
    if (name === 'statusFilter') {
      const status = getStatusFilterParamListForReusableComponents(val.target.value, filterItem.options);
      handleFilterChange({ ...val, target: { ...val.target, value: status } });
    } else {
      handleFilterChange(val);
    }
  };

  return (
    <Fragment>
      <FormControl
        className={clsx([classes.dropDownWrapper, filterContainerClass])}
        style={{ width: width }}
      >
        {props.inputLabel && <InputLabel id="inputLabel">{props.inputLabel}</InputLabel>}
        <div className={classes.dropDownContainer}>
          <p className={classes.filterType}>{label || ''}</p>
          <Select
            MenuProps={{
              classes: { select: classes.selectRoot, list: classes.dropDownMenuList },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              MenuListProps: {
                disablePadding: true,
              },
              getContentAnchorEl: null,
            }}
            onClick={() => {
              setOpen(!isOpen);
            }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={options[defaultActiveIndex]?.value}
            // disableunderline
            open={isOpen}
            name={requestKey}
            onChange={onChange}
            {...props}
            IconComponent={KeyboardArrowDown}
          >
            {options?.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value} className={classes.listTag}>
                  <div className={classes.labelWrapper}>
                    {option?.iconColor && (
                      <span
                        style={{ backgroundColor: option?.iconColor }}
                        className={classes.statusIcon}
                      ></span>
                    )}
                    <p className={classes.label}>{option.label || option.value}</p>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </FormControl>
    </Fragment>
  );
};
export default withStyles(style)(GenericFilters);
