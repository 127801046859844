import { Grid, Typography } from '@mui/material';
import React from 'react';
import { DrawerBoxComponent } from './DrawerBoxComponent';
// import { APPOINTMENTS } from '../../shared/Strings';

export const AppointmentDateTimeSummary = ({ dateTime }) => {
  return (
    <DrawerBoxComponent>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="h3"> Date Time</Typography>
        </Grid>
        <Grid item>
          <Typography variant="p">Sat, January 6, 12:00 PM</Typography>
        </Grid>
      </Grid>
    </DrawerBoxComponent>
  );
};
