// import React from 'react';
import AwardPoints from '../assets/images/awardpoint-rounded.svg';
import Bills from '../assets/images/bills.svg';
import SecondOpinion from '../assets/images/second_opinion.svg';
import { HOME } from './constants';
import { replaceImageProperty } from './helper';
import PwaAwardPoints from '../assets/images/pwaAwardPoints.svg';
import PwaBills from '../assets/images/pwaBills.svg';
import PwaSecondOpinions from '../assets/images/pwaSecondOpinions.svg';

const ICONS = [SecondOpinion, Bills, AwardPoints];
const PWA_ICONS = [PwaSecondOpinions, PwaBills, PwaAwardPoints];

const get = () => replaceImageProperty(HOME, ICONS, PWA_ICONS);
const homeIconsGetter = { get };

export default homeIconsGetter;
