import React, { useState, useEffect, useContext } from 'react';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import StatusIcon from '@mui/icons-material/FiberManualRecord';
import {
  fetchStatusObj,
  capitalizeFirstLetter,
  getFileNameAndExtension,
  checkOneTimeUser,
} from '../../utils/helper';
import { withStyles } from '@mui/styles';
import MarkRestrictedCard from '../molecules/SelfReporting/MarkRestrictedCard';
import { Card, CardContent, CardActions, Typography, Grid, Box, Icon } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import IconButton from '@mui/material/IconButton';
import { useNavigate, useParams } from 'react-router-dom';
import ConditionsService from '../../service/ConditionsService';
import Loader from '../atoms/Loader';
import {
  DEFAULT_NULL_REPLACEMENT,
  OPTIONS_VIEW,
  SELF_REPORTING_TEXTS,
  SNACKBAR_TYPE,
} from '../../utils/constants';
import ProceduresService from '../../service/ProceduresService';
import DiagnosticReportsService from '../../service/LabResultsService';
import ImmunizationsService from '../../service/ImmunizationService';
import AllergiesService from '../../service/AllergiesService';
import colors from '../../utils/colors';
import LaunchDocumentImg from '../../assets/images/launchDocument.png';
import DocumentService from '../../service/DocumentService/index.js';
import MedicationsService from '../../service/MedicationsService';
import CoverageService from '../../service/CoverageService';
import SnackbarToast from '../organisms/SnackbarToast';
import { carePlanService } from '../../pages/CarePlan';
import { markAsRestrictedCareTeam } from '../../pages/CareTeam/careTeamService';
import UserCircle from '../../assets/images/defaultAvatarBlack.svg';
import LaunchIcon from '@mui/icons-material/Launch';
import useGetScreenMode from '../../hooks/useGetScreenMode';
import { markAsRestrictedFamilyMemberHistory } from '../../pages/FamilyMemberHistory/FamilyHistoryServices';
import ClaimService from '../../service/ClaimsService';
import VisitsService from '../../service/VisitsService';
import VitalService from '../../service/VitalsService';
// import { LaunchIcon } from '@mui/material';
import CARE_TEAM_ICON from '../../assets/images/icons/careTeamIcon.svg';
import Bill from '../../service/Bill';
import ProviderDetails from '../../features/dynamicRecords/components/ProviderDetails';
import { UserInfoContext } from '../Routes';

const styles = (theme) => ({
  editButton: {
    marginRight: 24,
    color: '#036FCB',
    border: '1px solid #A0A4A6',
    padding: '6px 16px',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: 14,
    width: 74,
    borderRadius: 40,
    boxSizing: 'border-box',
    textAlign: 'center',
    '&:hover': {
      background: '#036FCB',
      color: '#fff',
    },
  },
  heading1: {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: theme.spacing(2),
    fontWeight: 600,
    lineHeight: '20px',
    margintTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
  cardRoot: {
    border: '1px solid #E4E4E4',
    borderRadius: theme.spacing(1.5),
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    height: 56,
    width: 280,
    cursor: 'pointer',
  },
  careTeamCardRoot: {
    border: '1px solid #E4E4E4',
    borderRadius: theme.spacing(1.5),
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'space-between',
    width: 280,
    cursor: 'pointer',
  },
  careTeamCardGrid: {
    paddingRight: 16,
    paddingBottom: 16,
  },
  optionsContainer: {
    marginTop: 20,
  },
  documentWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: -24,
  },
  documentUrl: {
    color: colors.BLUE,
    fontSize: 16,
    fontWeight: 500,
    marginRight: 40,
  },
  launchDocumentIcon: { marginLeft: 8, position: 'relative', top: 3, cursor: 'pointer' },
  subTitle: {
    fontWeight: 600,
    fontSize: 18,
    color: colors.BLACK_B900,
    lineHeight: '24px',
  },
  careTeamCategory: {
    lineHeight: 0,
    fontSize: '18px',
    color: '#667085',
    fontWeight: 600,
    fontFamily: 'Inter',
    lineHeight: '0px',
  },
  PopUpWrapper: {
    maxWidth: '640px',
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '24px',
    padding: '32px',
    fontFamily: 'Inter',
    position: 'fixed',
    right: 24,
    top: 24,
    bottom: 24,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      right: 0,
      padding: '20px',
    },
  },
  column_name: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    width: '100%',
    height: '20px',
    color: '#828282',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    order: 0,
    flex: 'none',
    flexGrow: 0,
    margin: '4px 0px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
});
const PopUpModalStyle = {
  heading: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    wordBreak: 'break-word',
    letterSpacing: '0px',
    weight: 600,
    fontSize: '28px',
    lineHeight: '32px',
    color: '#000000',
  },
  icon: {
    height: '32px',
    width: '32px',
  },
  column_value: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1F1F1F',
    order: 1,
    // flexGrow: 0,
    flex: 'none',
    margin: '4px 0px',
    wordWrap: 'break-word',
    maxWidth: '100%',
  },
  status_value: {
    boxSizing: 'border-box',
    paddingRight: 10,
    marginTop: 2,
  },
  dataSource: {
    background: '#E4E4E4',
    border: '1px solid #C0C0C0',
    boxSizing: 'border-box',
    borderRadius: '24px',
    color: '#1F1F1F',
    fontWeight: 500,
    fontSize: '11px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: 4,
    padding: '0px 8px',
    minWidth: 100,
    display: 'inline-block',
  },
  secondRow: {
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  wrapper: {
    borderRadius: '8px',
    height: '296px',
    padding: '0px 24px',
  },
  services_wrapper: {
    borderRadius: '8px',
    width: '456px',
    padding: '0px 24px',
    height: '152px',
  },
  flexBox: {
    display: 'flex',
    flex: 1,
  },
  flexContent: {
    flex: '33%',
  },
  scrollY: {
    overflow: 'scroll',
    border: 'none',
  },
  colWidth: (width) => {
    return width === 'halfWidth' || width === 'halfwidth'
      ? '50%'
      : width === 'fullWidth' || width === 'fullwidth'
      ? '100%'
      : width === 'twoThird' || width === 'twothird'
      ? '66%'
      : width === 'oneThird' || width === 'onethird'
      ? '33%'
      : '33%';
  },
  services_column_name: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    color: '4B4B4B',
  },
  services_column_value: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    display: 'flex',
    alignItems: 'center',
    color: '#828282',
  },
  services_container: {
    padding: '0px',
  },
  services_heading: {
    color: '#000000',
    lineHeight: '16px',
    fontSize: 16,
    fontWeight: 600,
  },
  PopUptitleBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '40px',
    padding: 0,
  },
  PopUptitleBar_heading_sec: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'flex-start',
    marginBottom: 12,
  },
  iconWrapper: { marginRight: '12px' },
  careTeamIconWrapper: {
    paddingRight: 8,
  },
  closeButton: {
    height: 'fit-content',
    width: '110px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  status_icon: { width: '8px', height: '8px', marginRight: '8px' },
  statusIcon: { width: 12, height: 12 },
  statusContainer: { display: 'flex', alignItems: 'center' },
  statusColumn: { display: 'flex', alignItems: 'center', marginRight: 4 },
  subTitle: {
    fontWeight: 600,
    fontSize: 18,
    color: colors.BLACK_B900,
    lineHeight: '24px',
    marginTop: 12,
  },
  bgColorForInitial: (bgColor) => ({
    backgroundColor: bgColor,
    fontWeight: 600,
    fontFamily: 'Inter',
    fontSize: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
    width: 48,
    borderRadius: '50px',
    margin: '-8px 8px 0px 0px',
    lineHeight: 0,
  }),
};

export const renderStatusView = (status, color) => {
  const statusObj =
    status && status !== '-' ? fetchStatusObj(status, color) : { status: '-', color: null };
  return (
    <div style={PopUpModalStyle.statusContainer}>
      {statusObj.color && (
        <span style={PopUpModalStyle.statusColumn}>
          <StatusIcon htmlColor={statusObj.color} style={PopUpModalStyle.statusIcon} />
        </span>
      )}
      <p style={{ ...PopUpModalStyle.column_value, ...PopUpModalStyle.status_value }}>
        {statusObj.status}
      </p>
    </div>
  );
};

function PopUpModal(props) {
  const {
    setOpen,
    data,
    documents,
    title,
    icon,
    showEdit = false,
    hideOptions = false,
    handleEdit,
    markAsRestricted,
    historicalRecordsParamsObj,
    restrictedDescription,
    clinicalRecord,
    currentObj,
    customComponent,
    customBodyComponent,
    classes,
    careTeamViewFlag,
    careTeamCategory = '',
    bgColorForInitial = '',
  } = props;

  const [hideHistoricalRecordsOption, setHideHistoricalRecordsOption] = useState(false);
  const [careTeamView, setCareteamView] = useState(false);
  const [careTeamViewData, setCareTeamViewData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderText, setLoaderText] = useState(SELF_REPORTING_TEXTS.PLEASE_WAIT);
  const [restricted, setRestricted] = useState(markAsRestricted);
  // const [documentsInfo, setDocumentsInfo] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState(SNACKBAR_TYPE.SUCCESS);
  const screenMode = useGetScreenMode();
  const isOneTimeUser = checkOneTimeUser();
  const { partner, grantorId, granteeId } = useParams();
  const { userInfo, setUserInfo } = useContext(UserInfoContext);
  const betaUser = userInfo && userInfo.betaUser;

  useEffect(() => {
    if (!historicalRecordsParamsObj || !historicalRecordsParamsObj.code) {
      setHideHistoricalRecordsOption(true);
    } else {
      setHideHistoricalRecordsOption(false);
    }
  }, [historicalRecordsParamsObj]);

  useEffect(() => {
    if (careTeamViewFlag) {
      setCareteamView(true);
      setCareTeamViewData(customBodyComponent.careTeam);
    } else {
      setCareteamView(false);
      setCareTeamViewData('');
    }
  }, [careTeamViewFlag]);
  // useEffect(() => {
  //   // fetch documents binary data
  //   if (documents && documents.length > 0) {
  //     constructDocumentUrls(documents);
  //   }
  // }, [documents]);

  // const constructDocumentUrls = async (data) => {
  //   const promises = [];
  //   data.forEach(async (docArn) => {
  //     promises.push(DocumentService.downloadFile(docArn));
  //   });
  //   return Promise.all(promises).then((res) => {
  //     const documentsArr = [];
  //     res.forEach((obj, i) => {
  //       const url = window.URL.createObjectURL(new Blob([obj.data]));
  //       documentsArr.push({ url: url, binaryData: obj.data, docArn: data[i] });
  //     });
  //     setDocumentsInfo(documentsArr);
  //   });
  // };

  const navigate = useNavigate();

  const handleHistoricalRecordsClick = () => {
    if (historicalRecordsParamsObj && historicalRecordsParamsObj.resourceType) {
      if (historicalRecordsParamsObj.partner) {
        // history.push({
        //   pathname: `/circles/${historicalRecordsParamsObj.resourceType}/historicalRecords`,
        //   state: { resourceData: historicalRecordsParamsObj },
        // });
        navigate(`/circles/${historicalRecordsParamsObj.resourceType}/historicalRecords`, {
          state: { resourceData: historicalRecordsParamsObj },
        });
      } else {
        // history.push({
        //   pathname: `/records/${historicalRecordsParamsObj.resourceType}/historicalRecords`,
        //   state: { resourceData: historicalRecordsParamsObj },
        // });
        navigate(`/circles/${historicalRecordsParamsObj.resourceType}/historicalRecords`, {
          state: { resourceData: historicalRecordsParamsObj },
        });
      }
    }
  };

  const handleRedirectToCareTeam = (linkObj) => {
    let url = '/records/care_team';
    if (screenMode.isCircle) {
      url = `/circles/${partner}/care_team`;
    } else if (screenMode.involvesConsent || isOneTimeUser) {
      url = `/${
        screenMode.isCircle ? 'circles' : 'records'
      }/individual/care_team/${grantorId}/${granteeId}`;
    }
    const careTeamId = linkObj?.reference?.split('/')[1];
    if (careTeamId) {
      navigate(url, { state: { careTeamId } });
    }
  };

  const OptionsView = () => {
    return (
      <div className={classes.optionsContainer}>
        {!hideHistoricalRecordsOption && (
          <div>
            <Typography className={classes.heading1}>{OPTIONS_VIEW.OPTIONS}</Typography>
            <Card className={classes.cardRoot} onClick={handleHistoricalRecordsClick}>
              <CardContent>
                <Typography>{OPTIONS_VIEW.HISTORICAL_RECORDS}</Typography>
              </CardContent>
              <CardActions>
                <IconButton>
                  <ChevronRightIcon />
                </IconButton>
              </CardActions>
            </Card>
          </div>
        )}
        <MarkRestrictedCard
          description={restrictedDescription}
          enabled={restricted}
          onChange={(val) => markAsRestrictedCall(val)}
        />
      </div>
    );
  };

  const CareTeamView = () => {
    return (
      careTeamViewData && (
        <div className={classes.optionsContainer}>
          <div>
            <Typography className={classes.heading1}>Care team</Typography>
            <Grid container>
              {careTeamViewData &&
                careTeamViewData.map((data, index) => {
                  const category = JSON.parse(data.display)?.category?.replaceAll(/[\[\],]+/g, '');

                  return (
                    <Grid className={classes.careTeamCardGrid} item key={index}>
                      <Card className={classes.careTeamCardRoot}>
                        <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={PopUpModalStyle.careTeamIconWrapper}>
                            <img
                              src={CARE_TEAM_ICON}
                              alt="cap"
                              style={PopUpModalStyle.icon}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = UserCircle;
                              }}
                            />
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography style={{ fontSize: 14, color: '#344054' }}>
                              {JSON.parse(data?.display)?.name ?? DEFAULT_NULL_REPLACEMENT}
                            </Typography>
                            <Typography style={{ fontWeight: 400, color: '#667085' }}>
                              {category ?? DEFAULT_NULL_REPLACEMENT}
                            </Typography>
                          </div>
                        </CardContent>
                        <CardActions>
                          <IconButton onClick={() => handleRedirectToCareTeam(data)}>
                            <LaunchIcon style={{ color: '#667085', fontSize: 22 }} />
                          </IconButton>
                        </CardActions>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </div>
      )
    );
  };

  const BillsHeaderBox = () => {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          width: 576,
          height: 144,
          borderRadius: 8,
          backgroundColor: '#f8f8f8',
        }}
      >
        <Typography style={{ fontWeight: 500, fontSize: 16, lineHeight: '24px', color: '#666666' }}>
          Amount paid
        </Typography>
        <Typography style={{ fontWeight: 600, fontSize: 28, lineHeight: '40px', color: '#344054' }}>
          $455.5
        </Typography>
        <Typography style={{ fontWeight: 400, fontSize: 14, lineHeight: '20px', color: '#000000' }}>
          <StatusIcon htmlColor="green" style={{ paddingRight: 5, width: 12, height: 12 }} />
          Paid on Mar, 26,2021
        </Typography>
      </Box>
    );
  };

  const markAsRestrictedCall = async (currentState) => {
    setIsLoading(true);
    try {
      switch (clinicalRecord) {
        case 'Condition':
          await ConditionsService.updateMarkAsRestrictedCondition(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Medication':
          switch (historicalRecordsParamsObj.medicationCategoryType) {
            case 'MedicationStatement':
              await MedicationsService.markAsRestrictedMedicationStatement(
                currentObj.id,
                currentState === true ? 'restricted' : 'unrestricted',
              );
              break;
            case 'MedicationDispense':
              await MedicationsService.markAsRestrictedMedicationDispense(
                currentObj.id,
                currentState === true ? 'restricted' : 'unrestricted',
              );
              break;
            default:
              await MedicationsService.markAsRestrictedMedicationRequest(
                currentObj.id,
                currentState === true ? 'restricted' : 'unrestricted',
              );
              break;
          }
          break;
        case 'Procedure':
          await ProceduresService.updateMarkAsRestrictedProcedures(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Lab Result':
          await DiagnosticReportsService.updateMarkAsRestrictedDiagnosticReport(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Immunization':
          await ImmunizationsService.updateMarkAsRestrictedImmunizations(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Allergy':
          await AllergiesService.updateMarkAsRestrictedAllergyIntolerance(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Coverage':
          await CoverageService.markAsRestrictedCoverage(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Care plan':
          await carePlanService.markAsRestricted(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Care team':
          await markAsRestrictedCareTeam(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Family history':
          await markAsRestrictedFamilyMemberHistory(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Claims':
          await ClaimService.markAsRestrictedClaims(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Visits':
          await VisitsService.markAsRestrictedVisit(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Social History':
          await VitalService.markAsRestrictedObservation(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        case 'Bills':
          await Bill.markAsRestrictedBillsRequest(
            currentObj.id,
            currentState === true ? 'restricted' : 'unrestricted',
          );
          break;
        default:
          break;
      }
      setRestricted(currentState);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
    setLoaderText('');
  };

  const openDocument = async (documentObj) => {
    const fileInfo = getFileNameAndExtension(documentObj.docArn);
    const link = document.createElement('a');
    link.href = documentObj.url;
    link.setAttribute('download', `${fileInfo.fileNameWithoutExtension}.${fileInfo.extension}`);
    document.body.appendChild(link);
    link.click();
  };

  const downloadDocument = async (docArn) => {
    setSnackbarMessage('Downloading document ...');
    setSnackbarOpen(true);
    setSnackbarType(SNACKBAR_TYPE.INFO);
    try {
      const res = await DocumentService.downloadFile(docArn);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      openDocument({ url: url, binaryData: res.data, docArn: docArn });
      setSnackbarMessage('Downloaded document successfully');
      setSnackbarOpen(true);
      setSnackbarType(SNACKBAR_TYPE.SUCCESS);
    } catch (err) {
      setSnackbarMessage('Something went wrong while downloading the document');
      setSnackbarOpen(true);
      setSnackbarType(SNACKBAR_TYPE.ERROR);
    }
  };

  const renderDocumentName = (docArn) => {
    const fileInfo = getFileNameAndExtension(docArn);
    return <span>{fileInfo.fileName}</span>;
  };

  return (
    <Modal
      keepMounted
      open={true}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      style={PopUpModalStyle.scrollY}
    >
      <div className={classes.PopUpWrapper}>
        {isLoading && <Loader overlay text={loaderText} />}
        <div style={PopUpModalStyle.PopUptitleBar}>
          <div style={PopUpModalStyle.PopUptitleBar_heading_sec}>
            {icon && (
              <div style={PopUpModalStyle.iconWrapper}>
                <img
                  src={icon}
                  alt="cap"
                  style={PopUpModalStyle.icon}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = UserCircle;
                  }}
                />
              </div>
            )}
            {bgColorForInitial && (
              <p style={PopUpModalStyle.bgColorForInitial(bgColorForInitial)}>
                {title[0].toUpperCase()}
              </p>
            )}
            <div>
              <Typography style={PopUpModalStyle.heading}>{title}</Typography>
              {careTeamCategory && <p className={classes.careTeamCategory}>{careTeamCategory}</p>}
            </div>
          </div>
          <div style={PopUpModalStyle.closeButton}>
            {showEdit && !hideOptions && (
              <span
                className={classes.editButton}
                onClick={() => handleEdit({ newRestrictedVal: restricted })}
              >
                Edit
              </span>
            )}

            <CloseIcon onClick={(e) => setOpen(false)} />
          </div>
        </div>
        {customComponent && (
          <div>
            <customComponent.component {...customComponent.props} />
          </div>
        )}
        {/* <BillsHeaderBox /> */}
        <div style={PopUpModalStyle.secondRow}>
          {data &&
            data.map((rowData, i) => {
              if (rowData.hide) {
                return '';
              }
              if (rowData.showSubtitle) {
                return (
                  <div
                    style={{
                      flex: PopUpModalStyle.colWidth('fullWidth'),
                      maxWidth: PopUpModalStyle.colWidth('fullWidth'),
                      boxSizing: 'border-box',
                    }}
                    key={i}
                  >
                    <p className={classes.subTitle}>{rowData.column_name}</p>
                  </div>
                );
              }
              return rowData.column_name ? (
                <div
                  style={{
                    flex: PopUpModalStyle.colWidth(rowData.width),
                    maxWidth: PopUpModalStyle.colWidth(rowData.width),
                    marginBottom: '15px',
                    boxSizing: 'border-box',
                    paddingRight: 10,
                  }}
                  key={i}
                >
                  <p className={classes.column_name}>{rowData.column_name}</p>
                  {rowData.value instanceof Array ? (
                    <div
                      style={{
                        ...PopUpModalStyle.column_value,
                        paddingLeft: '24px',
                      }}
                    >
                      {rowData.column_name === 'Documents' ||
                      rowData.column_name === 'Documents' ? (
                        <div className={classes.documentWrapper}>
                          {rowData.value.map(
                            (val, i) =>
                              (
                                // <span key={val + i} className={classes.documentUrl}>
                                //   Document{parseInt(i)+1}
                                // </span>
                                <span key={val + i} className={classes.documentUrl}>
                                  {renderDocumentName(val)}
                                  {/* {documentsInfo[i] && documentsInfo[i].url && (
                                <img
                                  src={LaunchDocumentImg}
                                  onClick={() => openDocument(documentsInfo[i])}
                                  className={classes.launchDocumentIcon}
                                />
                              )} */}
                                  {/* {documentsInfo[i] && documentsInfo[i].url && ( */}
                                  <img
                                    src={LaunchDocumentImg}
                                    onClick={() => downloadDocument(val)}
                                    className={classes.launchDocumentIcon}
                                  />
                                  {/* )} */}
                                </span>
                              ),
                          ) || '-'}
                        </div>
                      ) : (
                        <ul style={{ margin: '0px', padding: '0px' }}>
                          {rowData.value.map((val, i) => (
                            <li key={i} style={{ lineHeight: '24px' }}>
                              {val}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  ) : rowData.column_name === 'Data source' ||
                    rowData.column_name === 'Data Source' ? (
                    <p
                      style={{
                        ...PopUpModalStyle.column_value,
                        ...PopUpModalStyle.dataSource,
                      }}
                    >
                      {rowData.value}
                    </p>
                  ) : rowData.column_name === 'Status' ||
                    rowData.column_name === 'status' ||
                    rowData.show_status_icon ? (
                    renderStatusView(rowData.value, rowData.status_color)
                  ) : rowData.column_name && rowData.column_name.toLowerCase() === 'subtitle' ? (
                    <p className={classes.subTitle}>{rowData.column_name}</p>
                  ) : (
                    <p style={{ ...PopUpModalStyle.column_value }}>
                      {rowData.capitalize ? capitalizeFirstLetter(rowData.value) : rowData.value}
                    </p>
                  )}
                </div>
              ) : (
                ''
              );
            })}
          <div style={{ width: '100%' }}>{props.children}</div>
        </div>

        { betaUser && clinicalRecord === 'Coverage' &&(<ProviderDetails/>)}

        {customBodyComponent && (
          <div>
            <customBodyComponent.component {...customBodyComponent.props} />
          </div>
        )}

        {/* options ui */}
        {!hideOptions && <OptionsView />}
        {careTeamView && <CareTeamView />}
        {snackbarOpen && (
          <SnackbarToast
            open={snackbarOpen}
            message={snackbarMessage}
            duration={3000}
            onClose={() => setSnackbarOpen(false)}
            alertStyle={{ backgroundColor: '#000000' }}
            type="success"
          />
        )}
      </div>
    </Modal>
  );
}

export default withStyles(styles)(PopUpModal);
