import React from 'react';
import { withStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';

const CustomizedSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 24,
    padding: 0,
    borderRadius: 40,
  },
  switchBase: {
    padding: 1,
    width: 24,
    height: 24,
    '&$checked': {
      transform: 'translateX(16px)',
      color: 'white',
      '& + $track': {
        backgroundColor: '#036FCB',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
    width: 21,
    height: 21,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#D0D0D0',
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const CustomizedSwitches = (props) => {
  const { switchOnOff, onChange } = props;

  return <CustomizedSwitch checked={switchOnOff} onChange={onChange} />;
};

export default CustomizedSwitches;
